import React from "react";
import { useLocation } from "react-router-dom";
import { GenericMenu } from "../types";
import styled from "styled-components";
import { useSignalRConnection } from "../hooks/useConnection";
import { useSignalRDispatch } from "../hooks/useSignalR";
import { ActionType as SignalRActionType } from "../providers/ScadaPlusSignalRProvider";
import config from "config";
import Header from "components/Header";
import MobileHeader from "components/MobileHeader";

const PageGrid = styled.div`
  grid-template-rows: auto repeat(11, 1fr);
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  overflow-y: hidden;
  height: 100%;
`;

const HeaderArea = styled.div<MobileProps>`
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 1;
  grid-column-end: -1;

  background-color: ${(props: MobileProps) =>
    props.mobile
      ? (props) => props.theme.colors.bodyBackgroundColor
      : (props) => props.theme.colors.headerBackgroundColor};
`;

const SubPagesHeader = styled.div`
  grid-row-start: 2;
  grid-row-end: -1;
  grid-column-start: 1;
  grid-column-end: -1;
  padding: ${(props) => props.theme.spacing.medium};
`;

export type ScadaPlusRouteProps = {
  menu: GenericMenu;
  mobile?: boolean;
};

type MobileProps = {
  mobile?: boolean;
};

const RouteComponent: React.FunctionComponent<ScadaPlusRouteProps> = (
  props
) => {
  const location = useLocation();
  const signalRDispatch = useSignalRDispatch();
  const sensorConnection = useSignalRConnection(config.sensorSubscribeURL);
  const alarmConnection = useSignalRConnection(config.alarmSubscribeURL);

  React.useEffect(() => {
    if (sensorConnection && sensorConnection.connection) {
      signalRDispatch({
        connection: sensorConnection.connection!,
        connectionId: sensorConnection.connectionId!,
        type: SignalRActionType.SetSensorConnection,
      });
    }

    return () => {
      if (sensorConnection) {
        signalRDispatch({
          type: SignalRActionType.ClearSensorConnection,
        });
        sensorConnection.connection?.stop();
      }
    };
  }, [sensorConnection, location.pathname, signalRDispatch]);

  React.useEffect(() => {
    if (alarmConnection && alarmConnection.connection) {
      signalRDispatch({
        connection: alarmConnection.connection!,
        connectionId: alarmConnection.connectionId!,
        type: SignalRActionType.SetAlarmConnection,
      });
    }

    return () => {
      if (alarmConnection) {
        signalRDispatch({
          type: SignalRActionType.ClearAlarmConnection,
        });
        alarmConnection.connection?.stop();
      }
    };
  }, [alarmConnection, location.pathname, signalRDispatch]);

  return (
    <>
      {props.mobile ? (
        <>
          <HeaderArea mobile={props.mobile}>
            <MobileHeader menu={props.menu} mobile={props.mobile} />
          </HeaderArea>
          {props.children}
        </>
      ) : (
        <PageGrid>
          <HeaderArea className="headerArea" mobile={props.mobile}>
            <Header menu={props.menu} />
          </HeaderArea>
          <SubPagesHeader>{props.children}</SubPagesHeader>
        </PageGrid>
      )}
    </>
  );
};

const ScadaPlusRouteComponent: React.FunctionComponent<ScadaPlusRouteProps> = (
  props
) => {
  return (
    <RouteComponent mobile={props.mobile} menu={props.menu}>
      {props.children}
    </RouteComponent>
  );
};

export default ScadaPlusRouteComponent;
