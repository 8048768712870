import { ActionMeta, GroupBase, Options, SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import styled from "styled-components";
import { DropdownIndicatorProps } from "react-select/dist/declarations/src/components/indicators";
import { components } from "react-select";
import ArrowDropDownSVG from "../svg/ArrowDropDownSVG";
import Select from "react-select";

const Control = styled.div``;

const Container = styled.div`
  display: inline-block;
  width: 100%;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.textInputBackgroundColor};
  padding: 0%;
  border-radius: ${(props) => props.theme.borderRadius.light};
`;

const customStyles = {
  option: (
    provided: any,
    state: { isSelected: boolean; isFocused: boolean; selectProps: any }
  ) => ({
    ...provided,
    color: "black",
    backgroundColor: state.isSelected
      ? state.selectProps.dropdownselected
      : state.isFocused
      ? state.selectProps.dropdownhover
      : state.selectProps.dropdowncolor,
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    padding: 0,
    display: "none",
  }),
  menu: (provided: any, { selectProps: { dropdowncolor } }: any) => ({
    ...provided,
    backgroundColor: dropdowncolor,
  }),
  menuList: (provided: any, { selectProps: { dropdowncolor } }: any) => ({
    ...provided,
    backgroundColor: dropdowncolor,
    borderRadius: "0 0 0.5rem 0.5rem",
  }),
  control: (
    provided: any,
    { selectProps: { borderColor, dropdownselected } }: any
  ) => ({
    ...provided,
    borderColor: borderColor,
    backgroundColor: "none",
    border: "2",
    borderRadius: "4px",
    "&:focus-within": {
      borderColor: dropdownselected,
      borderWidth: "2px",
    },
    "&:hover": {
      borderColor: null,
    },
    width: "100%",
    boxShadow: "none",
    align: "left",
  }),
  input: (provided: any) => ({
    ...provided,
    width: "100%",
    height: "100%",
    padding: 0,
    borderRadius: "4px",
  }),
};

type DropDownSelectProps = {
  defaultValue?: string | undefined;
  onChange:
    | ((newValue: SingleValue<string>, actionMeta: ActionMeta<string>) => void)
    | undefined;
  onCreate: ((inputValue: string) => void) | undefined;
  options: any;
  selectedOption?: Options<any> | undefined;
  errorColor?: any;
  placeholderSelect?: string;
  dropdownColor?: string;
  dropdownHover?: string;
  dropdownSelected?: string;
};

const DropdownIndicator = (
  props: JSX.IntrinsicAttributes &
    DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownSVG width="16" height="16" color="black" />
      </components.DropdownIndicator>
    )
  );
};

export const DropDownSelect = ({
  onChange,
  onCreate,
  options,
  selectedOption,
  defaultValue,
  placeholderSelect,
  dropdownColor,
  dropdownHover,
  dropdownSelected,
}: DropDownSelectProps): JSX.Element => {
  return (
    <Control>
      <Container>
        {onCreate ? (
          <CreatableSelect
            {...options}
            components={{ DropdownIndicator }}
            defaultInputValue={defaultValue ? defaultValue : undefined}
            value={options.filter(function (options: any) {
              return options.value === selectedOption;
            })}
            searchPlaceHolder="search Personnel..."
            label="Single select"
            placeholder={placeholderSelect}
            onChange={onChange}
            styles={customStyles}
            options={options}
            onCreateOption={onCreate}
            dropdowncolor={dropdownColor}
            dropdownhover={dropdownHover}
            dropdownselected={dropdownSelected}
          />
        ) : (
          <Select
            {...options}
            components={{ DropdownIndicator }}
            defaultInputValue={defaultValue ? defaultValue : undefined}
            value={options.filter(function (options: any) {
              return options.value === selectedOption;
            })}
            searchPlaceHolder="search Personnel..."
            label="Single select"
            placeholder={placeholderSelect}
            onChange={onChange}
            styles={customStyles}
            options={options}
            onCreateOption={onCreate}
            dropdowncolor={dropdownColor}
            dropdownhover={dropdownHover}
            dropdownselected={dropdownSelected}
          ></Select>
        )}
      </Container>
    </Control>
  );
};
