import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import { SensorData } from "hooks/useSubscription";
import { useTagsContext } from "hooks/useTags";
import {
  shouldUsePrimaryStyle,
  shouldUseSignalStyle,
  shouldUseWarningStyle,
} from "utils/production-utils";
type UseGetSVGColorArgs = {
  unitStatusCode: SensorData | null;
  unitOperationCode: SensorData | null;
};
export const useGetSVGColor = ({
  unitOperationCode,
  unitStatusCode,
}: UseGetSVGColorArgs) => {
  const { theme } = useScadaPlusTheme();
  const {
    tags: { WTUROpCde, WTURStCde },
  } = useTagsContext();
  if (unitStatusCode?.id && WTURStCde && WTUROpCde && unitOperationCode) {
    if (shouldUseWarningStyle(unitOperationCode.value, unitStatusCode.value)) {
      return theme.colors.warning;
    } else if (
      shouldUseSignalStyle(unitOperationCode.value, unitStatusCode.value)
    ) {
      return theme.colors.signal;
    } else if (
      shouldUsePrimaryStyle(unitOperationCode.value, unitStatusCode.value)
    ) {
      return theme.colors.mainColor.primary;
    } else {
      return theme.colors.errorCardBackgroundColor;
    }
  } else {
    return "";
  }
};

