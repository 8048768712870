import config from "../config";
import {
  ActiveBoardingLocationTypeCounter,
  ServiceBoarding,
  ObjectType,
} from "../types";
import { useScadaPlusQuery } from "./useScadaPlusQuery";

export const usePersonnelOnSite = (
  plantId: string,
  componentUnitId: string
) => {
  const plant = componentUnitId === "" ? true : false;
  const constructQueryUrl = () => {
    if (plant)
      return `${config.api.url}/${config.api.serviceBoarding}?plantId=${plantId}`;
    else
      return `${config.api.url}/${config.api.serviceBoarding}?ComponentUnitId=${componentUnitId}`;
  };

  return useScadaPlusQuery<Array<ServiceBoarding>>(
    plant ? `boarding-${plantId}` : `boarding-${componentUnitId}`,
    constructQueryUrl()
  );
};

// TODO: Figure out if you need to refetch every 10second. Consider using a query cache key and invalidate the query cache key when new personell information has been added.   
//   >(urlName, url, 10000); --> >(urlName, url);
export const usePersonnelLocationCounter = (
  plantId: string,
  componentUnitId: string
) => {
  const plant = componentUnitId === "" ? true : false;
  const urlName = plant
    ? `${config.api.serviceBoardingCounters}?plantId=${plantId}&isOffshore=true`
    : `${config.api.serviceBoardingCounters}?ComponentUnitId=${componentUnitId}&isOffshore=true`;

  const url = plant
    ? `${config.api.url}/${config.api.serviceBoardingCounters}?plantId=${plantId}&isOffshore=true`
    : `${config.api.url}/${config.api.serviceBoardingCounters}?ComponentUnitId=${componentUnitId}&isOffshore=true`;

  return useScadaPlusQuery<
    ObjectType<Array<ActiveBoardingLocationTypeCounter>>
  >(urlName, url, 10000);
};
