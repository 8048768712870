import { HubConnection } from "@microsoft/signalr";
import React from "react";

type Dispatch = (action: Action) => void;

type Connection = {
  connection: HubConnection;
  connectionId: string;
};

type State = {
  sensorConnection?: Connection;
  alarmConnection?: Connection;
  errorMessageConnection?: Connection;
};

const SignalRContext = React.createContext<State>({});

const SignalRDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

enum ActionType {
  SetSensorConnection = "SetSensorConnection",
  ClearSensorConnection = "ClearSensorConnection",
  ClearAlarmConnection = "ClearAlarmConnection",
  SetAlarmConnection = "SetAlarmConnection",
  SetErrorMessageConnection = "SetErrorMessageConnection",
}

type SetSensorConnection = {
  connection: HubConnection;
  connectionId: string;
  type: ActionType.SetSensorConnection;
};

type SetAlarmConnection = {
  connection: HubConnection;
  connectionId: string;
  type: ActionType.SetAlarmConnection;
};

type SetErrorMessageConnection = {
  connection: HubConnection;
  connectionId: string;
  type: ActionType.SetErrorMessageConnection;
};

type ClearSensorConnection = {
  type: ActionType.ClearSensorConnection;
};

type ClearAlarmConnection = {
  type: ActionType.ClearAlarmConnection;
};

type Action =
  | SetSensorConnection
  | SetAlarmConnection
  | ClearAlarmConnection
  | ClearSensorConnection
  |SetErrorMessageConnection;

const signalRReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SetSensorConnection: {
      return {
        ...state,
        sensorConnection: {
          connection: action.connection,
          connectionId: action.connectionId,
        },
      };
    }
    case ActionType.SetAlarmConnection: {
      return {
        ...state,
        alarmConnection: {
          connection: action.connection,
          connectionId: action.connectionId,
        },
      };
    }
    case ActionType.SetErrorMessageConnection: {
      return {
        ...state,
        errorMessageConnection: {
          connection: action.connection,
          connectionId: action.connectionId,
        },
      };
    }
    case ActionType.ClearSensorConnection: {
      return {
        ...state,
        sensorConnection: undefined,
      };
    }
    case ActionType.ClearAlarmConnection: {
      return {
        ...state,
        alarmConnection: undefined,
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const ScadaPlusSignalRProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(signalRReducer, {});

  return (
    <SignalRContext.Provider value={state}>
      <SignalRDispatchContext.Provider value={dispatch}>
        {props.children}
      </SignalRDispatchContext.Provider>
    </SignalRContext.Provider>
  );
};

export { ScadaPlusSignalRProvider, SignalRContext, SignalRDispatchContext, ActionType };
