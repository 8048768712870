import styled from "styled-components";

const Container = styled.div`
  padding-left: 0.9rem;
`;
const List = styled.ul`
  font-size: ${(props) => props.theme.constantFontSizes.small};
`;
interface NonComplianceListProps {
  nonComplianceList: Array<string>;
}

export const NonComplianceList: React.FC<NonComplianceListProps> = (props) => {
  const nonComplianceList: any = props.nonComplianceList.map(function (
    listItem
  ) {
    return <li>{listItem}</li>;
  });
  return (
    <Container>
      <List>{nonComplianceList}</List>
    </Container>
  );
};
