import React from "react";
import { useIntl } from "react-intl";
import styled, { DefaultTheme } from "styled-components";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import { GenericAlarmPriorityCounter } from "types";
import {
  getTotalNumberOfAlarms,
  getTotalNumberOfAlarmsPriorityFour,
  getTotalNumberOfAlarmsPriorityOne,
  getTotalNumberOfAlarmsPriorityThree,
  getTotalNumberOfAlarmsPriorityTwo,
} from "utils/alarm-utils";
import { isUsingMobileDevice } from "utils/device-utils";
import AlarmPriorityDisplayTile from "../AlarmPriorityDisplay";
const mobile = isUsingMobileDevice(window.navigator);

const AlarmTypeCountersArea = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(5, 1fr);
  grid-row: 1 / 1;
  margin-bottom: ${(props) => props.theme.spacing.light};
  background-color: ${(props: AlarmTypeCounterProps) =>
    props.theme.colors.componentBackgroundColor.primary};
  border-top-left-radius: ${(props: AlarmTypeCounterProps) =>
    props.curveTopleft && props.theme.borderRadius.light};
  border-top-right-radius: ${(props: AlarmTypeCounterProps) =>
    props.curveTopRight && props.theme.borderRadius.light};
`;

const AlarmTypeCounter = styled.div`
  padding: ${(props) => props.theme.spacing.light};
  font-size: ${(props) =>
    mobile
      ? props.theme.scaledFontSizes.large
      : props.theme.scaledFontSizes.medium};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 600;
`;

const AlarmCounterNumber = styled.span`
  color: ${(props) => props.theme.colors.textColor.tertiary};
  font-weight: 500;
`;

const AlarmTypeCounterText = styled.span`
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
  color: ${(props) => props.theme.colors.textColor.secondary};
`;
type AlarmTypeCounterProps = {
  highlight?: boolean;
  curveTopleft?: boolean;
  curveTopRight?: boolean;
  theme: DefaultTheme;
  alarmTileWidth?: string;
};

type AlarmPriorityHeadersProps = {
  alarmTileWidth?: string;
  alarmPriorityData: GenericAlarmPriorityCounter[];
};

export const AlarmPriorityHeaders: React.FC<AlarmPriorityHeadersProps> = (
  props
) => {
  const { formatMessage } = useIntl();
  const { theme } = useScadaPlusTheme();

  const totalNumberOfAlarmsPriorityOne = getTotalNumberOfAlarmsPriorityOne(
    props.alarmPriorityData
  );
  const totalNumberOfAlarmsPriorityTwo = getTotalNumberOfAlarmsPriorityTwo(
    props.alarmPriorityData
  );
  const totalNumberOfAlarmsPriorityThree = getTotalNumberOfAlarmsPriorityThree(
    props.alarmPriorityData
  );
  const totalNumberOfAlarmsPriorityFour = getTotalNumberOfAlarmsPriorityFour(
    props.alarmPriorityData
  );

  return (
    <AlarmTypeCountersArea curveTopleft curveTopRight theme={theme}>
      <AlarmTypeCounter theme={theme}>
        {props.alarmPriorityData
          ? getTotalNumberOfAlarms(props.alarmPriorityData)
          : "-"}
        <AlarmTypeCounterText>
          {formatMessage({
            id: "pu.Alarms",
            defaultMessage: "Missing translation",
          })}
        </AlarmTypeCounterText>
      </AlarmTypeCounter>
      <AlarmTypeCounter theme={theme}>
        <AlarmCounterNumber>
          {totalNumberOfAlarmsPriorityOne ?? "-"}
        </AlarmCounterNumber>
        <AlarmPriorityDisplayTile
          alarmPriortity={1}
          height="1.3rem"
          width={props.alarmTileWidth ? props.alarmTileWidth : "55%"}
        >
          {formatMessage({
            id: "alarms.PriOne",
            defaultMessage: "Missing translation",
          })}
        </AlarmPriorityDisplayTile>
      </AlarmTypeCounter>
      <AlarmTypeCounter theme={theme}>
        <AlarmCounterNumber>
          {totalNumberOfAlarmsPriorityTwo ?? "-"}
        </AlarmCounterNumber>
        <AlarmPriorityDisplayTile
          alarmPriortity={2}
          height="1.3rem"
          width={props.alarmTileWidth ? props.alarmTileWidth : "55%"}
        >
          {formatMessage({
            id: "alarms.PriTwo",
            defaultMessage: "Missing translation",
          })}
        </AlarmPriorityDisplayTile>
      </AlarmTypeCounter>
      <AlarmTypeCounter theme={theme}>
        <AlarmCounterNumber>
          {totalNumberOfAlarmsPriorityThree ?? "-"}
        </AlarmCounterNumber>
        <AlarmPriorityDisplayTile
          alarmPriortity={3}
          height="1.3rem"
          width={props.alarmTileWidth ? props.alarmTileWidth : "55%"}
        >
          {formatMessage({
            id: "alarms.PriThree",
            defaultMessage: "Missing translation",
          })}
        </AlarmPriorityDisplayTile>
      </AlarmTypeCounter>
      <AlarmTypeCounter theme={theme}>
        <AlarmCounterNumber>
          {totalNumberOfAlarmsPriorityFour ?? "-"}
        </AlarmCounterNumber>
        <AlarmPriorityDisplayTile
          alarmPriortity={4}
          height="1.3rem"
          width={props.alarmTileWidth ? props.alarmTileWidth : "55%"}
        >
          {formatMessage({
            id: "alarms.PriFour",
            defaultMessage: "Missing translation",
          })}
        </AlarmPriorityDisplayTile>
      </AlarmTypeCounter>
    </AlarmTypeCountersArea>
  );
};
