import React, { useState } from "react";
import GlobalStyle from "./Globalstyle";
import "react-toastify/dist/ReactToastify.css";
import ScadaPlus from "./pages/ScadaPlus";
import { loginRequest } from "./config";
import { ScadaPlusTagsProvider } from "./providers/ScadaPlusTagsProvider";
import { QueryClientProvider, QueryClient, QueryCache } from "react-query";
import { ScadaPlusThemeProvider } from "./providers/ScadaPlusThemeProvider";
import { ScadaPlusLanguageProvider } from "./providers/ScadaPlusLanguageProvider";
import { ScadaPlusViewPortProvider } from "./providers/ScadaPlusViewPortProvider";
import Authenticating from "./components/Authenticating";
import { ScadaPlusErrorProvider } from "./providers/ScadaPlusErrorProvider";
import { ScadaPlusSignalRProvider } from "./providers/ScadaPlusSignalRProvider";
import {
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";
import { Configuration, InteractionType } from "@azure/msal-browser";
import { RequestInterceptor } from "./components/RequestInterceptor";

const App: React.FC = () => {
  const [internetExplorer, setInternetExplorer] = useState(false);
  const queryClient = new QueryClient({
    queryCache: new QueryCache(),
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  

  React.useEffect(() => {
    const usingInternetExplorer = () => {
      const ua = navigator.userAgent;
      const msie = ua.indexOf("MSIE ");
      const trident = ua.indexOf("Trident/");

      if (msie > 0 || trident > 0) {
        return true;
      }
      return false;
    };
    setInternetExplorer(usingInternetExplorer);
  }, []);

  return (
    <ScadaPlusLanguageProvider>
      <ScadaPlusThemeProvider>
        <ScadaPlusViewPortProvider>
          <ScadaPlusSignalRProvider>
            <QueryClientProvider client={queryClient}>
              <ScadaPlusTagsProvider>
                <GlobalStyle />
                <MsalAuthenticationTemplate
                  interactionType={InteractionType.Redirect}
                  authenticationRequest={loginRequest}
                >
                  <RequestInterceptor>
                    <ScadaPlusErrorProvider>
                      <ScadaPlus />
                    </ScadaPlusErrorProvider>
                  </RequestInterceptor>
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <Authenticating />
                </UnauthenticatedTemplate>
              </ScadaPlusTagsProvider>
            </QueryClientProvider>
          </ScadaPlusSignalRProvider>
        </ScadaPlusViewPortProvider>
      </ScadaPlusThemeProvider>
    </ScadaPlusLanguageProvider>
  );
};

export default App;
