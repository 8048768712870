import Button from "components/Button";
import ScadaPlussRadioButton from "components/RadioButton";
import { useState } from "react";
import styled from "styled-components";

const ComponentContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.mainColor.primary};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  padding: 0.5rem;
`;

const ComponentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: ${(props) => props.theme.constantFontSizes.mediumLarge};
  padding: 0.5rem;
`;

const ViewSelector = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  font-size: ${(props) => props.theme.constantFontSizes.medium};
`;

const ViewSelectorRadioGroup = styled.div`
  display: grid;
  grid-template-columns: minmax(3rem, auto) 0.1rem minmax(3rem, auto);
  grid-gap: 0.5rem;
  grid-column-start: right;
  height: 30px;
  padding-left: 0.5rem;
`;

const ScrollableContainer = styled.div`
  width: 100%;
  height: 150px;
  overflow-y: scroll;
`;

const SIFLogTable = styled.table`
  border-spacing: 0;
  width: 100%;
`;

const TableHeader = styled.th`
  background-color: ${(props) => props.theme.colors.cardBackgroundHover};
  text-align: start;
  position: sticky;
  top: 0;
  padding: 0.2rem;
`;

const TableBody = styled.tbody`
  max-height: 100px;
  white-space: nowrap;
  font-size: ${(props) => props.theme.constantFontSizes.small};
`;

const TableFooter = styled.tfoot`
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding: 0.5rem;
`;

const TableRow = styled.tr`
  border: 1px solid aliceblue;
`;

interface LogItem {
  id: number;
  date: string;
  description: string;
  objectID: string;
  approved: string;
  acknowledged: string;
}

interface SIFLogProps {
  logList: Array<LogItem>;
  archiveList: Array<LogItem>;
}

export const SIFLog: React.FC<SIFLogProps> = (props) => {
  const [selectedView, setSelectedView] = useState("activeView");

  const onChangeSelectedView = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedView(event.target.value);
  };

  return (
    <ComponentContainer>
      <ComponentHeader>
        LOG
        <ViewSelector>
          View:
          <ViewSelectorRadioGroup onChange={onChangeSelectedView}>
            <ScadaPlussRadioButton
              name="radioGroup_logDataDisplay"
              id="radioButton_activeView"
              value="activeView"
              radioButtonLabel={"Active"}
              checked={selectedView === "activeView"}
            ></ScadaPlussRadioButton>
            |
            <ScadaPlussRadioButton
              name="radioGroup_logDataDisplay"
              id="radioButton_archiveView"
              value="archiveView"
              radioButtonLabel={"Archive"}
              checked={selectedView === "archiveView"}
            ></ScadaPlussRadioButton>
          </ViewSelectorRadioGroup>
        </ViewSelector>
      </ComponentHeader>

      <ScrollableContainer>
        <SIFLogTable>
          <thead>
            <tr>
              <TableHeader>Date</TableHeader>
              <TableHeader>Description</TableHeader>
              <TableHeader>Object ID</TableHeader>
              <TableHeader>Approved</TableHeader>
              <TableHeader>Acknowledged</TableHeader>
            </tr>
          </thead>
          {selectedView === "activeView" ? (
            <TableBody>
              {props.logList.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <td>{item.date}</td>
                    <td>{item.description}</td>
                    <td>{item.objectID}</td>
                    <td>{item.approved}</td>
                    <td>{item.acknowledged}</td>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              {props.archiveList.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <td>{item.date}</td>
                    <td>{item.description}</td>
                    <td>{item.objectID}</td>
                    <td>{item.approved}</td>
                    <td>{item.acknowledged}</td>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </SIFLogTable>
      </ScrollableContainer>

      <TableFooter>
        {" "}
        <Button type={"primary"} size="small">
          Delete
        </Button>
      </TableFooter>
    </ComponentContainer>
  );
};
