import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { GenericAlarm, GenericAlarmComment } from "types";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import CheckBox from "components/CheckBox";
import { useHistory, useParams } from "react-router-dom";
import AlarmPriorityDisplayTile from "components/AlarmPriorityDisplay";
import { MdComment, MdFlag } from "react-icons/md";
import { constructAlarmUrl, getSectionId, getType } from "utils/routing-utils";
import {
  resolveAlarmBackgroundColor,
  resolveHoverColor,
  resolveAlarmDescriptionFontColor,
  resolveSelectedAlarmBackgroundColor,
  resolveThemeCheckedIconColor,
} from "utils/alarm-utils";
import {
  Acknowledge,
  Supplier,
  Type,
  Title,
  Unit,
  Area,
  Comment,
} from "../areas";
import { useIntl } from "react-intl";
import Loader from "components/Loader";
import OrigoTooltip from "components/OrigoTooltip";
import { AlarmViewModel } from "../AlarmList";
import { useLocalDateTimeFormatShort } from "hooks/useDateTimeFormat";

const Container = styled.div`
  background-color: ${(props: AlarmItemStyleProps) =>
    props.checked
      ? resolveSelectedAlarmBackgroundColor(
          props.genericAlarm,
          props.theme,
          props.checked
        )
      : resolveAlarmBackgroundColor(
          props.genericAlarm,
          props.theme,
          props.acknowledged
        )};
  display: grid;
  grid-template-columns: repeat(17, minmax(0, 1fr));
  padding: 0 ${(props) => props.theme.spacing.medium};
  height: 2.5rem;
  border-radius: ${(props) => props.theme.borderRadius.light};
  font-size: ${(props) => props.theme.scaledFontSizes.small};
  color: ${(props: AlarmItemStyleProps) =>
    resolveAlarmDescriptionFontColor(
      props.genericAlarm,
      props.acknowledged,
      props.theme
    )};
  cursor: pointer;
  margin-bottom: 2px;
  &:hover {
    background-color: ${(props: AlarmItemStyleProps) =>
      (props.checked && props.acknowledged) || !props.checked
        ? resolveHoverColor(props.genericAlarm, props.theme, props.acknowledged)
        : resolveSelectedAlarmBackgroundColor(
            props.genericAlarm,
            props.theme,
            props.checked
          )};
  }
`;

const StyledFlag = styled(MdFlag)`
  display: block;
  box-sizing: content-box;
  align-self: center;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0 ${(props) => props.theme.spacing.extraHeavy};
  color: ${(props) => props.theme.colors.signal};
`;

const CommentIcon = styled(MdComment)`
  height: 1.5rem;
  width: 1.5rem;
`;

const TooltipGrid = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 5px;
  padding: ${(props) => props.theme.spacing.light};
  max-width: min-content;
`;

const TooltipAbout = styled.div`
  display: flex;
  grid-row: 1/2;
`;

const TooltipComment = styled.div`
  text-align: start;
`;

const DateField = styled.div`
  align-self: center;
  white-space: nowrap;
`;

interface AlarmItemStyleProps {
  theme: DefaultTheme;
  genericAlarm: GenericAlarm;
  acknowledged: boolean;
  checked?: boolean;
}

interface AlarmItemProps {
  alarmView: AlarmViewModel;
  toggleCheckbox: (alarmId: string) => void;
}

type ItemParams = {
  plantId: string;
  unitId: string | undefined;
};

const formatTooltip = (tooltip?: GenericAlarmComment): string => {
  if (tooltip && tooltip.comment) {
    return tooltip.comment.length > 150
      ? `${tooltip.comment.slice(0, 150)}...`
      : tooltip.comment;
  }
  return "";
};

export const AlarmItem: React.FC<AlarmItemProps> = (props) => {
  const { theme } = useScadaPlusTheme();
  const history = useHistory();
  const type = getType(history.location.search);
  const { plantId } = useParams<ItemParams>();
  const sectorId = getSectionId(history.location.search);
  const { formatMessage } = useIntl();
  const dateTimeLocal = useLocalDateTimeFormatShort();

  return (
    <Container
      className="alarm-item-container"
      genericAlarm={props.alarmView.data}
      acknowledged={props.alarmView.data.isAcknowledged}
      theme={theme}
      onClick={() => {
        history.push(
          constructAlarmUrl(plantId, sectorId, type, props.alarmView.data.id)
        );
      }}
      data-testid="item-container"
      checked={props.alarmView.checked}
    >
      <Acknowledge data-testid="item-acknowledge">
        {props.alarmView.loading ? (
          <Loader
            size={18}
            style={{
              alignItems: "flex-start",
              display: "block",
              paddingRight: "34px",
            }}
          />
        ) : (
          <CheckBox
            onChange={(e: React.MouseEvent) => {
              e.stopPropagation();
              !props.alarmView.data.isAcknowledged &&
                props.toggleCheckbox(props.alarmView.data.id);
            }}
            disable={props.alarmView.data.isAcknowledged}
            checked={props.alarmView.checked}
            checkedBackgroundColor={`${
              props.alarmView.data.isAcknowledged
                ? "transparent"
                : theme.colors.textColor.primary
            }`}
            checkedIconColor={resolveThemeCheckedIconColor(
              props.alarmView,
              props.alarmView.data.isAcknowledged,
              theme
            )}
          />
        )}
        <AlarmPriorityDisplayTile
          alarmPriortity={props.alarmView.data.priority}
          acknowledged={props.alarmView.data.isAcknowledged}
          width="1.5rem"
          height="1.5rem"
          listItem
        >
          {props.alarmView.data.priority}
        </AlarmPriorityDisplayTile>
        {props.alarmView.data.isFlagged && (
          <OrigoTooltip
            render={
              <TooltipGrid>
                <TooltipAbout>
                  {formatMessage({
                    id: "alarms.Flagged",
                    defaultMessage: "Flagged",
                  })}
                  {": "}
                  {dateTimeLocal.format(
                    props.alarmView.data.flaggedAt
                      ? new Date(props.alarmView.data.flaggedAt)
                      : undefined
                  )}{" "}
                  {formatMessage({
                    id: "alarms.AcknowledgedBy",
                    defaultMessage: "By",
                  })}{" "}
                  {props.alarmView.data.flaggedBy}
                </TooltipAbout>
              </TooltipGrid>
            }
          >
            <StyledFlag />
          </OrigoTooltip>
        )}
      </Acknowledge>
      <Type>{props.alarmView.data.condition}</Type>
      <DateField>
        {dateTimeLocal.format(new Date(props.alarmView.data.timestamp))}
      </DateField>
      <Title title={props.alarmView.data.description}>
        {props.alarmView.data.description}
      </Title>
      <Unit>{props.alarmView.data.componentUnitName}</Unit>
      <Area>{props.alarmView.data.area}</Area>
      <Supplier>{props.alarmView.data.supplier}</Supplier>
      {props.alarmView.data.comments.length >= 1 && (
        <Comment>
          <OrigoTooltip
            render={
              <TooltipGrid>
                <TooltipAbout>
                  {dateTimeLocal.format(
                    new Date(props.alarmView.data.comments[0].createdAt)
                  )}{" "}
                  {formatMessage({
                    id: "alarms.AcknowledgedBy",
                    defaultMessage: "By",
                  })}{" "}
                  {props.alarmView.data.comments[0].createdBy}
                </TooltipAbout>
                <TooltipComment>
                  {formatTooltip(props.alarmView.data.comments[0])}
                </TooltipComment>
              </TooltipGrid>
            }
          >
            <CommentIcon />
          </OrigoTooltip>
        </Comment>
      )}
    </Container>
  );
};
