import PersonnelAlarmCounterSVG from "components/svg/PersonnelAlarmCounterSVG";
import React from "react";
import styled from "styled-components";

const Container = styled.div``;

export const SectionPersonnelAlarmCounter: React.FC = () => (
  <Container>
    <PersonnelAlarmCounterSVG />
  </Container>
);
