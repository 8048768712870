import config from "config";
import { useScadaPlusQuery } from "hooks/useScadaPlusQuery";
import React from "react";
import { useQueryClient } from "react-query";
import { GenericAlarmPriorityCounter } from "types";
export const useGetAlarmData = (
  componentUnitId: string,
  newAlarmData: unknown | null
) => {
  const queryClient = useQueryClient();
  const alarmPrioritiesQueryName = `alarmPriorities-${componentUnitId}`;
  const { data: alarmPriorityData } = useScadaPlusQuery<
    Array<GenericAlarmPriorityCounter>
  >(
    alarmPrioritiesQueryName,
    `${config.api.url}/${config.api.genericAlarmPriorityCounters}?ComponentUnitId=${componentUnitId}`
  );
  React.useEffect(() => {
    if (newAlarmData) {
      queryClient.invalidateQueries(alarmPrioritiesQueryName);
    }
  }, [newAlarmData, queryClient, alarmPrioritiesQueryName]);
  return alarmPriorityData;
};