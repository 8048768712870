import styled from "styled-components";

const ContentContainer = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
`;

const ValueDisplay = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
  width: 40px;
  height: 40px;
  font-size: ${(props) => props.theme.scaledFontSizes.large};
  text-align: center;
`;

const RedPath = styled.path`
  stroke: ${(props) => props.theme.colors.warning};
  stroke-linecap: round;
  stroke-width: 10;
  fill: none;
  stroke-dasharray: 248;
  stroke-dashoffset: 80;
`;

interface CircularProgressbarProps {
  value: number;
  maxValue: number;
}

export const CircularProgressbar: React.FC<CircularProgressbarProps> = (
  props
) => {
  return (
    <ContentContainer>
      <ValueDisplay>{props.value}</ValueDisplay>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="37 -5 120 100"
        width="120"
        height="100"
      >
        <path
          d="M55,90 A55,55 0 1,1 140,90"
          strokeLinecap="round"
          strokeWidth={6}
          fill="none"
        ></path>
        <RedPath d="M55,90 A55,55 0 1,1 140,90"></RedPath>
      </svg>
    </ContentContainer>
  );
};
