import React from "react";
import styled from "styled-components";
import { PortalWithState } from "react-portal";

export enum Size {
  Small = "Small",
  Medium = "Medium",
  Large = "Large",
  FullPage = "FullPage",
}

export enum Background {
  Normal = "Normal",
  Dark = "Dark",
}

const Overlay = styled.div<OverlayProps>`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: ${(props) =>
    props.background === Background.Normal
      ? "rgba(0, 0, 0, 0.5)"
      : props.background === Background.Dark
      ? "rgba(0, 0, 0, 0.80)"
      : "rgba(0, 0, 0, 0.5)"};
`;

const Content = styled.div<ModalProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) =>
    props.size === Size.Small
      ? "20%"
      : props.size === Size.Medium
      ? "40%"
      : props.size === Size.Large
      ? "60%"
      : props.size === Size.FullPage
      ? "100%"
      : "60%"};
`;

interface IPortalContext {
  close?: () => void;
  onClose?: () => void;
}

type ModalProps = {
  size?: Size;
  background?: Background;
};
type OverlayProps = Pick<ModalProps, "background">;

const { Provider, Consumer } = React.createContext<IPortalContext>({});

export { Consumer as PortalConsumer };

export const Modal: React.FC<ModalProps> = (props) => {
  const stopPropagationOnModal = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const bindings: IPortalContext = {};
  return (
    <PortalWithState
      defaultOpen
      closeOnEsc
      onClose={() => bindings.onClose && bindings.onClose()}
    >
      {({ closePortal, portal }) => {
        bindings.close = closePortal;

        return portal(
          <Overlay
            className="modal-overlay"
            onClick={() => bindings.onClose && bindings.onClose()}
            background={props.background}
          >
            <Content onClick={stopPropagationOnModal} size={props.size}>
              <Provider value={bindings} children={props.children} />
            </Content>
          </Overlay>
        );
      }}
    </PortalWithState>
  );
};
