import React from "react";
import styled from "styled-components";
import { Credit as CreditData } from "../../../types";

const Container = styled.a`
  color: ${(props) => props.theme.colors.mainColor.primary};
`;

type CreditProps = {
  data: CreditData;
};

export const Credit: React.FC<CreditProps> = ({ data }) => (
  <Container href={data.link}>{data.name}</Container>
);
