import React from "react";
import styled from "styled-components";
import InfoCard from "../../../components/InfoCard";
import BoatSVG from "../../../components/svg/BoatSVG";
import { useIntl } from "react-intl";
import PersonnelOnSiteCounters from "../../../components/PersonnelOnSiteCounters";
import WindTurbineSVG from "components/svg/WindTurbineSVG";
import { usePersonnelLocationCounter } from "hooks/useGetPersonnelOnSite";

const BackgroundContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5em;
  padding: ${(props) => props.theme.spacing.extraLight};
  height: 85%;
`;

/* prettier-ignore */
const LocationContainer = styled.div`
  border-radius: ${(props) => props.theme.borderRadius.medium};
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundActive.primary};
  font-weight: 600;
  height: 100%;

  @media screen and (max-height: ${(props) => props.theme.mediaQueries.maxHeight}) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    place-items: center;
    padding: 0.5rem;

  }
  @media screen and (min-height: ${(props) => props.theme.mediaQueries.minHeight}) {
    display: grid;
    grid-template-rows: 2fr 1fr;
    padding: 0.5rem;
    

  }

`;

/* prettier-ignore */
const Icon = styled.div`
  @media screen and (max-height: ${(props) => props.theme.mediaQueries.maxHeight}) {
    grid-column: 1/2;
  }
  @media screen and (min-height: ${(props) => props.theme.mediaQueries.minHeight}) {
    grid-row: 1/2;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* prettier-ignore */
const KeyNumber = styled.div`
  @media screen and (max-height: ${(props) => props.theme.mediaQueries.maxHeight}) {
    grid-column: 2/2;
    justify-content: center;
    align-content: center;
  }
  @media screen and (min-height: ${(props) => props.theme.mediaQueries.minHeight}) {
    grid-row: 2/2;
    justify-self: center;
    align-self: top;
  }
  font-size: ${(props) => props.theme.scaledFontSizes.large};
  color: ${(props) => props.theme.colors.textColor.primary};
  text-align: center;
`;

interface IComponentProps {
  plantId: string;
  componentUnitId: string;
}

export const PersonnelOnSiteCard: React.FC<IComponentProps> = (props) => {
  const { formatMessage } = useIntl();
  const { data: personnelLocationCounter } = usePersonnelLocationCounter(
    props.plantId,
    ""
  );

  return (
    <InfoCard
      style={{
        height: "100%",
      }}
      title={formatMessage({
        id: "overview.PersonnelOnSite",
        defaultMessage: "Personnel on site",
      })}
    >
      <BackgroundContainer>
        <LocationContainer>
          <Icon>
            <BoatSVG width={"50%"} height={"50%"} />
          </Icon>
          <KeyNumber>
            <PersonnelOnSiteCounters
              plantId={props.plantId}
              data={personnelLocationCounter}
              counterType={"vessel"}
            />
          </KeyNumber>
        </LocationContainer>
        <LocationContainer>
          <Icon>
            <WindTurbineSVG width={"50%"} height={"50%"} />
          </Icon>
          <KeyNumber>
            <PersonnelOnSiteCounters
              plantId={props.plantId}
              data={personnelLocationCounter}
              counterType={"turbine"}
            />
          </KeyNumber>
        </LocationContainer>
      </BackgroundContainer>
    </InfoCard>
  );
};
