import ProgressBar from "components/ProgressBar";
import { GenericAlarmPriorityCounter } from "types";
import WindTurbineAlarmInfoBar from "../WindTurbineAlarmInfoBar";

enum ComponentUnitSize {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
}
type WindTurbineInforationContainer = {
  progress: number;
  currentProductionValue: number;
  alarmPriorityData: GenericAlarmPriorityCounter[] | undefined;
  componentUnitShortName: string;
  alarmPriorityCounter: GenericAlarmPriorityCounter | undefined;
  size: ComponentUnitSize;
};
export const WindTurbineInforationContainer = (
  props: WindTurbineInforationContainer
) => {
  return (
    <>
      <ProgressBar
        progress={props.progress}
        unitAbbreviation={"mhw"}
        value={props.currentProductionValue}
      />
      <WindTurbineAlarmInfoBar
        alarms={props.alarmPriorityData}
        unitName={props.componentUnitShortName}
        priority={Number(props.alarmPriorityCounter?.priority)}
        unitSize={props.size}
      />
    </>
  );
};
