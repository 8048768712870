import React from "react";
import styled from "styled-components";
import ScadaPlussLogoSVG from "components/svg/ScadaPlussLogoSVG";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";

type Method = () => void;

type HomeButtonProps = {
  onClicked?: Method;
  size?: string;
};

type SizeProps = {
  size?: string;
};

const Name = styled.div<SizeProps>`
  display: flex;
  color: ${(props) => props.theme.colors.headerTitleColor};
  align-items: center;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.secondaryFontFamily};
  height: 100%;
  width: 6.25rem;

  cursor: ${(props) => (props.onClick ? `pointer` : `auto`)};
`;

export const HomeButton: React.FC<HomeButtonProps> = (props) => {
  const { theme } = useScadaPlusTheme();
  return (
    <Name
      size={props.size}
      data-testid="scadaplusbutton"
      onClick={props.onClicked}
    >
      <ScadaPlussLogoSVG
        fillScadaPlusIconHoverColor={theme.colors.origoLogoHoverColor}
        fillScadaPlusIconColor={theme.colors.origoLogoColor}
      />
    </Name>
  );
};
