import { SensorData } from "hooks/useSubscription";
import { LatLngTuple } from "leaflet";
import styled from "styled-components";
import { ComponentUnit, Layers, PersonnelLevel } from "types";
import { getHighestPriority, getTotalNumberOfAlarms } from "utils/alarm-utils";
import { toMegaWatt } from "utils/production-utils";
import WindTurbineBlades from "../WindTurbineBlades";
import ProductionIndicatorSVG from "../ProductionIndacatorSVG";
import WindTurbinePersonnelSVG from "../WindTurbinePersonnelSVG";
import { WindTurbineInforationContainer } from "../WindTurbineInformationContainer/WindTurbineInforationContainer";
import {
  useGetAlarmData,
  useGetBladeColor,
  useGetLastProduction,
  useGetSVGColor,
  useSubWindOverviewData,
} from "../hooks";
import TotalAlarmCountDisplay from "../TotalAlarmCountDisplay";

const getSizeOfComponentUnit = (sizeArg: ComponentUnitSize) => {
  const size =
    sizeArg === ComponentUnitSize.LARGE || sizeArg === ComponentUnitSize.MEDIUM
      ? "70px"
      : "25px";
  return size;
};
enum ComponentUnitSize {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
}
type InternalProps = {
  unitSize: string;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement> | undefined;
};
const Container = styled.div<InternalProps>`
  position: absolute;
  width: ${(props) => props.unitSize};
  height: ${(props) => props.unitSize};
`;
const InfoBackground = styled.div<InternalProps>`
  width: ${(props) => props.unitSize};
  height: ${(props) => props.unitSize};
  background-color: ${(props) => props.theme.colors.mainColor.secondary};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: ${(props) => props.theme.spacing.medium};
  gap: 5px;
`;
type ScadaPlusWindTurbineMarkerProps = {
  markerPos: LatLngTuple;
  componentUnit: ComponentUnit;
  layers: Layers;
  size: ComponentUnitSize;
  productionLayerActive: boolean;
  personell: PersonnelLevel;
  currentProduction: SensorData | null;
};
export const ScadaPlusWindTurbineMarker = (
  props: ScadaPlusWindTurbineMarkerProps
) => {
  const { newAlarmData, unitOperationCode, unitStatusCode } =
    useSubWindOverviewData(props.componentUnit.id);
  const alarmPriorityData = useGetAlarmData(
    props.componentUnit.id,
    newAlarmData
  );
  const lastProductionData = useGetLastProduction(props.componentUnit.id);
  const bladeColor = useGetBladeColor(unitOperationCode);
  const svgColor = useGetSVGColor({
    unitOperationCode,
    unitStatusCode,
  });
  const unitSize = getSizeOfComponentUnit(props.size);
  const currentProductionValue = props.currentProduction
    ? toMegaWatt(Number(props.currentProduction.value))
    : 0;
  const progress =
    (currentProductionValue /
      toMegaWatt(props.componentUnit.energyProductionCapacity)) *
    100;
  0;
  const alarmPriorityCounter = getHighestPriority(
    alarmPriorityData ? alarmPriorityData : []
  );

  const isTurbineProducing = props.currentProduction
    ? true
    : lastProductionData?.value
    ? true
    : false;
  return (
    <Container unitSize={unitSize}>
      <WindTurbineBlades
        rectangle={[
          [props.markerPos[0], props.markerPos[1]],
          [props.markerPos[0] + 0.01, props.markerPos[1] + 0.01],
        ]}
        componentUnitId={props.componentUnit.id}
        bladeColor={bladeColor}
        width={unitSize}
        height={unitSize}
        isMapEnabled={props.layers.map}
      />
      {isTurbineProducing && (
        <ProductionIndicatorSVG
          isMapEnabled={props.layers.map}
          width={unitSize}
          height={unitSize}
          color={svgColor}
        />
      )}
      {!props.productionLayerActive &&
        alarmPriorityCounter?.numberOfAlarms !== undefined && (
          <TotalAlarmCountDisplay
            width={unitSize}
            height={unitSize}
            priority={Number(alarmPriorityCounter.priority)}
          />
        )}
      {props.layers.personnel && (
        <WindTurbinePersonnelSVG
          width={unitSize}
          height={unitSize}
          data={props.personell}
        />
      )}
      {props.productionLayerActive ? (
        props.layers.map ? (
          <InfoBackground unitSize={unitSize}>
            <WindTurbineInforationContainer
              progress={progress}
              currentProductionValue={currentProductionValue}
              alarmPriorityCounter={alarmPriorityCounter}
              alarmPriorityData={alarmPriorityData}
              componentUnitShortName={props.componentUnit.shortName}
              size={props.size}
            />
          </InfoBackground>
        ) : (
          <WindTurbineInforationContainer
            progress={progress}
            currentProductionValue={currentProductionValue}
            alarmPriorityCounter={alarmPriorityCounter}
            alarmPriorityData={alarmPriorityData}
            componentUnitShortName={props.componentUnit.shortName}
            size={props.size}
          />
        )
      ) : (
        <span />
      )}
    </Container>
  );
};
