import React, { useEffect } from "react";
import styled from "styled-components";
import Alarm from "components/Alarm";
import { GenericAlarm, GenericAlarmPriorityCounter } from "types";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import config from "config";
import { useAlarmSubscription } from "hooks/useSubscription";
import { constructReportAlarmUrl } from "utils/routing-utils";
import AlarmPriorityHeaders from "components/AlarmPriorityHeaders";
import { useScadaPlusQuery } from "hooks/useScadaPlusQuery";
import { useGetAlarms } from "hooks/useGetAlarms";

const AlarmsArea = styled.div`
  grid-row: 1/5;
  grid-column: 5/6;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundColor.primary};
`;

/* prettier-ignore */
const AlarmsBody = styled.div`
  padding: ${(props) => props.theme.spacing.medium};
  border-radius: 0 0 ${(props) => props.theme.borderRadius.medium} ${(props) => props.theme.borderRadius.medium};
  height: 100%;
  overflow-y: scroll;
`;

const NoAlarmsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  color: ${(props) => props.theme.colors.textColor.secondary};
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
  font-weight: 600;
`;

type AlarmsReportProps = {
  plantId: string;
  unitId: string;
};

const yesterday = (): Date => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(12);
  return yesterday;
};

const today = (): Date => {
  const today = new Date();
  today.setHours(12);
  return today;
};

export const AlarmsReport: React.FC<AlarmsReportProps> = (props) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const alarmPrioritiesQueryName = `alarmPriorities-plant-${props.plantId}`;
  const alarmsQueryName = `activeAlarmsForPowerPlant-${props.plantId}`;
  const queryClient = useQueryClient();
  const pageSize = 100;
  const from = yesterday();
  const to = today();

  const { data: alarms } = useGetAlarms(
    { plantId: props.plantId },
    { pageSize, from, to }
  );

  const { data: alarmPriorityData } = useScadaPlusQuery<
    Array<GenericAlarmPriorityCounter>
  >(alarmPrioritiesQueryName, () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(12);

    const today = new Date();
    today.setHours(12);
    return `${config.api.url}/${
      config.api.genericAlarmPriorityCounters
    }?PlantId=${
      props.plantId
    }&FromDate=${yesterday.toUTCString()}&ToDate=${today.toUTCString()}&Size=100`;
  });

  const { data: newAlarm } = useAlarmSubscription({
    filter: {
      windPowerPlantId: props.plantId,
    },
  });

  useEffect(() => {
    if (newAlarm) {
      queryClient.invalidateQueries(alarmsQueryName);
      queryClient.invalidateQueries(alarmPrioritiesQueryName);
    }
  }, [
    newAlarm,
    queryClient,
    props.unitId,
    props.plantId,
    alarmsQueryName,
    alarmPrioritiesQueryName,
  ]);
  return (
    <AlarmsArea>
      {alarmPriorityData && (
        <AlarmPriorityHeaders alarmPriorityData={alarmPriorityData} />
      )}
      <AlarmsBody>
        {alarms && alarms.length > 0 ? (
          alarms.map((a: GenericAlarm) => (
            <Alarm
              alarm={a}
              key={a.id}
              onClick={() =>
                history.push(
                  constructReportAlarmUrl(
                    props.plantId,
                    undefined,
                    undefined,
                    a.id
                  )
                )
              }
            />
          ))
        ) : (
          <NoAlarmsContainer>
            {formatMessage({
              id: "alarms.noAlarms",
              defaultMessage: "No Alarms",
            })}
          </NoAlarmsContainer>
        )}
      </AlarmsBody>
    </AlarmsArea>
  );
};
