import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InfoCard from "../../../components/InfoCard";
import {
  useSensorSubscription,
  SensorData,
} from "../../../hooks/useSubscription";
import { useIntl } from "react-intl";
import NumberValueFormatter from "../../../components/NumberValueFormatter";
import CardLabel from "../../../components/CardLabel";
import { useTagsContext } from "../../../hooks/useTags";
import { toMegaWatt } from "../../../utils/production-utils";
import { MeasurementUnitSymbolConstants } from "utils/utils";
import MeasurementUnitAbbreviation from "components/MeasurementUnitAbbreviation";

/* prettier-ignore */
const PowerCardContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(4, minmax(0, auto));
  padding: ${(props) => props.theme.spacing.heavy};
  height: 85%;
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth} ) {
    padding: ${(props) => props.theme.spacing.medium}; 
  }
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth}) and (display-mode: fullscreen)  {
    padding: ${(props) => props.theme.spacing.medium}; 
  }
`;

/* prettier-ignore */
const DataDisplayContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 2rem;
  height: ${(props) => props.theme.numberSizes.medium};
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth} ) {
    height: ${(props) => props.theme.numberSizes.small};
  }
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth}) and (display-mode: fullscreen)  {
    height: ${(props) => props.theme.numberSizes.small};
  }
`;

const DataName = styled.div`
  grid-column: 1;
`;

const DataValue = styled.div`
  grid-column: 2;
`;

interface IComponentProps {
  componentUnitId: string;
}

export const PowerCard: React.FC<IComponentProps> = (props) => {
  const { tags } = useTagsContext();
  const [activePower, setActivePower] = useState<SensorData | null>(null);
  const [reactivePower, setReactivePower] = useState<SensorData | null>(null);
  const [averageCurrent, setAverageCurrent] = useState<SensorData | null>(null);
  const [averageVoltage, setAverageVoltage] = useState<SensorData | null>(null);
  const { formatMessage } = useIntl();

  const { sensorData: power } = useSensorSubscription({
    filters: [
      {
        id: tags.WTURW,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTURVar,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTRFTurPPVAvg,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTRFTurAAvg,
        windTurbineId: props.componentUnitId,
      },
    ],
  });

  useEffect(() => {
    switch (power?.id) {
      case tags.WTURW:
        setActivePower(power);
        break;
      case tags.WTURVar:
        setReactivePower(power);
        break;
      case tags.WTRFTurAAvg:
        setAverageCurrent(power);
        break;
      case tags.WTRFTurPPVAvg:
        setAverageVoltage(power);
        break;
      default:
        break;
    }
  }, [power, tags.WTRFTurAAvg, tags.WTRFTurPPVAvg, tags.WTURVar, tags.WTURW]);

  return (
    <InfoCard
      title={formatMessage({
        id: "pu.Power",
        defaultMessage: "Power",
      })}
      style={{ height: "100%" }}
      className="power_infoCard"
    >
      <PowerCardContainer className="powerContainer">
        <DataDisplayContainer className="power_activePowerDisplay">
          <DataName className="power_dataName">
            <CardLabel className="power_cardLabel">
              {formatMessage({
                id: "pu.ActivePower",
                defaultMessage: "Missing text",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="power_dataValue">
            <NumberValueFormatter
              className="power_numberValueFormatter"
              value={toMegaWatt(Number(activePower?.value))}
              precision={1}
            />
          </DataValue>
          {activePower && (
            <MeasurementUnitAbbreviation
              measurementType={MeasurementUnitSymbolConstants.megaWatt}
            />
          )}
        </DataDisplayContainer>

        <DataDisplayContainer className="power_reactivePowerDisplay">
          <DataName className="power_dataName">
            <CardLabel className="power_cardLabel">
              {formatMessage({
                id: "pu.ReactivePower",
                defaultMessage: "Missing text",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="power_dataValue">
            <NumberValueFormatter
              className="power_numberValueFormatter"
              value={toMegaWatt(Number(reactivePower?.value))}
              precision={1}
            />
          </DataValue>
          {reactivePower && (
            <MeasurementUnitAbbreviation
              measurementType={
                MeasurementUnitSymbolConstants.megaVoltAmpsReactive
              }
            />
          )}
        </DataDisplayContainer>

        <DataDisplayContainer className="power_averageCurrentDisplay">
          <DataName className="power_dataName">
            <CardLabel className="power_cardLabel">
              {formatMessage({
                id: "pu.AverageCurrent",
                defaultMessage: "Missing text",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="power_dataValue">
            <NumberValueFormatter
              className="power_numberValueFormatter"
              value={Number(averageCurrent?.value)}
              precision={1}
            />
          </DataValue>
          {averageCurrent && (
            <MeasurementUnitAbbreviation
              measurementType={MeasurementUnitSymbolConstants.ampere}
            />
          )}
        </DataDisplayContainer>

        <DataDisplayContainer className="power_averageVoltageDisplay">
          <DataName className="power_dataName">
            <CardLabel className="power_cardLabel">
              {formatMessage({
                id: "pu.AverageVoltage",
                defaultMessage: "Missing text",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="power_dataValue">
            <NumberValueFormatter
              className="power_numberValueFormatter"
              value={Number(averageVoltage?.value)}
              precision={1}
            />
          </DataValue>
          {averageVoltage && (
            <MeasurementUnitAbbreviation
              measurementType={MeasurementUnitSymbolConstants.voltage}
            />
          )}
        </DataDisplayContainer>
      </PowerCardContainer>
    </InfoCard>
  );
};
