import React from "react";
import {
  PermissionsContext,
  PermissionsDispatchContext,
  Permissiontype,
} from "../providers/ScadaPlusPermissionsProvider";

export const usePermissions = () => {
  const context = React.useContext(PermissionsContext);
  if (context === undefined) {
    throw new Error(
      "usePermissions must be used within a ScadaPlusAuthPermissionsProvider"
    );
  }
  return context;
};

export const usePowerplantPermissions = (powerPlantId: string) => {
  const context = React.useContext(PermissionsContext);
  if (context === undefined) {
    throw new Error(
      "usePermissions must be used within a ScadaPlusAuthPermissionsProvider"
    );
  }
  return context.permissions.filter((x) => x.id === powerPlantId);
};

export const usePermission = (
  powerPlantId: string,
  permissionType: Permissiontype
) => {
  const context = React.useContext(PermissionsContext);
  if (context === undefined) {
    throw new Error(
      "usePermissions must be used within a ScadaPlusAuthPermissionsProvider"
    );
  }

  const powerplantPermissions = context.permissions.filter(
    (x) => x.id === powerPlantId
  )[0];
  if (powerplantPermissions) {
    return powerplantPermissions.permissions.includes(permissionType);
  }

  return false;
};

export const usePermissionsDispatch = () => {
  const context = React.useContext(PermissionsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "usePermissionsDispatch must be used within a ScadaPlusAuthPermissionsProvider"
    );
  }
  return context;
};
