import styled from "styled-components";
import CircularProgressbar from "../CircularProgressbar";
import NonComplianceList from "./NonComplianceList";

const ContentContainer = styled.div``;

const Header = styled.div`
  font-size: ${(props) => props.theme.constantFontSizes.mediumLarge};
  padding-bottom: 0.6rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 0.5rem;
`;

interface GlobalSIFComplianceProps {
  value: number;
  maxValue: number;
  nonComplianceList: Array<string>;
}

export const GlobalSIFCompliance: React.FC<GlobalSIFComplianceProps> = (
  props
) => {
  return (
    <ContentContainer>
      <Header>Non-Compliant: Worst 5 </Header>
      <Content>
        <CircularProgressbar
          value={props.value}
          maxValue={props.maxValue}
        ></CircularProgressbar>
        <NonComplianceList
          nonComplianceList={props.nonComplianceList}
        ></NonComplianceList>
      </Content>
    </ContentContainer>
  );
};
