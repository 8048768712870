// STKN new WT status card
import React, { useState } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import InfoCard from "../../../components/InfoCard";
import { useTheme } from "styled-components";
import { ActiveBoardingLocationTypeCounter, ObjectType } from "../../../types";
import PersonnelOnSiteCounters from "components/PersonnelOnSiteCounters";
import HardhatPersonnelSVG from "components/svg/HardhatPersonnelSVG";
import PersonnelTracking from "components/PersonnelTracking";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 85%;
  width: 100%;
`;

const StatusGraphics = styled.div`
  height: 50%;
  width: 50%;
`;

const Counter = styled.div`
  padding-top: 3%;
  font-size: ${(props) => props.theme.numberSizes.small};
`;

interface IComponentProps {
  componentUnitId: string;
  plantId: string;
  onSitePersonnelData?: ObjectType<ActiveBoardingLocationTypeCounter[]>;
}

export const PersonnelCard: React.FC<IComponentProps> = (props) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const [showPersonnelTracking, setShowPersonnelTracking] = useState(false);
  const Click = () => {
    setShowPersonnelTracking(!showPersonnelTracking);
  };

  const isPersonnelAtTurbine = (): boolean => {
    const serviceLocationTypes =
      props.onSitePersonnelData?.serviceLocationTypes;

    if (!props.onSitePersonnelData || !serviceLocationTypes) {
      return false;
    }
    const turbine = serviceLocationTypes.filter((x) =>
      x.serviceLocationTypeDescription === "Wind Turbine" &&
      x.servicePersonellOnBoard
        ? x.servicePersonellOnBoard > 0
        : false
    );
    return turbine.length > 0 && (turbine[0].servicePersonellOnBoard ?? 0) > 0;
  };

  const resolveCardBackgroundColor = () => {
    return isPersonnelAtTurbine()
      ? theme.colors.signal
      : theme.colors.componentBackgroundColor.primary;
  };

  const resolveCardHoverColor = () => {
    return isPersonnelAtTurbine() ? theme.colors.signalHover : "";
  };

  const personnelAtTurbineSVGColor = () => {
    return isPersonnelAtTurbine()
      ? theme.colors.bodyBackgroundColor
      : theme.colors.mainColor.tertiary;
  };

  return (
    <InfoCard
      title={formatMessage({
        id: "pu.Personnel",
        defaultMessage: "Missing text",
      })}
      style={{
        height: "100%",
      }}
      onClick={Click}
      backgroundColor={resolveCardBackgroundColor()}
      hoverColor={resolveCardHoverColor()}
    >
      <Container>
        <StatusGraphics>
          <HardhatPersonnelSVG
            height="100%"
            width="100%"
            color={personnelAtTurbineSVGColor()}
          />
        </StatusGraphics>
        <Counter>
          <PersonnelOnSiteCounters
            plantId={props.plantId}
            data={props.onSitePersonnelData}
            counterType={"turbine"}
          />
        </Counter>
      </Container>
      {showPersonnelTracking && (
        <PersonnelTracking
          onClose={() => setShowPersonnelTracking(!showPersonnelTracking)}
          plantId={props.plantId}
          componentUnitId={props?.componentUnitId}
        />
      )}
    </InfoCard>
  );
};
