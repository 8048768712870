import config from "../config";
import { UseQueryResult } from "react-query";
import { useScadaPlusQuery } from "./useScadaPlusQuery";
import { GenericAlarm, IdCollection } from "types";
import { getAlarmsQueryName } from "utils/query-invalidation-utils";

const baseUrl = `${config.api.url}/${config.api.genericAlarm}`;

export type AlarmQueryParameters = {
  pageSize: number;
  from?: Date;
  to?: Date;
  refreshInterval?: number;
};

export const useGetAlarm = (alarmId: string): UseQueryResult<GenericAlarm> => {
  return useScadaPlusQuery<GenericAlarm>(alarmId, `${baseUrl}/${alarmId}`);
};

export const useGetAlarms = (
  ids: IdCollection,
  params: AlarmQueryParameters
): UseQueryResult<Array<GenericAlarm>> => {
  const intervalUrlParam = constructIntervalUrlString(params?.from, params?.to);
  const sharedUrlParams = `&Size=${params.pageSize}&Page=1&SortByTimeStamp=true${intervalUrlParam}`;
  const alarmsQueryName = getAlarmsQueryName([ids.plantId, ids.sectionId]);

  return useScadaPlusQuery<Array<GenericAlarm>>(
    alarmsQueryName,
    () => {
      if (ids.componentUnitId) {
        return `${baseUrl}?ComponentUnitId=${ids.componentUnitId}${sharedUrlParams}`;
      }
      if (ids.sectionId) {
        return `${baseUrl}?SectionId=${ids.sectionId}${sharedUrlParams}`;
      }
      return `${baseUrl}?PlantId=${ids.plantId}${sharedUrlParams}`;
    },
    params?.refreshInterval
  );
};

const constructIntervalUrlString = (from?: Date, to?: Date): string => {
  const fromString = from ? `&FromDate=${from.toUTCString()}` : "";
  const toString = to ? `&ToDate=${to.toUTCString()}` : "";
  return `${fromString}${toString}`;
};
