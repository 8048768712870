import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { GenericMenu } from "../types";
import MobileOverview from "./Mobile/Overview";
import ScadaPlusRouteComponent from "./ScadaPlusRouteComponent";

export type RoutesProps = {
  menu: GenericMenu;
};

const MobileRoutes: React.FunctionComponent<RoutesProps> = (props) => {
  return (
    <Switch>
      <Route
        path="/plant/:plantId/overview"
        render={() => {
          return (
            <ScadaPlusRouteComponent mobile menu={props.menu}>
              <MobileOverview />
            </ScadaPlusRouteComponent>
          );
        }}
      />
      <Redirect path="/" to={`/plant/${props.menu[0].id}/overview`} />
    </Switch>
  );
};

export default MobileRoutes;
