import React from "react";
import { useScadaPlusTheme } from "../../../hooks/useScadaPlusTheme";

type BoatProps = {
  width: string;
  height: string;
};

export const BoatSVG: React.FC<BoatProps> = (props) => {
  const { theme } = useScadaPlusTheme();
  return (
    <svg
      viewBox="0 0 45 40"
      width={props.width}
      height={props.height}
      key={theme.name}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.8771 11.25H13.1271C12.0959 11.25 11.2521 12.0938 11.2521 13.125V18.6938L21.3209 15.375C22.0896 15.1313 22.8959 15.1313 23.6646 15.375L33.7521 18.6938V13.125C33.7521 12.0938 32.9084 11.25 31.8771 11.25ZM30.4896 37.2375C32.6646 38.6063 35.0834 39.375 37.5021 39.375H39.3771C40.4084 39.375 41.2521 40.2188 41.2521 41.25C41.2521 42.2813 40.4084 43.125 39.3771 43.125H37.5021C34.9146 43.125 32.3646 42.4875 30.0021 41.2688C25.2771 43.6875 19.7271 43.6875 15.0021 41.2688C12.6396 42.4688 10.0896 43.125 7.50214 43.125H5.62714C4.59589 43.125 3.75214 42.2813 3.75214 41.25C3.75214 40.2188 4.59589 39.375 5.62714 39.375H7.50214C9.92089 39.375 12.3396 38.6063 14.4959 37.2375C14.7959 37.05 15.1896 37.05 15.4896 37.2375C19.8209 39.9938 25.1646 39.9938 29.4959 37.2375C29.7959 37.05 30.1896 37.05 30.4896 37.2375Z"
        fill={theme.colors.mainColor.primary}
        fillOpacity="0.4"
      />
      <path
        d="M13.1271 11.25H31.8771C32.9084 11.25 33.7521 12.0938 33.7521 13.125V18.6938L23.6646 15.375C22.8959 15.1313 22.0896 15.1313 21.3209 15.375L11.2521 18.6938V13.125C11.2521 12.0938 12.0959 11.25 13.1271 11.25Z"
        fill={theme.colors.mainColor.primary}
      />
      <path
        d="M7.40839 35.625H7.50214C10.1646 35.625 12.5646 34.3125 14.3646 32.55C14.7209 32.2125 15.3021 32.2125 15.6584 32.55C17.4396 34.3125 19.8396 35.625 22.5021 35.625C25.1646 35.625 27.5646 34.3125 29.3646 32.55C29.7209 32.1938 30.2834 32.1938 30.6584 32.55C32.4396 34.3125 34.8396 35.625 37.5021 35.625H37.5959L41.1396 23.1C41.2896 22.6125 41.2521 22.0875 41.0271 21.6375C40.8021 21.1875 40.3896 20.85 39.9021 20.7L37.5021 19.9125V11.25C37.5021 9.1875 35.8146 7.5 33.7521 7.5H28.1271V3.75C28.1271 2.71875 27.2834 1.875 26.2521 1.875H18.7521C17.7209 1.875 16.8771 2.71875 16.8771 3.75V7.5H11.2521C9.18964 7.5 7.50214 9.1875 7.50214 11.25V19.9125L5.08339 20.7C4.59589 20.85 4.18339 21.1875 3.95839 21.6375C3.73339 22.0875 3.67714 22.6125 3.84589 23.1L7.40839 35.625Z"
        fill="url(#id1)"
      />
      <defs>
        <linearGradient
          id="id1"
          x1="22.4887"
          y1="1.875"
          x2="22.4887"
          y2="47.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop style={{ stopColor: theme.colors.mainColor.primary }} />
          <stop
            offset="1"
            style={{
              stopColor: theme.colors.mainColor.secondary,
              stopOpacity: 0,
            }}
          />
        </linearGradient>
      </defs>
    </svg>
  );
};
