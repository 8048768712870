import React, { ReactElement } from "react";
import styled, { DefaultTheme } from "styled-components";
import { IconContext } from "react-icons";

enum ButtonType {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
}

enum ButtonSize {
  XS = "xs",
  Small = "small",
  Medium = "medium",
  Large = "large",
}

interface ButtonProps {
  type: "primary" | "secondary" | "tertiary";
  children?: React.ReactNode;
  onclick?: () => void | any;
  size?: "xs" | "small" | "medium" | "large";
  icon?: ReactElement;
  iconSize?: number;
  opacity?: number;
  hideBackground?: boolean;
  style?: React.CSSProperties;
  width?: string;
  height?: string;
  center?: boolean;
  hovercolor?: string;
  disabled?: boolean;
  padding?: string;
}

interface InternalProps {
  size?: "xs" | "small" | "medium" | "large";
  theme: DefaultTheme;
  opacity?: number;
  hideBackground?: boolean;
  width?: string;
  height?: string;
  center?: boolean;
  color?: string;
  hovercolor?: string;
  disabled?: boolean;
  padding?: string;
}

const Container = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: ${(props: InternalProps) => {
    return props.center ? "center" : "inherit";
  }};
  font-weight: 600;
  line-height: 19px;
  border-radius: ${(props) => props.theme.borderRadius.light};
  font-size: ${(props: InternalProps) => calculateFontSize(props)};
  opacity: ${(props: InternalProps) => (props.opacity ? props.opacity : "1")};
  border: none;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
    outline-offset: none;
  }
  padding: ${(props: InternalProps) =>
    props.padding ? props.padding : calculateButtonPadding(props)};
  width: ${(props: InternalProps) => (props.width ? props.width : "inherit")};
  height: ${(props: InternalProps) =>
    props.height ? props.height : "inherit"};
`;

const calculateFontSize = (props: InternalProps) => {
  if (props.size) {
    switch (props.size) {
      case ButtonSize.Large:
        return props.theme.scaledFontSizes.large;
      case ButtonSize.Medium:
        return props.theme.scaledFontSizes.medium;
      case ButtonSize.Small:
        return props.theme.scaledFontSizes.small;
      case ButtonSize.XS:
        return props.theme.scaledFontSizes.xs;
      default:
        return props.theme.scaledFontSizes.large;
    }
  }
  return props.theme.scaledFontSizes.large;
};

const calculateButtonPadding = (props: InternalProps) => {
  if (props.size) {
    switch (props.size) {
      case ButtonSize.Large:
        return "25px 85px";

      case ButtonSize.Medium:
        return "12px 42px";

      case ButtonSize.Small:
        return "6px 21px";

      case ButtonSize.XS:
        return "4px 10px";

      default:
        return "25px 85px";
    }
  }
  return "25px 85px";
};

/* prettier-ignore */
const Primary = styled(Container)`
  background-color: ${(props: InternalProps) =>
    props.hideBackground
      ? "transparent"
      : props.theme.colors.buttonColors.primaryButtonColor};
  color: ${(props) =>
    props.theme.colors.buttonColors.primaryButtonTextColor.primary};
  font-family: ${(props) => props.theme.primaryFontFamily};
  &:hover {
    background-color: ${(props) =>
      props.hovercolor ? props.hovercolor : props.theme.colors.buttonColors.primaryButtonHoverColor};
  }
  &:active {
    background-color: ${(props) => props.theme.colors.mainColor.tertiary};
  }
  &:disabled {
    background-color: ${(props) => props.theme.colors.alarmDetailsColour.alarmSelected.quinary};
    color: ${(props) =>
    props.theme.colors.buttonColors.primaryButtonTextColor.secondary};
    &:hover {  
      cursor: not-allowed;} 
  }
`;

/* prettier-ignore */
const Secondary = styled(Container)`
  background-color: ${(props: InternalProps) =>
    props.hideBackground
      ? "transparent"
      : props.theme.colors.buttonColors.secondaryButtonColor};
  color: ${(props) =>
    props.theme.colors.buttonColors.secondaryButtonTextColor.primary};
  font-family: ${(props) => props.theme.primaryFontFamily};
  margin-right: 5px;
  &:hover {
    background-color: ${(props) =>
      props.hovercolor ? props.hovercolor : props.theme.colors.buttonColors.secondaryButtonHoverColor};
    opacity: ${(props) => (props.hovercolor ? props.opacity : "1")};
  }
  &:active {
    background-color: "${(props) => props.theme.colors.mainColor.tertiary}";
  }
  &:disabled {
    background-color: ${(props) => props.theme.colors.alarmDetailsColour.alarmSelected.quinary};
    &:hover {  
      cursor: not-allowed;} 
  }

`;

/* prettier-ignore */
const Tertiary = styled(Container)`
  background-color: ${(props: InternalProps) =>
    props.hideBackground
      ? "transparent"
      : props.theme.colors.buttonColors.tertiaryButtonColor};
  color: ${(props) =>
    props.theme.colors.buttonColors.secondaryButtonTextColor.primary};
  font-family: ${(props) => props.theme.primaryFontFamily};
  margin-right: 5px;
  &:hover {
    background-color: ${(props) =>
      props.hovercolor ? props.hovercolor : props.theme.colors.buttonColors.tertiaryButtonHoverColor};
    opacity: ${(props) => (props.hovercolor ? props.opacity : "1")};
  }
  &:active {
    background-color: "${(props) => props.theme.colors.mainColor.tertiary}";
  }
  &:disabled {
    background-color: ${(props) => props.theme.colors.alarmDetailsColour.alarmSelected.quinary};
    &:hover {  
      cursor: not-allowed;} 
  }
`;

/* prettier-ignore */
const Standard = styled(Container)`
  background-color: ${(props: StandardButtonProps) => props.color};
  color: ${(props) =>
    props.theme.colors.buttonColors.primaryButtonTextColor.secondary};
  font-family: ${(props) => props.theme.primaryFontFamily};
  &:active {
    background-color: ${(props) => props.theme.colors.mainColor.tertiary};
  }
  &:hover {
    background-color: ${(props) => props.hovercolor ? props.hovercolor : props.theme.colors.buttonColors.primaryButtonHoverColor};
    opacity: ${(props) => (props.hovercolor ? props.opacity : "1")};
  }
`;

const ButtonElementsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface IButtonIconProps {
  margin?: boolean;
}

const IconContainer = styled.div`
  margin-right: ${(props: IButtonIconProps) => (props.margin ? "0.5em" : 0)};
  line-height: 0;
`;

type ButtonContentProps = Pick<ButtonProps, "icon" | "iconSize">;

const ButtonContent: React.FC<ButtonContentProps> = (props) => (
  <ButtonElementsContainer>
    {props.icon && (
      <IconContainer margin={props.children !== undefined}>
        <IconContext.Provider
          value={{
            size: props.iconSize ? props.iconSize.toString() + "px" : "30px",
          }}
        >
          {React.cloneElement(props.icon, {
            "data-testid": "testIcon",
          })}
        </IconContext.Provider>
      </IconContainer>
    )}
    {props.children}
  </ButtonElementsContainer>
);

export const Button: React.FC<ButtonProps> = (props) => {
  if (props.type === ButtonType.Secondary) {
    return (
      <Secondary
        style={props.style}
        className="button secondary"
        data-testid="buttontest"
        size={props.size}
        onClick={props.onclick}
        opacity={props.opacity}
        hideBackground={props.hideBackground}
        hovercolor={props.hovercolor}
        center={props.center}
        width={props.width}
        height={props.height}
        disabled={props.disabled}
        padding={props.padding}
      >
        <ButtonContent {...props} />
      </Secondary>
    );
  } else if (props.type === ButtonType.Tertiary) {
    return (
      <Tertiary
        style={props.style}
        className="button tertiary"
        data-testid="buttontest"
        size={props.size}
        onClick={props.onclick}
        opacity={props.opacity}
        hideBackground={props.hideBackground}
        hovercolor={props.hovercolor}
        center={props.center}
        width={props.width}
        height={props.height}
        disabled={props.disabled}
        padding={props.padding}
      >
        <ButtonContent {...props} />
      </Tertiary>
    );
  } else {
    return (
      <Primary
        style={props.style}
        className="button primary"
        data-testid="buttontest"
        size={props.size}
        onClick={props.onclick}
        opacity={props.opacity}
        hideBackground={props.hideBackground}
        hovercolor={props.hovercolor}
        width={props.width}
        center={props.center}
        height={props.height}
        disabled={props.disabled}
        padding={props.padding}
      >
        <ButtonContent {...props} />
      </Primary>
    );
  }
};

type StandardButtonProps = {
  color: string;
} & Pick<ButtonProps, "icon" | "iconSize" | "onclick" | "size">;

export const StandardButton: React.FC<StandardButtonProps> = (props) => (
  <Standard
    color={props.color}
    icon={props.icon}
    onClick={props.onclick}
    size={props.size}
  >
    <ButtonContent {...props} />
  </Standard>
);
