import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Layers } from "../../../types";
import { useGetComponentUnits } from "hooks/useGetComponentUnit";
import * as ships from "../../../ShipPos.json";
import WindTurbinesOverview from "./WindTurbinesOverview";
import LayerButtons from "./LayerButtons";
import { useResizeObserver } from "./WindTurbinesOverview/hooks";

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 50px;
`;

const LayerArea = styled.div`
  grid-row-start: 2;
  grid-row-end: -1;
  grid-column-start: 1;
  grid-column-end: -1;
  align-self: end;
`;

// Send the defaults to the config?
const DefaultLayerData: Layers = {
  personnel: true,
  production: true,
  camera: false,
  marine: false,
  map: false,
};

interface WindTurbineProps {
  plantId: string;
  sectionId: string | undefined;
}

export const WindTurbines: React.FC<WindTurbineProps> = (props) => {
  const [displayProductionButton, setProductionButtonDisplayState] =
    useState(false);

  const [layers, setLayers] = useState<Layers>(DefaultLayerData);

  useEffect(() => {
    setLayers(DefaultLayerData);
  }, [props.plantId]);

  const { data: windTurbines } = useGetComponentUnits(
    props.plantId,
    props.sectionId
  );

  const ref = useRef<HTMLDivElement | null>(null);

  useResizeObserver(ref);

  return (
    <Container ref={ref}>
      <LayerArea>
        <LayerButtons
          layers={layers}
          setLayers={setLayers}
          plantId={props.plantId}
          displayProductionButton={displayProductionButton}
        />
      </LayerArea>
      <WindTurbinesOverview
        setProductionButtonDisplayState={setProductionButtonDisplayState}
        plantId={props.plantId}
        sectionId={props.sectionId}
        layers={layers}
        componentUnits={windTurbines ? windTurbines : []}
        ships={ships as any}
      />
    </Container>
  );
};
