import React from "react";
import { Production } from "../../types";
import { useIntl } from "react-intl";
import { sum } from "../../utils/production-utils";
import ProductionChart from "components/ProductionChart";
import { Mode } from "components/ProductionChart/ProductionChart";
import { useLocalDateTimeFormatShort } from "hooks/useDateTimeFormat";

type MonthlyProductionChartProps = {
  data: Production | undefined;
  isFetching: boolean;
};

export const MonthlyProductionChart: React.FC<MonthlyProductionChartProps> = (
  props
) => {
  const { formatMessage } = useIntl();
  const dateTimeLocal = useLocalDateTimeFormatShort();

  return (
    <ProductionChart
      data={props.data}
      keyValue={sum(props.data)}
      mode={Mode.Month}
      isLoading={props.isFetching}
      title={formatMessage({
        id: "overview.months",
        defaultMessage: "This month (MWh)",
      })}
      getTooltipText={(actual: number, forecast: number, timestamp: number) => {
        return [
          {
            key: `${dateTimeLocal.format(timestamp)}:`,
            value: `${actual.toFixed(2).toString()} ${formatMessage({
              id: "pu.MWh",
              defaultMessage: "MWh",
            })}`,
          },
          {
            key: formatMessage({
              id: "pu.Estimated",
              defaultMessage: "Estimated total: ",
            }),
            value: forecast.toFixed(2).toString(),
          },
        ];
      }}
    />
  );
};
