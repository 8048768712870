import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import InfoCard from "components/InfoCard";
import ActiveConnectionSVG from "components/svg/ActiveConnectionSVG";
import { useGetComponentUnits } from "hooks/useGetComponentUnit";

const ConnectionStatus = styled.div`
  margin-left: ${(props) => props.theme.spacing.medium};
`;

const GridStatusContentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-basis: 75%;
`;

const GridStatusCountContainer = styled.div`
  font-size: ${(props) => props.theme.scaledFontSizes.large};
  font-weight: 600;
`;

const OF = styled.span`
  text-transform: uppercase;
  font-size: ${(props) => props.theme.scaledFontSizes.xs};
  color: ${(props) => props.theme.colors.textColor.quaternary};
`;

const ProducingUnits = styled.div`
  color: ${(props) => props.theme.colors.textColor.tertiary};
`;

type GridStatusProps = {
  plantId: string;
  sectionId?: string;
};

export const GridStatusCard: React.FC<GridStatusProps> = (props) => {
  const { formatMessage } = useIntl();

  const { data: windTurbines } = useGetComponentUnits(
    props.plantId,
    props.sectionId
  );

  return (
    <InfoCard
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      title="Grid Status"
      titleRightElement={
        <>
          <ActiveConnectionSVG noAnimation height={18} width={18} />
          <ConnectionStatus>
            {formatMessage({
              id: "overview.GridStatus.Normal",
              defaultMessage: "Translation Not Found",
            })}
          </ConnectionStatus>
        </>
      }
    >
      {windTurbines && (
        <GridStatusContentContainer>
          <GridStatusCountContainer>
            {windTurbines.length}{" "}
            <OF>
              {formatMessage({
                id: "overview.section.Of",
                defaultMessage: "Translation Not Found",
              })}
            </OF>{" "}
            {windTurbines.length}
          </GridStatusCountContainer>
          <ProducingUnits>
            {formatMessage({
              id: "overview.ProducingUnits",
              defaultMessage: "Translation Not Found",
            })}
          </ProducingUnits>
        </GridStatusContentContainer>
      )}
    </InfoCard>
  );
};
