export const propertyOf = <TObj>(name: keyof TObj) => name;

export type MeasurementUnitSymbol = {
    meter: string;
    metersPerSecond: string;
    degree: string;
    degreeCelcius: string;
    megaWatt: string;
    megaVoltAmpsReactive: string;
    megaWattHour: string;
    kiloWattHour: string;
    percentage: string;
    ampere: string;
    voltage: string;
    hour: string;
    dailyMWh: string;
    revolutionsPerMinute: string;
    hertz: string;
};

export const MeasurementUnitSymbolConstants: MeasurementUnitSymbol = {
  meter: "m",
  metersPerSecond: "m/s",
  degree: "°",
  degreeCelcius: "°C",
  megaWatt: "MW",
  megaVoltAmpsReactive: "MVAr",
  megaWattHour: "MWh",
  kiloWattHour: "kWh",
  percentage: "%",
  ampere: "A",
  voltage: "VAC",
  hour: "Hour",
  dailyMWh: "DailyMWh",
  revolutionsPerMinute: "RPM",
  hertz: "Hz"
};
