export const retSaveDateFormat = (inp: string) => {
  if (inp.includes(".")) {
    const str = inp.split(".");
    return str[1] + "/" + str[0] + "/" + str[2];
  } else {
    return inp;
  }
};

export const validateInput = (inputDate: string) => {
  try {
    // if (inputDate === "") return;
    const temp = new Date(retSaveDateFormat(inputDate));
    if (isNaN(temp.getTime())) {
      return false;
    } else {
      return true;
    }
  } catch {
    return false;
  }
};

export const getTimeDiffUTC = (timestamp: string) => {
  const dt = new Date();
  const utcStrNow = dt.toUTCString().replace("GMT", "").trim(); // remove time zone postfix, if not, date will be converted to UTC for the second time below.
  return (
    (
      (new Date(utcStrNow).valueOf() - new Date(timestamp).valueOf()) /
      1000
    ).toFixed(0) +
    " s, Timestamp: " +
    timestamp +
    " UTC"
  );
};

export const DateNow = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  return mm + "/" + dd + "/" + yyyy;
};
