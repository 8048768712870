import { RouteConstants, SearchConstants } from "../types";

export const getSectionId = (search: string) => {
  return (
    new URLSearchParams(search).get(SearchConstants.SECTION_ID) ?? undefined
  );
};

export const getType = (search: string) => {
  return new URLSearchParams(search).get(SearchConstants.TYPE) ?? undefined;
};

export const constructPlantUrl = (
  plantId: string,
  sectionId: string | undefined,
  type?: string | undefined
) => {
  const url = `${constructBaseUrl(plantId)}/${RouteConstants.Overview}`;
  return addQueryParams(url, sectionId, type);
};

export const constructComponentUnitUrl = (
  plantId: string,
  unitId: string | undefined,
  sectionId: string | undefined,
  type: string | undefined
) => {
  return addQueryParams(
    `${constructBaseUrl(plantId)}/${RouteConstants.ComponentUnit}/${unitId}`,
    sectionId,
    type
  );
};

export const constructAlarmUrl = (
  plantId: string,
  sectionId: string | undefined,
  type: string | undefined,
  alarmId?: string | undefined
) => {
  let url = `${constructBaseUrl(plantId)}/${RouteConstants.Alarm}`;
  if (alarmId) {
    url += `/${alarmId}`;
  }
  return addQueryParams(url, sectionId, type);
};

export const constructReportAlarmUrl = (
  plantId: string,
  sectionId: string | undefined,
  type: string | undefined,
  alarmId: string | undefined
) => {
  return addQueryParams(
    `${constructReportUrl(plantId)}/${RouteConstants.Alarm}/${alarmId}`,
    sectionId,
    type
  );
};

export const constructComponentUnitAlarmUrl = (
  plantId: string,
  sectionId: string | undefined,
  unitId: string | undefined,
  type: string | undefined,
  alarmId: string
) => {
  return addQueryParams(
    `${constructBaseUrl(plantId)}/${RouteConstants.ComponentUnit}/${unitId}/${
      RouteConstants.Alarm
    }/${alarmId}`,
    sectionId,
    type
  );
};

export const constructSecondDropdownUrl = (
  plantId: string,
  sectionId: string | undefined,
  type: string | undefined,
  url: string
) => {
  if (isAlarmRouteActive(url)) {
    return constructAlarmUrl(plantId, sectionId, type);
  }
  return constructPlantUrl(plantId, sectionId, type);
};

export const constructSingleLineUrl = (plantId: string) => {
  return `${constructBaseUrl(plantId)}/${RouteConstants.SingeLine}`;
};

export const constructReportUrl = (plantId: string) => {
  return `${constructBaseUrl(plantId)}/${RouteConstants.Report}`;
};

export const getRouteConstant = (url: string) => {
  if (isSingleLineRouteActive(url)) {
    return RouteConstants.SingeLine;
  }

  if (isReportRouteActive(url)) {
    return RouteConstants.Report;
  }

  if (isAlarmRouteActive(url)) {
    return RouteConstants.Alarm;
  }

  return RouteConstants.Overview;
};

const constructBaseUrl = (plantId: string) => {
  return `/plant/${plantId}`;
};

const addQueryParams = (
  url: string,
  sectionId: string | undefined,
  type: string | undefined
) => {
  if (sectionId) {
    url = `${url}?${SearchConstants.SECTION_ID}=${sectionId}`;
  }

  if (type) {
    url = `${sectionId ? `${url}&` : `${url}?`}${SearchConstants.TYPE}=${type}`;
  }
  return url;
};

const isAlarmRouteActive = (url: string) => {
  return url.indexOf(RouteConstants.Alarm) > -1;
};

const isSingleLineRouteActive = (url: string) => {
  return url.indexOf(RouteConstants.SingeLine) > -1;
};

const isReportRouteActive = (url: string) => {
  return url.indexOf(RouteConstants.Report) > -1;
};
