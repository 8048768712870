//STKN new card status for WT on main page

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InfoCard from "../../../../components/InfoCard";
import {
  useSensorSubscription,
  SensorData,
} from "../../../../hooks/useSubscription";
import { useIntl } from "react-intl";
import CardLabel from "../../../../components/CardLabel";
import { useTagsContext } from "../../../../hooks/useTags";
import NumberValueFormatter from "components/NumberValueFormatter";
import { MeasurementUnitSymbolConstants } from "utils/utils";
import MeasurementUnitAbbreviation from "components/MeasurementUnitAbbreviation";
import { useLocalDateFormat } from "hooks/useDateTimeFormat";

/* prettier-ignore */
const ProductionOverViewCardContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(10, minmax(0, auto));
  padding: ${(props) => props.theme.spacing.heavy};
  height: 85%;
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth} ) {
    padding: ${(props) => props.theme.spacing.medium}; 
  }
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth}) and (display-mode: fullscreen)  {
    padding: ${(props) => props.theme.spacing.medium}; 
  }
`;

/* prettier-ignore */
const DataDisplayContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 2rem;
  height: ${(props) => props.theme.numberSizes.medium};
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth} ) {
    height: ${(props) => props.theme.numberSizes.small};
  }
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth}) and (display-mode: fullscreen)  {
    height: ${(props) => props.theme.numberSizes.small};
  }
`;

const DataName = styled.div`
  grid-column: 1;
`;

const DataValue = styled.div`
  grid-column: 2;
`;

interface ProductionOverviewProps {
  componentUnitId: string;
}

export const ProductionOverviewCard: React.FC<ProductionOverviewProps> = (
  props
) => {
  const { tags } = useTagsContext();
  const [windSpeedGustMax, setWindSpeedGustMax] = useState<SensorData | null>(
    null
  );
  const [windSpeedMean, setWindSpeedMean] = useState<SensorData | null>(null);
  const [normalHours, setNormalHours] = useState<SensorData | null>(null);
  const [errorHours, setErrorHours] = useState<SensorData | null>(null);
  const [windInsideRange, setWindInsideRange] = useState<SensorData | null>(
    null
  );
  const [productionTime, setProductionTime] = useState<SensorData | null>(null);
  const [energy, setEnergy] = useState<SensorData | null>(null);
  const [gridFault, setGridFault] = useState<SensorData | null>(null);
  const [externalErrorHours, setExternalErrorHours] =
    useState<SensorData | null>(null);
  const [availability, setAvailability] = useState<SensorData | null>(null);
  const [resetDateTotal, setResetDateTotal] = useState<SensorData | null>(null);

  const { formatMessage } = useIntl();
  const dateTimeLocal = useLocalDateFormat();

  const { sensorData: productionOverview } = useSensorSubscription({
    filters: [
      {
        id: tags.WMETWdGustSpdMax, //Windspeed Gust Max Total
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WMETHorWdSpdMax, //Windspeed Mean Total
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WAVLAviTmsRs, //Ok Hours Total
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WAVLRsStatTot, //ResetDateTot
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WAVLErTmsRs, //Error Hours Total
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WAVLWndInRng, //Wind Inside Range Total
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WAVLOpTmsRs, //Production Time Generator 1 SubTotal
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WAVLRsStatSub, //ResetDateSub,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WAVLAccEnRs, //Energy Generator 1 SubTotal
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WAVLGrdFltTmhRs, //Grid Fault SubTotal
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WAVLExtErTmhRs, //External Error Total
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WAVLTurAvl, //Availability Total
        windTurbineId: props.componentUnitId,
      },
    ],
  });

  useEffect(() => {
    switch (productionOverview?.id) {
      case tags.WMETWdGustSpdMax:
        setWindSpeedGustMax(productionOverview);
        break;
      case tags.WMETHorWdSpdMax:
        setWindSpeedMean(productionOverview);
        break;
      case tags.WAVLAviTmsRs:
        setNormalHours(productionOverview);
        break;
      case tags.WAVLErTmsRs:
        setErrorHours(productionOverview);
        break;
      case tags.WAVLWndInRng:
        setWindInsideRange(productionOverview);
        break;
      case tags.WAVLOpTmsRs:
        setProductionTime(productionOverview);
        break;
      case tags.WAVLAccEnRs:
        setEnergy(productionOverview);
        break;
      case tags.WAVLExtErTmhRs:
        setExternalErrorHours(productionOverview);
        break;
      case tags.WAVLGrdFltTmhRs:
        setGridFault(productionOverview);
        break;
      case tags.WAVLTurAvl:
        setAvailability(productionOverview);
        break;
      case tags.WAVLRsStatTot:
        setResetDateTotal(productionOverview);
        break;
      default:
        break;
    }
  }, [productionOverview, tags]);

  const getResetDate = () => {
    const d = resetDateTotal ? resetDateTotal.value : "";
    const rset = d ? dateTimeFmt(new Date(d)) : "";
    const dateMessage = rset
      ? formatMessage({
          id: "prodOverview.ToolTipDate",
          defaultMessage: "Text missing",
        }) + rset
      : "";

    return dateMessage;
  };

  const dateTimeFmt = (value?: Date) => {
    if (value === null) {
      return "";
    }

    const res = dateTimeLocal.format(value);
    return res;
  };

  return (
    <InfoCard
      title={formatMessage({
        id: "prodOverview.Heading",
        defaultMessage: "Text missing",
      })}
      style={{ height: "100%" }}
      tooltip={
        formatMessage({
          id: "prodOverview.ToolTip",
          defaultMessage: "Text missing",
        }) + getResetDate()
      }
      className="productionOverview_infoCard"
    >
      <ProductionOverViewCardContainer className="productionOverviewContainer">
        <DataDisplayContainer className="productionOverview_energyDisplay">
          <DataName className="productionOverview_dataName">
            <CardLabel className="productionOverview_cardLabel">
              {formatMessage({
                id: "prodOverview.Energy",
                defaultMessage: "Text missing",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="productionOverview_dataValue">
            <NumberValueFormatter
              className="productionOverview_numberValueFormatter"
              value={Number(energy?.value)}
              precision={0}
            />
          </DataValue>
          {energy && (
            <MeasurementUnitAbbreviation
              measurementType={MeasurementUnitSymbolConstants.kiloWattHour}
            />
          )}
        </DataDisplayContainer>

        <DataDisplayContainer className="productionOverview_productionTimeDisplay">
          <DataName className="productionOverview_dataName">
            <CardLabel className="productionOverview_cardLabel">
              {formatMessage({
                id: "prodOverview.ProdTime",
                defaultMessage: "Text missing",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="productionOverview_dataValue">
            <NumberValueFormatter
              className="productionOverview_numberValueFormatter"
              value={Number(productionTime?.value)}
              precision={1}
            />
          </DataValue>
          {productionTime && (
            <MeasurementUnitAbbreviation
              measurementType={MeasurementUnitSymbolConstants.hour}
            />
          )}
        </DataDisplayContainer>

        <DataDisplayContainer className="productionOverview_windSpeedMeanDisplay">
          <DataName className="productionOverview_dataName">
            <CardLabel className="productionOverview_cardLabel">
              {formatMessage({
                id: "prodOverview.WindSpdMean",
                defaultMessage: "Text missing",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="productionOverview_dataValue">
            <NumberValueFormatter
              className="productionOverview_numberValueFormatter"
              value={Number(windSpeedMean?.value)}
              precision={1}
            />
          </DataValue>
          {windSpeedMean && (
            <MeasurementUnitAbbreviation
              measurementType={MeasurementUnitSymbolConstants.metersPerSecond}
            />
          )}
        </DataDisplayContainer>

        <DataDisplayContainer className="productionOverview_windSpeedMaxDisplay">
          <DataName className="productionOverview_dataName">
            <CardLabel className="productionOverview_cardLabel">
              {formatMessage({
                id: "prodOverview.WindSpdMax",
                defaultMessage: "Text missing",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="productionOverview_dataValue">
            <NumberValueFormatter
              className="productionOverview_numberValueFormatter"
              value={Number(windSpeedGustMax?.value)}
              precision={1}
            />
          </DataValue>
          {windSpeedGustMax && (
            <MeasurementUnitAbbreviation
              measurementType={MeasurementUnitSymbolConstants.metersPerSecond}
            />
          )}
        </DataDisplayContainer>

        <DataDisplayContainer className="productionOverview_normalHoursDisplay">
          <DataName className="productionOverview_dataName">
            <CardLabel className="productionOverview_cardLabel">
              {formatMessage({
                id: "prodOverview.NormalHour",
                defaultMessage: "Text missing",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="productionOverview_dataValue">
            <NumberValueFormatter
              className="productionOverview_numberValueFormatter"
              value={Number(normalHours?.value)}
              precision={1}
            />
          </DataValue>
          {normalHours && (
            <MeasurementUnitAbbreviation
              measurementType={MeasurementUnitSymbolConstants.hour}
            />
          )}
        </DataDisplayContainer>

        <DataDisplayContainer className="productionOverview_errorHoursDisplay">
          <DataName className="productionOverview_dataName">
            <CardLabel className="productionOverview_cardLabel">
              {formatMessage({
                id: "prodOverview.ErrHour",
                defaultMessage: "Text missing",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="productionOverview_dataValue">
            <NumberValueFormatter
              className="productionOverview_numberValueFormatter"
              value={Number(errorHours?.value)}
              precision={1}
            />
          </DataValue>
          {errorHours && (
            <MeasurementUnitAbbreviation
              measurementType={MeasurementUnitSymbolConstants.hour}
            />
          )}
        </DataDisplayContainer>

        <DataDisplayContainer className="productionOverview_availabilityDisplay">
          <DataName className="productionOverview_dataName">
            <CardLabel className="productionOverview_cardLabel">
              {formatMessage({
                id: "prodOverview.Availability",
                defaultMessage: "Text missing",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="productionOverview_dataValue">
            <NumberValueFormatter
              className="productionOverview_numberValueFormatter"
              value={Number(availability?.value)}
              precision={1}
            />
          </DataValue>
          {availability && (
            <MeasurementUnitAbbreviation
              measurementType={MeasurementUnitSymbolConstants.percentage}
            />
          )}
        </DataDisplayContainer>

        <DataDisplayContainer className="productionOverview_windInsideRangeDisplay">
          <DataName className="productionOverview_dataName">
            <CardLabel className="productionOverview_cardLabel">
              {formatMessage({
                id: "prodOverview.WindInsRa",
                defaultMessage: "Text missing",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="productionOverview_dataValue">
            <NumberValueFormatter
              className="productionOverview_numberValueFormatter"
              value={Number(windInsideRange?.value)}
              precision={1}
            />
          </DataValue>
          {windInsideRange && (
            <MeasurementUnitAbbreviation
              measurementType={MeasurementUnitSymbolConstants.hour}
            />
          )}
        </DataDisplayContainer>

        <DataDisplayContainer className="productionOverview_externalErrorDisplay">
          <DataName className="productionOverview_dataName">
            <CardLabel className="productionOverview_cardLabel">
              {formatMessage({
                id: "prodOverview.ExtErrTime",
                defaultMessage: "Text missing",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="productionOverview_dataValue">
            <NumberValueFormatter
              className="productionOverview_numberValueFormatter"
              value={Number(externalErrorHours?.value)}
              precision={1}
            />
          </DataValue>
          {externalErrorHours && (
            <MeasurementUnitAbbreviation
              measurementType={MeasurementUnitSymbolConstants.hour}
            />
          )}
        </DataDisplayContainer>

        <DataDisplayContainer className="productionOverview_gridFaultDisplay">
          <DataName className="productionOverview_dataName">
            <CardLabel className="productionOverview_cardLabel">
              {formatMessage({
                id: "prodOverview.GridFltTime",
                defaultMessage: "Text missing",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="productionOverview_dataValue">
            <NumberValueFormatter
              className="productionOverview_numberValueFormatter"
              value={Number(gridFault?.value)}
              precision={1}
            />
          </DataValue>
          {gridFault && (
            <MeasurementUnitAbbreviation
              measurementType={MeasurementUnitSymbolConstants.hour}
            />
          )}
        </DataDisplayContainer>
      </ProductionOverViewCardContainer>
    </InfoCard>
  );
};
