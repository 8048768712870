import {
  Mode,
  ProductionChart,
} from "components/ProductionChart/ProductionChart";
import React from "react";
import { useIntl } from "react-intl";
import { Production } from "../../types";
import { getCurrentHourData } from "../../utils/production-utils";
import { useLocalDateTimeFormatShort } from "hooks/useDateTimeFormat";

type HourlyProductionChartProps = {
  data: Production | undefined;
  isFetching: boolean;
};

export const HourlyProductionChart: React.FC<HourlyProductionChartProps> = (
  props
) => {
  const { formatMessage } = useIntl();
  const dateTimeLocal = useLocalDateTimeFormatShort();

  return (
    <ProductionChart
      data={props.data}
      mode={Mode.Hour}
      keyValue={getCurrentHourData(new Date().getHours(), props.data)}
      isLoading={props.isFetching}
      title={formatMessage({
        id: "overview.byHour",
        defaultMessage: "By Hour (MW)",
      })}
      getTooltipText={(actual: number, forecast: number, timestamp: number) => {
        return [
          {
            key: `${dateTimeLocal.format(timestamp)}:`,
            value: `${actual.toFixed(2).toString()} ${formatMessage({
              id: "pu.MWh",
              defaultMessage: "MWh",
            })}`,
          },
          {
            key: formatMessage({
              id: "pu.Potential",
              defaultMessage: "Potential: ",
            }),
            value: forecast.toFixed(2).toString(),
          },
        ];
      }}
    />
  );
};
