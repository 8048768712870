import {
  toast,
  ToastOptions,
  ToastContainer,
  UpdateOptions,
  Id,
} from "react-toastify";
import { ReactText, ReactElement } from "react";
import styled from "styled-components";
import React from "react";
import Wendy from "components/Wendy";
import { WendyHeadStyles, WendyBodyStyles } from "components/Wendy/Wendy";

const ToastContent = styled.div`
  display: flex;
  align-items: center;
`;

const ToastText = styled.div`
  margin-left: ${(props) => props.theme.spacing.medium};
`;

type Toasts = {
  success: (content: string | ReactElement) => ReactText;
  error: (content: string | ReactElement) => ReactText;
  warn: (content: string | ReactElement) => ReactText;
  info: (content: string | ReactElement) => ReactText;
  basic: (content: string | ReactElement) => ReactText;
  update: (toastId: Id, options?: UpdateOptions | undefined) => void;
};

const createToasts = (options?: ToastOptions): Toasts => {
  const defaults: ToastOptions = {
    ...options,
    hideProgressBar:
      options?.hideProgressBar !== undefined ? options.hideProgressBar : true,
    autoClose: options?.autoClose !== undefined ? options.autoClose : 2000,
    position: "top-right",
  };

  const generateId = () => "_" + Math.random().toString(36).substr(2, 9);

  const success = (content: string | ReactElement) =>
    toast(
      <ToastContent>
        <Wendy HeadStyle={WendyHeadStyles.Happy} />
        <ToastText>{content}</ToastText>
      </ToastContent>,
      {
        ...defaults,
        toastId: generateId(),
        type: "success",
      }
    );

  const error = (content: string | ReactElement) =>
    toast(
      <ToastContent>
        <Wendy
          BodyStyle={WendyBodyStyles.Alert}
          HeadStyle={WendyHeadStyles.Surprised}
        />
        <ToastText>{content}</ToastText>
      </ToastContent>,
      {
        ...defaults,
        toastId: generateId(),
        type: "error",
      }
    );

  const warn = (content: string | ReactElement) =>
    toast(content, { ...defaults, toastId: generateId(), type: "warning" });

  const info = (content: string | ReactElement) =>
    toast(content, { ...defaults, toastId: generateId(), type: "info" });

  const basic = (content: string | ReactElement) =>
    toast(content, { ...defaults, toastId: generateId() });

  const update = (toastId: Id, options?: UpdateOptions) =>
    toast.update(toastId, {
      hideProgressBar: defaults.hideProgressBar,
      autoClose: defaults.autoClose,
      position: defaults.position,
      ...options,
    });

  return {
    success,
    error,
    warn,
    info,
    basic,
    update,
  };
};

export const useToasts = (options?: ToastOptions) => {
  return createToasts(options);
};

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__close-button {
    color: ${(props) =>
      props.theme.colors.buttonColors.primaryButtonTextColor.primary};
  }
  .Toastify__toast-container {
  }
  .Toastify__toast {
    background: ${(props) => props.theme.colors.mainColor.primary};
    font-family: ${(props) => props.theme.primaryFontFamily};
    color: ${(props) =>
      props.theme.colors.buttonColors.primaryButtonTextColor.primary};
  }
  .Toastify__toast--error {
    background: ${(props) => props.theme.colors.warning};
  }
  .Toastify__toast--warning {
    background: ${(props) => props.theme.colors.signal};
  }
  .Toastify__toast--success {
    background: ${(props) => props.theme.colors.ready};
  }
  .Toastify__toast--info {
    color: ${(props) => props.theme.colors.textColor.primary};
  }
  .Toastify__toast-body {
    padding: ${(props) => props.theme.spacing.medium};
  }
  .Toastify__progress-bar {
  }
`;
