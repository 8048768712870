export const constructQueryName = (
  base: string,
  ids: Array<string | undefined>
) => {
  const queryName = ids.reduce(
    (prev, curr) => (curr ? `${prev}_${curr}` : prev),
    `${base}`
  );
  return queryName ? queryName : "fallback"; // TODO: robustify
};

export const getAlarmPrioritiesQueryName = (ids: Array<string | undefined>) => {
  return constructQueryName("alarmPriorities", ids);
};

export const getAlarmsQueryName = (ids: Array<string | undefined>): string => {
  return constructQueryName("alarms", ids);
};
