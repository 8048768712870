import React from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { getSectionId } from "../../utils/routing-utils";
import AlarmListContainer from "./AlarmListContainer";

const PageGrid = styled.div`
  display: grid;
  height: 100%;
  grid-column-gap: ${(props) => props.theme.gridColumnGap};
  grid-template-rows: auto 1fr;
  grid-template-columns: 5fr 1fr;
`;

type Params = {
  plantId: string;
  unitId: string | undefined;
};

export const AlarmsView: React.FC = () => {
  const { plantId, unitId } = useParams<Params>();
  const location = useLocation();
  const sectorId = getSectionId(location.search);

  return (
    <PageGrid>
      {plantId && (
        <AlarmListContainer
          plantId={plantId}
          sectionId={sectorId}
          componentUnitId={unitId}
        />
      )}
    </PageGrid>
  );
};
