import React from "react";
import { useScadaPlusTheme } from "../../../hooks/useScadaPlusTheme";

type BoatProps = {
  width: string;
  height: string;
};

export const OpenEditSVG: React.FC<BoatProps> = (props) => {
  const { theme } = useScadaPlusTheme();
  return (
    <svg
      width="43"
      height="50"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2063 21.0844L29.2906 15L23.2063 8.91561L20.9391 11.1828L23.1531 13.3969H16.6032V6.84691L18.8172 9.06096L21.0844 6.79375L15 0.709412L8.91561 6.79375L11.1828 9.06096L13.3969 6.84691V13.3969H6.84691L9.06096 11.1828L6.79375 8.91561L0.709412 15L6.79375 21.0844L9.06096 18.8172L6.84691 16.6031H13.3969V23.1531L11.1828 20.939L8.91561 23.2063L15 29.2906L21.0844 23.2063L18.8172 20.939L16.6032 23.1531V16.6031H23.1531L20.9391 18.8172L23.2063 21.0844Z"
        fill={theme.colors.buttonColors.primaryButtonColor}
        fillOpacity="0.6"
      />
    </svg>
  );
};
