import React, { useState } from "react";
import config from "../../../../../config";
import { useTagsContext } from "../../../../../hooks/useTags";
import { useScadaPlusTheme } from "../../../../../hooks/useScadaPlusTheme";
import { getTemperatureColor } from "../../../../../utils/tag-utils";
import { useIntl } from "react-intl";
import { useScadaPlusQuery } from "../../../../../hooks/useScadaPlusQuery";
import styled from "styled-components";
import LineChart from "components/LineChart";
import { Line } from "components/LineChart/LineChart";
import { useParams } from "react-router-dom";

type Temperature = {
  objectName: string;
  objectNameId: string;
  temperatures: Array<TemperatureDatum>;
};

type TemperatureDatum = {
  value: number;
  timestamp: string;
};

type WindProductionParams = {
  plantId: string;
  unitId: string;
};

type TemperaturesProps = {
  onError: (timestamp: Date | undefined, error: string | undefined) => void;
};

const GraphDataMissingMessage = styled.div`
  justify-content: center;
  display: flex;
`;

export const TemperaturesChart: React.FC<TemperaturesProps> = (props) => {
  const { tags } = useTagsContext();
  const { theme } = useScadaPlusTheme();
  const { formatMessage } = useIntl();
  const { unitId } = useParams<WindProductionParams>();
  const [lastQueriedTimestamp, setLastQueriedTimeStamp] = useState<
    Date | undefined
  >(undefined);

  const { data, isLoading } = useScadaPlusQuery<Array<Temperature>>(
    `temperatures-chart-${unitId}`,
    () => {
      const toDate = new Date();
      const fromDate = new Date(toDate);
      fromDate.setHours(toDate.getHours() - 4);
      return `${
        config.api.url
      }/temperatures?windTurbineId=${unitId} &fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`;
    },
    60 * 1000,
    (errorMessage) => {
      if (errorMessage.status) {
        props.onError(
          lastQueriedTimestamp,
          `Server response status: ${errorMessage.status} - ${errorMessage.text}`
        );
      } else {
        props.onError(lastQueriedTimestamp, errorMessage.text);
      }
    },
    () => {
      setLastQueriedTimeStamp(new Date());
    }
  );

  const lines: Array<Line> = data
    ? data.map((x) => {
        return {
          id: x.objectNameId,
          name: formatMessage({ id: `unit.Temperature.${x.objectName}` }),
          color: getTemperatureColor(x.objectNameId, tags, theme),
          data: x.temperatures.map((t) => {
            return {
              value: t.value,
              timestamp: new Date(t.timestamp),
            };
          }),
        };
      })
    : [];

  if (lines.length === 0 && !isLoading) {
    return (
      <GraphDataMissingMessage>
        {formatMessage({
          id: "pu.Temperatures.Graph.NoData",
          defaultMessage: "No data available",
        })}
      </GraphDataMissingMessage>
    );
  }

  return <LineChart lines={lines} min={0} max={60} />;
};
