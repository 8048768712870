import React from "react";
import styled from "styled-components";
import ContentGrid from "../../components/ContentGrid";
import { useParams } from "react-router-dom";
import ExpandableView from "./ExpandableView";
import { usePersonnelLocationCounter } from "../../hooks/useGetPersonnelOnSite";
import HourlyProductionCard from "components/HourlyProductionCard";
import MonthlyProductionCard from "components/MonthlyProductionCard";
import PersonnelCard from "./PersonnelCard";
import PositionAndWindCard from "./PositionAndWindCard";
import StatusCard from "./StatusCard";
import MotionAndPositionCard from "./MotionAndPositionCard";
import RotatingEquipment from "./RotatingEquipment";
import UnitControlCard from "./UnitControlCard/unitControlCard";

const StatusArea = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 1;
`;

const PersonnelArea = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 1;
`;

const RotatingEquipmentArea = styled.div`
  grid-column: 1 / 3;
  grid-row: 2 / span 2;
`;

const MotionArea = styled.div`
  grid-column: 3 / 4;
  grid-row: 1 / 2;
`;

const HourlyProductionArea = styled.div`
  grid-column: 4 / 5;
  grid-row: 1 / 2;
`;

const MonthlyProductionArea = styled.div`
  grid-column: 5 / -1;
  grid-row: 1 / 2;
`;

const PositionAndWindArea = styled.div`
  grid-column: 2 /3;
  grid-row: 4 / -1;
`;

const OperationalArea = styled.div`
  grid-column: 1 /2;
  grid-row: 4 / -1;
`;

type WindProductionParams = {
  plantId: string;
  unitId: string;
};

export const WindProductionUnit: React.FC = () => {
  const { plantId, unitId } = useParams<WindProductionParams>();
  const idCollection = { plantId: plantId, componentUnit: unitId };
  const { data: personnelLocationCounter } = usePersonnelLocationCounter(
    "",
    unitId
  );

  return (
    <ContentGrid key={unitId}>
      <StatusArea>
        <StatusCard componentUnitId={unitId} plantId={plantId} />
      </StatusArea>
      <PersonnelArea>
        <PersonnelCard
          componentUnitId={unitId}
          plantId={plantId}
          onSitePersonnelData={personnelLocationCounter}
        />
      </PersonnelArea>
      <RotatingEquipmentArea>
        <RotatingEquipment />
      </RotatingEquipmentArea>
      <PositionAndWindArea>
        <PositionAndWindCard componentUnitId={unitId} />
      </PositionAndWindArea>
      <OperationalArea>
        <UnitControlCard
          controlPermission={true}
          powerPlantId={plantId}
          unitId={unitId}
        />
      </OperationalArea>
      <MotionArea>
        <MotionAndPositionCard componentUnitId={unitId} />
      </MotionArea>
      <ExpandableView componentUnitId={unitId} plantId={plantId} />
      <HourlyProductionArea>
        <HourlyProductionCard idCollection={idCollection} />
      </HourlyProductionArea>
      <MonthlyProductionArea>
        <MonthlyProductionCard idCollection={idCollection} />
      </MonthlyProductionArea>
    </ContentGrid>
  );
};
