import InfoCard from "components/InfoCard";
import PositionAndWindAnimationSVG from "components/svg/PositionAndWindAnimationSVG";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useTheme } from "styled-components";
import { useSensorSubscription } from "../../../hooks/useSubscription";
import { useTagsContext } from "../../../hooks/useTags";

interface IComponentProps {
  componentUnitId: string;
}

export const PositionAndWindCard: React.FC<IComponentProps> = (props) => {
  const { tags } = useTagsContext();
  const theme = useTheme();
  const [surge, setSurge] = useState<number | undefined>(undefined);
  const [sway, setSway] = useState<number | undefined>(undefined);
  const [wspd, setWspd] = useState<number | undefined>(undefined);
  const [wdir, setWdir] = useState<number | undefined>(undefined);
  const { formatMessage } = useIntl();
  const [latDiff, setLatDiff] = useState<number | undefined>(undefined);
  const [lonDiff, setLonDiff] = useState<number | undefined>(undefined);

  const centerPoint = 87.5;
  const { sensorData: pos } = useSensorSubscription({
    filters: [
      {
        id: tags.WTOWSway,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTOWSurge,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WYAWYwAng,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WMETHorWdSpd,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTURLatitude,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTURLongitude,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTURValidity,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTURLatDiff,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTURLonDiff,
        windTurbineId: props.componentUnitId,
      },
    ],
  });

  useEffect(() => {
    switch (pos?.id) {
      case tags.WTOWSway:
        setSway(Number(pos.value) * 15 + centerPoint);
        break;
      case tags.WTOWSurge:
        setSurge(Number(pos.value) * -15 + centerPoint);
        break;
      case tags.WYAWYwAng:
        setWdir(Number(pos.value) + 90.0);
        break;
      case tags.WMETHorWdSpd:
        setWspd(Number(pos.value) * 2);
        break;
      case tags.WTURLatitude:
        //setLat(Number(pos.value));
        break;
      case tags.WTURLongitude:
        //setLon(Number(pos.value));
        break;
      case tags.WTURValidity:
        //setVal(Number(pos.value));
        break;
      case tags.WTURLatDiff:
        setLatDiff(Number(pos.value) * -5.0 + centerPoint);
        break;
      case tags.WTURLonDiff:
        setLonDiff(Number(pos.value) * 5.0 + centerPoint);
        break;
      default:
        break;
    }
  }, [pos, props, tags, centerPoint]);

  return (
    <InfoCard
      title={formatMessage({
        id: "pu.Position",
        defaultMessage: "Position",
      })}
      style={{ height: "100%" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "65%",
          width: "100%",
        }}
      >
        <PositionAndWindAnimationSVG
          width="130%"
          height="130%"
          posGnssX={lonDiff}
          posGnssY={latDiff}
          posMruX={sway}
          posMruY={surge}
          fill={theme.colors.mainColor.tertiary}
          colorMru={theme.colors.mainColor.primary}
          objectFill={theme.colors.mainColor.primary}
          r={wspd}
          angle={wdir}
        />
      </div>
    </InfoCard>
  );
};
