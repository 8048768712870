import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundColor.primary};
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: ${(props) => props.theme.borderRadius.heavy};
  padding: ${(props) => props.theme.spacing.heavy};
  &:hover {
    cursor: ${(props: CardProps) =>
      props.onClick !== undefined ? "pointer" : null};
  }
`;

type CardProps = {
  onClick?: () => void;
  style?: React.CSSProperties;
};

export const HexagonCard: React.FC<CardProps> = (props) => {
  return (
    <Container
      style={props.style}
      onClick={() => props.onClick && props.onClick()}
    >
      {props.children}
    </Container>
  );
};
