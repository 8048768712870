import React from "react";
import { useTheme } from "styled-components";

interface InfoSvgProps {
  width: number | string;
  height: number | string;
  color?: string;
}

export const InfoSvg: React.FC<InfoSvgProps> = (props) => {
  const theme = useTheme();
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 58 58"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <ellipse
          stroke={props.color ? props.color : theme.colors.textColor.secondary}
          ry="27.5"
          rx="29"
          id="svg_1"
          cy="29.89999"
          cx="30.2"
          fill={props.color ? props.color : theme.colors.textColor.secondary}
        />
        <text
          font-style="normal"
          transform="matrix(0.426021 0 0 0.28751 13.2364 9.12873)"
          stroke="none"
          font-weight="bold"
          text-anchor="start"
          font-family="'Roboto Slab'"
          font-size="180"
          id="svg_2"
          y="135.7717"
          x="11.35767"
          stroke-width="0"
          fill={
            props.color
              ? props.color
              : theme.colors.componentBackgroundColor.primary
          }
        >
          i
        </text>
      </g>
    </svg>
  );
};
