import React from "react";

interface IComponentProps {
  width: number;
  height: number;
  color?: string;
  style?: React.CSSProperties;
}

export const HeadSurprised: React.FC<IComponentProps> = (props) => {
  return (
    <svg
      data-testid="headsurprised"
      width={props.width}
      height={props.width / 2}
      viewBox="0 0 28 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6343 0H6.36566C2.84897 0 0 2.90909 0 6.5C0 10.0909 2.84897 13 6.36566 13H21.6343C25.151 13 28 10.0909 28 6.5C28 2.90909 25.151 0 21.6343 0Z"
        fill="url(#paint0_radial)"
      />
      <path
        d="M6.41018 10.6818C4.13991 10.6818 2.31479 8.81818 2.31479 6.5C2.31479 4.18181 4.13991 2.31818 6.41018 2.31818C8.68045 2.31818 10.5056 4.18181 10.5056 6.5C10.5056 8.81818 8.63593 10.6818 6.41018 10.6818ZM6.41018 4.13636C5.11924 4.13636 4.09539 5.18181 4.09539 6.5C4.09539 7.81818 5.11924 8.86363 6.41018 8.86363C7.70112 8.86363 8.72496 7.81818 8.72496 6.5C8.72496 5.22727 7.6566 4.13636 6.41018 4.13636Z"
        fill="#FF762E"
      />
      <path
        d="M21.8124 10.6818C19.5421 10.6818 17.717 8.81818 17.717 6.5C17.717 4.18181 19.5421 2.31818 21.8124 2.31818C24.0827 2.31818 25.9078 4.18181 25.9078 6.5C25.9078 8.81818 24.0827 10.6818 21.8124 10.6818ZM21.8124 4.13636C20.5215 4.13636 19.4976 5.18181 19.4976 6.5C19.4976 7.81818 20.5215 8.86363 21.8124 8.86363C23.1033 8.86363 24.1272 7.81818 24.1272 6.5C24.1272 5.22727 23.1033 4.13636 21.8124 4.13636Z"
        fill="#FF762E"
      />
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14 5.5) rotate(90) scale(7.5 16.1538)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EBEBEB" />
        </radialGradient>
      </defs>
    </svg>
  );
};
