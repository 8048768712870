import {
  useLocalDateTimeFormat,
  useUTCDateTimeFormat,
} from "hooks/useDateTimeFormat";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

const HeaderDateTimeDisplay = styled.span``;

export enum TimeZone {
  LOCAL = "local",
  UTC = "utc",
}
type ClockProps = {
  timeZone?: TimeZone;
};

export const Clock: React.FC<ClockProps> = (props) => {
  const [date, setDate] = useState(new Date());
  const dateTimeLocal = useLocalDateTimeFormat();
  const dateTimeUTC = useUTCDateTimeFormat();

  useEffect(() => {
    const timer = setInterval(() => tick(), 1000);

    return () => {
      clearInterval(timer);
    };
  });

  const tick = () => {
    setDate(new Date());
  };

  return props.timeZone === TimeZone.LOCAL ? (
    <>
      <HeaderDateTimeDisplay
        className="date-time-display--local"
        data-testid="datetestid"
      >
        {dateTimeLocal.format(date)}
      </HeaderDateTimeDisplay>
    </>
  ) : props.timeZone === TimeZone.UTC ? (
    <>
      <HeaderDateTimeDisplay
        className="date-time-display--utc"
        data-testid="datetestid"
      >
        {dateTimeUTC.format(date)}
      </HeaderDateTimeDisplay>
    </>
  ) : (
    <>
      <HeaderDateTimeDisplay data-testid="datetestid">{`${date.toLocaleDateString()}`}</HeaderDateTimeDisplay>
      <HeaderDateTimeDisplay data-testid="clocktestid">{`${date.toLocaleTimeString()}`}</HeaderDateTimeDisplay>
    </>
  );
};
