import React from "react";
import styled from "styled-components";
import { usePermissions } from "../../../hooks/usePermissions";

const Container = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.medium};
`;

const AccountPermissions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${(props) => props.theme.spacing.heavy};
`;

const AccountPowerPlantPermissions = styled.div`
  border-radius: ${(props) => props.theme.borderRadius.medium};
  margin: ${(props) => props.theme.spacing.light};
  padding: ${(props) => props.theme.spacing.heavy};
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.mainColor.quaternary};
`;

const PowerPlantPermissionsTitle = styled.div`
  color: ${(props) => props.theme.colors.mainColor.primary};
  font-size: ${(props) => props.theme.scaledFontSizes.mediumLarge};
  font-weight: 600;
`;

const AccessList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
`;

const AccountPowerPlantAccess = styled.div`
  font-size: ${(props) => props.theme.scaledFontSizes.small};
`;

export const AccountInfo: React.FC = () => {
  const account = usePermissions();

  return (
    <Container>
      <AccountPermissions>
        {account.permissions.map((x) => (
          <AccountPowerPlantPermissions key={x.id}>
            <PowerPlantPermissionsTitle>{x.name}:</PowerPlantPermissionsTitle>
            <AccessList>
              {x.permissions.map((a) => (
                <AccountPowerPlantAccess key={`${a}_${x.id}`}>
                  {a}
                </AccountPowerPlantAccess>
              ))}
            </AccessList>
          </AccountPowerPlantPermissions>
        ))}
      </AccountPermissions>
    </Container>
  );
};
