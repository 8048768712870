import { line, scaleLinear } from "d3";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import { GaugeNeedleProps } from "../gauge-types";

export const GaugeNeedle: React.FC<GaugeNeedleProps> = (props) => {
  const { theme } = useScadaPlusTheme();

  const needleLine: string | null = line()([
    [props.width / 2, 0],
    [0, -props.head],
    [-(props.width / 2), 0],
    [0, props.tail],
    [props.width / 2, 0],
  ]);

  const valueScale = scaleLinear()
    .domain([0, 1])
    .range([props.minAngle, props.maxAngle]);

  const needleValue = valueScale(props.value ?? 0);

  return (
    <path
      className="gauge-needle"
      d={needleLine === null ? undefined : needleLine}
      transform={`translate(${props.center.x}, ${props.center.y}) rotate(${needleValue})`}
      fill={theme.colors.iconColor}
      style={{
        transition: "all 0.25s 0.25s",
      }}
    />
  );
};
