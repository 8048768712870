import { useEffect, useState } from "react";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import { useScadaPlusErrorDispatch } from "./useScadaPlusError";
import { MessageDescriptor, useIntl } from "react-intl";
import { Dispatch, ErrorState } from "../providers/ScadaPlusErrorProvider";

type ConnectionState = {
  connection: HubConnection | null;
  error: Error | null;
  connectionId: string | null;
};

const createConnection = async (connectionUrl: string) => {
  return new HubConnectionBuilder()
    .withUrl(connectionUrl, {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
      // accessTokenFactory: () => data.accessToken, add access token back when security is included
    })
    .configureLogging(LogLevel.Error)
    .withAutomaticReconnect()
    .build();
};

const startConnection = (
  connectionUrl: string,
  dispatchError: Dispatch<ErrorState>,
  setState: React.Dispatch<React.SetStateAction<ConnectionState>>,
  formatMessage: (descriptor: MessageDescriptor) => string
) => {
  const connectionId = uuidv4();
  createConnection(connectionUrl).then((connection) => {
    connection
      .start()
      .then(() => {
        setState({
          error: null,
          connectionId: connectionId,
          connection: connection,
        });
      })
      // .catch(() => {
      //   dispatchError({
      //     show: true,
      //     text: formatMessage({
      //       id: "error.UnableToFetchData",
      //       defaultMessage:
      //         "We are really sorry - SCADA+ is experiencing issues retrieving data. Please contact Origo Support Centre: +47 932 38 009",
      //     }),
      //   });
      // });
  });
};

/* This hook sets up a connection that runs for the life time of the application */
const useGlobalSignalRConnection = (connectionUrl: string) => {
  const dispatchError = useScadaPlusErrorDispatch();
  const { formatMessage } = useIntl();
  const [state, setState] = useState<ConnectionState>({
    connection: null,
    error: null,
    connectionId: null,
  });

  useEffect(() => {
    startConnection(connectionUrl, dispatchError, setState, formatMessage);
  }, [connectionUrl, formatMessage, dispatchError]);

  return state;
};

/* This hook sets up a connection that runs for a specific page */
const useSignalRConnection = (connectionUrl: string) => {
  const dispatchError = useScadaPlusErrorDispatch();
  const { formatMessage } = useIntl();
  const location = useLocation();
  const [state, setState] = useState<ConnectionState>({
    connection: null,
    error: null,
    connectionId: null,
  });

  useEffect(() => {
    startConnection(connectionUrl, dispatchError, setState, formatMessage);
  }, [location.pathname, connectionUrl, formatMessage, dispatchError]);

  return state;
};

export { useSignalRConnection, useGlobalSignalRConnection };
