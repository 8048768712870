import InfoCard from "components/InfoCard";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

const RotatingEquipmentImage = styled.img`
  height: 90%;
  width: 150%;
  padding: ${(props) => props.theme.spacing.heavy};
`;
export const RotatingEquipment: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <InfoCard
      title={formatMessage({
        id: "pu.RotatingEquipment",
        defaultMessage: "Translation Not Found",
      })}
      style={{ height: "100%" }}
    >
      <RotatingEquipmentImage src="/placeholder/rotating_equipment.jpg" />
    </InfoCard>
  );
};
