import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InfoCard from "components/InfoCard";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import { GenericAlarmPriorityCounter } from "types";
import AlarmPriorities from "./AlarmPriorities";
import { getTotalNumberOfAlarms } from "utils/alarm-utils";
import { useIntl } from "react-intl";
import { useScadaPlusQuery } from "hooks/useScadaPlusQuery";
import config from "config";

const CardContentContainer = styled.div`
  contain: content;
`;

type AlarmCountOverviewProps = {
  sectionId?: string;
  plantId: string;
};

export const AlarmCountOverviewCard: React.FC<AlarmCountOverviewProps> = (
  props
) => {
  const { theme } = useScadaPlusTheme();
  const [alarmCount, setAlarmCount] = useState(0);
  const { formatMessage } = useIntl();
  const alarmPrioritiesQueryName = `alarmPriorities-plant-${props.plantId}_${props.sectionId}`;

  const { data: alarmPriorityData, isLoading: alarmPriorityDataLoading } =
    useScadaPlusQuery<Array<GenericAlarmPriorityCounter>>(
      alarmPrioritiesQueryName,
      () => {
        if (props.sectionId) {
          return `${config.api.url}/${config.api.genericAlarmPriorityCounters}?SectionId=${props.sectionId}`;
        }

        return `${config.api.url}/${config.api.genericAlarmPriorityCounters}?PlantId=${props.plantId}`;
      }
    );

  useEffect(() => {
    if (alarmPriorityData) {
      setAlarmCount(getTotalNumberOfAlarms(alarmPriorityData));
    }
  }, [alarmPriorityData]);

  return (
    <InfoCard
      title={formatMessage({
        id: "overview.Alarms",
        defaultMessage: "Alarms",
      })}
      keyNumber={alarmCount}
      defaultZero
    >
      <CardContentContainer>
        {!alarmPriorityDataLoading && alarmPriorityData && (
          <AlarmPriorities
            alarmPriorityData={alarmPriorityData}
            theme={theme}
          />
        )}
      </CardContentContainer>
    </InfoCard>
  );
};
