import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import config from "config";

const configuration: Configuration = {
  auth: {
    clientId: config.clientId,
    authority: `https://login.microsoftonline.com/${config.tenantId}`,
    redirectUri: config.redirectUri,
  },
};

export default new PublicClientApplication(configuration);
