import React from "react";
import { scaleBand, scaleLinear } from "d3";
import { TotalEventsGraphProps } from "./interfaces";
import BottomAxis from "./BottomAxis";
import LeftAxis from "./LeftAxis";
import EventGraphBars from "./EventGraphBars";

export const TotalEventsGraph: React.FC<TotalEventsGraphProps> = (props) => {
  const margin = { top: 10, right: 0, bottom: 20, left: 30 };
  const width = 500 - margin.left - margin.right;
  const height = 200 - margin.top - margin.bottom;

  const scaleX = scaleBand()
    .domain(props.data.map(({ label }) => label))
    .range([0, width])
    .padding(0.5);

  // toDo: figure out how to fix the "possibly undefined" issue
  const scaleY = scaleLinear()
    .domain([0, Math.max(...props.data.map(({ value }) => value))])
    .range([height, 0]);

  return (
    <svg
      width={width + margin.left + margin.right}
      height={height + margin.top + margin.bottom}
    >
      <g transform={`translate(${margin.left}, ${margin.top})`}>
        <BottomAxis scale={scaleX} transform={`translate(0, ${height})`} />
        <LeftAxis scale={scaleY} />
        <EventGraphBars
          data={props.data}
          height={height}
          scaleX={scaleX}
          scaleY={scaleY}
        />
      </g>
    </svg>
  );
};
