import Loader from "components/Loader";
import MeasurementUnitAbbreviation from "components/MeasurementUnitAbbreviation";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const NumberContainer = styled.div`
  display: flex;
`;

/* prettier-ignore */
const Number = styled.div<alignProps>`
  font-size: ${(props) => props.theme.numberSizes.medium};
  line-height: ${(props) => props.theme.numberSizes.medium};
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth} ) {
      font-size: ${(props) => props.theme.numberSizes.small};
      line-height: ${(props) => props.theme.numberSizes.small}; 
  }
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth}) and (display-mode: fullscreen)  {
      font-size: ${(props) => props.theme.numberSizes.small};
      line-height: ${(props) => props.theme.numberSizes.small};  
  }
  text-align: ${(alignProps) =>
    alignProps.horizontalAlignment ? alignProps.horizontalAlignment : "right"};
  width: 100%;
`;

type alignProps = {
  horizontalAlignment?: string;
};

interface NumberValueFormatterProps {
  align?: string;
  value?: number;
  precision: number;
  measurementType?: string;
  loaderSize?: number;
  loaderPadding?: string;
  loaderAlign?: string;
  className?: string;
}

export const NumberValueFormatter: React.FC<NumberValueFormatterProps> = (
  props
) => {
  const [, setPreviousValue] = useState(0);
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    setPreviousValue(currentValue);
    setCurrentValue(props.value !== undefined ? props.value : 0);
  }, [currentValue, props.value]);

  const renderNoNumberValuePlaceholder = () => {
    return "-";
  };

  const renderPrecisionValue = (precision: number) => {
    if (currentValue === undefined || isNaN(currentValue)) {
      return renderNoNumberValuePlaceholder();
    } else {
      return currentValue.toFixed(precision);
    }
  };

  return (
    <div className={props.className}>
      {props.value === 0 || props.value ? (
        <NumberContainer>
          <Number horizontalAlignment={props.align}>
            <div>{renderPrecisionValue(props.precision)}</div>
          </Number>
          {props.measurementType && (
            <MeasurementUnitAbbreviation
              measurementType={props.measurementType}
            />
          )}
        </NumberContainer>
      ) : (
        <Loader
          size={props.loaderSize ? props.loaderSize : 20}
          style={{
            display: "flex",
            alignItems: props.loaderAlign ? props.loaderAlign : "flex-end",
            padding: props.loaderPadding,
          }}
        />
      )}
    </div>
  );
};
