import React from "react";
import ContentGrid from "components/ContentGrid";
import ProductionReport from "./ProductionReport";
import ReportChart from "./ReportChart";
import ReliabilityReport from "./ReliabilityReport";
import AlarmsReport from "./AlarmsReport";
import { useParams } from "react-router-dom";
import TransitionsReport from "./TransitionsReport";

type ReportParams = {
  plantId: string;
  unitId: string;
};

export const Report: React.FC = () => {
  const { plantId, unitId } = useParams<ReportParams>();
  return (
    <ContentGrid key={plantId}>
      <ReportChart plantId={plantId} />
      <ProductionReport />
      <ReliabilityReport />
      <TransitionsReport />
      <AlarmsReport plantId={plantId} unitId={unitId}></AlarmsReport>
    </ContentGrid>
  );
};
