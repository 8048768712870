import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { getTotalNumberOfAlarms } from "utils/alarm-utils";
import OrigoTooltip from "components/OrigoTooltip";
import { GenericAlarm, GenericAlarmPriorityCounter } from "types";

const AlarmMessageContainer = styled.div<ListedAlarmsMessageStyleProps>`
  display: flex;
  height: ${(props) =>
    props.containerHeight ? props.containerHeight : "100%"};
  &:hover {
    cursor: pointer;
  }
`;

const DisplayedAlarmsMessage = styled.div<ListedAlarmsMessageStyleProps>`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.scaledFontSizes.small};
  color: ${(props) => props.theme.colors.textColor.tertiary};
  width: 100%;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  padding-left: ${(props) => (props.padding ? props.padding : "")};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "")};
`;

type ListedAlarmsMessageStyleProps = {
  fontSize?: string;
  textAlign?: string;
  padding?: string;
  containerHeight?: string;
  alignSelf?: string;
};

type ListedAlarmsMessageProps = {
  activeAlarms: GenericAlarm[] | undefined;
  alarmPriorityData: GenericAlarmPriorityCounter[] | undefined;
  fontSize?: string;
  textAlign?: string;
  padding?: string;
  containerHeight?: string;
  alignSelf?: string;
};

export const ListedAlarmsMessage: React.FC<ListedAlarmsMessageProps> = (
  props
) => {
  const { formatMessage } = useIntl();

  const listedAlarmsTooltip = () => {
    if (props.activeAlarms && props.alarmPriorityData) {
      if (getTotalNumberOfAlarms(props.alarmPriorityData) < 1) {
        return formatMessage({
          id: "alarms.noAlarms",
          defaultMessage: "Text missing",
        });
      } else if (
        props.activeAlarms?.length <
        getTotalNumberOfAlarms(props.alarmPriorityData)
      ) {
        return formatMessage({
          id: "alarms.Tooltip.SeeInsight",
          defaultMessage: "Text missing",
        });
      } else {
        return formatMessage({
          id: "alarms.Tooltip.ActiveAlarmsOnly",
          defaultMessage: "Text missing",
        });
      }
    }

    return formatMessage({
      id: "alarms.noAlarms",
      defaultMessage: "Text missing",
    });
  };

  return (
    <OrigoTooltip render={listedAlarmsTooltip()}>
      <AlarmMessageContainer containerHeight={props.containerHeight}>
        {props.alarmPriorityData && (
          <DisplayedAlarmsMessage
            fontSize={props.fontSize}
            textAlign={props.textAlign}
            padding={props.padding}
            alignSelf={props.alignSelf}
            className="DisplayedAlarmsMessage"
          >
            {formatMessage({
              id: "alarms.Displaying",
              defaultMessage: "Text missing",
            })}
            {props.activeAlarms?.length}
            {formatMessage({
              id: "alarms.OutOf",
              defaultMessage: "Text missing",
            })}
            {getTotalNumberOfAlarms(props.alarmPriorityData)}
          </DisplayedAlarmsMessage>
        )}
      </AlarmMessageContainer>
    </OrigoTooltip>
  );
};
