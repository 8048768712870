import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import styled, { DefaultTheme } from "styled-components";

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props: SIFAlertStyleProps) => props.backgroundColor};
  border-radius: ${(props: SIFAlertStyleProps) =>
    props.theme.borderRadius.light};
  height: 4rem;
`;

interface SIFAlertStyleProps {
  backgroundColor?: string;
  theme: DefaultTheme;
}

interface SIFAlertProps {
  alert: boolean;
  title: string;
}

export const SIFAlert: React.FC<SIFAlertProps> = (props) => {
  const { theme } = useScadaPlusTheme();

  const resolveSIFAlertBackgroundColor = () => {
    return props.alert
      ? theme.colors.warning
      : theme.colors.cardBackgroundHover;
  };

  return (
    <ContentContainer backgroundColor={resolveSIFAlertBackgroundColor()}>
      {props.title}
    </ContentContainer>
  );
};
