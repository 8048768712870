import React from "react";
import { axisLeft, select } from "d3";
import { useEffect, useRef } from "react";
import { LeftAxisProps } from "../interfaces";

export const LeftAxis: React.FC<LeftAxisProps> = (props) => {
  const ref = useRef<SVGGElement>(null);
  useEffect(() => {
    if (ref.current) {
      select(ref.current).call(axisLeft(props.scale));
    }
  }, [props.scale]);

  return <g ref={ref} />;
};
