import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import { useScadaPlusTheme } from "../../hooks/useScadaPlusTheme";
import { useIntl } from "react-intl";
import { useLocalDateTimeFormat } from "hooks/useDateTimeFormat";

interface LineChartChartProps {
  lines: Array<Line>;
  max: number;
  min: number;
}

export type Line = {
  id: string;
  name: string;
  color: string;
  data: Array<Datum>;
};

export type Datum = {
  timestamp: Date;
  value: number;
};

type ContainerProps = {
  padding: string;
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: ${(props: ContainerProps) => {
    return props.padding;
  }};
`;

export const LineChart = (props: LineChartChartProps) => {
  const { theme } = useScadaPlusTheme();
  const { formatMessage } = useIntl();
  const dateTimeLocal = useLocalDateTimeFormat();
  const d3Container = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (props.lines.length > 0 && d3Container.current) {
      const svg = d3.select(d3Container.current);
      const { width, height } = d3Container.current.getBoundingClientRect();
      svg.attr("viewBox", `0 0 ${width} ${height}`);

      const xScale = d3.scaleTime().rangeRound([0, width]);

      const zScale = d3.scaleOrdinal(props.lines.map((x) => x.color));

      const dataset = props.lines.map((l) => {
        return {
          label: l.name,
          values: l.data.map(function (d: Datum) {
            return { timestamp: new Date(d.timestamp), value: d.value };
          }),
        };
      });

      const min = d3.min(dataset, function (c) {
        return d3.min(c.values, function (d) {
          return d.timestamp;
        });
      });

      const max = d3.max(dataset, function (c) {
        return d3.max(c.values, function (d) {
          return d.timestamp;
        });
      });

      const yScale = d3
        .scaleLinear()
        .range([height, 0])
        .domain([props.min, props.max]);

      xScale.domain([min!, max!]);

      const line = d3
        .line<Datum>()
        .curve(d3.curveCardinal)
        .x((d: Datum) => {
          return xScale(d.timestamp)!;
        })
        .y((d: Datum) => {
          return yScale(d.value)!;
        });
      const lines = svg
        .selectAll(".line-data")
        .data(dataset)
        .enter()
        .append("g")
        .attr("class", "line-data")
        .attr("data-testid", "line-data");

      lines
        .append("path")
        .attr("class", "line")
        .style("stroke", function (d: any) {
          return zScale(d.label);
        })
        .style("vector-effect", "non-scaling-stroke")
        .style("stroke-width", "2px")
        .style("fill", "none")
        .transition()
        .duration(200)
        .ease(d3.easeLinear)
        .on("start", (a, b, c) => {
          d3.select(c[b])!.attr("d", function (d: any) {
            return line(d.values);
          });
        });

      // Tooltip
      svg.select(".focus").remove();
      svg.select(".overlay").remove();
      svg.select(".tooltip").remove();

      const focus = svg
        .append("g")
        .attr("class", "focus")
        .append("circle")
        .style("fill", theme.colors.mainColor.primary)
        .attr("r", 4)
        .style("display", "none");

      const tooltip = svg
        .append("g")
        .attr("class", "tooltip")
        .style("display", "none");

      tooltip
        .append("text")
        .attr("class", "textKey")
        .attr("y", 20)
        .attr("x", 30)
        .style("text-anchor", "start")
        .attr("font-size", theme.constantFontSizes.small)
        .attr("fill", theme.colors.textColor.primary);

      tooltip
        .append("text")
        .attr("class", "textValue")
        .attr("y", 20)
        .attr("x", 80)
        .attr("font-weight", "600")
        .attr("font-size", theme.constantFontSizes.small)
        .attr("fill", theme.colors.textColor.primary);

      tooltip
        .append("text")
        .attr("class", "textKey2")
        .attr("y", 20)
        .attr("x", 30)
        .style("text-anchor", "start")
        .attr("font-size", theme.constantFontSizes.small)
        .attr("fill", theme.colors.textColor.primary);

      tooltip
        .append("text")
        .attr("class", "textValue2")
        .attr("y", 20)
        .attr("x", 80)
        .attr("font-weight", "600")
        .attr("font-size", theme.constantFontSizes.small)
        .attr("fill", theme.colors.textColor.primary);

      svg
        .append("g")
        .attr("class", "overlay")
        .append("rect")
        .attr("fill", "none")
        .attr("width", width)
        .attr("height", height)
        .on("mouseover", function () {
          focus.style("display", null);
          tooltip.style("display", null);
        })
        .on("mouseout", function () {
          focus.style("display", "none");
          tooltip.style("display", "none");
        })
        .style("pointer-events", "all")
        .on("mousemove", (a, b, c) => {
          const pointer = d3.mouse(c[b]!);
          const xm = xScale.invert(pointer[0]);
          const ym = yScale.invert(pointer[1]);

          let lineToShow: Line | undefined = undefined;
          let datum: Datum | undefined = undefined;
          let minValue = 454545454;

          for (let li = 0; li < props.lines.length; li++) {
            const l: Line = props.lines[li];
            const timestamps = l.data.map((x) => x.timestamp);
            const i1 = d3.bisectLeft(timestamps, xm, 1);
            const i0 = i1 - 1;
            const i =
              xm.getTime() - timestamps[i0].getTime() >
              timestamps[i1].getTime() - xm.getTime()
                ? i1
                : i0;
            const d: Datum = l.data[i];
            const distance = Math.abs(d.value - ym);
            if (distance < minValue) {
              lineToShow = l;
              minValue = distance;
              datum = d;
            }
          }
          if (lineToShow && datum) {
            const x = xScale(datum.timestamp)!;
            const y = yScale(datum.value)!;
            focus
              .attr("cx", xScale(datum.timestamp)!)
              .attr("cy", yScale(datum.value)!);

            const adjustedX = x + 170 > width ? x - 170 : x + 40;
            const adjustedY = y + 150 > height ? y - 50 : y + 30;
            tooltip
              .select(".textKey")
              .attr("x", adjustedX)
              .attr("y", adjustedY);

            tooltip
              .select(".textValue")
              .attr("x", adjustedX + 90)
              .attr("y", adjustedY);

            tooltip
              .select(".textKey2")
              .attr("x", adjustedX)
              .attr("y", adjustedY + 30);

            tooltip
              .select(".textValue2")
              .attr("x", adjustedX + 90)
              .attr("y", adjustedY + 30);

            tooltip.select(".textKey").text(lineToShow.name);
            tooltip.select(".textValue").text(`${datum.value.toFixed(2)} °C`);

            // STKN time tooltip label + flip TP up/down bottom
            const lblTime = {
              id: formatMessage({
                id: "unit.Temperature.Timelbl",
                defaultMessage: "Time",
              }),
            };

            const tpDate = {
              value: dateTimeLocal.format(datum.timestamp),
            };

            tooltip.select(".textKey2").text(lblTime.id);
            tooltip.select(".textValue2").text(`${tpDate.value}`);
          }
        });
    }
  }, [props.lines, props.min, props.max, theme, formatMessage, dateTimeLocal]);

  return (
    <Container padding={theme.spacing.heavy}>
      <svg
        key={theme.name}
        ref={d3Container}
        preserveAspectRatio="none"
        width={"100%"}
        height={"100%"}
      ></svg>
    </Container>
  );
};
