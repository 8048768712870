import React from "react";
import styled from "styled-components";
import { Section } from "types";
import config from "config";
import { useParams } from "react-router-dom";
import LiveSectorDisplay from "pages/Overview/Sections/LiveSectionDisplay";
import { useScadaPlusQuery } from "hooks/useScadaPlusQuery";

type SectionsProps = {
  plantId: string;
};

const Container = styled.div`
  display: flex;
  height: 100%;
  max-width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  & > * {
    flex-basis: 20%;
    margin-left: ${(props) => props.theme.spacing.heavy};
    margin-right: ${(props) => props.theme.spacing.heavy};
  }
`;

type Params = {
  plantId: string;
};

export const Sections: React.FC<SectionsProps> = (props) => {
  const { plantId } = useParams<Params>();

  const { data: sectionData } = useScadaPlusQuery<Array<Section>>(
    `sector-overview-${plantId}`,
    `${config.api.url}/${config.api.plants}/${plantId}/${config.api.sections}`
  );

  return (
    <Container>
      {sectionData &&
        sectionData.map((section) => (
          <LiveSectorDisplay key={section.id} section={section} />
        ))}
    </Container>
  );
};
