import styled from "styled-components";

export const TextInputTitle = styled.div`
  background-color: ${(props) => props.theme.colors.textInputBackgroundColor};
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
  color: ${(props) => props.theme.colors.textColorInverse.half};
  font-family: ${(props) => props.theme.primaryFontFamily};
  font-weight: normal;
  line-height: 19px;
  border-radius: ${(props) => props.theme.borderRadius.medium}
    ${(props) => props.theme.borderRadius.medium} 0 0;
  padding: ${(props) => props.theme.spacing.medium};
`;
