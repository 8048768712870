import React from "react";
import styled from "styled-components";
import BodyAlert from "./svg/BodyAlert";
import BodyEmpty from "./svg/BodyEmpty";
import BodyNoPower from "./svg/BodyNoPower";
import BodyPower from "./svg/BodyPower";
import HeadEyesShut from "./svg/HeadEyesShut";
import { HeadHappy } from "./svg/HeadHappy/HeadHappy";
import HeadSad from "./svg/HeadSad";
import HeadSurprised from "./svg/HeadSurprised";

export enum WendyHeadStyles {
  Happy,
  Sad,
  Surprised,
  EyesShut,
}

export enum WendyBodyStyles {
  Empty,
  Alert,
  Power,
  NoPower,
}

export type WendyProps = {
  HeadStyle?: WendyHeadStyles;
  BodyStyle?: WendyBodyStyles;
  size?: number;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${(props) => props.theme.spacing.light};
`;

const Spacer = styled.div`
  margin-top: 2px;
  margin-bottom: 2px;
`;

export const Wendy: React.FC<WendyProps> = (props) => {
  const size = props.size ? props.size : 24;

  const renderHead = () => {
    switch (props.HeadStyle) {
      case WendyHeadStyles.Happy:
        return <HeadHappy width={size} height={size} />;
      case WendyHeadStyles.Sad:
        return <HeadSad width={size} height={size} />;
      case WendyHeadStyles.Surprised:
        return <HeadSurprised width={size} height={size} />;
      case WendyHeadStyles.EyesShut:
        return <HeadEyesShut width={size} height={size} />;

      default:
        return <HeadHappy width={size} height={size} />;
    }
  };

  const renderBody = () => {
    switch (props.BodyStyle) {
      case WendyBodyStyles.Empty:
        return <BodyEmpty width={size} height={size} />;
      case WendyBodyStyles.Alert:
        return <BodyAlert width={size} height={size} />;
      case WendyBodyStyles.NoPower:
        return <BodyNoPower width={size} height={size} />;
      case WendyBodyStyles.Power:
        return <BodyPower width={size} height={size} />;

      default:
        return <BodyEmpty width={size} height={size} />;
    }
  };

  return (
    <Container>
      {renderHead()}
      <Spacer />
      {renderBody()}
    </Container>
  );
};
