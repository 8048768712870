import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { GenericMenu } from "../../types";
import Button from "../Button";
import { MdBrightness2, MdPerson, MdWbSunny } from "react-icons/md";
import {
  useScadaPlusTheme,
  useScadaPlusThemeDispatch,
} from "../../hooks/useScadaPlusTheme";
import { light, dark, ThemeNames } from "../../theme";
import { useIntl } from "react-intl";
import {
  useScadaPlusLanguage,
  useScadaPlusLanguageDispatch,
} from "../../hooks/useScadaPlusLanguage";

import User from "../User";
import Clock from "components/Clock";
import { TimeZone } from "components/Clock/Clock";
import NavigationMenu from "components/NavigationMenu";
import HomeButton from "components/HomeButton";
import AboutButton from "components/AboutButton";

type MobileProps = {
  mobile?: boolean;
};
const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props: WindPowerPlantAreaProps) =>
    props.mobile ? "repeat(4,1fr) 1fr" : "repeat(14, 1fr) 1fr 2fr 1fr"};
  grid-column-gap: ${(props: WindPowerPlantAreaProps) =>
    props.mobile ? "3em" : "1em"};
  grid-template-rows: 1fr;
  > *:not(:last-child) {
    padding: ${(props: MobileProps) =>
        props.mobile
          ? (props) => props.theme.spacing.heavy
          : (props) => props.theme.spacing.medium}
      0;
  }
`;

const ScadaPlusArea = styled.div`
  grid-column: 1 / 2;
  place-self: center;
  margin-left: 0.5rem;
`;

type WindPowerPlantAreaProps = {
  mobile?: boolean;
};

const ButtonsArea = styled.div`
  grid-column: ${(props: WindPowerPlantAreaProps) =>
    props.mobile ? "4/-1" : "11 / 15"};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${(props) => props.theme.spacing.heavy};
`;

const CopyrightText = styled.span`
  font-size: 0.6em;
  color: ${(props) => props.theme.colors.mainColor.quaternary};
`;

const LanguageText = styled.div`
  font-size: ${(props) => props.theme.constantFontSizes.medium};
  color: ${(props) => props.theme.colors.headerButtonIconColor};
  display: flex;
  place-items: center;
`;

const ClockArea = styled.div`
  grid-column: 15 / 17;
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.scaledFontSizes.small};
  justify-content: center;
  margin-left: 3.2em;
  font-weight: 600;
  line-height: 1.3;
  height: 100%;
`;

const LocalTime = styled.div`
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
  color: ${(props) => props.theme.colors.mainColor.primary};
`;
const UTCTime = styled.div`
  font-size: ${(props) => props.theme.scaledFontSizes.xs};
  color: ${(props) => props.theme.colors.mainColor.secondary};
`;
const UTC = styled.span`
  font-size: ${(props) => props.theme.scaledFontSizes.xs};
`;
const OrigoLogoArea = styled.div`
  grid-column: 17/-1;
  place-self: center;
`;

type HeaderProps = {
  menu: GenericMenu;
  mobile?: boolean;
};

export const Header: React.FC<HeaderProps> = (props) => {
  const history = useHistory();
  const dispatchTheme = useScadaPlusThemeDispatch();
  const language = useScadaPlusLanguage();
  const dispatchLanguage = useScadaPlusLanguageDispatch();
  const { formatMessage } = useIntl();
  const { theme } = useScadaPlusTheme();
  const flipTheme = () => {
    if (theme.name === ThemeNames.Light) {
      dispatchTheme(dark);
    } else {
      dispatchTheme(light);
    }
  };

  const flipLanguage = () => {
    if (language.language === "en") {
      dispatchLanguage("no");
    } else {
      dispatchLanguage("en");
    }
  };

  return (
    <Grid className="headerGrid" mobile={props.mobile}>
      {!props.mobile && (
        <ScadaPlusArea>
          <HomeButton onClicked={() => history.push("/")} />
        </ScadaPlusArea>
      )}
      <NavigationMenu menu={props.menu} mobile={props.mobile} />
      <ButtonsArea mobile={props.mobile}>
        <Button
          size="small"
          type="tertiary"
          icon={
            theme.name === "dark" ? (
              <MdWbSunny fill={theme.colors.headerButtonIconColor} />
            ) : (
              <MdBrightness2 fill={theme.colors.headerButtonIconColor} />
            )
          }
          iconSize={props.mobile ? 20 : 25}
          onclick={() => flipTheme()}
        />
        <Button size="small" type="tertiary" onclick={() => flipLanguage()}>
          <LanguageText>
            {formatMessage({
              id: "general.languageName",
              defaultMessage: "M",
            })}
          </LanguageText>
        </Button>
        {!props.mobile && <UserButton />}
      </ButtonsArea>
      {!props.mobile && (
        <ClockArea>
          <LocalTime>
            <Clock timeZone={TimeZone.LOCAL} />
          </LocalTime>
          <UTCTime>
            <UTC>UTC</UTC> <Clock timeZone={TimeZone.UTC} />
          </UTCTime>
          <CopyrightText>
            {formatMessage({
              id: "general.Copyright",
              defaultMessage: "Translation Not Found",
            })}
          </CopyrightText>
        </ClockArea>
      )}
      {!props.mobile && (
        <OrigoLogoArea>
          <AboutButton />
        </OrigoLogoArea>
      )}
    </Grid>
  );
};

const UserButton: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const { theme } = useScadaPlusTheme();

  return (
    <>
      <Button
        size="small"
        type="tertiary"
        onclick={() => setShowModal(!showModal)}
        icon={<MdPerson size={25} fill={theme.colors.headerButtonIconColor} />}
      ></Button>
      {showModal && <User onClose={() => setShowModal(!showModal)} />}
    </>
  );
};
