import React from "react";

interface WarningSvgProps {
  width: number | string;
  height: number | string;
  color?: string;
  colorSymbol?: string;
  style?: React.CSSProperties;
}

export const WarningSvg: React.FC<WarningSvgProps> = (props) => {
  return (
    <svg
      width={props.height}
      height={props.width}
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g fill={props.color}>
          <path d="M 7.2903343,59.686856 H 57.957977 c 5.18109,0 8.410901,-5.83229 5.820309,-10.477139 L 38.444527,3.773817 c -2.590592,-4.64486752 -9.050204,-4.64486752 -11.640796,0 l -25.3337777,45.4359 c -2.59057,4.644849 0.639236,10.477139 5.820381,10.477139 z" />
        </g>
        <g fill={props.colorSymbol}>
          <path
            d="M4.71023 0.893898H0.3125L0.676136 17.3257H4.33523L4.71023 0.893898ZM2.50568 24.4166C3.84659 24.4166 4.99432 23.303 5.00568 21.9166C4.99432 20.553 3.84659 19.4394 2.50568 19.4394C1.11932 19.4394 -0.00568175 20.553 0.00568199 21.9166C-0.00568175 23.303 1.11932 24.4166 2.50568 24.4166Z"
            transform="translate(30 20)"
          />
        </g>
      </g>
    </svg>
  );
};
