import AlarmDisplay from "pages/Overview/WindTurbines/AlarmDisplay";
import React from "react";
import styled from "styled-components";
import { GenericAlarmPriorityCounter } from "types";
import {
  getTotalNumberOfAlarmsPriorityFour,
  getTotalNumberOfAlarmsPriorityOne,
  getTotalNumberOfAlarmsPriorityThree,
  getTotalNumberOfAlarmsPriorityTwo,
} from "utils/alarm-utils";
import { ComponentUnitSize } from "../WindTurbineMarker";
import WindTurbineName from "../WindTurbineName";

type WindTurbineAlarmInfoBarProps = {
  unitName: string;
  priority: number;
  unitSize: ComponentUnitSize;
  alarms: GenericAlarmPriorityCounter[] | undefined;
};

const InfoBar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.1em;
  place-content: center;
  background: ${(props) =>
    props.theme.colors.productionUnitMarkerInfoPillBackground};
  border-radius: ${(props) => props.theme.borderRadius.medium};
`;

export const WindTurbineAlarmInfoBar: React.FC<WindTurbineAlarmInfoBarProps> = (
  props
) => {
  const totalNumberOfAlarmsPriorityOne = getTotalNumberOfAlarmsPriorityOne(
    props.alarms
  )!;

  const totalNumberOfAlarmsPriorityTwo = getTotalNumberOfAlarmsPriorityTwo(
    props.alarms
  )!;

  const totalNumberOfAlarmsPriorityThree = getTotalNumberOfAlarmsPriorityThree(
    props.alarms
  )!;

  const totalNumberOfAlarmsPriorityFour = getTotalNumberOfAlarmsPriorityFour(
    props.alarms
  )!;

  const priorityOne = 1;
  const priorityTwo = 2;
  const priorityThree = 3;
  const priorityFour = 4;

  return (
    <InfoBar>
      <WindTurbineName
        windTurbineName={props.unitName}
        unitSize={props.unitSize}
      />
      {totalNumberOfAlarmsPriorityOne > 0 && (
        <AlarmDisplay priority={priorityOne} lessBorderRadius>
          {totalNumberOfAlarmsPriorityOne}
        </AlarmDisplay>
      )}
      {totalNumberOfAlarmsPriorityTwo > 0 && (
        <AlarmDisplay priority={priorityTwo} lessBorderRadius>
          {totalNumberOfAlarmsPriorityTwo}
        </AlarmDisplay>
      )}
      {totalNumberOfAlarmsPriorityThree > 0 && (
        <AlarmDisplay priority={priorityThree} lessBorderRadius>
          {totalNumberOfAlarmsPriorityThree}
        </AlarmDisplay>
      )}
      {totalNumberOfAlarmsPriorityFour > 0 && (
        <AlarmDisplay priority={priorityFour} lessBorderRadius>
          {totalNumberOfAlarmsPriorityFour}
        </AlarmDisplay>
      )}
    </InfoBar>
  );
};
