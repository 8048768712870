import {
  Background,
  Modal,
  PortalConsumer,
  Size,
} from "components/Modal/Modal";
import Wendy from "components/Wendy";
import { WendyHeadStyles } from "components/Wendy/Wendy";
import React from "react";
import { MdClose } from "react-icons/md";
import { useIntl } from "react-intl";
import styled from "styled-components";
import Button from "../../components/Button";
import config from "../../config";
import { useScadaPlusTheme } from "../../hooks/useScadaPlusTheme";
import Credit from "./Credit";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundColor.primary};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  justify-content: center;
  padding: ${(props) => props.theme.spacing.heavy};
  border: 3px solid ${(props) => props.theme.colors.mainColor.tertiary};
`;

const Title = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: ${(props) => props.theme.scaledFontSizes.large};
  margin-bottom: ${(props) => props.theme.spacing.light};
`;

const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Link = styled.a`
  color: ${(props) => props.theme.colors.mainColor.primary};
`;

const SubTitle = styled.p`
  margin-bottom: ${(props) => props.theme.spacing.heavy};
  font-size: ${(props) => props.theme.scaledFontSizes.small};
`;

const MainContent = styled.div`
  font-size: ${(props) => props.theme.scaledFontSizes.small};
`;

type AboutProps = {
  onClose: () => void;
};

export const About: React.FC<AboutProps> = (props) => {
  const { formatMessage } = useIntl();
  const { theme } = useScadaPlusTheme();

  return (
    <Modal size={Size.Medium} background={Background.Dark}>
      <Container data-testid="aboutpagetestid">
        <TitleArea>
          <Title>
            <Wendy size={35} HeadStyle={WendyHeadStyles.Happy} />
            {formatMessage({
              id: "general.about",
              defaultMessage: "Translation Not Found",
            })}{" "}
            {formatMessage({
              id: "overview.appName",
              defaultMessage: "Translation Not Found",
            })}
          </Title>
          <PortalConsumer>
            {(value) => (
              <Button
                style={{
                  borderRadius: "50%",
                  height: "35.19px",
                }}
                onclick={() => value.close!()}
                size="xs"
                type="primary"
                icon={<MdClose fill={theme.colors.iconColor} size={18} />}
              />
            )}
          </PortalConsumer>
        </TitleArea>
        <SubTitle>
          {formatMessage({
            id: "about.deliveredBy",
            defaultMessage: "Translation Not Found",
          })}{" "}
          <Link href={config.links.origo}>Origo Solutions</Link>,{" "}
          {formatMessage({
            id: "about.location",
            defaultMessage: "Translation Not Found",
          })}
        </SubTitle>
        <MainContent>
          <p>
            {formatMessage({
              id: "about.credentials",
              defaultMessage: "Translation Not Found",
            })}
          </p>
          <ul>
            {config.credits.map((c) => (
              <li key={c.link}>
                <Credit data={c} />
              </li>
            ))}
          </ul>
          <p>
            {formatMessage({
              id: "about.endtext",
              defaultMessage: "Translation Not Found",
            })}
          </p>
        </MainContent>
      </Container>

      <PortalConsumer>
        {(value) => {
          value.onClose = props.onClose;
          return <span></span>; //TODO:Had to be added due to react complaints. Use Hook - context?
        }}
      </PortalConsumer>
    </Modal>
  );
};
