import {
    useAlarmSubscription,
    useSensorSubscription,
  } from "hooks/useSubscription";
  import { useTagsContext } from "hooks/useTags";
  export const useProductionUnitCircleMarkerData = (componentUnitId: string) => {
    const {
      tags: { WTUROpCde, WTURStCde },
    } = useTagsContext();
    const { sensorData: unitOperationCode } = useSensorSubscription({
      filters: [
        {
          id: WTUROpCde,
          windTurbineId: componentUnitId,
        },
      ],
    });
    const { sensorData: unitStatusCode } = useSensorSubscription({
      filters: [
        {
          id: WTURStCde,
          windTurbineId: componentUnitId,
        },
      ],
    });
    const { data: newAlarmData } = useAlarmSubscription({
      filter: {
        windTurbineId: componentUnitId,
      },
    });
    return {
      unitOperationCode,
      unitStatusCode,
      newAlarmData,
    };
  };
  
  