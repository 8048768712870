import styled, { DefaultTheme } from "styled-components";
import CircularProgressbar from "../CircularProgressbar";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: aliceblue;
  border-radius: ${(props: SelectedSIFComplianceStyleProps) =>
    props.theme.borderRadius.light};
  background-color: ${(props: SelectedSIFComplianceStyleProps) =>
    props.theme.colors.cardBackgroundHover};
`;

const Header = styled.p`
  padding: 1rem;
`;

interface SelectedSIFComplianceStyleProps {
  backgroundColor?: string;
  theme: DefaultTheme;
}

export const SelectedSIFCompliance: React.FC = () => {
  return (
    <Container>
      <Header>SIF Compliance</Header>
      <CircularProgressbar value={19} maxValue={100}></CircularProgressbar>
    </Container>
  );
};
