import React, { useEffect } from "react";
import styled from "styled-components";
import WindTurbines from "./WindTurbines";
import Box from "components/Box";
import ContentGrid from "components/ContentGrid";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { getSectionId, getType } from "utils/routing-utils";
import { OverViewConstants } from "types";
import GridStatusCard from "./GridStatusCard";
import AlarmCountOverviewCard from "./AlarmCountOverviewCard";
import HourlyProductionCard from "components/HourlyProductionCard";
import MonthlyProductionCard from "components/MonthlyProductionCard";
import DailyProductionCard from "./DailyProductionCard";
import CurrentProductionCard from "./CurrentProductionCard";
import PersonnelOnSiteCard from "pages/Overview/PersonnelOnSiteCard";
import Section from "pages/Overview/Sections";
import WeatherCard from "components/WeatherCard";

const WindTurbineOverviewArea = styled.div`
  grid-column: 1 / 5;
  grid-row: 1/ 4;
`;

const CurrentProductionArea = styled.div`
  grid-column: 5 / -1;
  grid-row: 1/2;
`;

const HourlyProductionArea = styled.div`
  grid-column: 5 / -1;
  grid-row: 2/3;
`;
const DailyProductionArea = styled.div`
  grid-column: 5 / -1;
  grid-row: 3/4;
`;

const MonthlyProductionArea = styled.div`
  grid-column: 5 / -1;
  grid-row: 4/5;
`;

const AlarmCountArea = styled.div`
  grid-column: 3 / 4;
  grid-row: 4 / 5;
`;

const WeatherArea = styled.div`
  grid-column: 4 / 5;
  grid-row: 4/5;
`;

type Props = {
  columnStart: number;
  columnEnd: number;
  rowStart: number;
  rowEnd: number;
};

export type OverviewParams = {
  plantId: string;
};

const PlaceholderCard = styled(Box)`
  grid-column-start: ${(props: Props) => props.columnStart};
  grid-column-end: ${(props: Props) => props.columnEnd};
  grid-row-start: ${(props: Props) => props.rowStart};
  grid-row-end: ${(props: Props) => props.rowEnd};
`;

export const Overview: React.FC = () => {
  const history = useHistory();
  const { plantId } = useParams<OverviewParams>();
  const sectionId = getSectionId(history.location.search);
  const queryClient = useQueryClient();
  const overviewType = getType(history.location.search);

  useEffect(() => {
    queryClient.invalidateQueries("hourProduction");
    queryClient.invalidateQueries("productionPast24Hours");
    queryClient.invalidateQueries("productionMonth");
    queryClient.invalidateQueries("getWindTurbinesOverview");
  }, [plantId, sectionId, queryClient]);

  return (
    <ContentGrid>
      <WindTurbineOverviewArea>
        {overviewType === OverViewConstants.SECTIONS ? (
          <Section plantId={plantId} />
        ) : (
          <WindTurbines plantId={plantId} sectionId={sectionId} /> // TODO: support other types of componentUnits
        )}
      </WindTurbineOverviewArea>
      <CurrentProductionArea>
        {plantId && (
          <CurrentProductionCard
            key={`currentproduction_${plantId}_${sectionId}`}
            plantId={plantId}
            sectionId={sectionId}
          />
        )}
      </CurrentProductionArea>
      <HourlyProductionArea>
        {plantId && (
          <HourlyProductionCard idCollection={{ plantId, sectionId }} />
        )}
      </HourlyProductionArea>
      <DailyProductionArea>
        {plantId && (
          <DailyProductionCard plantId={plantId} sectionId={sectionId} />
        )}
      </DailyProductionArea>
      <PlaceholderCard rowStart={4} rowEnd={5} columnStart={1} columnEnd={2}>
        <GridStatusCard plantId={plantId} sectionId={sectionId} />
      </PlaceholderCard>
      <PlaceholderCard rowStart={4} rowEnd={5} columnStart={2} columnEnd={3}>
        <PersonnelOnSiteCard plantId={plantId} componentUnitId={""} />
      </PlaceholderCard>
      <PlaceholderCard rowStart={4} rowEnd={5} columnStart={3} columnEnd={4} />
      <AlarmCountArea>
        <AlarmCountOverviewCard
          key={`alarmcount_${plantId}_${sectionId}`}
          plantId={plantId}
          sectionId={sectionId}
        />
      </AlarmCountArea>
      <WeatherArea>{plantId && <WeatherCard plantId={plantId} />}</WeatherArea>
      <MonthlyProductionArea>
        {plantId && (
          <MonthlyProductionCard idCollection={{ plantId, sectionId }} />
        )}
      </MonthlyProductionArea>
    </ContentGrid>
  );
};
