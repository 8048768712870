import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: ${(props) => props.theme.colors.mainColor.tertiary};
  border-radius: ${(props) => props.theme.borderRadius.medium};
}

::-webkit-scrollbar-thumb:hover {
  background-color: ${(props) => props.theme.colors.mainColor.secondary};
}

::-webkit-scrollbar {
	width: ${(props) => props.theme.scrollBarWidth};
}

progress { 
  background-color: ${(props) => props.theme.colors.mainColor.quaternary}; 
  border-radius: ${(props) => props.theme.borderRadius.medium};
}



progress::-moz-progress-bar { 
  background-color: ${(props) => props.theme.colors.mainColor.quaternary}; 
  border-radius: ${(props) => props.theme.borderRadius.medium};
}


progress::-webkit-progress-bar { 
  background-color: ${(props) => props.theme.colors.mainColor.quaternary};
  border-radius: ${(props) => props.theme.borderRadius.medium}; 
}


progress::-webkit-progress-value {
  border-radius: ${(props) => props.theme.borderRadius.medium};
  background-color: ${(props) => props.theme.colors.mainColor.primary};
}

progress::-moz-progress-value {
  border-radius: ${(props) => props.theme.borderRadius.medium};
  background-color: ${(props) => props.theme.colors.mainColor.primary};

}

#root {
  height: 100vh;
  scrollbar-color: ${(props) =>
    props.theme.colors.mainColor.secondary} transparent;
scrollbar-width: thin;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/fonts/inter-v2-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/inter-v2-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-600 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('/fonts/inter-v2-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/inter-v2-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-700 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('/fonts/inter-v2-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/inter-v2-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@font-face {
  font-family: 'Agency FB';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('/fonts/agencyfb.ttf') format('truetype');
  }

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}



/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}
/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-size: 1em;
  font-family:  ${(props) => props.theme.primaryFontFamily};
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor};
  color: ${(props) => props.theme.colors.textColor.primary};
  margin: 0;
  padding: 0;

}
/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}

.clickableBox {
	:hover {
		cursor: pointer;
	}
}

.st0{fill:none;stroke:${(props) =>
  props.theme.colors.mainColor.primary};stroke-width:2;stroke-miterlimit:10;}
	.st1{fill:${(props) => props.theme.colors.mainColor.primary};fill-opacity:0.2;}
	.st2{fill: ${(props) => props.theme.colors.mainColor.primary};}
	.st3{font-family:'Inter';}
	.st4{font-size:12px;}
	.st5{fill:none;stroke:${(props) => props.theme.colors.mainColor.primary};}
	.st6{opacity:0.4;fill:${(props) => props.theme.colors.mainColor.primary};}
	.st7{fill:none;stroke:${(props) =>
    props.theme.colors.mainColor.primary};stroke-width:2;}
	.st8{fill:${(props) =>
    props.theme.colors.mainColor.primary};fill-opacity:0.4;stroke:${(props) =>
  props.theme.colors.signal};stroke-width:2;}
	.st9{fill:none;stroke:${(props) => props.theme.colors.signal};stroke-width:2;}
	.st10{fill:${(props) => props.theme.colors.textColor.primary};}
	.st11{font-size:21px;}
	.st12{fill:${(props) => props.theme.colors.mainColor.primary};}
	.st13{fill:url(#SVGID_1_);}
	.st14{fill:url(#SVGID_2_);}
	.st15{fill:url(#SVGID_3_);}
	.st16{fill:${(props) => props.theme.colors.mainColor.primary};}
	.st17{fill:#C63D34;}
	.st18{fill:#0D131B;}
	.st19{fill:#64BD5D;}
	.st20{fill:${(props) => props.theme.colors.signal};}
	.st21{fill:${(props) =>
    props.theme.colors.mainColor.primary};fill-opacity:0.4;stroke:${(props) =>
  props.theme.colors.mainColor.primary};stroke-width:2;}
	.st22{fill:url(#SVGID_4_);}
	.st23{fill:url(#SVGID_5_);}
	.st24{fill:url(#SVGID_6_);}
	.st25{fill:url(#SVGID_7_);}
	.st26{fill:url(#SVGID_8_);}
	.st27{fill:url(#SVGID_9_);}x
	.st28{fill:url(#SVGID_10_);}
	.st29{fill:url(#SVGID_11_);}
	.st30{fill:url(#SVGID_12_);}
	.st31{fill:url(#SVGID_13_);}
	.st32{fill:url(#SVGID_14_);}
	.st33{fill:url(#SVGID_15_);}
	.st34{fill:url(#SVGID_16_);}
	.st35{fill:url(#SVGID_17_);}
	.st36{fill:url(#SVGID_18_);}
	.st37{fill:url(#SVGID_19_);}
	.st38{fill:url(#SVGID_20_);}
	.st39{fill:url(#SVGID_21_);}
	.st40{fill:url(#SVGID_22_);}
	.st41{fill:url(#SVGID_23_);}
	.st42{fill:url(#SVGID_24_);}
	.st43{fill:url(#SVGID_25_);}
	.st44{fill:url(#SVGID_26_);}
	.st45{fill:url(#SVGID_27_);}
	.st46{fill:url(#SVGID_28_);}
	.st47{fill:url(#SVGID_29_);}
	.st48{fill:url(#SVGID_30_);}
	.st49{fill:url(#SVGID_31_);}
	.st50{fill:url(#SVGID_32_);}
	.st51{fill:url(#SVGID_33_);}
	.st52{fill:url(#SVGID_34_);}
	.st53{fill:url(#SVGID_35_);}
	.st54{fill:url(#SVGID_36_);}
	.st55{fill:url(#SVGID_37_);}
	.st56{fill:url(#SVGID_38_);}
	.st57{fill:url(#SVGID_39_);}
	.st58{fill:url(#SVGID_40_);}
	.st59{fill:url(#SVGID_41_);}
	.st60{fill:url(#SVGID_42_);}
	.st61{fill:url(#SVGID_43_);}
	.st62{fill:url(#SVGID_44_);}
	.st63{fill:url(#SVGID_45_);}
	.st64{fill:url(#SVGID_46_);}
	.st65{fill:url(#SVGID_47_);}
	.st66{fill:url(#SVGID_48_);}
	.st67{fill:url(#SVGID_49_);}
	.st68{fill:url(#SVGID_50_);}
	.st69{fill:url(#SVGID_51_);}
	.st70{fill:url(#SVGID_52_);}
	.st71{fill:url(#SVGID_53_);}
	.st72{fill:url(#SVGID_54_);}
	.st73{fill:url(#SVGID_55_);}
	.st74{fill:url(#SVGID_56_);}
	.st75{fill:url(#SVGID_57_);}
	.st76{fill:url(#SVGID_58_);}
	.st77{fill:url(#SVGID_59_);}
	.st78{fill:url(#SVGID_60_);}
	.st79{fill:url(#SVGID_61_);}
	.st80{fill:url(#SVGID_62_);}
	.st81{fill:url(#SVGID_63_);}
	.st82{fill:url(#SVGID_64_);}
	.st83{fill:url(#SVGID_65_);}
	.st84{fill:url(#SVGID_66_);}
	.st85{fill:url(#SVGID_67_);}
	.st86{fill:url(#SVGID_68_);}
	.st87{fill:url(#SVGID_69_);}
	.st88{fill:url(#SVGID_70_);}
	.st89{fill:url(#SVGID_71_);}
	.st90{fill:url(#SVGID_72_);}
	.st91{fill:url(#SVGID_73_);}
	.st92{fill:url(#SVGID_74_);}
	.st93{fill:url(#SVGID_75_);}
	.st94{fill:url(#SVGID_76_);}
	.st95{fill:url(#SVGID_77_);}
	.st96{fill:url(#SVGID_78_);}
	.st97{fill:url(#SVGID_79_);}
	.st98{fill:url(#SVGID_80_);}
	.st99{fill:url(#SVGID_81_);}
	.st100{fill:url(#SVGID_82_);}
	.st101{fill:url(#SVGID_83_);}
	.st102{fill:url(#SVGID_84_);}
	.st103{fill:url(#SVGID_85_);}
	.st104{fill:url(#SVGID_86_);}
	.st105{fill:url(#SVGID_87_);}
	.st106{fill:url(#SVGID_88_);}
	.st107{fill:url(#SVGID_89_);}
	.st108{fill:url(#SVGID_90_);}
	.st109{fill:url(#SVGID_91_);}
	.st110{fill:url(#SVGID_92_);}
	.st111{fill:url(#SVGID_93_);}
	.st112{fill:url(#SVGID_94_);}
	.st113{fill:url(#SVGID_95_);}
	.st114{fill:url(#SVGID_96_);}
	.st115{fill:url(#SVGID_97_);}
	.st116{fill:url(#SVGID_98_);}
	.st117{fill:url(#SVGID_99_);}
	.st118{fill:url(#SVGID_100_);}
	.st119{fill:url(#SVGID_101_);}
	.st120{fill:url(#SVGID_102_);}
	.st121{fill:url(#SVGID_103_);}
	.st122{fill:url(#SVGID_104_);}
	.st123{fill:url(#SVGID_105_);}
	.st124{fill:url(#SVGID_106_);}
	.st125{fill:url(#SVGID_107_);}
	.st126{fill:url(#SVGID_108_);}

`;

export default GlobalStyle;
