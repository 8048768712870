import styled from "styled-components";
export const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: 10px;
  grid-template-rows: repeat(4, minmax(0, 1fr));
  height: 100%;
  grid-column-gap: ${(props) => props.theme.gridColumnGap};
  grid-row-gap: ${(props) => props.theme.gridColumnGap};
`;
