//STKN new card status for WT on main page

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InfoCard from "../../../../components/InfoCard";
import {
  useSensorSubscription,
  SensorData,
} from "../../../../hooks/useSubscription";
import { useIntl } from "react-intl";
import NumberValueFormatter from "../../../../components/NumberValueFormatter";
import CardLabel from "../../../../components/CardLabel";
import { useTagsContext } from "../../../../hooks/useTags";
import { MeasurementUnitSymbolConstants } from "utils/utils";
import MeasurementUnitAbbreviation from "components/MeasurementUnitAbbreviation";

/* prettier-ignore */
const TurbineStatusCardContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(4, minmax(0, auto));
  padding: ${(props) => props.theme.spacing.heavy};
  height: 85%;
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth} ) {
    padding: ${(props) => props.theme.spacing.medium}; 
  }
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth}) and (display-mode: fullscreen)  {
    padding: ${(props) => props.theme.spacing.medium}; 
  }
`;

/* prettier-ignore */
const DataDisplayContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 2rem;
  height: ${(props) => props.theme.numberSizes.medium};
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth} ) {
    height: ${(props) => props.theme.numberSizes.small};
  }
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth}) and (display-mode: fullscreen)  {
    height: ${(props) => props.theme.numberSizes.small};
  }
`;

const DataName = styled.div`
  grid-column: 1;
`;

const DataValue = styled.div`
  grid-column: 2;
`;

interface IComponentProps {
  componentUnitId: string;
}

export const TurbineStatusCard: React.FC<IComponentProps> = (props) => {
  const { tags } = useTagsContext();

  const [yawAngle, setYawAngle] = useState<SensorData | null>(null);
  const [rotorRpm, setRotorRpm] = useState<SensorData | null>(null);
  const [generatorRpm, setGeneratorRpm] = useState<SensorData | null>(null);
  const [frequency, setFrequency] = useState<SensorData | null>(null);

  const { formatMessage } = useIntl();

  const { sensorData: wtstatus } = useSensorSubscription({
    filters: [
      {
        id: tags.WYAWYwAng,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WGENRotSpd,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WROTRotSpd,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WCNVGnHz,
        windTurbineId: props.componentUnitId,
      },
    ],
  });

  useEffect(() => {
    switch (wtstatus?.id) {
      case tags.WYAWYwAng:
        setYawAngle(wtstatus);
        break;
      case tags.WGENRotSpd:
        setGeneratorRpm(wtstatus);
        break;
      case tags.WROTRotSpd:
        setRotorRpm(wtstatus);
        break;
      case tags.WCNVGnHz:
        setFrequency(wtstatus);
        break;
      default:
        break;
    }
  }, [wtstatus, tags]);

  return (
    <InfoCard
      title={formatMessage({
        id: "statusWT.Heading",
        defaultMessage: "Text missing",
      })}
      style={{ height: "100%" }}
      className="turbineStatus_infoCard"
    >
      <TurbineStatusCardContainer className="turbineStatusContainer">
        <DataDisplayContainer className="turbineStatus_yawAngleDisplay">
          <DataName className="turbineStatus_dataName">
            <CardLabel className="turbineStatus_cardLabel">
              {formatMessage({
                id: "statusWT.YawAng",
                defaultMessage: "Text missing",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="turbineStatus_dataValue">
            <NumberValueFormatter
              className="turbineStatus_numberValueFormatter"
              value={Number(yawAngle?.value)}
              precision={1}
            />
          </DataValue>
          {yawAngle && (
            <MeasurementUnitAbbreviation
              measurementType={MeasurementUnitSymbolConstants.degree}
            />
          )}
        </DataDisplayContainer>

        <DataDisplayContainer className="turbineStatus_generatorDisplay">
          <DataName className="turbineStatus_dataName">
            <CardLabel className="turbineStatus_cardLabel">
              {formatMessage({
                id: "statusWT.GenRpm",
                defaultMessage: "Text missing",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="turbineStatus_dataValue">
            <NumberValueFormatter
              className="turbineStatus_numberValueFormatter"
              value={Number(generatorRpm?.value)}
              precision={1}
            />
          </DataValue>
          {generatorRpm && (
            <MeasurementUnitAbbreviation
              measurementType={
                MeasurementUnitSymbolConstants.revolutionsPerMinute
              }
            />
          )}
        </DataDisplayContainer>

        <DataDisplayContainer className="turbineStatus_rotorDisplay">
          <DataName className="turbineStatus_dataName">
            <CardLabel className="turbineStatus_cardLabel">
              {formatMessage({
                id: "statusWT.RotnRpm",
                defaultMessage: "Text missing",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="turbineStatus_dataValue">
            <NumberValueFormatter
              className="turbineStatus_numberValueFormatter"
              value={Number(rotorRpm?.value)}
              precision={1}
            />
          </DataValue>
          {rotorRpm && (
            <MeasurementUnitAbbreviation
              measurementType={
                MeasurementUnitSymbolConstants.revolutionsPerMinute
              }
            />
          )}
        </DataDisplayContainer>

        <DataDisplayContainer className="turbineStatus_frequencyDisplay">
          <DataName className="turbineStatus_dataName">
            <CardLabel className="turbineStatus_cardLabel">
              {formatMessage({
                id: "statusWT.Freqw",
                defaultMessage: "Text missing",
              })}
            </CardLabel>
          </DataName>
          <DataValue className="turbineStatus_dataValue">
            <NumberValueFormatter
              className="turbineStatus_numberValueFormatter"
              value={Number(frequency?.value)}
              precision={1}
            />
          </DataValue>
          {frequency && (
            <MeasurementUnitAbbreviation
              measurementType={MeasurementUnitSymbolConstants.hertz}
            />
          )}
        </DataDisplayContainer>
      </TurbineStatusCardContainer>
    </InfoCard>
  );
};
