import React from "react";

interface IComponentProps {
  width: number;
  height: number;
  color?: string;
  style?: React.CSSProperties;
}

export const BodyNoPower: React.FC<IComponentProps> = (props) => {
  return (
    <svg
      data-testid="bodynopower"
      width={props.width}
      height={props.height}
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8092 0.0454559C18.6312 0.0454559 18.4531 0.0454559 18.2751 0.0454559H7.72498C7.54692 0.0454559 7.36885 0.0454559 7.19079 0.0454559C5.05407 0.227274 3.05089 1.36364 1.75995 3.18182C0.424499 5.09091 -0.0206516 7.54546 0.60256 9.81818L3.40701 20.4545L3.45153 20.5455C5.05407 24.9091 6.56758 28 11.0191 28H15.0255C19.5215 28 20.9905 24.9091 22.593 20.5455L25.3975 9.86364C26.0207 7.59091 25.5755 5.18182 24.2401 3.22727C22.9491 1.36364 20.946 0.227274 18.8092 0.0454559Z"
        fill="url(#paint0_radial)"
      />
      <path
        d="M10.663 7.63637L12.7107 3.63637C13.1558 2.81818 14.4022 3.13637 14.4022 4.09091V10.6364H16.4944C17.1622 10.6364 17.6073 11.3636 17.2957 12L16.4054 13.6818L10.663 7.63637ZM19.655 19L6.74563 5.45455C6.38951 5.09091 5.85533 5.09091 5.49921 5.45455C5.14309 5.81819 5.14309 6.36364 5.49921 6.72728L9.14944 10.5455L7.90302 12.9091C7.59142 13.5455 7.99205 14.2727 8.70429 14.2727H10.7965V20.8182C10.7965 21.7727 12.0429 22.0909 12.4881 21.2727L14.8919 16.6364L18.3641 20.2727C18.5421 20.4545 18.7647 20.5455 18.9873 20.5455C19.2099 20.5455 19.4324 20.4545 19.6105 20.2727C19.9666 19.9091 20.0111 19.3636 19.655 19Z"
        fill="#FF762E"
      />
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13 11.8724) rotate(90) scale(16.1276 14.6247)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EBEBEB" />
        </radialGradient>
      </defs>
    </svg>
  );
};
