import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import React from "react";

export const PersonnelAlarmCounterSVG: React.FC = () => {
  const { theme } = useScadaPlusTheme();
  return (
    <svg
      width="24"
      height="24"
      viewBox="10 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <g style={{ transform: "translate(23%, 34%)" }}>
        <path
          data-testid="attentionpersonneltest"
          d="M0.756277 49.9416L31.0694 2.17873C32.8837 -0.675025 37.117 -0.675025 38.9312 2.17873L69.2444 49.9416C71.2098 53.0206 68.942 57.0009 65.3135 57.0009H4.68716C0.983058 57.0009 -1.20917 53.0206 0.756277 49.9416Z"
          fill={theme.colors.signal}
        />
      </g>
      <g style={{ transform: "translate(46%, 48%)" }}>
        <path
          d="M30.8182 34.8182C30.8182 33.6118 30.8182 32.4673 30.8182 31.2919C30.8182 30.1783 30.4796 29.1885 29.8025 28.3224C28.6944 26.8995 27.2477 26.0025 25.6472 25.322C23.0925 24.2085 20.4146 23.6826 17.6752 23.497C14.0432 23.2496 10.4727 23.5898 7.02536 24.7962C5.08622 25.4767 3.27021 26.3737 1.85434 27.9203C1.08484 28.7555 0.56158 29.7144 0.5308 30.8588C0.50002 32.1271 0.5308 33.4262 0.5308 34.6944C0.5308 34.7254 0.56158 34.7872 0.59236 34.8182C10.6266 34.8182 20.6916 34.8182 30.8182 34.8182ZM19.4296 7.16488C18.7833 7.16488 18.1677 7.16488 17.5521 7.16488C17.5521 6.94836 17.5521 6.76277 17.5521 6.60811C17.5521 4.96871 17.5521 3.29838 17.5521 1.65898C17.5521 0.885676 17.2443 0.576355 16.4748 0.576355C15.9823 0.576355 15.459 0.576355 14.9666 0.576355C14.0432 0.576355 13.7661 0.854744 13.7661 1.81364C13.7661 3.42211 13.7661 5.0615 13.7661 6.66997C13.7661 6.85556 13.7661 7.01022 13.7661 7.22675C13.1198 7.22675 12.535 7.22675 11.8886 7.22675C11.8886 5.64921 11.8886 4.10261 11.8886 2.52508C11.7655 2.55601 11.7039 2.55601 11.6731 2.58694C11.55 2.6488 11.3961 2.71067 11.273 2.80346C8.87215 4.22634 7.76407 6.42251 7.61017 9.14454C7.57939 9.42292 7.54861 9.57759 7.27159 9.73225C6.53288 10.1034 6.07118 11.1551 6.25586 11.9594C12.535 11.9594 18.814 11.9594 25.1239 11.9594C25.0624 11.1551 24.9392 10.3818 24.2929 9.94877C23.7388 9.60852 23.708 9.17547 23.6773 8.68056C23.6773 8.61869 23.6773 8.55683 23.6773 8.49496C23.3695 5.9276 22.1691 3.97888 19.9221 2.67974C19.799 2.61787 19.6451 2.55601 19.4604 2.46321C19.4296 4.07168 19.4296 5.58735 19.4296 7.16488ZM8.07187 13.8771C8.22577 16.9085 9.51853 19.2284 12.1656 20.5894C14.9973 22.0432 17.7983 21.7648 20.353 19.8161C22.2614 18.3314 23.1848 16.3208 23.2156 13.8771C18.1677 13.8771 13.1506 13.8771 8.07187 13.8771Z"
          fill={"black"}
        />
      </g>
    </svg>
  );
};
