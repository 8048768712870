//STKN new SVG status warning
import React from "react";
import { useTheme } from "styled-components";

interface IComponentProps {
  width: number | string;
  height: number | string;
  color?: string;
  style?: React.CSSProperties;
}

export const WindTurbineStatusSVG: React.FC<IComponentProps> = (props) => {
  const theme = useTheme();

  return (
    <svg
      width={props.height}
      height={props.width}
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="10.3" fill={theme.colors.textColor.primary} />
      <circle
        cx="32"
        cy="32"
        r="20.6"
        stroke={theme.colors.textColor.secondary}
        strokeWidth="1"
      />
      <circle
        cx="32"
        cy="32"
        r="31"
        stroke={theme.colors.textColor.secondary}
        strokeWidth="1"
      />
    </svg>
  );
};
