import styled from "styled-components";

const ComponentContainer = styled.div`
  padding: 0rem 0rem 1rem 0.5rem;
`;

const ComponentHeader = styled.div`
  font-size: large;
  padding-bottom: 0.5rem;
`;

const ScrollableContainer = styled.div`
  width: 100%;
  height: 200px;
  overflow: auto;
`;

const SIFTable = styled.table`
  border-color: ${(props) => props.theme.colors.mainColor.primary};
  border-spacing: 0;
  width: 100%;
`;

const TableHeader = styled.th`
  background-color: ${(props) => props.theme.colors.mainColor.primary};
  text-align: start;
  position: sticky;
  top: 0;
  padding: 0.2rem;
`;

const TableBody = styled.tbody`
  white-space: nowrap;
`;

const TableRow = styled.tr`
  border: 1px solid aliceblue;
  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.origoLogoHoverColor};
  }
`;

interface SIFItem {
  sifid: number;
  testGroup: string;
  objectDescription: string;
  mode: string;
  actualMode: string;
  targetSil: number;
  tripSetting: string;
  sifCompliance: number;
}

interface SIFPerformanceMonitoringListProps {
  SIFList: Array<SIFItem>;
}

export const SIFPerformanceMonitoringList: React.FC<
  SIFPerformanceMonitoringListProps
> = (props) => {
  const handleClick = (item: number) => {
    console.log("You clicked, ", item);
  };

  return (
    <ComponentContainer>
      <ComponentHeader>SIF Performance Monitorings</ComponentHeader>

      <ScrollableContainer>
        <SIFTable>
          <thead>
            <tr>
              <TableHeader>Test group</TableHeader>
              <TableHeader>Object description</TableHeader>
              <TableHeader>Mode</TableHeader>
              <TableHeader>Actual mode</TableHeader>
              <TableHeader>Target SIL</TableHeader>
              <TableHeader>Trip setting</TableHeader>
              <TableHeader>SIF compliance</TableHeader>
            </tr>
          </thead>
          <TableBody>
            {props.SIFList.map((item) => {
              return (
                <TableRow
                  key={item.sifid}
                  onClick={() => handleClick(item.sifid)}
                >
                  <td>{item.testGroup}</td>
                  <td>{item.objectDescription}</td>
                  <td>{item.mode}</td>
                  <td>{item.actualMode}</td>
                  <td>{item.targetSil}</td>
                  <td>{item.tripSetting}</td>
                  <td>{item.sifCompliance}</td>
                </TableRow>
              );
            })}
          </TableBody>
        </SIFTable>
      </ScrollableContainer>
    </ComponentContainer>
  );
};
