import React, { useEffect, useRef, useState } from "react";
import { divIcon } from "leaflet";
import { Marker, Tooltip } from "react-leaflet";
import styled from "styled-components";

const MarkerTooltip = styled(Tooltip)`
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundColor.primary};
  border: none;
  :before {
    border: none;
  }
`;

type ContentProps = {
  onRender: (html: string) => void;
};

const Content: React.FC<ContentProps> = (props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref && ref.current) {
      const html = ref.current.innerHTML;

      if (html) {
        props.onRender(html);
      }
    }
  }, [ref, props]);

  return (
    <div className="jsx-marker" style={{ display: "none" }} ref={ref}>
      {props.children}
    </div>
  );
};

type CustomMarkerProps = {
  markerPosition: [number, number];
  toolTipContent?: JSX.Element | undefined;
  onClick?: () => void;
};

export const CustomMarker: React.FC<CustomMarkerProps> = (props) => {
  const [html, setHtml] = useState<string | null>(null);

  return (
    <React.Fragment>
      <Content
        onRender={(html) => {
          setHtml(html);
        }}
        children={props.children}
      ></Content>
      {html && (
        <Marker
          position={props.markerPosition}
          icon={divIcon({
            className: "marker",
            html,
          })}
          eventHandlers={{
            mouseup(e) {
              if (e.originalEvent.button !== 0) {
                return null;
              } else {
                props.onClick && props.onClick();
              }
            },
          }}
        >
          {/* TODO: fix tooltip direction for when tooltip gets hidden direction="top"*/}
          {props.toolTipContent && (
            <MarkerTooltip offset={[10, 20]}>
              {props.toolTipContent}
            </MarkerTooltip>
          )}
        </Marker>
      )}
    </React.Fragment>
  );
};
