import { lineRadial, scaleLinear } from "d3";
import React from "react";
import { GaugeArcProps } from "../gauge-types";

/** Ratio of arc to size of container. */
const baseRatio = 0.8;

/** Resolution of arc. */
const length = 50;

export const GaugeArc: React.FC<GaugeArcProps> = (props) => {
  /** Calculate max angle of arc from degrees to radians */
  const maxRatio = props.maxangle / 180;
  const arcMax = Math.PI * maxRatio;

  /** Calculate min angle of arc from degrees to radians */
  const minRatio = props.minangle / 180;
  const arcMin = Math.PI * minRatio;

  /** Linear map from [0,1] to [-135,135] degrees in radians */
  const refToRads = scaleLinear().domain([0, 1]).range([arcMin, arcMax]);

  /** Render scale to map [0,length - 1] to [minAndle, maxAngle] for rendering */
  const arcScale = scaleLinear()
    .domain([0, length - 1])
    .range([refToRads(props.min) ?? 0, refToRads(props.max) ?? 0]);

  /** Actual path function to generate path. */
  const arc = lineRadial()
    .angle((d, i) => arcScale(i) ?? 0)
    .radius((250 * baseRatio) / 2 - props.inset);

  const arcPath = arc({ length } as any);

  return (
    <path
      className="guage-arc"
      d={arcPath === null ? undefined : arcPath}
      transform={`translate(${props.center.x},${props.center.y})`}
      fill="transparent"
      style={{
        transition: "all 0.25s 0.25s",
      }}
      {...props}
    />
  );
};
