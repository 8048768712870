import React from "react";
import styled from "styled-components";
import AlarmDisplay from "../../../AlarmDisplay";
interface TotalAlarmCountDisplayProps {
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
  alarmCount?: string;
  priority: number;
}

const DisplayContainer = styled.div`
  position: absolute;
  top: 9%;
  right: 13%;
  z-index: 1000;
`;

export const TotalAlarmCountDisplay: React.FC<TotalAlarmCountDisplayProps> = (
  props
) => {
  return (
    <DisplayContainer>
      <AlarmDisplay size={"15px"} priority={props.priority}>
        {props.alarmCount && props.alarmCount}
      </AlarmDisplay>
    </DisplayContainer>
  );
};
