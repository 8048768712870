import styled from "styled-components";

const ComponentContainer = styled.div`
  background-color: ${(props) => props.theme.colors.cardBackgroundHover};
  border-radius: ${(props) => props.theme.borderRadius.medium};
`;

const ComponentHeader = styled.div`
  font-size: ${(props) => props.theme.constantFontSizes.mediumLarge};
  padding: 0.5rem 0 0 0.5rem;
`;

const TableContainer = styled.div`
  padding: 0.5rem;
`;

const DeviceTable = styled.table`
  border-color: ${(props) => props.theme.colors.mainColor.primary};
  border-spacing: 0;
  width: 100%;
`;

const TableHeader = styled.th`
  text-align: start;
  position: sticky;
  top: 0;
  padding: 0.2rem;
`;

const TableBody = styled.tbody`
  max-height: 100px;
  overflow-y: scroll;
  white-space: nowrap;

  tr:nth-child(odd) {
    background-color: ${(props) => props.theme.colors.mainColor.primary};
  }
`;

const TableRow = styled.tr`
  td:nth-child(4) {
    align-content: center;
    text-align: center;
    :hover {
      cursor: pointer;
      color: ${(props) => props.theme.colors.origoLogoHoverColor};
    }
  }
  td:nth-child(5) {
    text-align: center;
  }
  td:nth-child(6) {
    text-align: center;
  }
  td:nth-child(7) {
    text-align: center;
  }
`;

interface InputOutputDeviceItem {
  id: number;
  name: string;
  type: string;
  mainTestGroup: string;
  changeTestGroup: string;
  proofTestDelayed: string;
  detectedFailure: string;
  detectedFailureNr: number;
}

interface InputOutputDeviceListProps {
  deviceList: Array<InputOutputDeviceItem>;
}

export const InputOutputDeviceList: React.FC<InputOutputDeviceListProps> = (
  props
) => {
  const handleClick = (item: number) => {
    console.log("You clicked, ", item);
  };

  return (
    <ComponentContainer>
      <ComponentHeader>Input and Output Device</ComponentHeader>

      <TableContainer>
        <DeviceTable>
          <thead>
            <tr>
              <TableHeader>Name</TableHeader>
              <TableHeader>Type</TableHeader>
              <TableHeader>Main Test Group</TableHeader>
              <TableHeader>Change Test Group</TableHeader>
              <TableHeader>Proof Test Delayed</TableHeader>
              <TableHeader>Detected Failure</TableHeader>
              <TableHeader># Detected Failure</TableHeader>
            </tr>
          </thead>
          <TableBody>
            {props.deviceList.map((item) => {
              return (
                <TableRow key={item.id} onClick={() => handleClick(item.id)}>
                  <td>{item.name}</td>
                  <td>{item.type}</td>
                  <td>{item.mainTestGroup}</td>
                  <td>{item.changeTestGroup}</td>
                  <td>{item.proofTestDelayed}</td>
                  <td>{item.detectedFailure}</td>
                  <td>{item.detectedFailureNr}</td>
                </TableRow>
              );
            })}
          </TableBody>
        </DeviceTable>
      </TableContainer>
    </ComponentContainer>
  );
};
