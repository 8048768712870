import React from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import config from "../../../config";
import { getSectionId } from "../../../utils/routing-utils";
import { useScadaPlusQuery } from "../../../hooks/useScadaPlusQuery";
import Alarms from "../../WindProductionUnit/ExpandableView/Alarms";
import { useIntl } from "react-intl";
import AboutButton from "components/AboutButton";
import WeatherMobile from "components/WeatherMobile";
import MobileLiveSectorDisplay from "./MobileLiveSectorDisplay";
import MonthlyProductionCard from "components/MonthlyProductionCard";
import PersonnelOnSiteCard from "pages/Overview/PersonnelOnSiteCard";
import StatusCardMobile from "pages/WindProductionUnit/StatusCardMobile";
import { Section } from "types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing.heavy};
  & > * {
    margin-bottom: ${(props) => props.theme.spacing.medium};
  }
  padding-bottom: 0%;
  padding-top: 0%;
`;

const SectorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0%;
`;

const WindWaveContainer = styled.div`
  padding-bottom: 1%;
`;

const PersonnelOnSiteContainer = styled.div`
  padding-bottom: 1%;
`;

const StatusGraphContainer = styled.div`
  padding-bottom: 1%;
`;

const ProductionGraphContainer = styled.div`
  padding-bottom: 1%;
`;

const AlarmContainer = styled.div`
  padding-bottom: 0%;
`;

const FooterContainer = styled.div`
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 3%;
  margin-top: ${(props) => props.theme.spacing.light};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1;
  flex-direction: column;
  justify-content: right;
  align-items: right;
  white-space: nowrap;
  background-color: ${(props) => props.theme.colors.headerBackgroundColor};
`;

const CopyrightText = styled.div`
  grid-column: 1;
  padding-top: 1%;
  color: ${(props) => props.theme.colors.mainColor.secondary};
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding-bottom: 0%;
  font-size: ${(props) => props.theme.scaledFontSizes.mediumLarge};
`;

const OrigoLogoBox = styled.div`
  grid-column: 2;
  padding-top: 1%;
  color: ${(props) => props.theme.colors.mainColor.secondary};
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding-bottom: 0%;
  font-size: ${(props) => props.theme.scaledFontSizes.mediumLarge};
`;

type OverviewParams = {
  plantId: string;
  componentUnitId: string;
};

export const MobileOverview: React.FC = () => {
  const history = useHistory();
  const { plantId } = useParams<OverviewParams>();
  const sectionId = getSectionId(history.location.search);
  const { formatMessage } = useIntl();

  const { data: sectionData } = useScadaPlusQuery<Array<Section>>(
    `section-overview-${plantId}`,
    `${config.api.url}/${config.api.plants}/${plantId}/${config.api.sections}`
  );

  return (
    <Container>
      <SectorsContainer>
        {sectionData &&
          sectionData.map((section) => (
            <MobileLiveSectorDisplay key={section.id} plantId={plantId} />
          ))}
      </SectorsContainer>
      <WindWaveContainer>
        <WeatherMobile
          key={plantId!}
          plantId={plantId!}
          componentUnitId={"698c898f-261d-4d3d-92e9-2a98887c2269"}
        />
      </WindWaveContainer>
      <PersonnelOnSiteContainer>
        <PersonnelOnSiteCard plantId={plantId} componentUnitId={""} />
      </PersonnelOnSiteContainer>
      <StatusGraphContainer>
        <StatusCardMobile
          componentUnitId={"698c898f-261d-4d3d-92e9-2a98887c2269"}
          plantId={plantId}
        />
      </StatusGraphContainer>
      <ProductionGraphContainer>
        <MonthlyProductionCard idCollection={{ plantId, sectionId }} />
      </ProductionGraphContainer>
      <AlarmContainer>
        <Alarms componentUnitId={"698c898f-261d-4d3d-92e9-2a98887c2269"} />
      </AlarmContainer>
      <FooterContainer>
        <CopyrightText>
          {formatMessage({
            id: "general.Copyright",
            defaultMessage: "Translation Not Found",
          })}
        </CopyrightText>
        <OrigoLogoBox>
          <AboutButton />
        </OrigoLogoBox>
      </FooterContainer>
    </Container>
  );
};
