import TextAreaWithButtons from "components/TextAreaWithButtons";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import TextInputTitle from "../../TextInputTitle";

type CAProps = {
  isEditing: boolean;
};

const Container = styled.div<CAProps>`
  color: ${(props) => props.theme.colors.textColor.primary};
  font-size: ${(props) => props.theme.scaledFontSizes.small};
  font-family: ${(props) => props.theme.primaryFontFamily};
  width: ${(props) => (props.isEditing ? "100%" : "90%")};
`;

type CorrectiveActionsTextProps = {
  edit: boolean;
  correctiveAction?: string;
  onSubmit: (text: string) => void;
  onCancel: () => void;
};

export const CorrectiveActionsText: React.FC<CorrectiveActionsTextProps> = ({
  edit,
  correctiveAction,
  onSubmit,
  onCancel,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Container isEditing={edit}>
      {edit ? (
        <div>
          <TextInputTitle>
            {formatMessage({
              id: "alarms.NewCorrectiveAction",
              defaultMessage: "New corrective action",
            })}
          </TextInputTitle>
          <TextAreaWithButtons
            value={correctiveAction}
            onSubmit={onSubmit}
            onCancel={onCancel}
          ></TextAreaWithButtons>
        </div>
      ) : correctiveAction !== null ? (
        correctiveAction
      ) : (
        formatMessage({
          id: "alarms.NoCorrectiveActions",
          defaultMessage: "No corrective actions added to alarm",
        })
      )}
    </Container>
  );
};
