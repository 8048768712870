import React, { useEffect, useState } from "react";
import { LatLngTuple } from "leaflet";
import { MarineTrafficPosition } from "../../../../../types";
import CustomMarker from "../../../../../components/CustomMarker";
import { MdDirectionsBoat } from "react-icons/md";
import { useScadaPlusTheme } from "../../../../../hooks/useScadaPlusTheme";
import { useIntl } from "react-intl";
import { TooltipContent } from "../WindTurbineMarker/TooltipContent";

type MarineIconProps = {
  data: MarineTrafficPosition;
};

const MarineTrafficIcon: React.FC<MarineIconProps> = (props) => {
  const { theme } = useScadaPlusTheme();
  const [shouldRedraw, setShouldRedraw] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    setShouldRedraw(true);
  }, [theme]);

  useEffect(() => {
    if (shouldRedraw) {
      setShouldRedraw(false);
    }
  }, [shouldRedraw]);

  const markerPos: LatLngTuple = [
    parseFloat(props.data.LAT),
    parseFloat(props.data.LON),
  ];

  const mapToTooltip = () => {
    const tooltipTableData: Array<{ description: string; value: string }> = [];

    tooltipTableData.push({
      description: formatMessage({
        id: "overview.Type",
        defaultMessage: "Missing translation",
      }),
      value: props.data.SHIPTYPE,
    });
    tooltipTableData.push({
      description: formatMessage({
        id: "overview.Speed",
        defaultMessage: "Missing translation",
      }),
      value: props.data.SPEED,
    });
    tooltipTableData.push({
      description: formatMessage({
        id: "overview.Lat",
        defaultMessage: "Missing translation",
      }),
      value: props.data.LAT,
    });
    tooltipTableData.push({
      description: formatMessage({
        id: "overview.Lon",
        defaultMessage: "Missing translation",
      }),
      value: props.data.LON,
    });

    return tooltipTableData;
  };

  return (
    <CustomMarker
      markerPosition={markerPos}
      toolTipContent={
        <TooltipContent
          header={props.data.SHIPNAME}
          tooltipTableData={mapToTooltip()}
        />
      }
    >
      {!shouldRedraw && <MdDirectionsBoat size={30} />}
    </CustomMarker>
  );
};

export default MarineTrafficIcon;
