import styled from "styled-components";

const RadioButtonLabel = styled.label`
  display: grid;
  grid-template-columns: auto 1fr;
  height: 30px;
  align-items: center;
`;

/* prettier-ignore */
const RadioButton = styled.input`
  appearance: none;
  margin: 0;
  font: inherit;
  font-size: ${(props) => props.theme.numberSizes.medium};
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth} ) {
      font-size: ${(props) => props.theme.numberSizes.small}; 
  }
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth}) and (display-mode: fullscreen)  {
      font-size: ${(props) => props.theme.numberSizes.small}; 
  }
  width: 1.15em;
  height: 1.15em;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  background-color: ${(props) =>
    props.theme.colors.buttonColors.radioButtonBackground};
  border: 0.15em solid
    ${(props) => props.theme.colors.buttonColors.radioButtonBorder};
  color: ${(props) => props.theme.colors.textColor.primary};
  cursor: pointer;
  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: ${(props) =>
      props.theme.colors.buttonColors.radioButtonCheckedIndicator};
  }
  &:checked::before {
    transform: scale(1);
  }
`;

const RadioButtonText = styled.div`
  padding-left: 5px;
`;

type ScadaPlussRadioButtonProps = {
  name: string;
  id: string;
  value: string;
  checked?: boolean;
  radioButtonLabel: string;
};

export const ScadaPlussRadioButton: React.FC<ScadaPlussRadioButtonProps> = (
  props
) => {
  return (
    <RadioButtonLabel htmlFor={props.id}>
      <RadioButton
        type="radio"
        name={props.name}
        value={props.value}
        id={props.id}
        checked={props.checked}
      />
      <RadioButtonText>{props.radioButtonLabel}</RadioButtonText>
    </RadioButtonLabel>
  );
};
