import React, { useEffect, useState } from "react";
import {
  GenericMenu,
  OverViewConstants,
  RouteConstants,
  MenuPlant,
  MenuSection,
} from "../../types";
import { History } from "history";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  constructAlarmUrl,
  constructReportUrl,
  constructSecondDropdownUrl,
  constructSingleLineUrl,
  constructPlantUrl,
  constructComponentUnitUrl,
  getRouteConstant,
  getSectionId,
  getType,
} from "../../utils/routing-utils";
import { IntlShape, useIntl } from "react-intl";
import Dropdown from "components/Dropdown";
import {
  DropdownSingleItem,
  DropDownType,
  DropdownType,
  DropDownItemList,
} from "components/Dropdown/Dropdown";

type NavigationMenuProps = {
  menu: GenericMenu;
  mobile?: boolean;
};
type FirstDropdown = {
  dropdownItems: DropdownSingleItem[];
  model: MenuPlant;
  selected: DropdownSingleItem;
};
type SecondDropdown = {
  dropdownItems: Array<DropDownType>;
  selected: DropDownType;
};

type ThirdDropdown = {
  dropdownItems: Array<DropDownType>;
  selected: DropDownType;
};
type HeaderParms = {
  plantId: string;
  unitId: string;
};
type FirstDropDownProps = Pick<NavigationMenuProps, "mobile">;

const FirstDropdownContainer = styled.div`
  grid-column: ${(props: FirstDropDownProps) =>
    props.mobile ? "1 /4" : "2 / 5"};
`;

const SecondDropdownContainer = styled.div`
  grid-column: 5 / 8;
`;

const ThirdDropdownContainer = styled.div`
  grid-column: 8/11;
`;

const OverviewTranslateId = "header.Overview";
const ReportTranslateId = "header.Report";
const SinglelineTranslateId = "header.SingleLine";
const AllSectionsTranslateId = "header.AllSections";
const AllProductionUnitsTranslateId = "header.AllComponentUnits";
const AlarmsTranslateId = "header.Alarms";

const createFirstDropdownItem = (
  wp: MenuPlant,
  history: History,
  type: string | undefined
): DropdownSingleItem => {
  const typeDervied = type ? type : OverViewConstants.COMPONENT_UNITS;
  return {
    id: wp.id,
    text: wp.name,
    type: DropdownType.Single,
    onClick: () => {
      history.push(constructPlantUrl(wp.id, undefined, typeDervied));
    },
  };
};

const getFirstDropdown = (
  menu: GenericMenu,
  history: History,
  type: string | undefined
) => {
  return menu.map((plant: MenuPlant) => {
    return createFirstDropdownItem(plant, history, type);
  });
};

const getFirstDropdownEntry = (
  items: GenericMenu,
  history: History<unknown>,
  plantId: string | undefined,
  type: string | undefined
): [model: MenuPlant, selected: DropdownSingleItem] => {
  const plant = items.find((x) => {
    return x.id === plantId;
  });

  if (plant) {
    return [plant, createFirstDropdownItem(plant, history, type)];
  }

  return [items[0], createFirstDropdownItem(items[0], history, type)];
};

const getSecondDropdown = (
  plant: MenuPlant,
  history: History,
  translate: IntlShape
): Array<DropDownType> => {
  const staticEntries: Array<DropDownType> = [
    {
      id: "AllWindTurbines",
      text: translate.formatMessage({
        id: AllProductionUnitsTranslateId,
        defaultMessage: "All Production Units",
      }),
      type: DropdownType.Single,
      onClick: () => {
        history.push(
          constructSecondDropdownUrl(
            plant.id,
            undefined,
            OverViewConstants.COMPONENT_UNITS,
            history.location.pathname
          )
        );
      },
      translateId: AllProductionUnitsTranslateId,
    },
    {
      id: "AllSections",
      text: translate.formatMessage({
        id: AllSectionsTranslateId,
        defaultMessage: "All Sections",
      }),
      type: DropdownType.Single,
      onClick: () => {
        history.push(
          constructSecondDropdownUrl(
            plant.id,
            undefined,
            OverViewConstants.SECTIONS,
            history.location.pathname
          )
        );
      },
      translateId: AllSectionsTranslateId,
    },
  ];

  const dynamicEntries: DropDownItemList = {
    items: plant.sections.map((section): DropdownSingleItem => {
      return {
        id: `${section.id}`,
        text: section.shortName,
        type: DropdownType.Single,
        onClick: (item: DropdownSingleItem) => {
          history.push(
            constructSecondDropdownUrl(
              plant.id,
              item.id,
              undefined,
              history.location.pathname
            )
          );
        },
      };
    }),
    id: plant.sections.map((s) => s.id).reduce((x, p) => `${x}${p}`),
    type: DropdownType.Multiple,
  };

  staticEntries.push(dynamicEntries);
  return staticEntries;
};

const getSecondDropdownEntry = (
  sections: Array<MenuSection>,
  dropdowns: Array<DropDownType>,
  sectionId: string | undefined,
  type?: string | undefined
): DropDownType => {
  if (sectionId) {
    const section = sections.find((section) => section.id === sectionId);
    if (section) {
      const dropdownMultiple = dropdowns
        .filter((x) => x.type === DropdownType.Multiple)
        .flatMap((x) => (x as DropDownItemList).items);

      const dropdown = dropdownMultiple.find((x) => x.id === sectionId);

      if (dropdown) {
        return dropdown;
      }
    }
  }

  if (type === OverViewConstants.SECTIONS) {
    return dropdowns[1];
  }

  return dropdowns[0];
};

// Temporary, to make links unclickable on Zefyros
const isZefyros = (plantId: string) => {
  return plantId === "96f2c019-6081-4325-92a3-4e4e3f89b499" ? true : false;
};

const getThirdDropdownItems = (
  plant: MenuPlant,
  history: History,
  translate: IntlShape,
  type: string | undefined,
  sectionId: string | undefined
): Array<DropDownType> => {
  const allDropdownItems: Array<DropDownType> = [
    {
      id: RouteConstants.Overview,
      text: translate.formatMessage({
        id: OverviewTranslateId,
        defaultMessage: "Overview",
      }),
      type: DropdownType.Single,
      onClick: () => {
        history.push(
          constructPlantUrl(
            plant.id,
            sectionId,
            OverViewConstants.COMPONENT_UNITS
          )
        );
      },
      translateId: OverviewTranslateId,
    },
    {
      id: RouteConstants.Alarm,
      text: translate.formatMessage({
        id: AlarmsTranslateId,
        defaultMessage: "Alarms",
      }),
      type: DropdownType.Single,
      onClick: () => {
        history.push(constructAlarmUrl(plant.id, sectionId, type));
      },
      translateId: AlarmsTranslateId,
    },
    {
      id: RouteConstants.SingeLine,
      text: translate.formatMessage({
        id: SinglelineTranslateId,
        defaultMessage: "Singleline",
      }),
      type: DropdownType.Single,
      onClick: () => {
        // Temporary if statement, chekcing if the plant id is Zefyros, enabling link if it is not Zefyros
        if (!isZefyros(plant.id)) {
          history.push(constructSingleLineUrl(plant.id));
        }
      },
      translateId: SinglelineTranslateId,
      inactiveLink: isZefyros(plant.id),
    },
    {
      id: RouteConstants.Report,
      text: translate.formatMessage({
        id: ReportTranslateId,
        defaultMessage: "Report",
      }),
      type: DropdownType.Single,
      onClick: () => {
        // Temporary if statement, chekcing if the plant id is Zefyros, enabling link if it is not Zefyros
        if (!isZefyros(plant.id)) {
          history.push(constructReportUrl(plant.id));
        }
      },
      translateId: ReportTranslateId,
      inactiveLink: isZefyros(plant.id),
    },
  ];
  const componentUnitsInSection = plant.sections.find(
    (s) => s.id === sectionId
  );

  const productionUnits = componentUnitsInSection
    ? componentUnitsInSection.componentUnits
    : plant.sections.flatMap((x) => x.componentUnits);

  const productionUnitItems = productionUnits.map(
    (componentUnit): DropdownSingleItem => {
      return {
        id: `${componentUnit.id}`,
        text: componentUnit.shortName,
        type: DropdownType.Single,
        onClick: () => {
          history.push(
            constructComponentUnitUrl(
              plant.id,
              componentUnit.id,
              sectionId,
              OverViewConstants.COMPONENT_UNITS
            )
          );
        },
      };
    }
  );

  const list: DropDownItemList = {
    items: productionUnitItems,
    id: plant.sections.map((s) => s.id).reduce((x, p) => `${x}${p}`),
    type: DropdownType.Multiple,
  };

  allDropdownItems.push(list);
  return allDropdownItems;
};

const getThirdDropdownEntry = (
  sections: Array<MenuSection>,
  dropdowns: Array<DropDownType>,
  unitId: string | undefined,
  pathName: string
): DropDownType => {
  if (unitId) {
    const componentUnit = sections
      .flatMap((x) => x.componentUnits)
      .find((x) => x.id === unitId);

    if (componentUnit) {
      const dropdownMultiple = dropdowns
        .filter((x) => x.type === DropdownType.Multiple)
        .flatMap((x) => (x as DropDownItemList).items);

      const dropdown = dropdownMultiple.find((x) => x.id === unitId);

      if (dropdown) {
        return dropdown;
      }
    }
  }

  return (
    dropdowns.find((x) => x.id === getRouteConstant(pathName)) ?? dropdowns[0]
  );
};

export const NavigationMenu: React.FC<NavigationMenuProps> = (props) => {
  const history = useHistory();
  const translate = useIntl();
  const { plantId, unitId } = useParams<HeaderParms>();
  const [firstDropdown, setFirstDropdown] = useState<FirstDropdown | undefined>(
    undefined
  );
  const [secondDropdown, setSecondDropdown] = useState<
    SecondDropdown | undefined
  >(undefined);
  const [thirdDropdown, setThirdDropdown] = useState<
    ThirdDropdown | undefined
  >();
  const type = getType(history.location.search);
  const sectionId = getSectionId(history.location.search);

  useEffect(() => {
    if (props.menu) {
      const [model, selected] = getFirstDropdownEntry(
        props.menu,
        history,
        plantId,
        type
      );

      setFirstDropdown({
        dropdownItems: getFirstDropdown(props.menu, history, type),
        model: model,
        selected: selected,
      });
    }
  }, [props.menu, history, history.location.search, plantId, sectionId, type]);

  useEffect(() => {
    if (firstDropdown?.model) {
      const sectionDropdown = getSecondDropdown(
        {
          id: firstDropdown.model.id,
          name: firstDropdown.model.name,
          shortName: firstDropdown.model.shortName,
          itemTypeId: firstDropdown.model.itemTypeId,
          itemTypeName: firstDropdown.model.itemTypeName,
          sections: firstDropdown.model.sections,
        },
        history,
        translate
      );

      setSecondDropdown({
        dropdownItems: sectionDropdown,
        selected: getSecondDropdownEntry(
          firstDropdown.model.sections,
          sectionDropdown,
          sectionId,
          type
        ),
      });

      const thirdDropdownItems = getThirdDropdownItems(
        {
          id: firstDropdown.model.id,
          name: firstDropdown.model.name,
          shortName: firstDropdown.model.shortName,
          sections: firstDropdown.model.sections,
          itemTypeId: firstDropdown.model.itemTypeId,
          itemTypeName: firstDropdown.model.itemTypeName,
        },
        history,
        translate,
        type,
        sectionId
      );

      setThirdDropdown({
        dropdownItems: thirdDropdownItems,
        selected: getThirdDropdownEntry(
          firstDropdown.model.sections,
          thirdDropdownItems,
          unitId,
          history.location.pathname
        ),
      });
    }
  }, [
    plantId,
    unitId,
    sectionId,
    history,
    history.location,
    firstDropdown,
    translate,
    type,
  ]);

  return (
    <>
      <FirstDropdownContainer mobile={props.mobile}>
        {firstDropdown && firstDropdown.selected && (
          <Dropdown
            key={firstDropdown.selected.id}
            items={firstDropdown.dropdownItems}
            initial={firstDropdown.selected}
          />
        )}
      </FirstDropdownContainer>
      {!props.mobile && (
        <SecondDropdownContainer>
          {secondDropdown && secondDropdown.selected && (
            <Dropdown
              key={secondDropdown.selected.id}
              initial={secondDropdown.selected}
              items={secondDropdown.dropdownItems}
            />
          )}
        </SecondDropdownContainer>
      )}
      {!props.mobile && (
        <ThirdDropdownContainer>
          {thirdDropdown && (
            <Dropdown
              key={`${firstDropdown?.selected?.id}${secondDropdown?.selected?.id}${thirdDropdown.selected.id}`}
              initial={thirdDropdown.selected}
              items={thirdDropdown.dropdownItems}
            />
          )}
        </ThirdDropdownContainer>
      )}
    </>
  );
};
