import React, { useRef, useState, useEffect, ReactElement } from "react";
import styled from "styled-components";

type TooltipProps = {
  render: ReactElement | string;
  standardWidth?: boolean;
  overrideDimensionTop?: number;
};

type Dimensions = {
  x: number;
  y: number;
  left: number;
  right: number;
  top: number;
  bottom: number;
};

type TooltipBoxProps = Pick<Dimensions, "left" | "top">;

const TooltipBox = styled.div<TooltipBoxProps>`
  display: flex;
  position: absolute;
  visibility: hidden;
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  background-color: ${(props) => props.theme.colors.tooltipBackgroundColor};
  text-align: center;
  padding: ${(props) => props.theme.spacing.light};
  font-size: ${(props) => props.theme.scaledFontSizes.small};
  margin-top: 1px;
  border-radius: ${(props) => props.theme.borderRadius.light};
  color: ${(props) => props.theme.colors.textColor.primary};
  box-shadow: 0 4px 6px hsla(0, 0%, 0%, 0.1);
  z-index: 10000;
`;

const Children = styled.div``;

const TooltipComponent = styled.div`
  & ${Children}:hover + ${TooltipBox} {
    visibility: visible;
    opacity: 1;
    transition: 0.25s all ease;
    transition-delay: 0.75s;
  }
`;

export const OrigoTooltip: React.FC<TooltipProps> = (props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<Dimensions>({
    x: 0,
    y: 0,
    left: 0,
    right: 0,
    top: props.overrideDimensionTop ? props.overrideDimensionTop : 0,
    bottom: 0,
  });

  useEffect(() => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current!.getBoundingClientRect();

      setState({
        x: rect.x,
        y: rect.y,
        bottom: rect.bottom,
        left: rect.left,
        right: rect.right,
        top: props.overrideDimensionTop ? props.overrideDimensionTop : rect.top,
      });
    }
  }, [wrapperRef, props.overrideDimensionTop]);

  return (
    <TooltipComponent data-testid="TooltipTest" ref={wrapperRef}>
      <Children>{props.children}</Children>
      <TooltipBox
        data-testid="TooltipBoxTest"
        top={state.top}
        left={state.left}
      >
        {props.render}
      </TooltipBox>
    </TooltipComponent>
  );
};
