import React from "react";
import styled from "styled-components";
import { ComponentUnitSize } from "../WindTurbineMarker";

type WindTurbineNameProps = {
  windTurbineName: string;
  unitSize: ComponentUnitSize;
};
type InternalProps = Pick<WindTurbineNameProps, "unitSize">;

const Name = styled.div<InternalProps>`
  font-weight: 600;
  color: ${(props) =>
    props.theme.colors.buttonColors.secondaryButtonTextColor.primary};
  font-size: ${(props) =>
    props.unitSize === ComponentUnitSize.SMALL ||
    props.unitSize === ComponentUnitSize.MEDIUM
      ? props.theme.constantFontSizes.small
      : props.theme.constantFontSizes.medium};
  padding-right: ${(props) => props.theme.spacing.extraLight};
`;

export const WindTurbineName: React.FC<WindTurbineNameProps> = (props) => {
  return <Name unitSize={props.unitSize}>{props.windTurbineName}</Name>;
};
