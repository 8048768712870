import React from "react";
import { SignalRContext, SignalRDispatchContext } from "../providers/ScadaPlusSignalRProvider";

export const useSignalRContext = () => {
  const context = React.useContext(SignalRContext);
  if (context === undefined) {
    throw new Error("useSignalRContext must be used within a ScadaPlusSignalRProvider");
  }
  return context;
};

export const useSignalRDispatch = () => {
  const context = React.useContext(SignalRDispatchContext);
  if (context === undefined) {
    throw new Error("useSignalRDispatch must be used within a ScadaPlusSignalRProvider");
  }
  return context;
};
