import React, { useEffect } from "react";
import styled, { useTheme } from "styled-components";
import config from "config";
import { GenericAlarm, GenericAlarmPriorityCounter } from "types";
import { useQueryClient } from "react-query";
import Alarm from "components/Alarm";
import { useIntl } from "react-intl";
import { useAlarmSubscription } from "hooks/useSubscription";
import { useHistory } from "react-router-dom";
import { constructComponentUnitAlarmUrl, getType } from "utils/routing-utils";
import AlarmPriorityHeaders from "components/AlarmPriorityHeaders";
import { useScadaPlusQuery } from "hooks/useScadaPlusQuery";
import { isUsingMobileDevice } from "utils/device-utils";
import { useGetAlarms } from "hooks/useGetAlarms";
import ListedAlarmsMessage from "components/ListedAlarmsMessage";

const mobile = isUsingMobileDevice(window.navigator);

const UnitAlarms = styled.div`
  grid-row: 1 / 5;
  grid-column: 1 /-1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  max-height: 100%;
  min-height: 100%;
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundColor.primary};
`;

const UnitAlarmList = styled.div`
  height: 100%;
  overflow-y: scroll;
  margin-top: ${(props) => props.theme.spacing.light};
`;

const NoAlarmsContainer = styled.div`
  display: grid;
  place-items: center;
  color: ${(props) => props.theme.colors.textColor.secondary};
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
  font-weight: 600;
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundColor.primary};
`;

type AlarmsProps = {
  componentUnitId: string;
};

export const Alarms: React.FC<AlarmsProps> = (props) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const history = useHistory();
  const type = getType(history.location.search);
  const queryClient = useQueryClient();
  const alarmPrioritiesQueryName = `alarmPriorities-${props.componentUnitId}`;
  const alarmsQueryName = `activeAlarmsForUnit-${props.componentUnitId}`;
  const pageSize = 30;

  const { data: newAlarm } = useAlarmSubscription({
    filter: {
      windTurbineId: props.componentUnitId,
    },
  });

  const { data: alarmPriorityData } = useScadaPlusQuery<
    Array<GenericAlarmPriorityCounter>
  >(
    alarmPrioritiesQueryName,
    `${config.api.url}/${config.api.genericAlarmPriorityCounters}?ComponentUnitId=${props.componentUnitId}`
  );

  const { data: alarms } = useGetAlarms(
    {
      componentUnitId: props.componentUnitId,
    },
    { pageSize }
  );

  const filterActiveAlarms = (alarm: GenericAlarm) => {
    return alarm.isActive;
  };

  const activeAlarms = alarms?.filter(filterActiveAlarms);

  useEffect(() => {
    if (newAlarm) {
      queryClient.invalidateQueries(alarmsQueryName);
      queryClient.invalidateQueries(alarmPrioritiesQueryName);
    }
  }, [
    newAlarm,
    queryClient,
    props.componentUnitId,
    alarmsQueryName,
    alarmPrioritiesQueryName,
  ]);

  return (
    <UnitAlarms>
      {alarmPriorityData && activeAlarms && (
        <div>
          <AlarmPriorityHeaders alarmPriorityData={alarmPriorityData} />
          <ListedAlarmsMessage
            activeAlarms={activeAlarms}
            alarmPriorityData={alarmPriorityData}
            containerHeight="1.2rem"
            textAlign="center"
            fontSize={theme.scaledFontSizes.medium}
            padding="0.5rem"
            alignSelf="center"
          ></ListedAlarmsMessage>
        </div>
      )}
      {activeAlarms && activeAlarms.length > 0 && !mobile ? (
        <UnitAlarmList>
          {activeAlarms.map((alarm) => (
            <Alarm
              alarm={alarm}
              key={alarm.id}
              onClick={() =>
                history.push(
                  constructComponentUnitAlarmUrl(
                    alarm.plantId!,
                    undefined,
                    alarm.componentUnitId,
                    type,
                    alarm.id
                  )
                )
              }
            />
          ))}
        </UnitAlarmList>
      ) : (
        <NoAlarmsContainer>
          {!mobile ? (
            <div>
              {formatMessage({
                id: "alarms.noAlarms",
                defaultMessage: "No Alarms",
              })}
            </div>
          ) : (
            <></>
          )}
        </NoAlarmsContainer>
      )}
    </UnitAlarms>
  );
};
