import { useRef, useEffect } from "react";
import * as d3 from "d3";
import { ProductionDatum } from "../../types";
import { useScadaPlusTheme } from "../../hooks/useScadaPlusTheme";
import styled from "styled-components";
import { stack } from "d3";
import { Mode } from "components/ProductionChart/ProductionChart";

interface StackedBarChartProps {
  data: Array<ProductionDatum>;
  mode: Mode;
  fullWidth?: boolean;
  getTooltipText?: (
    actual: number,
    forecast: number,
    timestamp: number
  ) => [ToolTipText, ToolTipText];
}

type Datum = {
  actual: number;
  forecast: number;
  difference: number;
  timestamp: string;
};
type Colors = {
  [key: string]: string;
};

type ContainerProps = {
  fullWidth?: boolean;
  padding: string;
};

export type ToolTipText = {
  key: string;
  value: string;
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: ${(props: ContainerProps) => {
    return props.padding;
  }};
`;

export const StackedBarChart = (props: StackedBarChartProps) => {
  const { theme } = useScadaPlusTheme();
  const d3Container = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (d3Container.current) {
      const svg = d3.select(d3Container.current);
      const { width, height } = d3Container.current.getBoundingClientRect();
      svg.attr("viewBox", `0 0 ${width} ${height}`);

      if (props.data) {
        const dataset: Array<Datum> = props.data.map((x) => {
          return {
            actual: x.actual,
            forecast: x.forecast,
            timestamp: x.timestamp,
            difference: x.forecast - x.actual,
          };
        });
        //TODO:Typing - keyOf?
        const stackGenerator = stack()
          .keys(["actual", "difference"])
          .order(d3.stackOrderNone);

        const colors: Colors = {
          actual: theme.colors.mainColor.secondary,
          difference: theme.colors.mainColor.background,
        };

        const layers = stackGenerator(dataset as any);

        const max = d3.max(dataset, function (d) {
          return d.actual + d.difference;
        })!;

        const x = d3.scaleBand().range([0, width]);

        if (dataset.length > 1) {
          x.paddingInner(0.2);
        }

        x.domain(
          dataset.map((d) => {
            return props.mode === Mode.Month
              ? new Date(d.timestamp).getUTCDate().toString()
              : new Date(d.timestamp).getUTCHours().toString();
          })
        );

        const y = d3
          .scaleLinear()
          .range([height, 0])
          .domain([0, max ?? 0]);

        svg
          .selectAll(".layer")
          .data(layers)
          .join("g")
          .attr("class", "layer")
          .attr("fill", (layer, b, a) => {
            return colors[layer.key];
          })
          .selectAll(".bar")
          .data((layer) => layer)
          .join("rect")
          .attr("class", "bar")
          .attr("x", (sequence) => {
            return Number(
              props.mode === Mode.Month
                ? x(new Date(sequence.data.timestamp).getUTCDate().toString())
                : x(new Date(sequence.data.timestamp).getUTCHours().toString())
            );
          })
          .attr("width", x.bandwidth())
          .attr("y", (sequence) => {
            return y(sequence[1])!;
          })
          .attr("height", (sequence) => {
            let tmp = y(sequence[0])! - y(sequence[1])!;
            if (tmp < 0) {
              tmp = 0;
            }
            return tmp;
          })
          // .on("mousemove", function (d, b, c) {
          //   const xPosition = d3.mouse(c[b] as any)[0] + 20;
          //   const yPosition = d3.mouse(c[b] as any)[1] - 20;

          //   // if (xPosition + 170 > width) {
          //   //   xPosition -= 240;
          //   // }

          //   // if (yPosition < 0) {
          //   //   yPosition += 20;
          //   // }

          //   // if (yPosition + 40 > height) {
          //   //   yPosition -= 20;
          //   // }

          //   tooltip.attr(
          //     "transform",
          //     "translate(" + xPosition + "," + yPosition + ")"
          //   );

          //   let text = "";

          //   if (props.getTooltipText) {
          //     const texts = props.getTooltipText(
          //       d.data.actual,
          //       d.data.forecast,
          //       d.data.timestamp
          //     );
          //     tooltip.select(".lineOneKey").text(texts[0].key);
          //     tooltip.select(".lineOneValue").text(texts[0].value);
          //     tooltip.select(".lineTwoKey").text(texts[1].key);
          //     tooltip.select(".lineTwoValue").text(texts[1].value);

          //     const getXTextPosition = (texts: [ToolTipText, ToolTipText]) => {
          //       const stringLength = texts[0].key.concat(texts[1].key).length;
          //       const textXPosition =
          //         stringLength >= 25
          //           ? 110
          //           : stringLength <= 24 && stringLength >= 20
          //           ? 100
          //           : 80;
          //       return textXPosition;
          //     };

          //     tooltip
          //       .select(".lineOneValue")
          //       .attr("x", getXTextPosition(texts));
          //     tooltip
          //       .select(".lineTwoValue")
          //       .attr("x", getXTextPosition(texts));
          //   } else {
          //     text =
          //       d[0] === 0
          //         ? `${d.data.actual.toFixed(3)}`
          //         : `${d.data.forecast.toFixed(3)}`;
          //     tooltip.select("rect").attr("width", 60);
          //     tooltip.select("rect").attr("height", 30);
          //     tooltip.select(".lineOneKey").text(text);
          //   }
          // })
          .on("mouseover", function (d) {
            let text = "";

            // tooltip.attr(d3.event.stopPropagation());

            if (props.getTooltipText) {
              const texts = props.getTooltipText(
                d.data.actual,
                d.data.forecast,
                d.data.timestamp
              );

              tooltip.select(".lineOneKey").text(texts[0].key);
              tooltip.select(".lineOneValue").text(texts[0].value);
              tooltip.select(".lineTwoKey").text(texts[1].key);
              tooltip.select(".lineTwoValue").text(texts[1].value);

              const getXTextPosition = (texts: [ToolTipText, ToolTipText]) => {
                const stringLength = texts[0].key.concat(texts[1].key).length;
                const textXPosition =
                  stringLength >= 25
                    ? 110
                    : stringLength <= 24 && stringLength >= 20
                    ? 100
                    : 80;
                return textXPosition;
              };

              tooltip
                .select(".lineOneValue")
                .attr("x", getXTextPosition(texts));
              tooltip
                .select(".lineTwoValue")
                .attr("x", getXTextPosition(texts));
            } else {
              text =
                d[0] === 0
                  ? `${d.data.actual.toFixed(3)}`
                  : `${d.data.forecast.toFixed(3)}`;
              tooltip.select("rect").attr("width", 60);
              tooltip.select("rect").attr("height", 30);
              tooltip.select(".lineOneKey").text(text);
            }

            tooltip

              .style("display", null)
              .style("left", x + "px")
              .style("top", y + "px");
          })
          .on("mouseout", function () {
            tooltip.style("display", "none");
          });

        svg.select(".tooltip").remove();
        const tooltip = svg
          .append("g")
          .attr("class", "tooltip")
          .style("filter", "url(#drop-shadow)")
          .style("display", "none");

        tooltip
          .append("rect")
          .attr("class", "rect")
          .attr("width", 210)
          .attr("height", 50)
          .attr("y", 0)
          .attr("x", 0)
          .attr("fill", theme.colors.tooltipBackgroundColor)
          .attr("rx", theme.borderRadius.light)
          .attr("pointer-events", "none");

        tooltip
          .append("text")
          .attr("class", "lineOneKey")
          .attr("y", 20)
          .attr("x", 5)
          .attr("font-size", theme.scaledFontSizes.small)
          .attr("fill", theme.colors.textColor.primary)
          .attr("pointer-events", "none");

        tooltip
          .append("text")
          .attr("class", "lineOneValue")
          .attr("y", 20)
          .attr("font-weight", "600")
          .attr("font-size", theme.scaledFontSizes.small)
          .attr("fill", theme.colors.textColor.primary)
          .attr("pointer-events", "none");

        tooltip
          .append("text")
          .attr("class", "lineTwoKey")
          .attr("y", 40)
          .attr("x", 5)
          .attr("font-size", theme.constantFontSizes.small)
          .attr("fill", theme.colors.textColor.primary)
          .attr("pointer-events", "none");

        tooltip
          .append("text")
          .attr("class", "lineTwoValue")
          .attr("y", 40)
          .attr("font-size", theme.constantFontSizes.small)
          .attr("fill", theme.colors.textColor.primary)
          .attr("pointer-events", "none");

        const dropShadowFilter = tooltip
          .append("filter")
          .attr("id", "drop-shadow")
          .attr("height", "115%");

        dropShadowFilter
          .append("feGaussianBlur")
          .attr("in", "SourceAlpha")
          .attr("stdDeviation", 4)
          .attr("result", "blur");
        dropShadowFilter
          .append("feOffset")
          .attr("in", "blur")
          .attr("dx", 3)
          .attr("dy", 3)
          .attr("result", "offsetBlur");
        dropShadowFilter
          .append("feFlood")
          .attr("in", "offsetBlur")
          .attr("flood-color", theme.shadow.color)
          .attr("flood-opacity", "0.5")
          .attr("result", "offsetColor");
        dropShadowFilter
          .append("feComposite")
          .attr("in", "offsetColor")
          .attr("in2", "offsetBlur")
          .attr("operator", "in")
          .attr("result", "offsetBlur");

        const feMerge = dropShadowFilter.append("feMerge");

        feMerge.append("feMergeNode").attr("in", "offsetBlur");
        feMerge.append("feMergeNode").attr("in", "SourceGraphic");
      }
    }
  }, [props, theme]);

  return (
    <Container padding={theme.spacing.heavy} fullWidth={props.fullWidth}>
      <svg
        ref={d3Container}
        preserveAspectRatio="none"
        width={"100%"}
        height={"80%"}
      ></svg>
    </Container>
  );
};
