import HardhatPersonnelSVG from "components/svg/HardhatPersonnelSVG";
import React, { useEffect, useState } from "react";
import { useScadaPlusTheme } from "../../../../../../hooks/useScadaPlusTheme";
import { PersonnelLevel } from "../../../../../../types";
import { getPersonnelColor } from "../../../../../../utils/alarm-utils";

const CircleMarkerPersonnel: React.FC = () => {
  const { theme } = useScadaPlusTheme();
  const [personell, setPersonell] = useState<PersonnelLevel>("none");

  useEffect(() => {
    setPersonell("none");

    const randomNumber = Math.floor(Math.random() * (8 - 1) + 1);
    if (randomNumber === 1) {
      setPersonell("active");
      return;
    }

    if (randomNumber === 2) {
      setPersonell("attention");
      return;
    }

    setPersonell("none");
  }, []);

  if (personell === "none") return null;
  return (
    <HardhatPersonnelSVG
      width={"1em"}
      height={"1em"}
      color={getPersonnelColor(personell, theme)}
    />
  );
};

export default CircleMarkerPersonnel;
