import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import Box from "components/Box";
import InfoCard from "components/InfoCard";
import ProgressBar from "components/ProgressBar";
import { useTagsContext } from "hooks/useTags";
import { toMegaWatt } from "utils/production-utils";
import { useGetCapacity } from "hooks/useGetCapacity";
import { useGetLastSampleForTag } from "hooks/useGetLastSample";
import { Size } from "components/Modal/Modal";

const Container = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing.medium};
`;

const ProductionArea = styled.div`
  display: grid;
  margin-bottom: ${(props) => props.theme.spacing.medium};
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
`;

const ProductionContainer = styled.div`
  padding-top: 0%;
  grid-row: 1;
  grid-column: 2;
  align-items: right;
`;

const Progress = styled(ProgressBar)`
  align-items: right;
  justify-content: right;
  width: 100%;
  grid-column: 2;
  grid-row: 2;
  white-space: nowrap;
`;

const ProductionCapacity = styled.div`
  color: ${(props) => props.theme.colors.textColor.secondary};
  font-size: ${(props) => props.theme.scaledFontSizes.large};
  text-align: right;
  grid-row: 2;
  grid-column: 2;
  padding-right: 2%;
`;

const DownPlay = styled.span`
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
  color: ${(props) => props.theme.colors.textColor.quaternary};
`;

type CurrentProductionProps = {
  plantId?: string | undefined;
  sectionId?: string | undefined;
};

export const MobileLiveSectorDisplay: React.FC<CurrentProductionProps> = (
  props
) => {
  const { formatMessage } = useIntl();
  const { tags } = useTagsContext();
  const [capacity, setCapacity] = useState<number | undefined>(undefined);

  const { data: lastSample } = useGetLastSampleForTag(
    tags.WTURW,
    {
      plantId: props.plantId,
      sectionId: props.sectionId,
    },
    10 * 1000
  );

  const { data: resourceCapacity } = useGetCapacity(
    {
      plantId: props.plantId,
      sectionId: props.sectionId,
    },
    10 * 3000
  );

  useEffect(() => {
    if (lastSample && resourceCapacity) {
      setCapacity(
        (toMegaWatt(Number(lastSample.value)) /
          toMegaWatt(Number(resourceCapacity.capacity.value))) *
          100
      );
    }
  }, [lastSample, resourceCapacity]);

  const [totalProductionPercentage, setTotalProductionPercentage] = useState(0);
  const [totalProductionValue, setTotalProductionActual] = useState(0);

  useEffect(() => {
    const val = lastSample ? parseFloat(lastSample.value) / 1000 : 0;
    const cap = capacity ? capacity : 0;
    setTotalProductionPercentage(cap);
    setTotalProductionActual(val);
  }, [capacity, lastSample]);

  return (
    <Container>
      <Box>
        <InfoCard
          title={formatMessage({
            id: "header.Production",
            defaultMessage: "Text missing",
          })}
        >
          <ProductionArea>
            <ProductionContainer>
              <Progress
                progress={totalProductionPercentage}
                size={Size.Medium}
              />
            </ProductionContainer>
            <ProductionCapacity>
              {totalProductionPercentage.toFixed(0)}
              <DownPlay>%</DownPlay> / {totalProductionValue.toFixed(1)}{" "}
              {formatMessage({
                id: "pu.MW",
                defaultMessage: "Translation Not Found",
              })}
            </ProductionCapacity>
          </ProductionArea>
        </InfoCard>
      </Box>
    </Container>
  );
};
