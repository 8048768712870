import React, { SetStateAction, useEffect } from "react";
import {
  MapContainer,
  MapContainerProps,
  TileLayer,
  useMap,
} from "react-leaflet";
import styled from "styled-components";
import { ComponentUnit, Layers } from "types";
import { useHistory } from "react-router-dom";
import {
  getAverageLongitude,
  getAverageLatitude,
  getMapBounds,
} from "utils/windturbine-utils";
import { constructComponentUnitUrl, getType } from "utils/routing-utils";
import { LatLngTuple } from "leaflet";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import CircleMarker from "./CircleMarker";
import { OuterCircleSize } from "./CircleMarker/CircleMarker";
import MarineTrafficIcon from "./MarineTrafficIcon/MarineTrafficIcon";
import WindTurbineMarker from "./WindTurbineMarker";
import { ComponentUnitSize } from "./WindTurbineMarker/WindTurbineMarker";
import { usePersonnel } from "./hooks/usePersonnel";

const OverviewContainer = styled(MapContainer)<MapContainerProps>`
  background: ${(props) => props.theme.colors.bodyBackgroundColor};
`;

const WIND_TURBINE_ICON_TRESHOLD = 40;
const componentUnitMediumIconSizeThreshold = 90;
const windTurbineLargeIconSizeThreshold = 6;

/* prettier-ignore */
type MapOverviewProps = {
  componentUnits: Array<ComponentUnit>;
  sectionId: string | undefined;
  plantId: string;
  layers: Layers;
  ships: any;
  setProductionButtonDisplayState: React.Dispatch<React.SetStateAction<boolean>>;
};

type RelativeMapBoundsProps = {
  componentUnits: ComponentUnit[];
  center: LatLngTuple;
};

//TODO: Check padding and if fitBounds changes on resize, check if rerenders on reisze
const RelativeMapBounds = (props: RelativeMapBoundsProps) => {
  const map = useMap();
  if (props.componentUnits.length > 1) {
    map.fitBounds(
      getMapBounds(props.componentUnits),
      props.componentUnits.length < WIND_TURBINE_ICON_TRESHOLD
        ? { padding: [70, 120] }
        : { padding: [20, 20] }
    );
  } else {
    map.setView(props.center, 15);
  }
  return null;
};

export const WindTurbinesOverview: React.FC<MapOverviewProps> = (props) => {
  const { theme } = useScadaPlusTheme();
  const history = useHistory();
  const type = getType(history.location.search);
  const personell = usePersonnel(
    props.plantId,
    props.layers.personnel,
    props.componentUnits
  );

  useEffect(() => {
    if (displayAsCircleMarkers()) {
      props.setProductionButtonDisplayState(false);
    } else {
      props.setProductionButtonDisplayState(true);
    }
  }, [window.innerWidth, props.plantId, props.componentUnits.length]);

  const displayAsCircleMarkers = () => {
    if (
      props.componentUnits.length > WIND_TURBINE_ICON_TRESHOLD ||
      (props.componentUnits.length > 15 && window.innerWidth < 2200)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const center: LatLngTuple = [
    getAverageLatitude(props.componentUnits),
    getAverageLongitude(props.componentUnits),
  ];

  if (props.componentUnits.length === 0) {
    // TODO: use loader
    return <></>;
  }

  //TODO: When/if 10sec timer is removed, sett scrollwheelzoom and dragging to true
  // as it is now, the timer resets zoom ang dragging every 10 sec
  return (
    <OverviewContainer
      zoomSnap={0.1}
      key={`${props.plantId}${props.sectionId}${theme.name}`}
      scrollWheelZoom={false}
      dragging={false}
      zoomControl={false}
      doubleClickZoom={false}
      touchZoom={false}
      center={center}
      attributionControl={false}
      keyboard={false}
    >
      <div>
        {props.layers.map && (
          <TileLayer
            url={`../../${props.plantId}/{z}/{x}/{y}.png`}
            maxZoom={40}
          />
        )}

        {displayAsCircleMarkers()
          ? props.componentUnits.map((x) => {
              return (
                <CircleMarker
                  key={`circle_${x.id}`}
                  size={OuterCircleSize.SMALL}
                  componentUnit={x}
                  layers={props.layers}
                  productionLayerActive={props.sectionId ? true : false}
                  onClicked={() => {
                    history.push(
                      constructComponentUnitUrl(
                        props.plantId,
                        x.id,
                        props.sectionId,
                        type
                      )
                    );
                  }}
                />
              );
            })
          : props.componentUnits.map((x) => {
              return (
                <WindTurbineMarker
                  key={`unit_${x.id}`}
                  size={
                    props.componentUnits.length >
                    componentUnitMediumIconSizeThreshold
                      ? ComponentUnitSize.SMALL
                      : props.componentUnits.length <
                        windTurbineLargeIconSizeThreshold
                      ? ComponentUnitSize.LARGE
                      : ComponentUnitSize.MEDIUM
                  }
                  componentUnit={x}
                  layers={props.layers}
                  personell={personell.turbines[x.id]}
                  productionLayerActive={props.layers.production}
                  onClicked={() => {
                    history.push(
                      constructComponentUnitUrl(
                        props.plantId,
                        x.id,
                        props.sectionId,
                        type
                      )
                    );
                  }}
                />
              );
            })}
        {props.layers.marine &&
          props.ships.default.map((x: any) => (
            <MarineTrafficIcon data={x} key={x.IMO} />
          ))}
      </div>
      <RelativeMapBounds
        componentUnits={props.componentUnits}
        center={center}
      />
    </OverviewContainer>
  );
};
