import { DefaultTheme } from "styled-components";
import { IdCollection, Production } from "../types";
import { WindTurbineOperationValues, WindTurbineStatusValues } from "./tag-utils";

export const getCurrentHourData = (
  currentHour: number,
  data: Production | undefined
) => {
  if (!data || !data.data) {
    return undefined;
  }
  const item = data.data.find(
    (x) => currentHour === new Date(x!.timestamp).getHours()
  );

  if (item) {
    return item.actual / 1000;
  }
  return 0;
};

export const sum = (data: Production | undefined) => {
  if (!data || !data.data) {
    return undefined;
  }

  const aggregated = data.data
    .map((x) => x.actual)
    .reduce((acc, x) => acc + x, 0);

  return aggregated / 1000;
};

export const aggregate = (data: Production | undefined) => {
  if (!data || !data.data) {
    return {
      data: [],
    };
  }

  return {
    data: [
      {
        actual: data.data.reduce((a, b) => a + b.actual, 0),
        timestamp: new Date().toUTCString(),
        forecast: data.data.reduce((a, b) => a + b.forecast, 0),
      },
    ],
  };
};

export const valuesToMwh = (data: Production | undefined) => {
  return data && data.data
    ? data.data.map((x) => {
        return {
          actual: x.actual / 1000,
          forecast: x.forecast / 1000,
          timestamp: x.timestamp,
        };
      })
    : [];
};

const formatConfig = (prec: number) => {
  return {
    maximumFractionDigits: prec,
    minimumFractionDigits: prec,
  };
};

export const numberFormat = (
  value: number | bigint,
  cult: string,
  precission: number
) => new Intl.NumberFormat(cult, formatConfig(precission)).format(value);

export const toMegaWatt = (value: number) => {
  return value / 1000;
};

export const displayValueInMega = (
  value: number | undefined,
  precision = 1
) => {
  return value ? toMegaWatt(value).toFixed(precision) : displayValue(value);
};

export const displayValue = <T extends number | string>(
  value: T | undefined
) => {
  return `${value ? value : "-"}`;
};

export const getProductionColor = (
  productionPercentage: number,
  theme: DefaultTheme
) => {
  if (productionPercentage >= 20 && productionPercentage <= 40) {
    return theme.colors.attention;
  }

  if (productionPercentage >= 0 && productionPercentage < 20) {
    return theme.colors.warning;
  }

  return theme.colors.mainColor.primary;
};

export const getProductionInPercentage = (
  production: number,
  capacity: number
) => {
  return (production / capacity) * 100;
};

export const constructProductionUrl = (
  baseUrl: string,
  idCollection: IdCollection,
  urlParams?: string
): string => {
  if (urlParams) {
    urlParams = `&${urlParams}`;
  } else {
    urlParams = "";
  }

  if (idCollection.componentUnitId) {
    return `${baseUrl}?windTurbineId=${idCollection.componentUnitId}${urlParams}`;
  }
  if (idCollection.sectionId) {
    return `${baseUrl}?sectorId=${idCollection.sectionId}${urlParams}`;
  }
  if (idCollection.plantId) {
    return `${baseUrl}?windpowerplantId=${idCollection.plantId}${urlParams}`;
  }
  return `${baseUrl}?allWindPowerPlants=true${urlParams}`;
};

export const resolvePriorityColor = (operationData: string | undefined, statusData: string | undefined, theme: DefaultTheme) => {  
  if (operationData && statusData) {
    if (shouldUseWarningStyle(operationData, statusData)) {
        return theme.colors.warning;    
    } else if (shouldUseSignalStyle(operationData, statusData)) {
        return theme.colors.signal;
    } else if (shouldUsePrimaryStyle(operationData, statusData)) {
        return theme.colors.mainColor.primary;
    } else {
        return theme.colors.errorCardBackgroundColor;
      }
  } else {
    return "";
  }
};

export const resolveCardBackGroundColor = (operationData: string | undefined, statusData: string | undefined, theme: DefaultTheme) => {  
  if (operationData && statusData) {
    if (shouldUseWarningStyle(operationData, statusData)) {
        return theme.colors.warning;    
    } else if (shouldUseSignalStyle(operationData, statusData)) {
        return theme.colors.signal;
    } else if (shouldUsePrimaryStyle(operationData, statusData)) {
        return "";
    } else {
        return theme.colors.errorCardBackgroundColor;
      }
  } else {
    return "";
  }
};

export const resolveHover = (operationData: string | undefined, statusData: string | undefined, theme: DefaultTheme) => {
  if (operationData && statusData) {
    if (shouldUseSignalStyle(operationData, statusData)) {
      return theme.colors.signalHover;
    } else if (shouldUseWarningStyle(operationData, statusData)) {
      return theme.colors.alarmDetailsColour.alarm1Hover.primary;
    }
  } else {
    return theme.colors.errorCardBackgroundColor;
  }
};

export const shouldUseWarningStyle = (operationData: string | undefined, statusData: string | undefined,) => {
  if (
    operationData ===
      WindTurbineOperationValues.StoppedRequiresManualReset ||
    operationData === WindTurbineOperationValues.StoppedSevereError
  ) {
    return true;
  }

  if (
    statusData &&
    statusData === WindTurbineStatusValues.OutOfWorkManualRestart
  ) {
    return true;
  }

  return false;
};

export const shouldUseSignalStyle = (operationData: string | undefined, statusData: string | undefined,) => {
  if (
    statusData &&
    statusData !==
      WindTurbineStatusValues.SufficientWindForProduction &&
      statusData !== WindTurbineStatusValues.FaultCondition
  ) {
    return true;
  }

  if ( operationData &&
    operationData === WindTurbineOperationValues.ServiceMode ||
    operationData ===
      WindTurbineOperationValues.OperationalStopAutomaticRestart
  ) {
    return true;
  }

  return false;
};

export const shouldUsePrimaryStyle = (operationData: string | undefined, statusData: string | undefined,) => {
  if (operationData === WindTurbineOperationValues.Normal) {
    return true;
  }

  if (
    statusData === WindTurbineStatusValues.SufficientWindForProduction
  ) {
    return true;
  }

  return false;
};

