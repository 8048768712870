import React from "react";
import styled from "styled-components";

enum Size {
  Small = "Small",
  Medium = "Medium",
  Large = "Large",
  FullPage = "FullPage",
}

interface ProgressBarProps {
  progress: number;
  value?: number;
  backgroundColor?: string;
  unitAbbreviation?: string;
  style?: React.CSSProperties;
  className?: string | undefined;
  size?: Size;
}

interface IInternalComponentProps {
  progress?: number;
  value?: number;
  backgroundColor?: string;
  size?: Size;
}

const Container = styled.div<IInternalComponentProps>`
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.mainColor.quaternary};
  border-radius: ${(props) => props.theme.spacing.light};
`;

const Fill = styled.div<IInternalComponentProps>`
  width: ${(props) => (props.progress ? clampValue(props.progress) : 0)}%;
  background-color: ${(props) =>
    props.theme.colors.productionUnitMarkerProgressbarFill};
  height: ${(props) =>
    props.size
      ? props.size === Size.Small
        ? "20px"
        : props.size === Size.Medium
        ? "32px"
        : "64px"
      : "8px"};
  border-radius: ${(props) => props.theme.spacing.light};

  &:before {
    content: attr(data-label);
    font-size: ${(props) => props.theme.scaledFontSizes.small};
    position: absolute;
    text-align: center;
    margin-left: ${(props) => props.theme.spacing.heavy};
    color: ${(props) =>
      props.theme.colors.buttonColors.primaryButtonTextColor.primary};
  }
`;
const ValueDisplay = styled.div`
  color: ${(props) => props.theme.colors.textColor.primary};
  font-size: ${(props) => props.theme.scaledFontSizes.small};
  line-height: 16px;
`;

const clampValue = (num: number) => Math.min(Math.max(num, 0), 100);

export const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  return (
    <div className={props.className} style={props.style}>
      <Container
        data-testid="progressbarbackground"
        backgroundColor={props.backgroundColor}
        style={props.style}
      >
        <Fill
          data-testid="progressbarfill"
          size={props.size}
          style={{
            width: props.progress ? `${clampValue(props.progress)}%` : "0",
          }}
        />
      </Container>
      {props.value && (
        <ValueDisplay data-testid="progressbarvalue">
          {props.value.toFixed(1) + " " + props.unitAbbreviation}
        </ValueDisplay>
      )}
    </div>
  );
};
