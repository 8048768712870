import HexagonCard from "components/HexagonCard";
import ProgressBar from "components/ProgressBar";
import config from "config";
import { useGetCapacity } from "hooks/useGetCapacity";
import { useScadaPlusQuery } from "hooks/useScadaPlusQuery";
import {
  useSensorSubscription,
  useAlarmSubscription,
} from "hooks/useSubscription";
import { useTagsContext } from "hooks/useTags";
import { OverviewParams } from "pages/Overview/Overview";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { GenericAlarmPriorityCounter, OverViewConstants, Section } from "types";
import {
  getTotalNumberOfAlarmsPriorityOne,
  getTotalNumberOfAlarmsPriorityTwo,
  getTotalNumberOfAlarmsPriorityThree,
  getTotalNumberOfAlarmsPriorityFour,
} from "utils/alarm-utils";
import { toMegaWatt } from "utils/production-utils";
import { getAlarmPrioritiesQueryName } from "utils/query-invalidation-utils";
import { constructPlantUrl } from "utils/routing-utils";
import SectionPersonnelAlarmCounter from "../SectionPersonnelAlarmCounter";
import SectionProductionAlarmCounter from "../SectionProductionAlarmCounter";
import AlarmPriorityDisplayTile from "components/AlarmPriorityDisplay";

type LiveSectionDisplayProps = {
  section: Section;
};

const HexContainer = styled.div``;

const Title = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors.textColor.half};
`;

const OnlineSections = styled.div`
  color: ${(props) => props.theme.colors.textColor.primary};
  font-size: ${(props) => props.theme.scaledFontSizes.large};
  margin-bottom: ${(props) => props.theme.spacing.medium};
`;

const OF = styled.span`
  font-size: ${(props) => props.theme.constantFontSizes.small};
  color: ${(props) => props.theme.colors.textColor.half};
  font-weight: 400;
`;

const Progress = styled(ProgressBar)`
  justify-content: center;
  width: 80%;
  margin-bottom: ${(props) => props.theme.spacing.medium};
`;

const ProductionCapacity = styled.div`
  color: ${(props) => props.theme.colors.textColor.quaternary};
  margin-bottom: ${(props) => props.theme.spacing.medium};
`;

const ProductionCapacityDivider = styled.span`
  color: ${(props) => props.theme.colors.textColor.secondary};
`;

const ProductionCapacityNumber = styled.span`
  color: ${(props) => props.theme.colors.textColor.primary};
`;

const DownPlay = styled.span`
  font-size: ${(props) => props.theme.scaledFontSizes.xs};
  color: ${(props) => props.theme.colors.textColor.quaternary};
`;

const AlarmArea = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.medium};
  place-items: center;
`;

type MockedAlarms = {
  productionAlarmsCount: number;
  personnelAlarmsCount: number;
};

export const LiveSectionDisplay: React.FC<LiveSectionDisplayProps> = ({
  section,
}) => {
  const tagContext = useTagsContext();
  const { formatMessage } = useIntl();
  const { plantId } = useParams<OverviewParams>();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [mockedAlarms, setMockedAlarms] = useState<MockedAlarms>({
    productionAlarmsCount: 0,
    personnelAlarmsCount: 0,
  });

  const alarmPrioritiesQueryName = getAlarmPrioritiesQueryName([section.id]);
  const { sensorData: productionData } = useSensorSubscription({
    filters: [{ id: tagContext.tags.WAPCWSec, sectorId: section.id }],
  });

  const { data: alarmData } = useAlarmSubscription({
    filter: {
      sectorId: section.id,
    },
  });

  const { data: alarmPriorityData } = useScadaPlusQuery<
    Array<GenericAlarmPriorityCounter>
  >(
    alarmPrioritiesQueryName,
    `${config.api.url}/${config.api.genericAlarmPriorityCounters}?sectionId=${section.id}`
  );

  const [totalProductionPercentage, setTotalProductionPercentage] = useState(0);

  const { data: resourceCapacity } = useGetCapacity({
    sectionId: section.id,
    plantId: plantId,
  });

  useEffect(() => {
    if (alarmData) {
      queryClient.invalidateQueries(alarmPrioritiesQueryName);
    }
  }, [alarmData, alarmPrioritiesQueryName, queryClient]);

  useEffect(() => {
    if (productionData) {
      const productionValue = parseFloat(productionData.value);
      if (productionValue && resourceCapacity) {
        setTotalProductionPercentage(
          (productionValue / resourceCapacity.capacity.value) * 100
        );
      } else {
        setTotalProductionPercentage(0);
      }
    }
  }, [productionData, section]);

  useEffect(() => {
    let productionAlarmsCount = 0;
    let personnelAlarmsCount = 0;

    if (Math.floor(Math.random() * 2) + 1 === 1) {
      productionAlarmsCount = 2;
    }

    if (Math.floor(Math.random() * 2) + 1 === 1) {
      personnelAlarmsCount = 1;
    }

    setMockedAlarms({
      productionAlarmsCount: productionAlarmsCount,
      personnelAlarmsCount: personnelAlarmsCount,
    });
    // eslint-disable-next-line
  }, []);

  const handleHexCardClick = () => {
    history.push(
      constructPlantUrl(plantId, section.id, OverViewConstants.COMPONENT_UNITS)
    );
  };

  return (
    <HexContainer>
      <HexagonCard onClick={() => handleHexCardClick()}>
        <Title>
          <p>
            {formatMessage({
              id: "overview.Section",
              defaultMessage: "Translation Not Found",
            })}
            {" " + section.shortName}
          </p>
          <OnlineSections>
            <OF>
              {formatMessage({
                id: "overview.section.Of",
                defaultMessage: "Translation Not Found",
              })}
            </OF>{" "}
          </OnlineSections>
        </Title>
        <Progress progress={totalProductionPercentage} />
        <ProductionCapacity>
          <ProductionCapacityNumber>
            {totalProductionPercentage.toFixed(0)}
          </ProductionCapacityNumber>
          <DownPlay>%</DownPlay>{" "}
          <ProductionCapacityDivider>/</ProductionCapacityDivider>{" "}
          <ProductionCapacityNumber>
            {resourceCapacity ? toMegaWatt(resourceCapacity.capacity.value) : 0}
          </ProductionCapacityNumber>{" "}
          <DownPlay>
            {formatMessage({
              id: "pu.MW",
              defaultMessage: "Translation Not Found",
            })}
          </DownPlay>
        </ProductionCapacity>
        <AlarmArea>
          <AlarmPriorityDisplayTile
            height="1.5rem"
            width="1.5rem"
            alarmPriortity={1}
          >
            {getTotalNumberOfAlarmsPriorityOne(alarmPriorityData)}
          </AlarmPriorityDisplayTile>
          <AlarmPriorityDisplayTile
            height="1.5rem"
            width="1.5rem"
            alarmPriortity={2}
          >
            {getTotalNumberOfAlarmsPriorityTwo(alarmPriorityData)}
          </AlarmPriorityDisplayTile>
          <AlarmPriorityDisplayTile
            height="1.5rem"
            width="1.5rem"
            alarmPriortity={3}
          >
            {getTotalNumberOfAlarmsPriorityThree(alarmPriorityData)}
          </AlarmPriorityDisplayTile>
          <AlarmPriorityDisplayTile
            height="1.5rem"
            width="1.5rem"
            alarmPriortity={4}
          >
            {getTotalNumberOfAlarmsPriorityFour(alarmPriorityData)}
          </AlarmPriorityDisplayTile>
          {mockedAlarms.productionAlarmsCount > 0 && (
            <SectionProductionAlarmCounter
              count={mockedAlarms.productionAlarmsCount}
            />
          )}
          {mockedAlarms.personnelAlarmsCount > 0 && (
            <SectionPersonnelAlarmCounter />
          )}
        </AlarmArea>
      </HexagonCard>
    </HexContainer>
  );
};
