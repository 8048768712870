import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import React from "react";
import styled, { DefaultTheme } from "styled-components";
import {
  resolveAlarmPriorityDisplayTileFontColor,
  resolveAlarmPriorityTileColor,
} from "utils/alarm-utils";

const Container = styled.div<CircleProps>`
  height: ${(props) => containerSize(props)};
  width: ${(props) => containerSize(props)};

  background-color: ${(props) => resolveCircleBackgroundColor(props)};
  color: ${(props) =>
    props.priority
      ? resolveAlarmPriorityDisplayTileFontColor(props.priority, props.theme)
      : ""};
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => (props.lessBorderRadius ? "30%" : "50%")};
  display: flex;
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.constantFontSizes.small};
`;

type CircleProps = {
  priority?: number;
  outerCircle?: boolean;
  size?: string;
  lessBorderRadius?: boolean;
  fontSize?: string;
  count?: number;
};

type ContainerProps = {
  priority?: number;
  count?: number;
  theme: DefaultTheme;
};

const containerSize = (props: CircleProps) => {
  if (props.outerCircle) {
    return "100%";
  } else if (props.size) {
    return props.size;
  } else {
    return "20px";
  }
};

const resolveCircleBackgroundColor = (props: ContainerProps) => {
  if (props.priority) {
    return resolveAlarmPriorityTileColor(props.priority, props.theme);
  }

  if (props.count != null) {
    return props.count > 0
      ? props.theme.colors.mainColor.primary
      : props.theme.colors.mainColor.quinary;
  } else {
    return props.theme.colors.componentBackgroundActive.primary;
  }
};

export const CircleSVG: React.FC<CircleProps> = (props) => {
  const { theme } = useScadaPlusTheme();
  return (
    <Container
      priority={props.priority}
      count={props.count}
      size={props.size}
      outerCircle={props.outerCircle}
      lessBorderRadius={props.lessBorderRadius}
      data-testid="alarmcircletestid"
      fontSize={props.fontSize}
      theme={theme}
    >
      {props.children}
    </Container>
  );
};
