import { useIntl } from "react-intl";
import styled from "styled-components";
import { MeasurementUnitSymbolConstants } from "utils/utils";

/* prettier-ignore */
const DataMeasurementType = styled.div<alignProps>`
  grid-column: 3;
  position: relative;
  top: ${(props) => props.measurementTypeAlign};
  padding-left: ${(props) => props.theme.spacing.extraLight};
  font-size: ${(props) => props.theme.numberSizes.small};
  line-height: ${(props) => props.theme.numberSizes.medium};

  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth} ) {
      font-size: ${(props) => props.theme.numberSizes.xs};
      line-height: ${(props) => props.theme.numberSizes.small};
  }
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth}) and (display-mode: fullscreen)  {
      font-size: ${(props) => props.theme.numberSizes.xs};
      line-height: ${(props) => props.theme.numberSizes.small};
  }

`;

type alignProps = {
  measurementTypeAlign?: string;
};

interface MeasurementUnitAbbreviationProps {
  measurementType: string;
}

export const MeasurementUnitAbbreviation: React.FC<
  MeasurementUnitAbbreviationProps
> = (props) => {
  const { formatMessage } = useIntl();
  const meassurementTypeAlign = (measurementType: string) => {
    if (measurementType === MeasurementUnitSymbolConstants.degree) {
      return "-0.313rem";
    } else {
      return "0.125rem";
    }
  };

  const translateAbbreviation = (measurementType: string) => {
    if (props.measurementType === MeasurementUnitSymbolConstants.hour) {
      return formatMessage({
        id: "component.Weather." + MeasurementUnitSymbolConstants.hour,
        defaultMessage: "Text missing",
      });
    } else {
      return measurementType;
    }
  };

  return (
    <DataMeasurementType
      measurementTypeAlign={meassurementTypeAlign(props.measurementType)}
    >
      {translateAbbreviation(props.measurementType)}
    </DataMeasurementType>
  );
};
