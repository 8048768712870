import React from "react";
import {
  LanguageContext,
  LanguageDispatchContext,
} from "../providers/ScadaPlusLanguageProvider";

export const useScadaPlusLanguage = () => {
  const context = React.useContext(LanguageContext);
  if (context === undefined) {
    throw new Error(
      "useLanguageContext must be used within a ScadaPlusLanguageProvider"
    );
  }
  return context;
};

export const useScadaPlusLanguageDispatch = () => {
  const context = React.useContext(LanguageDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useLanguageDispatch must be used within a ScadaPlusLanguageProvider"
    );
  }
  return context;
};
