import Button from "components/Button";
import styled from "styled-components";
import JournalEntryItem from "./JournalEntryItem";

interface JournalItem {
  id: number;
  date: string;
  userName: string;
  message: string;
}

const JournalGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr 3fr 1fr;
  grid-row-gap: 0.5rem;
  background-color: ${(props) => props.theme.colors.cardBackgroundHover};
  border-radius: ${(props) => props.theme.borderRadius.medium};
`;

const Header = styled.div`
  font-size: ${(props) => props.theme.constantFontSizes.mediumLarge};
  padding: 0.5rem;
`;

/* prettier-ignore */
const ContentContainer = styled.div`
  background-color: ${(props) => props.theme.colors.mainColor.half};
  border-radius: 0 0 ${(props) => props.theme.borderRadius.light} ${(props) => props.theme.borderRadius.light};
  padding: 0.2rem;
`;

const JournalEntries = styled.ul`
  width: 100%;
  height: 150px;
  overflow-y: scroll;
`;

const Footer = styled.div`
  display: grid;
  grid-template-columns: auto 6rem;
  grid-column-gap: 0.5rem;
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  align-items: end;
`;

// toDo: This should probably be a text area. Leaving this for now until we've got the sizing of everything locked down.
const TextInput = styled.input`
  height: 100%;
  border-radius: ${(props) => props.theme.borderRadius.light};
  background-color: ${(props) =>
    props.theme.colors.buttonColors.primaryButtonTextColor.primary};
`;

// toDo: handle no data, no entries or errors
// events
// hookup to backend
interface SIFLogProps {
  journalEntries: Array<JournalItem>;
}

export const SIFJournal: React.FC<SIFLogProps> = (props) => {
  return (
    <JournalGrid>
      <Header>Journal</Header>
      <ContentContainer>
        <JournalEntries>
          {props.journalEntries.map((item) => {
            return <JournalEntryItem journalEntry={item}></JournalEntryItem>;
          })}
        </JournalEntries>
      </ContentContainer>
      <Footer>
        <TextInput></TextInput>
        <Button
          type={"primary"}
          size="small"
          width="6rem"
          height="2rem"
          center={true}
        >
          Save
        </Button>
      </Footer>
    </JournalGrid>
  );
};
