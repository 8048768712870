import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import styled, { useTheme } from "styled-components";
import Button from "../components/Button";
import config from "../config";
import Wendy from "../components/Wendy";
import { MdClose } from "react-icons/md";
import Modal from "components/Modal";
import { Size } from "components/Modal/Modal";
import { WendyBodyStyles, WendyHeadStyles } from "components/Wendy/Wendy";

type State = {
  width: number;
  height: number;
};

const Container = styled.div`
  height: 100%;
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundColor.primary};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  padding: ${(props) => props.theme.spacing.extraHeavy};
  border: 3px solid ${(props) => props.theme.colors.mainColor.tertiary};
`;

const GridContainer = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 1fr auto;
`;
const Title = styled.div`
  grid-row: 1/2;
  grid-column: 1/-1;
  font-size: ${(props) => props.theme.scaledFontSizes.large};
  align-self: center;
  margin: ${(props) => props.theme.spacing.medium};
  margin-bottom: ${(props) => props.theme.spacing.heavy};
`;
const Body = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 50px 1fr;
  grid-column-gap: 10px;
  padding: 0 10px;
`;
const GridWendy = styled.div`
  grid-column: 1/2;
  justify-self: start;
`;

const GridText = styled.div`
  grid-column: 2/3;
`;

const GridButton = styled.div`
  grid-row: 1/2;
  grid-column: 2/3;
  justify-self: end;
  align-self: start;
`;

const viewportContext = React.createContext<State>(
  config.screenResolutions.min
);

export const ScadaPlusViewPortProvider: React.FC = (props) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      <>
        <ViewPortSizeListener />
        {props.children}
      </>
    </viewportContext.Provider>
  );
};

const ViewPortSizeListener: React.FC = () => {
  const viewPort = useViewport();
  const { formatMessage } = useIntl();
  const [acknowledged, setAcknowledged] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    if (viewPort.width <= 812 || viewPort.height <= 812) {
      return;
    }

    if (viewPort.width < config.screenResolutions.min.width && !acknowledged) {
      setShowModal(true);
    }

    if (viewPort.width > config.screenResolutions.min.width && !acknowledged) {
      setShowModal(false);
    }

    if (
      viewPort.height < config.screenResolutions.min.height &&
      !acknowledged
    ) {
      setShowModal(true);
    }

    if (
      viewPort.height > config.screenResolutions.min.height &&
      !acknowledged
    ) {
      setShowModal(false);
    }
  }, [viewPort, acknowledged]);

  const handleOk = () => {
    setAcknowledged(true);
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <Modal size={Size.Medium}>
          <Container>
            <GridContainer>
              <Title>
                {formatMessage({
                  id: "error.Whooops",
                  defaultMessage: "Whooops",
                })}
              </Title>
              <GridButton>
                <Button
                  style={{
                    borderRadius: "50%",
                    height: "35.19px",
                  }}
                  onclick={() => handleOk()}
                  size="xs"
                  type="primary"
                  icon={<MdClose fill={theme.colors.iconColor} size={18} />}
                />
              </GridButton>
            </GridContainer>
            <Body>
              <GridWendy>
                <Wendy
                  size={40}
                  BodyStyle={WendyBodyStyles.Alert}
                  HeadStyle={WendyHeadStyles.Sad}
                />
              </GridWendy>
              <GridText>
                <p>
                  {formatMessage({
                    id: "window.screenResolutionTooSmall",
                    defaultMessage: "Translation not found",
                  })}
                </p>
                <p>
                  {formatMessage({
                    id: "error.LayoutIssues",
                    defaultMessage: "Translation not found",
                  })}
                </p>
              </GridText>
            </Body>
          </Container>
        </Modal>
      )}
    </>
  );
};

export const useViewport = () => {
  const { width, height } = React.useContext<State>(viewportContext);
  return { width, height };
};
