import styled from "styled-components";
import { SIFAlert } from "../SIFAlert/SIFAlert";

const ContentGrid = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-gap: 0.5rem;
`;

export const SIFAlertGrid: React.FC = () => {
  return (
    <ContentGrid>
      <SIFAlert alert={false} title={"Availability"}></SIFAlert>
      <SIFAlert alert={true} title={"Spurious Trip Rate"}></SIFAlert>
      <SIFAlert alert={false} title={"Fail Test Rate"}></SIFAlert>
      <SIFAlert alert={false} title={"Demand Rates"}></SIFAlert>
      <SIFAlert alert={false} title={"Failed to Operate"}></SIFAlert>
      <SIFAlert alert={false} title={"RRF Achieved"}></SIFAlert>
      <SIFAlert alert={true} title={"Device Failure"}></SIFAlert>
      <SIFAlert alert={false} title={"Proof Test Delayed"}></SIFAlert>
      <SIFAlert alert={false} title={"Excessive Bypass Time"}></SIFAlert>
    </ContentGrid>
  );
};
