import React from "react";
import {
    TagsContext,
    TagsDispatchContext,
} from "../providers/ScadaPlusTagsProvider";


export const useTagsContext = () => {
    const context = React.useContext(TagsContext);
    if (context === undefined) {
      throw new Error("useTagsContext must be used within a TagsProvider");
    }
    return context;
  };
  
  export const useTagsDispatch = () => {
    const context = React.useContext(TagsDispatchContext);
    if (context === undefined) {
      throw new Error("useTagsDispatch must be used within a TagsProvider");
    }
    return context;
  };