import styled from "styled-components";

export const Acknowledge = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  place-items: center;
  padding: 0;
`;

export const Type = styled.div`
  grid-column-start: 3;
  grid-column-end: 5;
  align-self: center;
`;

export const Time = styled.div`
  grid-column-start: 5;
  grid-column-end: 7;
  align-self: center;
`;

export const Title = styled.div`
  word-break: break-all;
  grid-column-start: 7;
  grid-column-end: 13;
  align-self: center;
  max-width: 19.6875rem;
  min-width: 11.25rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Unit = styled.div`
  grid-column-start: 13;
  align-self: center;
`;

export const Area = styled.div`
  grid-column: 14/15;
  align-self: center;
`;

export const Supplier = styled.div`
  grid-column: 15/16;
  align-self: center;
`;

export const Comment = styled.div`
  grid-column: 16/-1;
  align-self: center;
  justify-self: end;
`;
