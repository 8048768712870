// STKN new WT status card
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTheme } from "styled-components";
import InfoCard from "../../../components/InfoCard";
import { useTagsContext } from "../../../hooks/useTags";
import {
  useSensorSubscription,
  SensorData,
} from "../../../hooks/useSubscription";
import { useIntl } from "react-intl";
import WarningSVG from "components/svg/WarningSVG";
import WindTurbineStatusSVG from "components/svg/WindTurbineStatusSVG";

const Container = styled.div`
  display: grid;
  place-items: center;
  grid-template-rows: auto;
  grid-template-columns: 1fr 3fr 1fr;
  height: 30%;
  padding-bottom: 5%;
`;

const StatusTextArea = styled.div`
  padding-top: 0%;
  display: grid;
  place-items: center;
  grid-template-rows: 1;
  grid-template-columns: 1;
  grid-column: 2;
`;

const StatusText = styled.div`
  padding-top: 0%;
  grid-column: 1;
  grid-row: 1;
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.numberSizes.small};
`;

const StatusGraphics = styled.div`
  padding-top: 0%;
  padding-bottom: 9%;
  grid-column: 3;
  grid-row: 1;
  justify-content: center;
  display: flex;
`;

interface StatusCardMobileProps {
  componentUnitId: string;
  plantId: string;
  mobile?: boolean;
}

export const StatusCardMobile: React.FC<StatusCardMobileProps> = (props) => {
  const { tags } = useTagsContext();
  const { formatMessage } = useIntl();
  const [statuscode, setStatus] = useState<SensorData | null>(null);

  const { sensorData: tstatus } = useSensorSubscription({
    filters: [
      {
        id: tags.WTUROpCde,
        windTurbineId: props.componentUnitId,
      },
    ],
  });

  useEffect(() => {
    switch (tstatus?.id) {
      case tags.WTUROpCde:
        setStatus(tstatus);
        break;
      default:
        break;
    }
  }, [tstatus, tags]);

  const theme = useTheme();

  const st = statuscode ? statuscode.value : "-";
  const isStatusNormal = () => {
    if (st === "Normal") return true;
    else return false;
  };

  const getWarningBackgroundColor = () => {
    return theme.colors.textColor.primary;
  };

  const getWarningSymbolColor = () => {
    return theme.colors.warning;
  };

  const getText1 = () => {
    if (st === "Normal") return "NORMAL";
    else return "WARNING: Downtime";
  };

  const getBackColor = () => {
    if (st === "Normal") return theme.colors.componentBackgroundColor.primary;
    else return theme.colors.warning;
  };

  return (
    <InfoCard
      title={formatMessage({
        id: "pu.Status",
        defaultMessage: "Status",
      })}
      style={{
        height: "30%",
        background: getBackColor(),
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "30%",
          width: "100%",
        }}
      >
        <Container>
          <StatusGraphics>
            {isStatusNormal() ? (
              <WindTurbineStatusSVG
                width="60%"
                height="60%"
                color={theme.colors.componentBackgroundColor.primary}
              />
            ) : (
              <WarningSVG
                width="60%"
                height="60%"
                color={getWarningBackgroundColor()}
                colorSymbol={getWarningSymbolColor()}
              />
            )}
          </StatusGraphics>
          <StatusTextArea>
            <StatusText>{getText1()}</StatusText>
          </StatusTextArea>
        </Container>
      </div>
    </InfoCard>
  );
};
