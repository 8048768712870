export type Plant = {
  id: string;
  name: string;
  shortName: string;
  sections: Array<Section>;
  energyProductionCapacity: number;
};

export type Coordinates = {
  longitude: number;
  latitude: number;
};

export type IdCollection = {
  plantId?: string;
  sectionId?: string;
  componentUnitId?: string;
};

export type Measurement = {
  unit: string;
  value: number;
};

export type Production = {
  data: Array<ProductionDatum> | undefined;
};

export type ProductionDatum = {
  timestamp: string;
  actual: number;
  forecast: number;
};

export type GenericAlarm = {
  id: string;
  isActive: boolean;
  isAcknowledged: boolean;
  description?: string;
  componentUnitId?: string;
  supplier?: string;
  area?: string;
  timestamp: string;
  code?: string;
  priority: number;
  plantId?: string;
  sectionId?: string;
  acknowledgedAt?: string;
  acknowledgedBy?: string;
  correctiveActionAt?: string;
  correctiveAction?: string;
  flaggedAt?: string;
  flaggedBy?: string;
  isFlagged: boolean;
  comments: Array<GenericAlarmComment>;
  componentUnitName: string;
  condition: string;
};

export type GenericAlarmComment = {
  id: string;
  comment?: string;
  createdAt: string;
  createdBy?: string;
};

export type Tags = {
  WTUROpCde: string;
  WYAWYwAng: string;
  WGENRotSpd: string;
  WROTRotSpd: string;
  WCNVGnHz: string;
  WSTRBalDepth: string;
  WTOWTowPres: string;
  WTOWSway: string;
  WTOWHeading: string;
  WTOWSurge: string;
  WTOWHeave: string;
  WMETWaveHeigth: string;
  WMETWaveHeigthAvg: string;
  WMETWaveSigHeight: string;
  WTRMBrkOpSt: string;
  WTURStCde: string;
  WGENGnOpSt: string;
  WYAWYwSt: string;
  WAVLTLC: string;
  WAVLDisconSt1: string;
  WAVLEthSwOp: string;
  WAPCW: string;
  WTRFOilLevSt: string;
  WTRFTnkPresSt: string;
  WAPCWSec: string;
  WAPCWSpt: string;
  WTURTotWh: string;
  WTURTurSt: string;
  WTURTurStCounter: string;
  WTURW: string;
  WTURVar: string;
  WYAWCabWup: string;
  WNACPitch: string;
  WNACRoll: string;
  WNACYaw: string;
  WTOWPitch: string;
  WTOWRoll: string;
  WTOWYaw: string;
  WTRFTurA1: string;
  WTRFTurA3: string;
  WTRFTurA2: string;
  WTRFTurAAvg: string;
  WTRFTurPPV1: string;
  WTRFTurPPV2: string;
  WTRFTurPPV3: string;
  WTRFTurPPVAvg: string;
  WMETHorWdDir: string;
  WMETHorWdSpd: string;
  WMETEnvPres: string;
  WTRMGbxOilTmp: string;
  WNAClntnTmp: string;
  WMETEnvTmp: string;
  WTURRotTmp: string;
  WTURSttTmp: string;
  WAPCWAll: string;
  WMETWdGustSpdMax: string;
  WMETHorWdSpdMax: string;
  WAVLAviTmsRs: string;
  WAVLRsStatTot: string;
  WAVLErTmsRs: string;
  WAVLWndInRng: string;
  WAVLOpTmsRs: string;
  WAVLRsStatSub: string;
  WAVLAccEnRs: string;
  WAVLGrdFltTmhRs: string;
  WAVLExtErTmhRs: string;
  WAVLTurAvl: string;
  WTURLastError_1: string;
  WTURLatitude: string;
  WTURLongitude: string;
  WTURValidity: string;
  WTURLatDiff: string;
  WTURLonDiff: string;
};

export type ProductionValues = {
  current: number;
  max: number;
};

export type Layers = {
  personnel: boolean;
  production: boolean;
  camera: boolean;
  marine: boolean;
  map: boolean;
};

export type MenuComponentUnit = {
  id: string;
  name: string;
  itemTypeId: string;
  itemTypeName: string;
  shortName: string;
};

export type MenuSection = {
  id: string;
  name: string;
  shortName: string;
  itemTypeId: string;
  itemTypeName: string;
  componentUnits: Array<MenuComponentUnit>;
};

export type MenuPlant = {
  id: string;
  name: string;
  shortName: string;
  itemTypeId: string;
  itemTypeName: string;
  sections: Array<MenuSection>;
};

export type PersonnelLevel = "active" | "attention" | "none";

export type GenericMenu = Array<MenuPlant>;

export type AlarmTypeCounters = {
  1: number;
  2: number;
  3: number;
  4: number;
};

export type GenericAlarmPriorityCounter = {
  priority: number;
  numberOfAlarms: number;
};

export const SearchConstants = {
  SECTION_ID: "sectionId",
  TYPE: "type",
};

export const RouteConstants = {
  Alarm: "alarm",
  Overview: "overview",
  Report: "report",
  SingeLine: "single-line",
  ComponentUnit: "component-unit",
};
export const OverViewConstants = {
  SECTIONS: "sections",
  COMPONENT_UNITS: "component-units",
};
export type MarineTrafficPosition = {
  MMSI: string;
  IMO: string;
  SHIP_ID: string;
  LAT: string;
  LON: string;
  SPEED: string;
  HEADING: string;
  COURSE: string;
  STATUS: string;
  TIMESTAMP: Date;
  DSRC: string;
  UTC_SECONDS: string;
  SHIPNAME: string;
  SHIPTYPE: string;
  CALLSIGN: string;
  FLAG: string;
  LENGTH: string;
  WIDTH: string;
  GRT: string;
  DWT: string;
  DRAUGHT: string;
  YEAR_BUILT: string;
  ROT: string;
  TYPE_NAME: string;
  AIS_TYPE_SUMMARY: string;
  DESTINATION: string;
  ETA: Date;
};

export type Section = {
  id: string;
  name: string;
  shortName: string;
  componentUnits: Array<ComponentUnit>;
  energyProductionCapacity: number;
};

export type ComponentUnit = {
  id: string;
  name: string;
  shortName: string;
  type: ComponentUnitType;
  coordinates: Coordinates;
  energyProductionCapacity: number;
};

export type ComponentUnitType = {
  id: string;
  name: string;
  description?: string;
};

export type Credit = {
  link: string;
  name: string;
};

export type ObjectType<T> = {
  [key: string]: T;
};

export type LastSample = {
  id: string;
  value: string;
  timestamp: string;
};

export type ErrorMessage = {
  status?: number | undefined;
  text: string | undefined;
};

export type DailyReport = {
  numberOfStopsCommanded: number | undefined;
  numberOfStopsDueToMachineFault: number | undefined;
  numberOfStopsDueToGridFault: number | undefined;
  numberOfStopsEnvironmental: number | undefined;
  activeEnergyImport: number | undefined;
  activeEnergyExport: number | undefined;
  reactiveEnergyImport: number | undefined;
  reactiveEnergyExport: number | undefined;
  periodHours: number | undefined;
  turbineReady: number | undefined;
  generatorHours: number | undefined;
  baseHours: number | undefined;
  standardAvailability: number | undefined;
  commercialAvailability: number | undefined;
  capacityFactor: number | undefined;
  windPowerPlantAvailability: number | undefined;
  windAvailability: number | undefined;
  actualEnergy: number | undefined;
};

export type ServicePersonnel = {
  id: string;
  firstName?: string;
  lastName?: string;
  company?: string;
  plantId: string;
  active: boolean;
};

export type ServiceLocations = {
  serviceLocationId: string;
  serviceLocationTypeId: string;
  serviceLocationTypeDescription?: string;
  description?: string;
  componentUnitId?: string;
  plantId: string;
  isActive: boolean;
};

export type ServiceBoarding = {
  serviceBoardingId: string;
  servicePersonnelId: string;
  person: ServicePersonnel;
  dateTimeFrom: Date;
  dateTimeTo: Date;
  isActive: boolean;
  currentLocationId: string;
  currentServiceLocation: ServiceLocations;
  plantId: string;
  plant: Plant;
  purpose: string;
};

export type ServiceLocation = {
    serviceLocationId: string;
    serviceLocationTypeId: string;
    serviceLocationTypeDescription: string;
    description: string;
    componentUnitId: string;
    plantId: string;
    isActive: boolean;
 };

export type ActiveBoardingCounters = {
  totalServicePersonellOnBoard: number | undefined;
  serviceLocationTypes: Array<ActiveBoardingLocationTypeCounter>;
};

export type ActiveBoardingLocationTypeCounter = {
  serviceLocationTypeId: string | undefined;
  serviceLocationTypeDescription: string;
  servicePersonellOnBoard: number | undefined;
  componentUnitId: string | undefined;
};

export type ServiceBoardingUpdate = {
  serviceBoardingId: string | undefined;
  servicePersonnelId: string | undefined;
  dateTimeFrom: Date | undefined;
  dateTimeTo: Date | undefined;
  currentServiceLocationId: string | undefined;
  purpose: string | undefined;
};

export type ServiceBoardingInsert = {
  servicePersonnelId: string | undefined;
  dateTimeFrom: Date | undefined;
  dateTimeTo: Date | undefined;
  currentServiceLocationId: string | undefined;
  plantId: string | undefined;
  purpose: string | undefined;
};

export type ServicePersonnelUpdate = {
  firstName: string | undefined;
  lastName: string | undefined;
  company: string | undefined;
};

export type ServicePersonnelInsert = {
  firstName: string | undefined;
  lastName: string | undefined;
  company: string | undefined;
  plantId: string | undefined;
};

export type NewServicePersonnelRes = {
  id: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  company: string | undefined;
  plantId: string | undefined;
  active: boolean;
};

export type ProductionProps = {
  idCollection: IdCollection;
};
