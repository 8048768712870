import NumberValueFormatter from "components/NumberValueFormatter";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import React, { ReactNode } from "react";
import styled from "styled-components";
import Box from "../Box";
import Title from "../Title";
import OrigoTooltip from "components/OrigoTooltip";
import InfoSvg from "components/svg/InfoSVG";

/* prettier-ignore */
const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding-left: ${(props) => props.theme.spacing.medium};
  
  @media screen and (max-height: ${(props) => props.theme.mediaQueries.minHeight}) {
    padding: ${(props) => props.theme.spacing.light}
  }

  @media screen and (min-height: ${(props) => props.theme.mediaQueries.minHeight}) {
    padding: ${(props) => props.theme.spacing.medium};
  }
`;

const Container = styled(Box)`
  background-color: ${(props: InfoCardProps) => props.backgroundColor};
  &:hover {
    cursor: ${(props: InfoCardProps) =>
      props.onClick ? "pointer" : "default"};
    background-color: ${(props: InfoCardProps) =>
      props.onClick ? props.hoverColor : ""};
  }
`;

const CardTitle = styled(Title)`
  align-self: start;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  > *:last-child {
    padding-left: ${(props) => props.theme.spacing.medium};
  }
`;

const TitleRightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NumberContainer = styled.div`
  padding-right: ${(props) => props.theme.spacing.heavy};
  align-self: start;
`;

interface InfoCardProps {
  title?: string | ReactNode;
  style?: React.CSSProperties;
  hoverColor?: string;
  onClick?: () => void;
  keyNumber?: number;
  keyNumberPrecision?: number;
  titleRightElement?: ReactNode;
  defaultZero?: boolean;
  extraInfo?: string;
  backgroundColor?: string;
  measurementType?: string;
  displayNumber?: boolean;
  className?: string;
  tooltip?: string;
}

export const InfoCard: React.FC<InfoCardProps> = (props) => {
  const { theme } = useScadaPlusTheme();

  const resolveHoverColor = () => {
    return props.hoverColor
      ? props.hoverColor
      : theme.colors.cardBackgroundHover;
  };

  return (
    <Container
      style={props.style}
      onClick={props.onClick}
      backgroundColor={props.backgroundColor}
      hoverColor={resolveHoverColor()}
      className={props.className}
    >
      {props.title && (
        <Grid>
          <CardTitle data-testid="infocardtitletest">
            {props.title}
            {props.extraInfo}
            {props.tooltip && (
              <OrigoTooltip render={props.tooltip}>
                <InfoSvg width={"13"} height={"13"} />
              </OrigoTooltip>
            )}
          </CardTitle>
          {props.displayNumber && (
            <NumberContainer>
              <NumberValueFormatter
                value={props.keyNumber}
                precision={
                  props.keyNumberPrecision ? props.keyNumberPrecision : 0
                }
                measurementType={props.measurementType}
              />
            </NumberContainer>
          )}
          {props.titleRightElement && !props.keyNumber && (
            <TitleRightContainer>{props.titleRightElement}</TitleRightContainer>
          )}
        </Grid>
      )}
      {props.children}
    </Container>
  );
};
