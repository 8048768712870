import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import config from "../../../config";
import { useScadaPlusQuery } from "../../../hooks/useScadaPlusQuery";
import { DailyReport } from "../../../types";
import {
  displayValue,
  displayValueInMega,
} from "../../../utils/production-utils";
import { useLocalDateFormat } from "hooks/useDateTimeFormat";

const DailyReportChartArea = styled.div`
  grid-column: 1/2;
  grid-row: 1/5;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr 1fr;
  border-radius: ${(props) => props.theme.borderRadius.light};
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundColor.primary};
`;

const DailyReportTitleDate = styled.div`
  grid-row: 1/2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: ${(props) => props.theme.spacing.medium};
  font-size: ${(props) => props.theme.scaledFontSizes.mediumLarge};
`;

const ReportTitle = styled.p`
  grid-column: 1/2;
  justify-self: start;
  color: ${(props) => props.theme.colors.textColor.background};
`;

const DailyDate = styled.div`
  grid-column: 2/3;
  justify-self: end;
  color: ${(props) => props.theme.colors.textColor.primary};
`;

const DailyReportChartHeader = styled.div`
  grid-row: 2 / 3;
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  padding: ${(props) => props.theme.spacing.medium};
  text-align: center;
  color: ${(props) =>
    props.theme.colors.buttonColors.secondaryButtonTextColor.primary};
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
  justify-content: space-evenly;
  font-weight: 600;
`;

const BaseHeader = styled.div`
  padding: ${(props) => props.theme.spacing.medium};
  background-color: ${(props) =>
    props.theme.colors.buttonColors.primaryButtonColor};
`;

const DailyReportChartDaily = styled(BaseHeader)`
  border-radius: ${(props) => props.theme.borderRadius.medium} 0 0
    ${(props) => props.theme.borderRadius.medium};
  color: ${(props) => props.theme.colors.textColor.secondary};
`;

const DailyReportChartWeekly = styled(BaseHeader)`
  opacity: 0.7;
`;

const DailyReportChartMonthly = styled(BaseHeader)`
  opacity: 0.7;
`;

const DailyReportChartSelectTime = styled(BaseHeader)`
  border-radius: 0 ${(props) => props.theme.borderRadius.medium}
    ${(props) => props.theme.borderRadius.medium} 0;
  opacity: 0.7;
`;

const DailyReportChartTables = styled.div`
  grid-row: 3 / -1;
  grid-column: 1 /-1;
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundColor.primary};
  border-radius: ${(props) => props.theme.borderRadius.light};
  padding: ${(props) => props.theme.spacing.heavy} 0
    ${(props) => props.theme.spacing.heavy} 0;
`;

const TableTitle = styled.p`
  font-size: ${(props) => props.theme.scaledFontSizes.small};
  color: ${(props) => props.theme.colors.textColor.background};
  font-weight: ${(props) => props.theme.primaryFontFamily};
  padding: ${(props) => props.theme.spacing.medium};
`;

const ReportTable = styled.table`
  width: 100%;
  border-spacing: 0;
  padding: ${(props) => props.theme.spacing.medium};
`;

const TableBody = styled.tbody`
  > :nth-child(odd) {
    background-color: ${(props) => props.theme.colors.reportChartColor};
  }
`;

const ProductionRow = styled.tr`
  font-weight: 600;
`;

const BaseTableData = styled.td`
  font-size: ${(props) => props.theme.scaledFontSizes.small};
  padding: ${(props) => props.theme.spacing.medium};
  color: ${(props) => props.theme.colors.textColor.primary};
  width: 15%;
`;

const ProductionEntry = styled(BaseTableData)`
  width: 70%;
  border-radius: ${(props) => props.theme.borderRadius.medium} 0 0
    ${(props) => props.theme.borderRadius.medium};
  font-weight: normal;
`;

const ProductionMeasurements = styled(BaseTableData)`
  text-align: end;
`;

const MeasurementUnits = styled(BaseTableData)`
  color: ${(props) => props.theme.colors.textColor.background};
  border-radius: 0 ${(props) => props.theme.borderRadius.medium}
    ${(props) => props.theme.borderRadius.medium} 0;
`;

const RowContainer = styled.div``;

type ReportChartProps = {
  plantId: string;
};

export const ReportChart: React.FC<ReportChartProps> = (props) => {
  const dateTimeLocal = useLocalDateFormat();
  const { formatMessage } = useIntl();
  const { data: dailyReport } = useScadaPlusQuery<DailyReport>(
    "daily-report",
    `${config.api.url}/${config.api.dailyReport}?WindPowerPlantId=${props.plantId}`
  );
  return (
    <DailyReportChartArea>
      <DailyReportTitleDate>
        <ReportTitle>
          {formatMessage({
            id: "report.Report",
            defaultMessage: "Report",
          })}
        </ReportTitle>
        <DailyDate>{dateTimeLocal.format(Date.now())}</DailyDate>
      </DailyReportTitleDate>
      <DailyReportChartHeader>
        <DailyReportChartDaily>
          {formatMessage({ id: "report.Daily", defaultMessage: "Daily" })}
        </DailyReportChartDaily>

        <DailyReportChartWeekly>
          {formatMessage({
            id: "report.Weekly",
            defaultMessage: "Weekly",
          })}
        </DailyReportChartWeekly>
        <DailyReportChartMonthly>
          {formatMessage({
            id: "report.Monthly",
            defaultMessage: "Monthly",
          })}
        </DailyReportChartMonthly>
        <DailyReportChartSelectTime>
          {formatMessage({
            id: "report.SelectTime",
            defaultMessage: "Select time",
          })}
        </DailyReportChartSelectTime>
      </DailyReportChartHeader>
      <DailyReportChartTables>
        <RowContainer>
          <TableTitle>
            {formatMessage({
              id: "report.Chart.ProductionAtTurbines",
              defaultMessage: "Production at Turbines",
            })}
          </TableTitle>

          <ReportTable>
            <TableBody>
              <ProductionRow>
                <ProductionEntry>
                  {formatMessage({
                    id: "report.Chart.AvailabilityTurbine",
                    defaultMessage: "Availability at Turbines",
                  })}
                </ProductionEntry>
                <ProductionMeasurements>
                  {displayValue(dailyReport?.windAvailability)}
                </ProductionMeasurements>
                <MeasurementUnits>%</MeasurementUnits>
              </ProductionRow>
              <ProductionRow>
                <ProductionEntry>
                  {formatMessage({
                    id: "report.Chart.PeriodHours",
                    defaultMessage: "Period Hours",
                  })}
                </ProductionEntry>
                <ProductionMeasurements>
                  {displayValue(dailyReport?.periodHours)}
                </ProductionMeasurements>
                <MeasurementUnits>
                  {formatMessage({
                    id: "report.Hours",
                    defaultMessage: "Hours",
                  })}
                </MeasurementUnits>
              </ProductionRow>
              <ProductionRow>
                <ProductionEntry>
                  {formatMessage({
                    id: "report.Chart.TurbineReady",
                    defaultMessage: "Turbine Ready",
                  })}
                </ProductionEntry>
                <ProductionMeasurements>
                  {displayValue(dailyReport?.turbineReady)}
                </ProductionMeasurements>
                <MeasurementUnits>
                  {formatMessage({
                    id: "report.Hours",
                    defaultMessage: "Hours",
                  })}
                </MeasurementUnits>
              </ProductionRow>
              <ProductionRow>
                <ProductionEntry>
                  {formatMessage({
                    id: "report.Chart.GeneratorHours",
                    defaultMessage: "Generator Hours",
                  })}
                </ProductionEntry>
                <ProductionMeasurements>
                  {displayValue(dailyReport?.generatorHours)}
                </ProductionMeasurements>
                <MeasurementUnits>
                  {formatMessage({
                    id: "report.Hours",
                    defaultMessage: "Hours",
                  })}
                </MeasurementUnits>
              </ProductionRow>
              <ProductionRow>
                <ProductionEntry>
                  {formatMessage({
                    id: "report.Chart.BaseHours",
                    defaultMessage: "Base Hours",
                  })}
                </ProductionEntry>
                <ProductionMeasurements>
                  {displayValue(dailyReport?.baseHours)}
                </ProductionMeasurements>
                <MeasurementUnits>
                  {formatMessage({
                    id: "report.Hours",
                    defaultMessage: "Hours",
                  })}
                </MeasurementUnits>
              </ProductionRow>
            </TableBody>
          </ReportTable>
        </RowContainer>
        <RowContainer>
          <TableTitle>
            {formatMessage({
              id: "report.Chart.ProductionSubStation",
              defaultMessage: "Production at SubStation",
            })}
          </TableTitle>
          <ReportTable>
            <TableBody>
              <ProductionRow>
                <ProductionEntry>
                  {formatMessage({
                    id: "report.Chart.ActiveEnergyImport",
                    defaultMessage: "Active Energy Import",
                  })}
                </ProductionEntry>
                <ProductionMeasurements>
                  {displayValueInMega(dailyReport?.activeEnergyImport)}
                </ProductionMeasurements>
                <MeasurementUnits>
                  {formatMessage({
                    id: "pu.MWh",
                    defaultMessage: "MWh",
                  })}
                </MeasurementUnits>
              </ProductionRow>
              <ProductionRow>
                <ProductionEntry>
                  {formatMessage({
                    id: "report.Chart.ActiveEnergyExport",
                    defaultMessage: "Active Energy Export",
                  })}
                </ProductionEntry>
                <ProductionMeasurements>
                  {displayValueInMega(dailyReport?.activeEnergyExport)}
                </ProductionMeasurements>
                <MeasurementUnits>
                  {formatMessage({
                    id: "pu.MWh",
                    defaultMessage: "MWh",
                  })}
                </MeasurementUnits>
              </ProductionRow>
              <ProductionRow>
                <ProductionEntry>
                  {formatMessage({
                    id: "report.Chart.ReactiveEnergyImport",
                    defaultMessage: "Reactive Energy Import",
                  })}
                </ProductionEntry>
                <ProductionMeasurements>
                  {displayValueInMega(dailyReport?.reactiveEnergyImport)}
                </ProductionMeasurements>
                <MeasurementUnits>
                  {" "}
                  {formatMessage({
                    id: "pu.MVAr",
                    defaultMessage: "MVAr",
                  })}
                </MeasurementUnits>
              </ProductionRow>
              <ProductionRow>
                <ProductionEntry>
                  {formatMessage({
                    id: "report.Chart.ReactiveEnergyExport",
                    defaultMessage: "Reactive Energy Export",
                  })}
                </ProductionEntry>
                <ProductionMeasurements>
                  {displayValueInMega(dailyReport?.reactiveEnergyExport)}
                </ProductionMeasurements>
                <MeasurementUnits>
                  {" "}
                  {formatMessage({
                    id: "pu.MVAr",
                    defaultMessage: "MVAr",
                  })}
                </MeasurementUnits>
              </ProductionRow>
            </TableBody>
          </ReportTable>
        </RowContainer>
        <RowContainer>
          <TableTitle>
            {formatMessage({
              id: "report.Chart.OutageTime",
              defaultMessage: "Outage Time",
            })}{" "}
          </TableTitle>
          <ReportTable>
            <TableBody>
              <ProductionRow>
                <ProductionEntry>
                  {formatMessage({
                    id: "report.Chart.ShutDownMode",
                    defaultMessage: "Shut Down Mode",
                  })}
                </ProductionEntry>
                <ProductionMeasurements>-</ProductionMeasurements>
                <MeasurementUnits>
                  {formatMessage({
                    id: "report.Hours",
                    defaultMessage: "Hours",
                  })}
                </MeasurementUnits>
              </ProductionRow>
              <ProductionRow>
                <ProductionEntry>
                  {formatMessage({
                    id: "report.Chart.ExternalError",
                    defaultMessage: "External Error",
                  })}
                </ProductionEntry>
                <ProductionMeasurements>-</ProductionMeasurements>
                <MeasurementUnits>
                  {formatMessage({
                    id: "report.Hours",
                    defaultMessage: "Hours",
                  })}
                </MeasurementUnits>
              </ProductionRow>
              <ProductionRow>
                <ProductionEntry>
                  {formatMessage({
                    id: "report.Chart.GridFault",
                    defaultMessage: "Grid Fault",
                  })}
                </ProductionEntry>
                <ProductionMeasurements>-</ProductionMeasurements>
                <MeasurementUnits>
                  {formatMessage({
                    id: "report.Hours",
                    defaultMessage: "Hours",
                  })}
                </MeasurementUnits>
              </ProductionRow>
              <ProductionRow>
                <ProductionEntry>
                  {formatMessage({
                    id: "report.Chart.Total",
                    defaultMessage: "Total",
                  })}
                </ProductionEntry>
                <ProductionMeasurements>-</ProductionMeasurements>
                <MeasurementUnits>
                  {formatMessage({
                    id: "report.Hours",
                    defaultMessage: "Hours",
                  })}
                </MeasurementUnits>
              </ProductionRow>
            </TableBody>
          </ReportTable>
        </RowContainer>
        <RowContainer>
          <TableTitle>
            {formatMessage({
              id: "report.Chart.Transitions",
              defaultMessage: "Transitions",
            })}{" "}
          </TableTitle>
          <ReportTable>
            <TableBody>
              <ProductionRow>
                <ProductionEntry>
                  {formatMessage({
                    id: "report.Chart.Transitions",
                    defaultMessage: "Transitions",
                  })}
                </ProductionEntry>
                <ProductionMeasurements>
                  {displayValue(dailyReport?.numberOfStopsEnvironmental)}
                </ProductionMeasurements>
                <MeasurementUnits>
                  {formatMessage({
                    id: "report.Stops",
                    defaultMessage: "Stops",
                  })}
                </MeasurementUnits>
              </ProductionRow>
              <ProductionRow>
                <ProductionEntry>
                  {formatMessage({
                    id: "report.Chart.Commanded",
                    defaultMessage: "Commanded",
                  })}
                </ProductionEntry>
                <ProductionMeasurements>
                  {displayValue(dailyReport?.numberOfStopsCommanded)}
                </ProductionMeasurements>
                <MeasurementUnits>
                  {formatMessage({
                    id: "report.Stops",
                    defaultMessage: "Stops",
                  })}
                </MeasurementUnits>
              </ProductionRow>
              <ProductionRow>
                <ProductionEntry>
                  {formatMessage({
                    id: "report.Chart.MachineFault",
                    defaultMessage: "Machine Fault",
                  })}
                </ProductionEntry>
                <ProductionMeasurements>
                  {displayValue(dailyReport?.numberOfStopsDueToMachineFault)}
                </ProductionMeasurements>
                <MeasurementUnits>
                  {formatMessage({
                    id: "report.Stops",
                    defaultMessage: "Stops",
                  })}
                </MeasurementUnits>
              </ProductionRow>
              <ProductionRow>
                <ProductionEntry>
                  {formatMessage({
                    id: "report.Chart.GridFault",
                    defaultMessage: "Grid Fault",
                  })}
                </ProductionEntry>
                <ProductionMeasurements>
                  {displayValue(dailyReport?.numberOfStopsDueToGridFault)}
                </ProductionMeasurements>
                <MeasurementUnits>
                  {formatMessage({
                    id: "report.Stops",
                    defaultMessage: "Stops",
                  })}
                </MeasurementUnits>
              </ProductionRow>
            </TableBody>
          </ReportTable>
        </RowContainer>
      </DailyReportChartTables>
    </DailyReportChartArea>
  );
};
