import React from "react";

export enum Permissiontype {
  CONTROL = "CONTROL",
  CONFIRM = "CONFIRM",
  VIEW = "VIEW",
}

type Dispatch = (data: User) => void;

type State = {
  user: { firstName: string; lastName: string };
  permissions: Permissions;
  loaded: boolean;
};

const PermissionsContext = React.createContext<State>({
  permissions: [],
  loaded: false,
  user: { firstName: "", lastName: "" },
});

const PermissionsDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

export type Permissions = Array<Access>;

export type Access = {
  id: string;
  name: string;
  permissions: string[];
};

type UserRole = {
  name?: string;
  accessTo: Access[];
};

type User = {
  firstName: string;
  lastName: string;
  username?: string;
  roles: UserRole[];
};

const permissionReducer = (state: State, data: User) => {
  return {
    permissions: data.roles[0].accessTo,
    loaded: true,
    user: { firstName: data.firstName, lastName: data.lastName },
  };
};

const ScadaPlusPermissionsProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(permissionReducer, {
    permissions: [],
    loaded: false,
    user: { firstName: "", lastName: "" },
  });

  return (
    <PermissionsContext.Provider value={state}>
      <PermissionsDispatchContext.Provider value={dispatch}>
        {props.children}
      </PermissionsDispatchContext.Provider>
    </PermissionsContext.Provider>
  );
};

export {
  ScadaPlusPermissionsProvider,
  PermissionsContext,
  PermissionsDispatchContext,
};
