import React from "react";
import { MdClose } from "react-icons/md";
import styled from "styled-components";
import Button from "../../components/Button";
import { useScadaPlusTheme } from "../../hooks/useScadaPlusTheme";
import { useGetComponentUnit } from "../../hooks/useGetComponentUnit";
import Modal from "components/Modal";
import { Size, Background, PortalConsumer } from "components/Modal/Modal";
import ZefyrosStatusCard from "pages/WindProductionUnit/ZefyrosStatusCard";
import { ComponentUnit } from "types";
import StatusCardIconResolver from "pages/WindProductionUnit/StatusCardIconResolver";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  justify-content: center;
  padding: ${(props) => props.theme.spacing.heavy};
  border: 3px solid ${(props) => props.theme.colors.mainColor.tertiary};
`;

const Title = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: ${(props) => props.theme.scaledFontSizes.large};
  margin-bottom: ${(props) => props.theme.spacing.light};
`;

const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MainContent = styled.div`
  font-size: ${(props) => props.theme.scaledFontSizes.small};
`;

const ContainerHeading = styled.div`
  align-items: flex-start;
  display: grid;
  place-items: left;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: 0.5fr 1fr 1fr;
  height: 100%;
`;

const CardBg = styled.div`
  width: 10rem;
  border-radius: 5%;
  grid-column: 1 / 2;
  grid-row: 2 / 5;
`;

const StatusHeading = styled.div`
  padding-top: 0%;
  grid-column: 1 / -1;
  grid-row: 1;
  display: flex;
  justify-content: left;
  align-items: left;
`;

const StatusGraphics = styled.div`
  padding-top: 8%;
  grid-column: 1;
  grid-row: 2;
  display: flex;
  justify-content: center;
`;

const Text1 = styled.div`
  padding-top: 6%;
  grid-column: 1;
  grid-row: 3;
  text-align: center;
  font-size: ${(props) => props.theme.numberSizes.xs};
`;

const Text2 = styled.div`
  padding-bottom: 5%;
  grid-column: 1;
  grid-row: 4;
  text-align: center;
  font-size: ${(props) => props.theme.numberSizes.xs};
`;

const ContainerDetails = styled.div`
  align-items: flex-start;
  display: grid;
  place-items: left;
  grid-template-rows: auto auto auto;
  height: 100%;
`;

type TurbineStatusProps = {
  windTurbineId: string;
  plantId: string;
  backgroundColor: string;
  text1: string;
  text2: string;
  warningBackgroundColor: string;
  warningSymbolColor: string;
  operationCode?: string;
  statusCode?: string;
  onClose: () => void;
};

const getUnitShortName = (units?: Array<ComponentUnit>) => {
  if (!units || !units[0]) return "";
  return units[0].shortName;
};

export const TurbineStatus: React.FC<TurbineStatusProps> = (props) => {
  const { theme } = useScadaPlusTheme();

  const { data: windTurbines } = useGetComponentUnit(props.windTurbineId);

  return (
    <Modal size={Size.Medium} background={Background.Dark}>
      <Container>
        <TitleArea>
          <Title>
            {/* <StatusHeading> */}
            <ContainerHeading>
              <StatusHeading>
                {/* STKN  get selected turbine name. */}
                Status {getUnitShortName(windTurbines)}
              </StatusHeading>
              <CardBg
                style={{
                  height: "100%",
                  background: props.backgroundColor,
                }}
              />
              <StatusGraphics>
                <StatusCardIconResolver
                  operationCode={props.operationCode}
                  statusCode={props.statusCode}
                  iconHeight="4.375rem"
                  iconWidth="4.375rem"
                />
              </StatusGraphics>
              <Text1>{props.text1}</Text1>
              <Text2>{props.text2}</Text2>
            </ContainerHeading>
          </Title>
          <PortalConsumer>
            {(value) => (
              <Button
                style={{
                  borderRadius: "50%",
                  height: "35.19px",
                }}
                onclick={() => value.close!()}
                size="xs"
                type="primary"
                icon={<MdClose fill={theme.colors.iconColor} size={18} />}
              />
            )}
          </PortalConsumer>
        </TitleArea>
        <MainContent>
          <ContainerDetails>
            <ZefyrosStatusCard componentUnitId={props.windTurbineId} />
          </ContainerDetails>
        </MainContent>
      </Container>
      <PortalConsumer>
        {(value) => {
          value.onClose = props.onClose;
          return <span></span>; // TODO: Had to be added due to react complaints. Use Hook - context?
        }}
      </PortalConsumer>
    </Modal>
  );
};
