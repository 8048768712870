import GlobalSIFCompliance from "components/SIFComponents/GlobalSIFCompliance";
import SelectedSIFCompliance from "components/SIFComponents/SelectedSIFCompliance";
import SIFAlertGrid from "components/SIFComponents/SIFAlertGrid";
import SIFEventOverview from "components/SIFComponents/SIFEventOverview";
import SIFPerformanceMonitoringList from "components/SIFComponents/SIFPerformanceMonitoringList";
import styled, { DefaultTheme } from "styled-components";
import InputOutputDeviceList from "components/SIFComponents/InputOutputDeviceList";
import SIFLog from "components/SIFComponents/SIFLog";
import SIFJournal from "components/SIFComponents/SIFJournal";
import TotalEventsGraph from "components/SIFComponents/TotalEventsGraph";

// dummy data
import SIFLogArchive from "../../SIFLogArchiveDummyData.json";
import DeviceData from "../../InputOutputDeviceData.json";
import LogData from "../../SIFLogDummyData.json";
import sifData from "../../SIFPerfDummyData.json";
import complianceData from "../../SIFComplianceListDummyData.json";
import journalItemsData from "../../SIFJournalEntriesDummyData.json";
import totalEventsGraphData from "../../TotalEventsGraphData.json";

const DashboardGrid = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 2rem 1.5rem;
`;

const SelectedSIFOverview = styled.div`
  background-color: ${(props: SIFProps) =>
    props.theme.colors.componentBackgroundActive.tertiary};
  padding: 1rem 1.5rem;
  border-radius: ${(props) => props.theme.borderRadius.medium};
`;

const SelectedSIFOverviewRow2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 3fr;
  column-gap: 0.5rem;
  padding: 0.5rem 0;
`;

const SelectedSIFOverviewRow3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 0.5rem;
`;

interface SIFProps {
  backgroundColor?: string;
  theme: DefaultTheme;
}

export const SIFPerformanceMonitoringDashboard: React.FC = () => {
  return (
    <DashboardGrid>
      <div>
        <TotalEventsGraph data={totalEventsGraphData}> </TotalEventsGraph>
        <GlobalSIFCompliance
          value={19}
          maxValue={0}
          nonComplianceList={complianceData}
        ></GlobalSIFCompliance>
      </div>
      <div>
        <SIFPerformanceMonitoringList
          SIFList={sifData}
        ></SIFPerformanceMonitoringList>
        <SelectedSIFOverview>
          <InputOutputDeviceList
            deviceList={DeviceData}
          ></InputOutputDeviceList>
          <SelectedSIFOverviewRow2>
            <SelectedSIFCompliance />
            <SIFEventOverview />
            <SIFAlertGrid />
          </SelectedSIFOverviewRow2>
          <SelectedSIFOverviewRow3>
            <SIFJournal journalEntries={journalItemsData}></SIFJournal>
            <SIFLog logList={LogData} archiveList={SIFLogArchive}></SIFLog>
          </SelectedSIFOverviewRow3>
        </SelectedSIFOverview>
      </div>
    </DashboardGrid>
  );
};
