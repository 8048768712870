import React from "react";

const getTrans = (angle: number, deltaRadius: number) => {
  const rotX = 50;
  const rotY = 50;

  return (
    "translate(" +
    deltaRadius +
    " " +
    0 +
    ") rotate(" +
    angle +
    ", " +
    rotX +
    ", " +
    rotY +
    ")"
  );
};

interface IComponentProps {
  width: number | string;
  height: number | string;
  objectFill: string;
  angle?: number;
  r?: number;
}

export const WindAnimationMobileSVG: React.FC<IComponentProps> = (props) => {
  return (
    <svg
      width={props.height}
      height={props.width}
      viewBox="-10 -10 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform={getTrans(
          props.angle ? props.angle : 0,
          props.r ? props.r : 0
        )}
        d="M 80,95 50,5 15,95 50,79 80,95"
        fill={props.objectFill}
        fillOpacity="0.8"
      />
    </svg>
  );
};
