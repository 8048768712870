import React from "react";
import { LatLngTuple } from "leaflet";
import {
  Layers,
  PersonnelLevel,
  ComponentUnit,
  GenericAlarmPriorityCounter,
} from "types";
import { useSensorSubscription } from "hooks/useSubscription";
import { useTagsContext } from "hooks/useTags";
import CustomMarker from "components/CustomMarker";
import ScadaPlusWindTurbineMarker from "./ScadaPlusWindTurbineMarker";
import { TooltipContent } from "./TooltipContent";
import { getProductionInPercentage } from "utils/production-utils";
import { useIntl } from "react-intl";
import AlarmPriorityHeaders from "components/AlarmPriorityHeaders";
import { useScadaPlusQuery } from "hooks/useScadaPlusQuery";
import config from "config";

export enum ComponentUnitSize {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
}

type WindTurbineMarkerProps = {
  componentUnit: ComponentUnit;
  productionLayerActive: boolean;
  onClicked: () => void;
  layers: Layers;
  size: ComponentUnitSize;
  personell: PersonnelLevel;
};

export const WindTurbineMarker: React.FC<WindTurbineMarkerProps> = (props) => {
  const { tags } = useTagsContext();
  const { formatMessage } = useIntl();
  const alarmPrioritiesQueryName = `alarmPriorities-${props.componentUnit.id}`;
  const markerPos: LatLngTuple = [
    props.componentUnit.coordinates.latitude,
    props.componentUnit.coordinates.longitude,
  ];

  const { sensorData: currentProduction } = useSensorSubscription({
    filters: [
      {
        id: tags.WTURW,
        windTurbineId: props.componentUnit.id,
      },
    ],
  });

  const { data: alarmPriorityData } = useScadaPlusQuery<
    Array<GenericAlarmPriorityCounter>
  >(
    alarmPrioritiesQueryName,
    `${config.api.url}/${config.api.genericAlarmPriorityCounters}?ComponentUnitId=${props.componentUnit.id}`
  );

  const mapToTooltip = () => {
    const tooltipTableData: Array<{ description: string; value: string }> = [];
    tooltipTableData.push({
      description: formatMessage({
        id: "overview.Type",
        defaultMessage: "Missing translation",
      }),
      value:
        getProductionInPercentage(
          currentProduction && Number(currentProduction.value) > 0
            ? Number(currentProduction.value)
            : 0,
          props.componentUnit?.energyProductionCapacity ?? 0
        ).toFixed(2) + "  %",
    });

    return tooltipTableData;
  };

  const showAlarmDisplay = (
    alarmPriorityData: GenericAlarmPriorityCounter[]
  ) => {
    if (alarmPriorityData.length > 0) {
      return (
        <AlarmPriorityHeaders
          alarmPriorityData={alarmPriorityData}
          alarmTileWidth="110%"
        />
      );
    } else {
      return undefined;
    }
  };

  return (
    <CustomMarker
      toolTipContent={
        !props.productionLayerActive ? (
          <TooltipContent
            header={props.componentUnit.shortName}
            tooltipTableData={mapToTooltip()}
          >
            {showAlarmDisplay(alarmPriorityData ? alarmPriorityData : [])}
          </TooltipContent>
        ) : undefined
      }
      markerPosition={markerPos}
      onClick={() => {
        props.onClicked();
      }}
    >
      <ScadaPlusWindTurbineMarker
        componentUnit={props.componentUnit}
        currentProduction={currentProduction}
        layers={props.layers}
        markerPos={markerPos}
        personell={props.personell}
        productionLayerActive={props.productionLayerActive}
        size={props.size}
      />
    </CustomMarker>
  );
};
