import React from "react";
import Loader from "../Loader";
import { useIntl } from "react-intl";
import styled from "styled-components";

const Content = styled.div`
  display: grid;
  height: 100%;
  place-items: center;
  user-selcet: none;
`;

export const Authenticating: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <Content>
      <Loader>
        {formatMessage({
          id: "general.Authenticating",
          defaultMessage: "Authenticating...",
        })}
      </Loader>
    </Content>
  );
};
