import React from "react";
import { DefaultTheme as DefaultTheme, ThemeProvider } from "styled-components";
import { dark, light } from "../theme";

const localStorageKey = "theme";

type Dispatch = (theme: DefaultTheme) => void;

type State = {
  theme: DefaultTheme;
};

const getThemeFromLocalStorage = (): DefaultTheme | undefined => {
  const storedTheme = localStorage.getItem(localStorageKey);
  if (storedTheme !== null) {
    if (storedTheme === light.name) {
      return light;
    }
    return dark;
  }
  return undefined;
};

const getDefaultTheme = (): DefaultTheme => {
  const storedTheme = getThemeFromLocalStorage();
  return storedTheme ? storedTheme : light;
};

export const ThemeContext = React.createContext<State>({
  theme: dark,
});

export const ThemeDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

const themeReducer = (state: State, theme: DefaultTheme) => {
  localStorage.setItem(localStorageKey, theme.name);
  return { theme: theme };
};

const ScadaPlusThemeProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(themeReducer, {
    theme: getDefaultTheme(),
  });

  return (
    <ThemeContext.Provider value={state}>
      <ThemeProvider theme={state.theme}>
        <ThemeDispatchContext.Provider value={dispatch}>
          {props.children}
        </ThemeDispatchContext.Provider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ScadaPlusThemeProvider };
