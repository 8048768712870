import React from "react";
import { ErrorContext, ErrorDispatchContext } from "../providers/ScadaPlusErrorProvider";

export const useScadaPlusError = () => {
  const context = React.useContext(ErrorContext);
  if (context === undefined) {
    throw new Error(
      "useErrorContext must be used within a ScadaPlusErrorProvider"
    );
  }
  return context;
};

export const useScadaPlusErrorDispatch = () => {
  const context = React.useContext(ErrorDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useErrorDispatch must be used within a ScadaPlusErrorProvider"
    );
  }
  return context;
};
