import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Overview from "./Overview";
import WindProductionUnit from "./WindProductionUnit";
import { GenericMenu, OverViewConstants, RouteConstants } from "../types";
import AlarmInfo from "../components/AlarmInfo";
import SingleLine from "./SingleLine";
import Report from "./Report";
import ScadaPlusRouteComponent from "./ScadaPlusRouteComponent";
import AlarmsView from "./AlarmsView";
import SIFPerformanceMonitoringDashboard from "./SIFPerformanceMonitoringDashboard";

export type RoutesProps = {
  menu: GenericMenu;
};

const Routes: React.FunctionComponent<RoutesProps> = (props) => {
  return (
    <Switch>
      <Route
        path="/plant/:plantId/overview"
        render={() => {
          return (
            <ScadaPlusRouteComponent menu={props.menu}>
              <Overview />
            </ScadaPlusRouteComponent>
          );
        }}
      />
      <Route
        path={`/plant/:plantId/alarm`}
        render={() => {
          return (
            <ScadaPlusRouteComponent menu={props.menu}>
              <AlarmsView />
              <Route
                path="/plant/:plantId/alarm/:alarmId"
                component={AlarmInfo}
              />
            </ScadaPlusRouteComponent>
          );
        }}
      />

      <Route
        path="/plant/:plantId/single-line"
        render={() => {
          return (
            <ScadaPlusRouteComponent menu={props.menu}>
              <SingleLine />
            </ScadaPlusRouteComponent>
          );
        }}
      />

      <Route
        path={`/plant/:plantId/${RouteConstants.ComponentUnit}/:unitId`}
        render={() => {
          return (
            <ScadaPlusRouteComponent menu={props.menu}>
              <WindProductionUnit />
              <Route
                path={`/plant/:plantId/${RouteConstants.ComponentUnit}/:unitId/alarm/:alarmId`}
                component={AlarmInfo}
              />
            </ScadaPlusRouteComponent>
          );
        }}
      />

      <Route
        path="/plant/:plantId/report"
        render={() => {
          return (
            <ScadaPlusRouteComponent menu={props.menu}>
              <Report />
              <Route
                path="/plant/:plantId/report/alarm/:alarmId"
                component={AlarmInfo}
              />
            </ScadaPlusRouteComponent>
          );
        }}
      />

      <Route
        path="/SIFDashboard"
        render={() => {
          return (
            <ScadaPlusRouteComponent menu={props.menu}>
              <SIFPerformanceMonitoringDashboard></SIFPerformanceMonitoringDashboard>
            </ScadaPlusRouteComponent>
          );
        }}
      />

      <Redirect
        exact
        path="/"
        to={`./plant/${props.menu[0].id}/overview?type=${OverViewConstants.COMPONENT_UNITS}`}
      />
    </Switch>
  );
};

export default Routes;
