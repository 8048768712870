import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import {
  useScadaPlusError,
  useScadaPlusErrorDispatch,
} from "../../hooks/useScadaPlusError";
import { useIntl } from "react-intl";
import config from "../../config";
import { useScadaPlusQuery } from "../../hooks/useScadaPlusQuery";
import Wendy from "components/Wendy";
import { WendyHeadStyles, WendyBodyStyles } from "components/Wendy/Wendy";

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.signal};
  color: ${(props) =>
    props.theme.colors.buttonColors.primaryButtonTextColor.primary};
  padding: ${(props) => props.theme.spacing.medium};
  font-size: ${(props) => props.theme.scaledFontSizes.mediumLarge};
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const slideIn = keyframes`
0% {transform: translateY(-3em);}

100%{transform: translateY(0em);}
`;

const slideOut = keyframes`
0% {transform: translateY(0em); opacity: 1;}

100%{transform: translateY(-3em); opacity: 0;}
`;

const SlideInOut = styled.div`
  // TODO: Uncomment this if we want it to sit on top of elements, talk to designer.
  // position: fixed;
  // z-index: 1000;
  width: 100%;
  animation-name: ${(props: IInternalProps) =>
    props.isActive ? slideIn : slideOut};
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
`;

interface IInternalProps {
  isActive?: boolean;
}

export const ScadaPlusError: React.FC = () => {
  const scadaPlusErrorState = useScadaPlusError();
  const errorDispatch = useScadaPlusErrorDispatch();
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (scadaPlusErrorState.show === false && show === true) {
      setTimeout(() => {
        setShow(scadaPlusErrorState.show);
      }, 500);
    } else {
      setShow(scadaPlusErrorState.show);
    }
  }, [scadaPlusErrorState.show, show]);
  return (
    <>
      {show && (
        <SlideInOut
          onClick={() =>
            errorDispatch({ show: false, text: scadaPlusErrorState.text })
          }
          isActive={scadaPlusErrorState.show}
        >
          <Container data-testid="childtest">
            <Wendy
              size={28}
              HeadStyle={WendyHeadStyles.Sad}
              BodyStyle={WendyBodyStyles.NoPower}
            />
            {scadaPlusErrorState.text}
          </Container>
        </SlideInOut>
      )}
    </>
  );
};

const ScadaPlusErrorListener = () => {
  const errorDispatch = useScadaPlusErrorDispatch();
  const { formatMessage } = useIntl();
  const [clientError, setClientError] = useState<string | undefined>();

  const { data, isError } = useScadaPlusQuery<{ status: number }>(
    "healthcheck",
    `${config.api.baseUrl}/${config.api.healthCheck}`,
    20000,
    (errorMessage) => {
      if (errorMessage.status) {
        setClientError(
          `Server response status: ${errorMessage.status} - ${errorMessage.text}`
        );
      } else {
        setClientError(errorMessage.text);
      }
    }
  );

  // useEffect(() => {
  //   if ((data && data.status !== 2) || isError || clientError) {
  //     errorDispatch({
  //       show: true,
  //       text: formatMessage({
  //         id: "error.UnableToFetchData",
  //         defaultMessage:
  //           "We are really sorry - SCADA+ is experiencing issues retrieving data. Please contact Origo Support at: +47 932 38 009 or via e-mail at: support@origo.no",
  //       }),
  //     });
  //     setClientError(undefined);
  //   }
  // }, [data, isError, clientError, errorDispatch, formatMessage]);
  return <div></div>;
};

export { ScadaPlusErrorListener };
