import React from "react";
import { ActiveBoardingLocationTypeCounter, ObjectType } from "../../types";

interface CounterProps {
  plantId: string;
  data?: ObjectType<ActiveBoardingLocationTypeCounter[]>;
  counterType: string;
}

export const PersonnelOnSiteCounters: React.FC<CounterProps> = (props) => {
  const vessel = props.data?.serviceLocationTypes.filter(
    (x) => x.serviceLocationTypeDescription === "Vessel"
  );
  let onBoardBoat = 0;
  if (vessel && vessel.length > 0) {
    vessel.forEach((el) => {
      onBoardBoat += el.servicePersonellOnBoard
        ? el.servicePersonellOnBoard
        : 0;
    });
  }

  const turbine = props.data?.serviceLocationTypes.filter(
    (x) => x.serviceLocationTypeDescription === "Wind Turbine"
  );
  let onBoardTurbine = 0;
  if (turbine && turbine.length > 0) {
    turbine.forEach((el) => {
      onBoardTurbine += el.servicePersonellOnBoard
        ? el.servicePersonellOnBoard
        : 0;
    });
  }

  let counter = 0;
  if (props.counterType === "vessel") counter = onBoardBoat ? onBoardBoat : 0;
  else if (props.counterType === "turbine") {
    counter = onBoardTurbine ? onBoardTurbine : 0;
  } else {
    counter = onBoardBoat ? onBoardBoat : 0;
    counter += onBoardTurbine ? onBoardTurbine : 0;
  }

  return <div>{counter}</div>;
};
