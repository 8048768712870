// STKN new status popup for WT
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  useSensorSubscription,
  SensorData,
} from "../../../hooks/useSubscription";
import { useIntl } from "react-intl";
import CardLabel from "../../../components/CardLabel";
import { useTagsContext } from "../../../hooks/useTags";
import { getTimeDiffUTC } from "utils/date-and-time-utils";

const Container = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 3fr 4fr;
  grid-template-rows: repeat(16, minmax(0, auto));
  padding: ${(props) => props.theme.spacing.light};
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor};
`;

const WPSheading = styled.div`
  grid-column: 1 / 4;
  grid-row: 1;
  padding: 4%;
  font-size: ${(props) => props.theme.scaledFontSizes.large};
`;

const TurbineText = styled.div`
  grid-column: 2;
  grid-row: 2;
  line-height: 2;
  font-size: ${(props) => props.theme.scaledFontSizes.mediumLarge};
`;

const TurbineValue = styled.div`
  grid-column: 3;
  grid-row: 2;
  line-height: 2;
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
`;

const TurbineBrakeText = styled.div`
  grid-column: 2;
  grid-row: 3;
  line-height: 2;
`;

const TurbineBrakeValue = styled.div`
  grid-column: 3;
  grid-row: 3;
  line-height: 2;
`;

const GeneratorText = styled.div`
  grid-column: 2;
  grid-row: 4;
  line-height: 2;
`;

const GeneratorValue = styled.div`
  grid-column: 3;
  grid-row: 4;
  line-height: 2;
`;

const YawText = styled.div`
  grid-column: 2;
  grid-row: 5;
  line-height: 2;
`;

const YawValue = styled.div`
  grid-column: 3;
  grid-row: 5;
  line-height: 2;
`;

const OperatioCodeText = styled.div`
  grid-column: 2;
  grid-row: 6;
  line-height: 2;
`;

const OperatioCodeValue = styled.div`
  grid-column: 3;
  grid-row: 6;
  line-height: 2;
`;

const TLCStatusText = styled.div`
  grid-column: 2;
  grid-row: 7;
  line-height: 2;
`;

const TLCStatusValue = styled.div`
  grid-column: 3;
  grid-row: 7;
  line-height: 2;
`;

// const LastErrorText = styled.div`
//   grid-column: 2;
//   grid-row: 8;
//   line-height: 2;
// `;

// const LastErrorValue = styled.div`
//   grid-column: 3;
//   grid-row: 8;
//   line-height: 2;
// `;

// MV title text
const MVheading = styled.div`
  grid-column: 1 / 4;
  grid-row: 9;
  padding: 4%;
  font-size: ${(props) => props.theme.scaledFontSizes.large};
`;

const DisconnectorStateText = styled.div`
  grid-column: 2;
  grid-row: 10;
  line-height: 2;
`;

const DisconnectorStateValue = styled.div`
  grid-column: 3;
  grid-row: 10;
  line-height: 2;
`;

const EarthSwitchText = styled.div`
  grid-column: 2;
  grid-row: 11;
  line-height: 2;
`;

const EarthSwitchValue = styled.div`
  grid-column: 3;
  grid-row: 11;
  line-height: 2;
`;

// Transformer title text
const TFheading = styled.div`
  grid-column: 1 / 4;
  grid-row: 12;
  padding: 4%;
  font-size: ${(props) => props.theme.scaledFontSizes.large};
`;

const OilLevelText = styled.div`
  grid-column: 2;
  grid-row: 13;
  line-height: 2;
`;

const OilLevelValue = styled.div`
  grid-column: 3;
  grid-row: 13;
  line-height: 2;
`;

const OilPressureText = styled.div`
  grid-column: 2;
  grid-row: 14;
  line-height: 2;
`;

const OilPressureValue = styled.div`
  grid-column: 3;
  grid-row: 14;
  line-height: 2;
`;

const DebugHeading = styled.div`
  grid-column: 1 / 4;
  grid-row: 15;
  padding: 4%;
  font-size: ${(props) => props.theme.scaledFontSizes.large};
`;

const DebugLabel = styled.div`
  padding-top: 4%;
  grid-column: 2;
  grid-row: 16;
  line-height: 2;
`;

const TimeStamp = styled.div`
  padding-top: 4%;
  grid-column: 3;
  grid-row: 16;
  line-height: 2;
`;

interface IComponentProps {
  componentUnitId: string;
}

export const ZefyrosStatusCard: React.FC<IComponentProps> = (props) => {
  const { tags } = useTagsContext();
  const { formatMessage } = useIntl();
  const [brake, setBrake] = useState<SensorData | null>(null);
  const [tStCode, setStCode] = useState<SensorData | null>(null);
  const [gStCode, setgStCode] = useState<SensorData | null>(null);
  const [yStCode, setyStCode] = useState<SensorData | null>(null);
  const [opStCode, setopStCode] = useState<SensorData | null>(null);
  const [tlcStCode, settlcStCode] = useState<SensorData | null>(null);
  const [disconSt, setDisconSt] = useState<SensorData | null>(null);
  const [earthOpSt, setearthOpSt] = useState<SensorData | null>(null);
  const [oilLvlSt, setoilLvlSt] = useState<SensorData | null>(null);
  const [oilPrsSt, setoilPrsSt] = useState<SensorData | null>(null);
  const [timeDiff, setTimeDiff] = useState<string>("");
  // const [lastErr, setLastError] = useState<Data | null>(null);

  const { sensorData: Zefyrosdata } = useSensorSubscription({
    filters: [
      {
        id: tags.WTRMBrkOpSt,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTURStCde,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WGENGnOpSt,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WYAWYwSt,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTUROpCde,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WAVLTLC,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WAVLDisconSt1,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WAVLEthSwOp,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTRFOilLevSt,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTRFTnkPresSt,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTURLastError_1,
        windTurbineId: props.componentUnitId,
      },
    ],
  });

  useEffect(() => {
    switch (Zefyrosdata?.id) {
      case tags.WTRMBrkOpSt: {
        setBrake(Zefyrosdata);
        setTimeDiff(getTimeDiffUTC(Zefyrosdata.timestamp));
        break;
      }
      case tags.WTURStCde:
        setStCode(Zefyrosdata);
        break;
      case tags.WGENGnOpSt:
        setgStCode(Zefyrosdata);
        break;
      case tags.WYAWYwSt:
        setyStCode(Zefyrosdata);
        break;
      case tags.WTUROpCde:
        setopStCode(Zefyrosdata);
        break;
      case tags.WAVLTLC:
        settlcStCode(Zefyrosdata);
        break;
      case tags.WAVLDisconSt1:
        setDisconSt(Zefyrosdata);
        break;
      case tags.WAVLEthSwOp:
        setearthOpSt(Zefyrosdata);
        break;
      case tags.WTRFOilLevSt:
        setoilLvlSt(Zefyrosdata);
        break;
      case tags.WTRFTnkPresSt:
        setoilPrsSt(Zefyrosdata);
        break;
      default:
        break;
    }
  }, [Zefyrosdata, tags]);

  const getTLCtext = () => {
    if (tlcStCode) return tlcStCode.value === "0" ? "Deactivated" : "Activated";
    else return "-";
  };

  return (
    <Container>
      <WPSheading>
        {formatMessage({
          id: "statusWPS.Heading",
          defaultMessage: "Text Missing",
        })}
      </WPSheading>

      <TurbineText>
        <CardLabel>
          {formatMessage({
            id: "statusWPS.Turbine",
            defaultMessage: "Text Missing",
          })}
        </CardLabel>
      </TurbineText>
      <TurbineValue>{tStCode ? tStCode.value : "-"}</TurbineValue>
      <TurbineBrakeText>
        <CardLabel>
          {formatMessage({
            id: "statusWPS.TurbineBrake",
            defaultMessage: "Text Missing",
          })}
        </CardLabel>
      </TurbineBrakeText>
      <TurbineBrakeValue>{brake ? brake.value : "-"}</TurbineBrakeValue>
      <GeneratorText>
        <CardLabel>
          {formatMessage({
            id: "statusWPS.Generator",
            defaultMessage: "Text Missing",
          })}
        </CardLabel>
      </GeneratorText>
      <GeneratorValue>{gStCode ? gStCode.value : "-"}</GeneratorValue>

      <YawText>
        <CardLabel>
          {formatMessage({
            id: "statusWPS.Yaw",
            defaultMessage: "Text Missing",
          })}
        </CardLabel>
      </YawText>
      <YawValue>{yStCode ? yStCode.value : "-"}</YawValue>
      <OperatioCodeText>
        <CardLabel>
          {formatMessage({
            id: "statusWPS.OperatioCode",
            defaultMessage: "Text Missing",
          })}
        </CardLabel>
      </OperatioCodeText>
      <OperatioCodeValue>{opStCode ? opStCode.value : "-"}</OperatioCodeValue>
      <TLCStatusText>
        <CardLabel>
          {formatMessage({
            id: "statusWPS.TLCStatus",
            defaultMessage: "Text Missing",
          })}
        </CardLabel>
      </TLCStatusText>
      <TLCStatusValue>{getTLCtext()}</TLCStatusValue>

      {/* <LastErrorText>
          <CardLabel>
            {formatMessage({
              id: "statusWPS.TLCStatus",
              defaultMessage: "Last Error",
            })}
          </CardLabel>
        </LastErrorText> */}

      {/* <LastErrorValue>
           {lastErr ? lastErr.value : "-"}
        </LastErrorValue> */}

      <MVheading>
        {formatMessage({
          id: "statusMV.Heading",
          defaultMessage: "Text Missing",
        })}
      </MVheading>

      <DisconnectorStateText>
        <CardLabel>
          {formatMessage({
            id: "statusMW.DisconnectorState",
            defaultMessage: "Text Missing",
          })}
        </CardLabel>
      </DisconnectorStateText>
      <DisconnectorStateValue>
        {disconSt ? disconSt.value : "-"}
      </DisconnectorStateValue>
      <EarthSwitchText>
        <CardLabel>
          {formatMessage({
            id: "statusMW.EarthSwitch",
            defaultMessage: "Text Missing",
          })}
        </CardLabel>
      </EarthSwitchText>
      <EarthSwitchValue>{earthOpSt ? earthOpSt.value : "-"}</EarthSwitchValue>

      <TFheading>
        {formatMessage({
          id: "statusTF.Heading",
          defaultMessage: "Text Missing",
        })}
      </TFheading>

      <OilLevelText>
        <CardLabel>
          {formatMessage({
            id: "statusTF.OilLevel",
            defaultMessage: "Text Missing",
          })}
        </CardLabel>
      </OilLevelText>
      <OilLevelValue>{oilLvlSt ? oilLvlSt.value : "-"}</OilLevelValue>
      <OilPressureText>
        <CardLabel>
          {formatMessage({
            id: "statusTF.OilPressure",
            defaultMessage: "Text Missing",
          })}
        </CardLabel>
      </OilPressureText>
      <OilPressureValue>{oilPrsSt ? oilPrsSt.value : "-"}</OilPressureValue>

      <DebugHeading>
        {formatMessage({
          id: "statusSigR.Heading",
          defaultMessage: "Scada+ Subscription Status",
        })}
      </DebugHeading>

      <DebugLabel>
        <CardLabel>
          {formatMessage({
            id: "statusSigR.Latency",
            defaultMessage: "Sensor data latency",
          })}
        </CardLabel>
      </DebugLabel>
      <TimeStamp>{timeDiff}</TimeStamp>
    </Container>
  );
};
