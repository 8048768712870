import React from "react";
import { useScadaPlusTheme } from "../../../../../../hooks/useScadaPlusTheme";

type WindTurbineProps = {
  rectangle?: Array<Array<number>>;
  width: string;
  height: string;
  isMapEnabled: boolean;
  componentUnitId: string;
  bladeColor: string;
};

export const WindTurbineBlades: React.FC<WindTurbineProps> = (props) => {
  const { theme } = useScadaPlusTheme();

  return props.isMapEnabled ? (
    <svg width={props.width} height={props.height} viewBox="0 0 54 50">
      <circle
        cx="25.5"
        cy="25.5"
        r="25.5"
        fill={theme.colors.productionUnitMarkerCircleBackground}
        opacity={0.9}
      />
      <path
        d="M43.5583 35.3776C44.0869 35.6012 44.6911 35.6261 45.1694 35.3279C45.6728 35.0297 46.0001 34.483 46.0001 33.8618C45.9749 33.3897 45.7735 32.9673 45.4211 32.6691L35.6791 24.4194C35.6791 24.4194 32.8346 22.1085 29.9648 23.2267C28.7817 23.6739 27.7496 24.1212 26.9692 24.4939C26.2141 24.8418 26.1637 25.8855 26.8937 26.283L43.5583 35.3776Z"
        fill={props.bladeColor}
      />
      <path
        d="M25.1818 4.59019C25.1315 4.04353 24.8546 3.49686 24.3511 3.22353C23.8476 2.92534 23.1931 2.9005 22.6393 3.22353C22.2366 3.47201 21.9596 3.84474 21.859 4.29201L19.2661 16.7163C19.2661 16.7163 18.6116 20.2944 20.9779 22.2326C21.9596 23.0278 22.8407 23.6987 23.5456 24.2205C24.2252 24.7175 25.1566 24.2453 25.1566 23.4005L25.1818 4.59019Z"
        fill={props.bladeColor}
      />
      <path
        d="M6.80554 35.4024C6.35242 35.7254 6 36.2224 6 36.7939C6 37.3654 6.2769 37.9369 6.83071 38.2599C7.23348 38.4836 7.71177 38.5333 8.16488 38.409L20.4242 34.6072C20.4242 34.6072 23.9232 33.4393 24.477 30.4575C24.7036 29.2151 24.8798 28.1218 24.9805 27.2769C25.0812 26.4569 24.2001 25.8605 23.4701 26.2581L6.80554 35.4024Z"
        fill={props.bladeColor}
      />
    </svg>
  ) : (
    <svg
      data-testid="turbinebladestest"
      viewBox="0 0 159 143"
      width={props.width}
      key={`${props.componentUnitId}`}
      height={props.height}
    >
      <path
        d="M149.2 130.3C151.3 131.2 153.7 131.3 155.6 130.1C157.6 128.9 158.9 126.7 158.9 124.2C158.8 122.3 158 120.6 156.6 119.4L117.9 86.2C117.9 86.2 106.6 76.9 95.2 81.4C90.5 83.2 86.4 85 83.3 86.5C80.3 87.9 80.1 92.1 83 93.7L149.2 130.3Z"
        fill={`url(#paint0_linear_${props.componentUnitId})`}
      />
      <path
        d="M76.2 6.4C76 4.2 74.9 2 72.9 0.900002C70.9 -0.299998 68.3 -0.399998 66.1 0.900002C64.5 1.9 63.4 3.4 63 5.2L52.7 55.2C52.7 55.2 50.1 69.6 59.5 77.4C63.4 80.6 66.9 83.3 69.7 85.4C72.4 87.4 76.1 85.5 76.1 82.1L76.2 6.4Z"
        fill={`url(#paint1_linear_${props.componentUnitId})`}
      />
      <path
        d="M3.2 130.4C1.4 131.7 0 133.7 0 136C0 138.3 1.1 140.6 3.3 141.9C4.9 142.8 6.8 143 8.6 142.5L57.3 127.2C57.3 127.2 71.2 122.5 73.4 110.5C74.3 105.5 75 101.1 75.4 97.7C75.8 94.4 72.3 92 69.4 93.6L3.2 130.4Z"
        fill={`url(#paint2_linear_${props.componentUnitId})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_${props.componentUnitId}`}
          x1="137"
          y1="122"
          x2="147"
          y2="92"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={props.bladeColor} />
          <stop offset="1" stopColor={props.bladeColor} stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_${props.componentUnitId}`}
          x1="76"
          y1="22.5"
          x2="44.9418"
          y2="27.6492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={props.bladeColor} />
          <stop offset="1" stopColor={props.bladeColor} stopOpacity="0.3" />
        </linearGradient>
        <linearGradient
          id={`paint2_linear_${props.componentUnitId}`}
          x1="19"
          y1="121.5"
          x2="38"
          y2="147.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={props.bladeColor} />
          <stop offset="1" stopColor={props.bladeColor} stopOpacity="0.3" />
        </linearGradient>
      </defs>
    </svg>
  );
};
