import ScadaPlussRadioButton from "components/RadioButton";
import React, { useState } from "react";
import { MdChevronLeft } from "react-icons/md";
import { useIntl } from "react-intl";
import styled from "styled-components";
import Box from "../../../../components/Box";
import Button from "../../../../components/Button";
import InfoBoxErrorView from "../../../../components/InfoCardErrorView";
import Title from "../../../../components/Title";
import { useScadaPlusTheme } from "../../../../hooks/useScadaPlusTheme";
import TemperaturesChart from "./TemperaturesChart";
import TemperaturesList from "./TemperaturesList";

const Container = styled(Box)`
  height: 100%;
  padding: 4px;
`;

const HeaderContainer = styled.div`
  padding: 8px;
  display: grid;
  grid-template-columns: auto auto 1fr;
  height: 10%;
  grid-gap: 10px;
`;

const TemperatureViewRadioGroup = styled.div`
  display: grid;
  grid-template-columns: minmax(110px, auto) minmax(110px, auto);
  grid-gap: 10px;
  grid-column-start: right;
  height: 30px;
  padding-right: 15px;
`;

type Method = () => void;

type TemperaturesProps = {
  onToggle?: Method;
  onClick?: () => void;
};

type ErrorState = {
  error: string | undefined;
  timestamp: Date | undefined;
};

export const GraphAndListTemperature: React.FC<TemperaturesProps> = (props) => {
  const [selectedView, setSelectedView] = useState("listView");
  const [error, setError] = useState<ErrorState | undefined>(undefined);
  const { formatMessage } = useIntl();
  const { theme } = useScadaPlusTheme();

  const onChangeSelectedView = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedView(event.target.value);
  };
  return error ? (
    <InfoBoxErrorView
      lastQueriedTimestamp={error.timestamp}
      theme={theme}
      error={""}
    />
  ) : (
    <Container>
      <HeaderContainer>
        <Button
          type="secondary"
          width="30px"
          height="40px"
          padding="0px"
          center
          icon={<MdChevronLeft />}
          onclick={props.onClick}
        />
        <Title>
          {formatMessage({
            id: "pu.Temperatures",
            defaultMessage: "Temperatures (°C)",
          })}
        </Title>
        <TemperatureViewRadioGroup onChange={onChangeSelectedView}>
          <ScadaPlussRadioButton
            name="radioGroup_temperatureDataDisplay"
            id="radioButton_listView"
            value="listView"
            radioButtonLabel={formatMessage({
              id: "pu.Temperatures.List",
              defaultMessage: "Missing text",
            })}
            checked={selectedView === "listView"}
          ></ScadaPlussRadioButton>
          <ScadaPlussRadioButton
            name="radioGroup_temperatureDataDisplay"
            id="radioButton_graphView"
            value="graphView"
            radioButtonLabel={formatMessage({
              id: "pu.Temperatures.Graph",
              defaultMessage: "Missing text",
            })}
            checked={selectedView === "graphView"}
          ></ScadaPlussRadioButton>
        </TemperatureViewRadioGroup>
      </HeaderContainer>

      {selectedView === "listView" ? (
        <TemperaturesList
          onError={(timestamp, error) => {
            setError({
              error: error,
              timestamp: timestamp,
            });
          }}
        />
      ) : (
        <TemperaturesChart
          onError={(timestamp, error) => {
            setError({
              error: error,
              timestamp: timestamp,
            });
          }}
        />
      )}
    </Container>
  );
};
