import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { GenericAlarmPriorityCounter } from "types";
import {
  getTotalNumberOfAlarmsPriorityFour,
  getTotalNumberOfAlarmsPriorityOne,
  getTotalNumberOfAlarmsPriorityThree,
  getTotalNumberOfAlarmsPriorityTwo,
} from "utils/alarm-utils";
import AlarmTypeCounter from "../AlarmTypeCounter";
import { useIntl } from "react-intl";

const priorityOne = 1;
const priorityTwo = 2;
const priorityThree = 3;
const priorityFour = 4;

/* prettier-ignore */
const AlarmPriorityContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: ${(props) => props.theme.spacing.light};
  @media screen and (min-height: ${(props) => props.theme.mediaQueries.minWidth}) {
    grid-column-gap: ${(props) => props.theme.scaledFontSizes.medium};
  }
`;

type AlarmPrioritiesProps = {
  alarmPriorityData: GenericAlarmPriorityCounter[];
  theme: DefaultTheme;
};

export const AlarmPriorities: React.FC<AlarmPrioritiesProps> = ({
  alarmPriorityData,
  theme,
}) => {
  const { formatMessage } = useIntl();

  const totalNumberOfAlarmsPriorityOne =
    getTotalNumberOfAlarmsPriorityOne(alarmPriorityData)!;
  const totalNumberOfAlarmsPriorityTwo =
    getTotalNumberOfAlarmsPriorityTwo(alarmPriorityData)!;
  const totalNumberOfAlarmsPriorityThree =
    getTotalNumberOfAlarmsPriorityThree(alarmPriorityData)!;
  const totalNumberOfAlarmsPriorityFour =
    getTotalNumberOfAlarmsPriorityFour(alarmPriorityData)!;

  return (
    <AlarmPriorityContainer>
      <AlarmTypeCounter
        data={{
          priority: priorityOne,
          numberOfAlarms: totalNumberOfAlarmsPriorityOne,
        }}
        theme={theme}
      />
      <AlarmTypeCounter
        data={{
          priority: priorityTwo,
          numberOfAlarms: totalNumberOfAlarmsPriorityTwo,
        }}
        theme={theme}
      />
      <AlarmTypeCounter
        data={{
          priority: priorityThree,
          numberOfAlarms: totalNumberOfAlarmsPriorityThree,
        }}
        theme={theme}
      />

      <AlarmTypeCounter
        data={{
          priority: priorityFour,
          numberOfAlarms: totalNumberOfAlarmsPriorityFour,
        }}
        theme={theme}
      />
    </AlarmPriorityContainer>
  );
};
