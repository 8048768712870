import React from "react";
import { axisBottom, select } from "d3";
import { useEffect, useRef } from "react";
import { BottomAxisProps } from "../interfaces";

export const BottomAxis: React.FC<BottomAxisProps> = (props) => {
  const ref = useRef<SVGGElement>(null);
  useEffect(() => {
    if (ref.current) {
      select(ref.current).call(axisBottom(props.scale));
    }
  }, [props.scale]);

  return <g ref={ref} transform={props.transform} />;
};
