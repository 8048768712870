import CardLabel from "components/CardLabel";
import NumberValueFormatter from "components/NumberValueFormatter";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import { SensorData } from "hooks/useSubscription";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { MeasurementUnitSymbolConstants } from "utils/utils";

const TemperatureDataContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
`;

const TemperatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

interface IComponentProps {
  sensorData: SensorData | null;
  nacelleTagName: string;
  gearOilTagName: string;
  outdoorTagname: string;
}

export const TemperaturesData: React.FC<IComponentProps> = (props) => {
  const [nacelle, setNacelle] = useState<number | undefined>(undefined);
  const [gearOil, setGearOil] = useState<number | undefined>(undefined);
  const [outdoor, setOutdoor] = useState<number | undefined>(undefined);
  const { formatMessage } = useIntl();
  const { theme } = useScadaPlusTheme();

  useEffect(() => {
    switch (props.sensorData?.id) {
      case props.nacelleTagName:
        setNacelle(Number(props.sensorData?.value));
        break;
      case props.gearOilTagName:
        setGearOil(Number(props.sensorData?.value));
        break;
      case props.outdoorTagname:
        setOutdoor(Number(props.sensorData?.value));
        break;

      default:
        break;
    }
  }, [props]);

  return (
    <TemperatureDataContainer>
      <TemperatureContainer>
        <CardLabel>
          {formatMessage({
            id: "unit.Temperature.lntnTmp",
            defaultMessage: "Nacelle",
          })}
        </CardLabel>
        <NumberValueFormatter
          value={nacelle}
          precision={2}
          measurementType={MeasurementUnitSymbolConstants.degreeCelcius}
          loaderPadding={theme.spacing.light}
        />
      </TemperatureContainer>
      <TemperatureContainer>
        <CardLabel>
          {formatMessage({
            id: "unit.Temperature.GbxOilTmp",
            defaultMessage: "Gear oil",
          })}
        </CardLabel>
        <NumberValueFormatter
          value={gearOil}
          precision={2}
          measurementType={MeasurementUnitSymbolConstants.degreeCelcius}
          loaderPadding={theme.spacing.light}
        />
      </TemperatureContainer>
      <TemperatureContainer>
        <CardLabel>
          {formatMessage({
            id: "unit.Temperature.EnvTmp",
            defaultMessage: "Outdoor",
          })}
        </CardLabel>
        <NumberValueFormatter
          value={outdoor}
          precision={2}
          measurementType={MeasurementUnitSymbolConstants.degreeCelcius}
          loaderPadding={theme.spacing.light}
        />
      </TemperatureContainer>
    </TemperatureDataContainer>
  );
};
