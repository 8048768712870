import React from "react";
import { Tags } from "../types";

type Dispatch = (action: Action) => void;

const DefaultTags: Tags = {
  WTUROpCde: "",
  WYAWYwAng: "",
  WGENRotSpd: "",
  WROTRotSpd: "",
  WCNVGnHz:  "",
  WSTRBalDepth:  "",
  WTOWTowPres:  "",
  WTOWSway:  "",
  WTOWHeading:  "",
  WTOWSurge:  "",
  WTOWHeave:  "",
  WMETWaveHeigthAvg: "",
  WMETWaveSigHeight: "",
  WTRMBrkOpSt: "",
  WTURStCde: "",
  WGENGnOpSt: "",
  WYAWYwSt: "",
  WAVLTLC: "",
  WAVLDisconSt1: "",
  WAVLEthSwOp: "",
  WAPCW: "",
  WAPCWSec: "",
  WTRFOilLevSt: "",
  WTRFTnkPresSt: "",
  WAPCWSpt: "",
  WTURTotWh: "",
  WTURTurSt: "",
  WTURTurStCounter: "",
  WTURW: "",
  WTURVar: "",
  WYAWCabWup: "",
  WNACPitch: "",
  WNACRoll: "",
  WNACYaw: "",
  WTOWPitch: "",
  WTOWRoll: "",
  WTOWYaw: "",
  WTRFTurA1: "",
  WTRFTurA3: "",
  WTRFTurA2: "",
  WTRFTurAAvg: "",
  WTRFTurPPV1: "",
  WTRFTurPPV2: "",
  WTRFTurPPV3: "",
  WTRFTurPPVAvg: "",
  WMETHorWdDir: "",
  WMETHorWdSpd: "",
  WMETEnvPres: "",
  WMETWaveHeigth: "",
  WMETEnvTmp: "",
  WNAClntnTmp: "",
  WTRMGbxOilTmp: "",
  WTURRotTmp: "",
  WTURSttTmp: "",
  WAPCWAll: "",
  WMETWdGustSpdMax: "",
  WMETHorWdSpdMax: "",
  WAVLAviTmsRs: "",
  WAVLRsStatTot: "",
  WAVLErTmsRs: "",
  WAVLWndInRng: "",
  WAVLOpTmsRs: "",
  WAVLRsStatSub: "",
  WAVLAccEnRs: "",
  WAVLGrdFltTmhRs: "",
  WAVLExtErTmhRs: "",
  WAVLTurAvl: "",
  WTURLastError_1: "",
  WTURLatitude: "",
  WTURLongitude: "",
  WTURValidity: "",
  WTURLatDiff: "",
  WTURLonDiff: "",
};

type State = {
  tags: Tags;
  isTagsLoaded: boolean;
};

const TagsContext = React.createContext<State>({
  tags: DefaultTags,
  isTagsLoaded: false,
});

const TagsDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

enum ActionType {
  SetTags = "SetTags",
}

type SetTags = {
  tags: Tags;
  type: ActionType.SetTags;
};

type Action = SetTags;

const tagsReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.SetTags: {
      return { tags: action.tags, isTagsLoaded: true };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const ScadaPlusTagsProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(tagsReducer, {
    isTagsLoaded: false,
    tags: DefaultTags,
  });

  return (
    <TagsContext.Provider value={state}>
      <TagsDispatchContext.Provider value={dispatch}>
        {props.children}
      </TagsDispatchContext.Provider>
    </TagsContext.Provider>
  );
};

export { ScadaPlusTagsProvider, TagsDispatchContext, TagsContext, ActionType };
