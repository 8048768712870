import React from "react";

interface IComponentProps {
  width: number;
  height: number;
  color?: string;
  style?: React.CSSProperties;
}

export const HeadSad: React.FC<IComponentProps> = (props) => {
  return (
    <svg
      data-testid="headsad"
      width={props.width}
      height={props.width / 2}
      viewBox="0 0 28 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6343 0H6.36566C2.84897 0 0 2.90909 0 6.5C0 10.0909 2.84897 13 6.36566 13H21.6343C25.151 13 28 10.0909 28 6.5C28 2.90909 25.151 0 21.6343 0Z"
        fill="url(#paint0_radial)"
      />
      <path
        d="M6.63274 10C4.54053 10 2.80444 8.22728 2.80444 6.09091C2.80444 5.59091 3.20508 5.18182 3.69475 5.18182C4.18441 5.18182 4.58505 5.59091 4.58505 6.09091C4.58505 7.22728 5.51986 8.18182 6.63274 8.18182C7.74562 8.18182 8.68044 7.22728 8.68044 6.09091C8.68044 5.59091 9.08107 5.18182 9.57074 5.18182C10.0604 5.18182 10.461 5.59091 10.461 6.09091C10.461 8.27273 8.76947 10 6.63274 10Z"
        fill="#FF762E"
      />
      <path
        d="M21.3227 10C19.2305 10 17.4944 8.22728 17.4944 6.09091C17.4944 5.59091 17.8951 5.18182 18.3847 5.18182C18.8744 5.18182 19.275 5.59091 19.275 6.09091C19.275 7.22728 20.2099 8.18182 21.3227 8.18182C22.4356 8.18182 23.3704 7.22728 23.3704 6.09091C23.3704 5.59091 23.7711 5.18182 24.2607 5.18182C24.7504 5.18182 25.151 5.59091 25.151 6.09091C25.151 8.27273 23.415 10 21.3227 10Z"
        fill="#FF762E"
      />
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14 5.5) rotate(90) scale(7.5 16.1538)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EBEBEB" />
        </radialGradient>
      </defs>
    </svg>
  );
};
