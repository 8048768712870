import { AlarmViewModel } from "pages/AlarmsView/AlarmListContainer/AlarmList/AlarmList";
import { DefaultTheme } from "styled-components";
import { GenericAlarm, GenericAlarmPriorityCounter, PersonnelLevel } from "../types";

// PERSONNEL WARNING COLOUR HANDLER
export const getPersonnelColor = (
  options: PersonnelLevel,
  theme: DefaultTheme
) => {
  if (options === "attention") {
    return theme.colors.signal;
  }
  if (options === "active") {
    return theme.colors.ready;
  }
};

// ALARM COUNTERS
export const getTotalNumberOfAlarms = (
  alarmPriorityCounters: Array<GenericAlarmPriorityCounter>,
  priority?: number
) => {
  let filtered = alarmPriorityCounters;
  if (priority) {
    filtered = filtered.filter((x) => x.priority === priority);
  }
  return filtered.map((x) => x.numberOfAlarms).reduce((p, c) => p + c, 0);
};

export const getHighestPriority = (
  alarmPriorityCounters: Array<GenericAlarmPriorityCounter>
) => {
  if (alarmPriorityCounters.length === 0) {
    return undefined;
  }
  return alarmPriorityCounters.reduce((previous, current) =>
    current.priority < previous.priority ? current : previous
  );
};

export const getTotalNumberOfAlarmsPriorityOne = (
  alarmPriorityCounters: Array<GenericAlarmPriorityCounter> | undefined
) => {
  return alarmPriorityCounters
    ? getTotalNumberOfAlarms(alarmPriorityCounters, 1)
    : undefined;
};

export const getTotalNumberOfAlarmsPriorityTwo = (
  alarmPriorityCounters: Array<GenericAlarmPriorityCounter> | undefined
) => {
  return alarmPriorityCounters
    ? getTotalNumberOfAlarms(alarmPriorityCounters, 2)
    : undefined;
};

export const getTotalNumberOfAlarmsPriorityThree = (
  alarmPriorityCounters: Array<GenericAlarmPriorityCounter> | undefined
) => {
  return alarmPriorityCounters
    ? getTotalNumberOfAlarms(alarmPriorityCounters, 3)
    : undefined;
};

export const getTotalNumberOfAlarmsPriorityFour = (
  alarmPriorityCounters: Array<GenericAlarmPriorityCounter> | undefined
) => {
  return alarmPriorityCounters
    ? getTotalNumberOfAlarms(alarmPriorityCounters, 4)
    : undefined;
};

// ALARM FONT COLOUR HANDLERS
export const resolveAlarmPriorityDisplayTileFontColor = (
  level: number,
  theme: DefaultTheme,
  acknowledged?: boolean
) => {
  if (acknowledged) {
    switch (level) {
      case 1:
        return theme.colors.alarmPriorities?.[1].Acknowledged.priorityTileFontColor;
      case 2:
        return theme.colors.alarmPriorities?.[2].Acknowledged.priorityTileFontColor;
      case 3:
        return theme.colors.alarmPriorities?.[3].Acknowledged.priorityTileFontColor;
      case 4:
        return theme.colors.alarmPriorities?.[4].Acknowledged.priorityTileFontColor;
      default:
        return theme.colors.alarmPriorities?.[4].Acknowledged.priorityTileFontColor;
    }
  } else {
    switch (level) {
      case 1:
        return theme.colors.alarmPriorities?.[1].NotAcknowledged
          .priorityTileFontColor;
      case 2:
        return theme.colors.alarmPriorities?.[2].NotAcknowledged
          .priorityTileFontColor;
      case 3:
          return theme.colors.alarmPriorities?.[3].NotAcknowledged
          .priorityTileFontColor;
          case 4:
            return theme.colors.alarmPriorities?.[4].NotAcknowledged
            .priorityTileFontColor;
      default:
        return theme.colors.alarmPriorities?.[4].NotAcknowledged
            .priorityTileFontColor;
    }
  }
};

export const resolveAlarmDescriptionFontColor = (
  alarmData: GenericAlarm,
  acknowledged: boolean,
  theme: DefaultTheme
) => {
  const priorities: any = theme.colors.alarmPriorities;
  if (!acknowledged && alarmData.isActive) {

    if (alarmData.priority > 4) {
      return priorities[4].NotAcknowledged
      .alarmFontColorTwo;
    } else {
      return priorities[alarmData.priority.toString()].NotAcknowledged.alarmFontColorOne
      ? priorities[alarmData.priority.toString()].NotAcknowledged.alarmFontColorOne
    : "transparent";
    }    
  } else if (acknowledged && alarmData.isActive) {
    if (alarmData.priority == 3 || alarmData.priority == 4) {
      return priorities[alarmData.priority.toString()].Acknowledged
        .alarmFontColorTwo;   
    }
    if (alarmData.priority > 4) {
      return priorities[4].Acknowledged
      .alarmFontColorTwo;
    }
    return priorities[alarmData.priority.toString()].Acknowledged.alarmFontColorOne;
  }
};

export const resolveTimestampColor = (
  alarmData: GenericAlarm,
  acknowledged: boolean,
  theme: DefaultTheme
) => {
  if (!acknowledged && alarmData.priority === 1 || !acknowledged && alarmData.priority === 3 || !alarmData.isActive) {
    return theme.colors.productionUnitAlarmlistTimeStampColorLight;
  } 
};

export const resolveAlarmPriorityTileColor = (level: number, theme: DefaultTheme) => {
  switch (level) {
    case 1:
      return theme.colors.alarmPriorities?.[1].NotAcknowledged.priorityTileBackgroundColor;
    case 2:
      return theme.colors.alarmPriorities?.[2].NotAcknowledged.priorityTileBackgroundColor;
    case 3:
      return theme.colors.alarmPriorities?.[3].NotAcknowledged.priorityTileBackgroundColor;
    case 4:
      return theme.colors.alarmPriorities?.[4].NotAcknowledged.priorityTileBackgroundColor;
    default:
      return theme.colors.alarmPriorities?.[4].NotAcknowledged.priorityTileBackgroundColor;
  }
};

export const resolveAlarmListPriorityTileColor = (
  level: number,
  theme: DefaultTheme,
  acknowledged?: boolean 
) => {
  if (acknowledged) {
    switch (level) {
      case 1:
        return theme.colors.alarmPriorities?.[1].Acknowledged
          .priorityTileBackgroundColor;
      case 2:
        return theme.colors.alarmPriorities?.[2].Acknowledged
          .priorityTileBackgroundColor;
      case 3:
        return theme.colors.alarmPriorities?.[3].Acknowledged
          .priorityTileBackgroundColor;
      case 4:
        return theme.colors.alarmPriorities?.[4].Acknowledged
          .priorityTileBackgroundColor;
      default:
        return theme.colors.alarmPriorities?.[4].Acknowledged
          .priorityTileBackgroundColor;
    }
  } else {
    switch (level) {
      case 1:
        return theme.colors.alarmPriorities?.[1].NotAcknowledged
          .priorityTileBackgroundColor;
      case 2:
        return theme.colors.alarmPriorities?.[2].NotAcknowledged
          .priorityTileBackgroundColor;
      case 3:
        return theme.colors.alarmPriorities?.[3].NotAcknowledged
          .priorityTileBackgroundColor;
      case 4:
        return theme.colors.alarmPriorities?.[4].NotAcknowledged
          .priorityTileBackgroundColor;
      default:
        return theme.colors.alarmPriorities?.[4].NotAcknowledged
          .priorityTileBackgroundColor;
    }
  }
};

// Alarms page
export const resolveThemeCheckedIconColor = (
  alarmView: AlarmViewModel,
  acknowledged: boolean,
  theme: DefaultTheme
): string | undefined => {
  const priorities: any = theme.colors.alarmPriorities!;

  if (acknowledged) {

      return priorities < 5 ? priorities[alarmView.data.priority.toString()].Acknowledged
      .checkedIcon : priorities[4].Acknowledged.checkedIcon
  }
  return priorities < 5 ? priorities[alarmView.data.priority.toString()].NotAcknowledged
  .checkedIcon : priorities[4].NotAcknowledged.checkedIcon

};

// Alarm item container background
export const resolveAlarmBackgroundColor = (
  alarmData: GenericAlarm,
  theme: DefaultTheme,
  acknowledged?: boolean,
) => {
  if (!acknowledged && alarmData.isActive) {
    const priorities: any = theme.colors.alarmPriorities;
    if (alarmData.priority > 4) {
      return priorities[4].NotAcknowledged.background;
    } else {
      return priorities[alarmData.priority.toString()].NotAcknowledged.background
        ? priorities[alarmData.priority.toString()].NotAcknowledged.background
        : "transparent";
    }
  }

  if (!acknowledged && !alarmData.isActive) {
    const priorities: any = theme.colors.alarmPriorities!;
    if (alarmData.priority > 4) {
      return priorities[4].NotAcknowledged.inactiveAlarmBackground;
    } else {
      return priorities[alarmData.priority.toString()].Acknowledged.background
        ? priorities[alarmData.priority.toString()].NotAcknowledged.inactiveAlarmBackground
        : "transparent";
    }
  }
  return null;
};

export const resolveSelectedAlarmBackgroundColor = (
  alarmData: GenericAlarm,
  theme: DefaultTheme,
  checked?: boolean
) => {
  if (!alarmData.isAcknowledged && checked) {
    const priorities: any = theme.colors.alarmPriorities!;
    return priorities[alarmData.priority.toString()].NotAcknowledged.selected;
  }
  return false;
};

export const resolveHoverColor = (
  alarmData: GenericAlarm,
  theme: DefaultTheme,
  acknowledged: boolean,
  disableHover?: boolean
) => {
  if (!disableHover) {
    if (!acknowledged && alarmData.isActive) {
      const priorities: any = theme.colors.alarmPriorities!;
      return alarmData.priority < 5 ? priorities[alarmData.priority.toString()].NotAcknowledged.hover : priorities[4].NotAcknowledged.hover
    }
    if (acknowledged) {
      const priorities: any = theme.colors.alarmPriorities!
      return alarmData.priority < 5 ? priorities[alarmData.priority.toString()].Acknowledged.hover : priorities[4].Acknowledged.hover

      
    }
    if (!acknowledged && !alarmData.isActive) {
      const priorities: any = theme.colors.alarmPriorities!;
      return alarmData.priority < 5 ? priorities[alarmData.priority.toString()].Acknowledged.hover : priorities[4].Acknowledged.hover
    }
  }
};