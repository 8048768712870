import Loader from "components/Loader";
import WarningSVG from "components/svg/WarningSVG";
import WindTurbineStatusSVG from "components/svg/WindTurbineStatusSVG";
import Wendy from "components/Wendy";
import { WendyBodyStyles, WendyHeadStyles } from "components/Wendy/Wendy";
import styled, { useTheme } from "styled-components";
import {
  shouldUsePrimaryStyle,
  shouldUseSignalStyle,
  shouldUseWarningStyle,
} from "utils/production-utils";

const Container = styled.div`
  display: flex;
  align-content: center;
  padding-top: 2%;
`;

type StatusCardIconResolverProps = {
  operationCode?: string;
  statusCode?: string;
  iconWidth: string;
  iconHeight: string;
  size?: number;
};

export const StatusCardIconResolver: React.FC<StatusCardIconResolverProps> = (
  props
) => {
  const theme = useTheme();

  const resolveIcon = () => {
    if (shouldUseWarningStyle(props.operationCode, props.statusCode)) {
      return (
        <WarningSVG
          width={props.iconWidth}
          height={props.iconHeight}
          color={theme.colors.textColor.primary}
          colorSymbol={theme.colors.warning}
        />
      );
    } else if (shouldUseSignalStyle(props.operationCode, props.statusCode)) {
      return (
        <Wendy
          HeadStyle={WendyHeadStyles.EyesShut}
          BodyStyle={WendyBodyStyles.Empty}
          size={props.size}
        />
      );
    } else if (shouldUsePrimaryStyle(props.operationCode, props.statusCode)) {
      return (
        <WindTurbineStatusSVG
          width={props.iconWidth}
          height={props.iconHeight}
          color={theme.colors.componentBackgroundColor.primary}
        />
      );
    } else {
      return (
        <Loader
          size={props.size}
          style={{ alignItems: "flex-start", display: "block" }}
        />
      );
    }
  };
  return <Container>{resolveIcon()}</Container>;
};
