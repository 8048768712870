import React from "react";
import styled from "styled-components";
import { useSelect } from "downshift";
import { MdArrowDropDown } from "react-icons/md";
import { useIntl } from "react-intl";

const DropDownComponent = styled.div`
  color: ${(props) => props.theme.colors.headerDropdownTextColor};
  position: relative;
  &:hover {
    cursor: pointer;
  }
  font-weight: 600;
  height: 100%;
`;

const Menu = styled.ul`
  z-index: 10000;
  position: absolute;
  width: 100%;
  list-style: none;
  outline: none;
  margin-top: 0;
  padding: 0;
  background-color: ${(props) =>
    props.theme.colors.headerDropdownBackgroundColor};
  box-shadow: ${(props) => props.theme.shadow.boxShadow};
`;

const MenuItem = styled.li`
  padding: ${(props) => props.theme.spacing.light};
  margin: 0;
  cursor: pointer;
  z-index: 10000;
  padding: ${(props) => props.theme.spacing.light};
  padding-left: ${(props) => props.theme.spacing.medium};
  &:hover {
    background-color: ${(props) =>
      props.theme.colors.headerDropdownBackgroundHoverColor};
  }
  &.inactive-link {
    position: relative;
    pointer-events: none;
    color: ${(props) => props.theme.colors.textColor.quaternary};
    &:hover {
      background-color: inherit;
    }
  }
  &.tile-list-menu-item:hover {
    background-color: inherit;
  }
`;

const SelectedItem = styled.div`
  background-color: ${(props) => props.theme.colors.headerDropdownColor};
  display: flex;
  align-items: center;
  border-radius: ${(props) => props.theme.borderRadius.light};
  padding: ${(props) => props.theme.spacing.light};
  padding-left: ${(props) => props.theme.spacing.medium};
  height: inherit;
  color: ${(props) => props.theme.colors.headerDropdownSelectedItemTextColor};
`;

const DisplayName = styled.div``;

const DropdownIcon = styled(MdArrowDropDown)`
  margin-left: auto;
`;

const TileList = styled.ul`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: ${(props) => props.theme.spacing.extraLight};
`;

const Tile = styled.li`
  background: ${(props) => props.theme.colors.buttonColors.primaryButtonColor};
  &:hover {
    background: ${(props) =>
      props.theme.colors.buttonColors.primaryButtonHoverColor};
  }
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
  color: ${(props) =>
    props.theme.colors.buttonColors.primaryButtonTextColor.primary};
  text-align: center;
  list-style: none;
  font-weight: 400;
`;

export enum DropdownType {
  Single = "Single",
  Multiple = "Multiple",
}

export type DropdownSingleItem = {
  id: string;
  text: string;
  onClick: (item: DropdownSingleItem) => void;
  type: DropdownType.Single;
  translateId?: string;
  inactiveLink?: boolean;
};

export type DropDownItemList = {
  items: Array<DropDownType>;
  id: string;
  type: DropdownType.Multiple;
};

type DropdownProps = {
  items: Array<DropDownType>;
  initial: DropDownType | undefined;
  className?: string;
};

export type DropDownType = DropdownSingleItem | DropDownItemList;

export const Dropdown: React.FC<DropdownProps> = (props) => {
  const { formatMessage } = useIntl();
  const multiItems = props.items
    .filter((x) => x.type === DropdownType.Multiple)
    .flatMap((x) => (x as DropDownItemList).items);
  const singleItems = props.items.filter((x) => x.type === DropdownType.Single);

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect<DropDownType>({
    items: singleItems.concat(multiItems),
    initialSelectedItem: props.initial ? props.initial : props.items[0],
    onSelectedItemChange: (data) => {
      if (data.selectedItem) {
        if (data.selectedItem.type === DropdownType.Single) {
          return data.selectedItem.onClick(data.selectedItem!);
        }
      }
    },
  });

  return (
    <DropDownComponent className={props.className}>
      <SelectedItem {...getToggleButtonProps()}>
        <DisplayName>
          {selectedItem?.type === DropdownType.Single
            ? selectedItem?.translateId
              ? formatMessage({
                  id: selectedItem.translateId,
                  defaultMessage: `${selectedItem?.text}`,
                })
              : selectedItem?.text
            : ""}
        </DisplayName>
        <DropdownIcon size={32} />
      </SelectedItem>
      <Menu {...getMenuProps()}>
        {isOpen &&
          props.items.map((item, idx) => {
            if (item.type === DropdownType.Single) {
              return (
                <MenuItem
                  className={
                    item.inactiveLink === true ? "inactive-link" : undefined
                  }
                  key={`${item.id}${idx}`}
                  {...getItemProps({
                    item,
                    index: idx,
                  })}
                >
                  {item.text}
                </MenuItem>
              );
            } else {
              //Downshift used the index to look up in the collection. Use offset to get this correctly.
              const offset = singleItems.length;
              return (
                <MenuItem
                  className="tile-list-menu-item"
                  key={`${item.id}${offset}`}
                >
                  <TileList>
                    {item.items.map((subitem, index) => {
                      if (subitem.type === DropdownType.Single) {
                        return (
                          <Tile
                            key={`${subitem.id}${index + offset}`}
                            {...getItemProps({
                              item: subitem,
                              index: index + offset,
                            })}
                          >
                            {subitem.text}
                          </Tile>
                        );
                      } else {
                        return <div></div>;
                      }
                    })}
                  </TileList>
                </MenuItem>
              );
            }
          })}
      </Menu>
      {/* if you Tab from menu, focus goes on button, and it shouldn't. only happens here. */}
      <div tabIndex={0} />
    </DropDownComponent>
  );
};
