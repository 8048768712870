import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { useScadaPlusTheme } from "../../hooks/useScadaPlusTheme";

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 1.5rem;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${(props: CheckBoxStyleProps) =>
    props.checkedIconColor && props.checked
      ? props.checkedIconColor
      : props.theme.colors.textColorInverse.primary};
  stroke-width: 2px;
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  display: none;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  max-height: 18px;
  background-color: ${(props: CheckBoxStyleProps) =>
    props.checkedBackgroundColor && props.checked
      ? props.checkedBackgroundColor
      : props.checked
      ? props.theme.colors.attention
      : null};
  border-radius: ${(props) => props.theme.borderRadius.light};
  border: ${(props: CheckBoxStyleProps) =>
    props.checked
      ? null
      : `2px solid ${props.theme.colors.textColor.secondary}`};

  &:hover {
    cursor: ${(props: CheckBoxStyleProps) =>
      props.disable ? "default" : "pointer"};
    border-color: ${(props: CheckBoxStyleProps) =>
      props.disable ? "" : props.theme.colors.textColor.primary};
  }

  ${HiddenCheckbox}:focus + & {
  }

  ${Icon} {
    visibility: ${(props: CheckBoxStyleProps) =>
      props.checked ? "visible" : "hidden"};
  }
`;

interface CheckBoxComponentProps {
  checked: boolean;
  onChange?: (event: React.MouseEvent) => void;
  checkedBackgroundColor?: string;
  checkedIconColor?: string;
  disable?: boolean;
}
interface CheckBoxStyleProps {
  checked: boolean;
  theme: DefaultTheme;
  checkedBackgroundColor?: string;
  checkedIconColor?: string;
  disable?: boolean;
}

export const CheckBox: React.FC<CheckBoxComponentProps> = (props) => {
  const { theme } = useScadaPlusTheme();
  return (
    <Container
      onClick={(event) => {
        props.onChange && props.onChange(event);
      }}
    >
      <HiddenCheckbox data-testid="checkbox" checked={props.checked} readOnly />
      <StyledCheckbox
        checkedBackgroundColor={
          props.checkedBackgroundColor ?? theme.colors.textColor.primary
        }
        data-testid="styledCheckbox"
        checked={props.checked}
        theme={theme}
        disable={props.disable}
      >
        <Icon
          className="icon"
          checked={props.checked}
          checkedIconColor={props.checkedIconColor}
          viewBox="0 0 24 24"
        >
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </Container>
  );
};
