import React from "react";
import styled from "styled-components";
import Button from "components/Button";
import { MdDirectionsBoat, MdMap, MdPerson, MdShowChart } from "react-icons/md";
import { Layers } from "types";
import { useIntl } from "react-intl";
import config from "config";

const Container = styled.div`
  display: flex;
`;

type LayerButtonProps = {
  layers: Layers;
  plantId: string;
  setLayers: React.Dispatch<React.SetStateAction<Layers>>;
  displayProductionButton: boolean;
};

export const LayerButtons: React.FC<LayerButtonProps> = (props) => {
  const { formatMessage } = useIntl();
  const isMapEnabled = config.mapEnabled.find((x) => x === props.plantId);
  const isMarineEnabled = config.marineEnabled.find((x) => x === props.plantId);
  return (
    <Container>
      <Button
        onclick={() =>
          props.setLayers({
            ...props.layers,
            personnel: !props.layers.personnel,
          })
        }
        type="secondary"
        size="small"
        center
        icon={<MdPerson />}
        opacity={props.layers.personnel ? 1 : 0.5}
      >
        {formatMessage({
          id: "overview.layerButton.Personnel",
          defaultMessage: "Personnel",
        })}
      </Button>
      {props.displayProductionButton && (
        <Button
          onclick={() =>
            props.setLayers({
              ...props.layers,
              production: !props.layers.production,
            })
          }
          type="secondary"
          size="small"
          center
          icon={<MdShowChart />}
          opacity={props.layers.production ? 1 : 0.5}
        >
          {formatMessage({
            id: "overview.layerButton.Production",
            defaultMessage: "Production",
          })}
        </Button>
      )}
      {isMarineEnabled && (
        <Button
          onclick={() =>
            props.setLayers({
              ...props.layers,
              marine: !props.layers.marine,
            })
          }
          type="secondary"
          size="small"
          center
          icon={<MdDirectionsBoat />}
          opacity={props.layers.marine ? 1 : 0.5}
        >
          {formatMessage({
            id: "overview.layerButton.Marine",
            defaultMessage: "Marine",
          })}
        </Button>
      )}
      {isMapEnabled && (
        <Button
          onclick={() =>
            props.setLayers({
              ...props.layers,
              map: !props.layers.map,
            })
          }
          type="secondary"
          size="small"
          center
          icon={<MdMap />}
          opacity={props.layers.map ? 1 : 0.5}
        >
          {formatMessage({
            id: "overview.layerButton.Map",
            defaultMessage: "Map",
          })}
        </Button>
      )}
    </Container>
  );
};
