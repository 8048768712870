import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import AlarmPriorityDisplayTile from "components/AlarmPriorityDisplay";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import { GenericAlarm } from "types";
import { constructAlarmUrl, getSectionId, getType } from "utils/routing-utils";
import { useLocalDateTimeFormat } from "hooks/useDateTimeFormat";

const Container = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.colors.textColor.quaternary};

  &:hover {
    cursor: pointer;
  }
`;

const AlarmContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const TimestampContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${(props) => props.theme.scaledFontSizes.small};
`;

const Timestamp = styled.div``;

const Area = styled.div``;

const TimestampDivider = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.textColor.primary};
  font-size: ${(props) => props.theme.scaledFontSizes.small};
`;

interface IComponentProps {
  alarm: GenericAlarm;
}

export const MaintenanceAlarm: React.FC<IComponentProps> = (props) => {
  const dateTimeLocal = useLocalDateTimeFormat();
  const { theme } = useScadaPlusTheme();
  const history = useHistory();
  const type = getType(history.location.search);

  const sectorId = getSectionId(history.location.search);

  const resolveSvgTextColor = () => {
    const priorities: any = theme.colors.alarmPriorities!;

    return priorities[props.alarm.priority.toString()].NotAcknowledged
      .text as string;
  };

  return (
    <Container
      onClick={() =>
        history.push(
          constructAlarmUrl(
            props.alarm.plantId!,
            sectorId,
            type,
            props.alarm.id
          )
        )
      }
    >
      <AlarmPriorityDisplayTile
        alarmPriortity={props.alarm.priority}
        acknowledged={
          props.alarm.isAcknowledged ? props.alarm.isAcknowledged : undefined
        }
        height="1.3rem"
        width={theme.spacing.extraHeavy}
        listItem
      >
        <span style={{ color: resolveSvgTextColor() }}>!</span>
      </AlarmPriorityDisplayTile>

      <AlarmContainer>
        <TimestampContainer>
          <Timestamp>
            {dateTimeLocal.format(
              props.alarm.timestamp
                ? new Date(props.alarm.timestamp)
                : undefined
            )}
          </Timestamp>
          <TimestampDivider>{props.alarm.area ? "|" : ""}</TimestampDivider>{" "}
          <Area>{props.alarm.area}</Area>
        </TimestampContainer>
        <Title>{props.alarm.description}</Title>
      </AlarmContainer>
    </Container>
  );
};
