import OrigoLogoSVG from "components/svg/OrigoLogoSVG";
import React, { useState } from "react";
import styled from "styled-components";
import About from "../../pages/About";

const Container = styled.div`
  display: block;
  margin-left: auto;
  padding-right: 1rem;
`;

export const AboutButton: React.FC = () => {
  const [showAbout, setShowAbout] = useState(false);

  return (
    <Container>
      <OrigoLogoSVG
        onClick={() => setShowAbout(!showAbout)}
        width="6rem"
        height="3rem"
      />

      {showAbout && <About onClose={() => setShowAbout(!showAbout)} />}
    </Container>
  );
};
