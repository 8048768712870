import config from "../config";
import { IdCollection, LastSample } from "../types";
import { useScadaPlusQuery } from "./useScadaPlusQuery";

export const useGetLastSampleForTag = (
  tag: string,
  ids: IdCollection,
  interval: number = 10 * 3000
) => {
  return useScadaPlusQuery<LastSample>(
    `last-sample-${ids.plantId}_${ids.sectionId}`,
    () => {
      if (ids.sectionId) {
        return `${config.api.url}/${config.api.lastSample}?id=${tag}&sectorId=${ids.sectionId}`;
      }
      return `${config.api.url}/${config.api.lastSample}?id=${tag}&windpowerplantId=${ids.plantId}`;
    },
    interval
  );
};
