import React, { useEffect, useState } from "react";
import {
  SensorData,
  useSensorSubscription,
  SensorSubscriptionFilter,
} from "../../hooks/useSubscription";
import styled from "styled-components";
import InfoCard from "../InfoCard";
import { useIntl } from "react-intl";
import NumberValueFormatter from "../NumberValueFormatter";
import CardLabel from "../CardLabel";
import { useTagsContext } from "../../hooks/useTags";
import { MeasurementUnitSymbolConstants } from "utils/utils";

type WeatherProps = {
  plantId?: string;
  componentUnitId?: string;
};

const WeatherValuesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(4, minmax(0, 0.3fr));
  padding: ${(props) => props.theme.spacing.heavy};
  padding-top: 0%;
  height: 100%;
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
`;

const WinSpeedText = styled.div`
  grid-column: 1 /2;
  grid-row: 1 /2;
`;

const WindSpeedValue = styled.div`
  grid-row: 1 /2;
  grid-column: 2 /-1;
`;

const WavesText = styled.div`
  grid-column: 1 /2;
  grid-row: 2 / 3;
`;

const WavesValue = styled.div`
  grid-column: 2 /-1;
  grid-row: 2 / 3;
`;

export const WeatherCard: React.FC<WeatherProps> = (props) => {
  const { formatMessage } = useIntl();
  const { tags } = useTagsContext();
  const [windSpeed, setWindSpeed] = useState<SensorData | null>(null);
  const [waveSigHeight, setwaveSigHeight] = useState<SensorData | null>(null);

  const filters: Array<SensorSubscriptionFilter> = props.plantId
    ? [
        {
          id: tags.WMETEnvPres,
          windPowerPlantId: props.plantId,
        },
        {
          id: tags.WMETHorWdSpd,
          windPowerPlantId: props.plantId,
        },
        {
          id: tags.WMETWaveHeigth,
          windPowerPlantId: props.plantId,
        },
        {
          id: tags.WMETWaveHeigthAvg,
          windPowerPlantId: props.plantId,
        },
        {
          id: tags.WMETWaveSigHeight,
          windPowerPlantId: props.plantId,
        },
      ]
    : [
        {
          id: tags.WMETEnvPres,
          windTurbineId: props.componentUnitId,
        },
        {
          id: tags.WMETHorWdSpd,
          windTurbineId: props.componentUnitId,
        },
        {
          id: tags.WMETWaveHeigth,
          windTurbineId: props.componentUnitId,
        },
        {
          id: tags.WMETWaveHeigthAvg,
          windTurbineId: props.componentUnitId,
        },
        {
          id: tags.WMETWaveSigHeight,
          windPowerPlantId: props.plantId,
        },
      ];

  const { sensorData: sensorData } = useSensorSubscription({
    filters: filters,
  });

  useEffect(() => {
    if (tags) {
      switch (sensorData?.id) {
        case tags.WMETHorWdSpd:
          setWindSpeed(sensorData);
          break;
        case tags.WMETWaveSigHeight:
          setwaveSigHeight(sensorData);
          break;
        default:
          break;
      }
    }
  }, [sensorData, tags]);

  return (
    <InfoCard
      title={formatMessage({
        id: "component.Weather",
        defaultMessage: "Weather",
      })}
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <WeatherValuesContainer>
        <WinSpeedText>
          <CardLabel>
            {formatMessage({
              id: "component.Weather.Wind",
              defaultMessage: "Missing text",
            })}
          </CardLabel>
        </WinSpeedText>
        <WindSpeedValue>
          <NumberValueFormatter
            value={Number(windSpeed?.value)}
            precision={1}
            measurementType={MeasurementUnitSymbolConstants.metersPerSecond}
          />
        </WindSpeedValue>
        <WavesText>
          <CardLabel>
            {formatMessage({
              id: "component.Weather.Waves",
              defaultMessage: "Missing text",
            })}
          </CardLabel>
        </WavesText>
        <WavesValue>
          <NumberValueFormatter
            value={Number(waveSigHeight?.value)}
            precision={2}
            measurementType={MeasurementUnitSymbolConstants.meter}
          />
        </WavesValue>
      </WeatherValuesContainer>
    </InfoCard>
  );
};
