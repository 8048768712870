import ScadaPlusError from "components/ScadaPlusError";
import { ScadaPlusErrorListener } from "components/ScadaPlusError/ScadaPlusError";
import React from "react";

export type Dispatch<ErrorState> = (newState: ErrorState) => void;

export type ErrorState = {
  text: string;
  show: boolean;
};

export const ErrorContext = React.createContext<ErrorState>({
  show: false,
  text: "",
});

export const ErrorDispatchContext = React.createContext<
  Dispatch<ErrorState> | undefined
>(undefined);

const errorReducer = (state: ErrorState, newState: ErrorState) => {
  return newState;
};

const ScadaPlusErrorProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(errorReducer, {
    text: "",
    show: false,
  });

  return (
    <ErrorContext.Provider value={state}>
      <ErrorDispatchContext.Provider value={dispatch}>
        <ScadaPlusError />
        <ScadaPlusErrorListener />
        {props.children}
      </ErrorDispatchContext.Provider>
    </ErrorContext.Provider>
  );
};

const ShallowScadaPlusErrorProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(errorReducer, {
    text: "",
    show: false,
  });

  return (
    <ErrorContext.Provider value={state}>
      <ErrorDispatchContext.Provider value={dispatch}>
        <ScadaPlusError />
        {props.children}
      </ErrorDispatchContext.Provider>
    </ErrorContext.Provider>
  );
};

export { ScadaPlusErrorProvider, ShallowScadaPlusErrorProvider };
