import { useLocalDateTimeFormat } from "hooks/useDateTimeFormat";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

const AcknowledgedMessage = styled.div`
  display: flex;
  gap: 5px;
`;
const InformationTitle = styled.span`
  color: ${(props) => props.theme.colors.textColor.quaternary};
  font-size: ${(props) => props.theme.scaledFontSizes.small};
  font-family: ${(props) => props.theme.primaryFontFamily};
  font-weight: normal;
`;

const InformationValue = styled.span`
  color: ${(props) => props.theme.colors.textColor.primary};
  font-size: ${(props) => props.theme.scaledFontSizes.small};
  font-family: ${(props) => props.theme.primaryFontFamily};
`;
type AlarmAcknowledgedProps = {
  acknowledgedAt?: string;
  acknowledgedBy?: string;
};
export const AlarmAcknowledged: React.FC<AlarmAcknowledgedProps> = (props) => {
  const { formatMessage } = useIntl();
  const dateTimeLocal = useLocalDateTimeFormat();
  return (
    <AcknowledgedMessage>
      <InformationTitle>
        {formatMessage({
          id: "alarms.Acknowledged",
          defaultMessage: "Acknowledged",
        })}
      </InformationTitle>
      <InformationValue>
        {dateTimeLocal.format(
          props.acknowledgedAt ? new Date(props.acknowledgedAt) : undefined
        )}{" "}
      </InformationValue>

      <InformationTitle>
        {formatMessage({
          id: "alarms.AcknowledgedBy",
          defaultMessage: "By",
        })}
      </InformationTitle>
      <InformationValue>{props.acknowledgedBy}</InformationValue>
    </AcknowledgedMessage>
  );
};
