import styled from "styled-components";

interface JournalItem {
  id: number;
  date: string;
  userName: string;
  message: string;
}

const ContentContainer = styled.div`
  background-color: ${(props) => props.theme.colors.cardBackgroundHover};
  margin-bottom: 0.1rem;
  padding-left: 0.5rem;
  border-radius: ${(props) => props.theme.borderRadius.medium};
`;

const DateContainer = styled.div``;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface JournalEntryItemProps {
  journalEntry: JournalItem;
}

// toDo: these might need a click event that opens up a modal to read longer messges.
// Or perhaps some other solution is prefered, like being able to open the while journal
// component in a modal.
export const JournalEntryItem: React.FC<JournalEntryItemProps> = (props) => {
  return (
    <ContentContainer>
      <DateContainer>{props.journalEntry.date} </DateContainer>
      <MessageContainer>
        {props.journalEntry.userName}: {props.journalEntry.message}
      </MessageContainer>
    </ContentContainer>
  );
};
