import { Modal, PortalConsumer, Size } from "components/Modal/Modal";
import SingleLineModelSVG from "components/svg/SingleLineModelSVG";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useScadaPlusTheme } from "../../hooks/useScadaPlusTheme";
import SingleLineManagerBody from "./SingleLineManagerBody";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundColor.primary};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  justify-content: center;
  padding: ${(props) => props.theme.spacing.heavy};
  border: 3px solid ${(props) => props.theme.colors.mainColor.tertiary};
`;

type DataModel = {
  sections: Array<Section>;
};

type Section = {
  id: string;
  turbines: Array<Turbine>;
};

type Turbine = {
  id: string;
  name: string;
  errors: number;
  personnel: {
    present: boolean;
    status?: "ok" | "warning";
  };
  breakerBoxes: Array<BreakerBox>;
};

type BreakerBox = { on: boolean };

const temp: DataModel = {
  sections: [
    {
      id: "seksjon",
      turbines: [
        {
          id: "turbine_1_",
          errors: 0,
          name: "T1",
          personnel: { present: true, status: "ok" },
          breakerBoxes: [{ on: true }, { on: true }, { on: false }],
        },
        {
          id: "turbine_2_",
          errors: 10,
          name: "T2",

          personnel: { present: false },
          breakerBoxes: [{ on: true }, { on: true }, { on: true }],
        },
        {
          id: "turbine_3_",
          errors: 0,
          name: "T3",

          personnel: { present: true, status: "warning" },
          breakerBoxes: [{ on: true }, { on: false }, { on: false }],
        },
      ],
    },
  ],
};

export const SingleLine: React.FC = () => {
  const { theme } = useScadaPlusTheme();
  const [dataModel, setDataModel] = useState(temp);
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };

  const drawUnitErrorCounter = (
    element: Element,
    data: Turbine | undefined
  ) => {
    if (element.id.includes("error")) {
      if (data?.errors) {
        for (let i = 0; i < element.children.length; i++) {
          const errorChild = element.children[i];
          if (errorChild.tagName === "text") {
            errorChild.innerHTML = data.errors.toString();
          }
        }
      } else {
        element.setAttribute("display", "none");
      }
    }
  };

  const drawPersonellGraphics = (
    element: Element,
    data: Turbine | undefined
  ) => {
    if (element.id.includes("Personell")) {
      if (data?.personnel.present) {
        for (let i = 0; i < element.children.length; i++) {
          const personellChild = element.children[i];
          personellChild.setAttribute("display", "block");

          if (
            personellChild.id.includes("Green") &&
            data.personnel.status !== "ok"
          ) {
            personellChild.setAttribute("display", "none");
          }

          if (
            personellChild.id.includes("Orange") &&
            data.personnel.status !== "warning"
          ) {
            personellChild.setAttribute("display", "none");
          }
        }
      } else {
        for (let i = 0; i < element.children.length; i++) {
          const personellChild = element.children[i];
          personellChild.setAttribute("display", "none");
        }
      }
    }
  };

  const drawTurbineName = (element: Element, data: Turbine | undefined) => {
    for (let i = 0; i < element.children.length; i++) {
      const turbineChild = element.children[i];
      if (turbineChild.id.includes("name") && data) {
        turbineChild.innerHTML = data.name;
      } else if (turbineChild.id.includes("name") && !data) {
        turbineChild.innerHTML = "";
      }
    }
  };

  useEffect(() => {
    const drawBreakerBoxesStatus = (
      element: Element,
      data: Turbine | undefined
    ) => {
      const boxes: Array<Element> = [];
      const lines: Array<Element> = [];
      const sectionBoxes = document.getElementById("box_5_")?.children;

      for (let i = 0; i < sectionBoxes!.length; i++) {
        const element = sectionBoxes![i];
        if (element.id.includes("Off")) {
          element.setAttribute("display", "none");
        }
      }

      for (let i = 0; i < element.children.length; i++) {
        const turbineChild = element.children[i];

        if (turbineChild.id.includes("box")) {
          boxes.push(turbineChild);
        }

        if (turbineChild.id.includes("lines")) {
          for (let i = 0; i < turbineChild.children.length; i++) {
            const element = turbineChild.children[i];
            lines.push(element);
          }

          if (turbineChild.id.includes("lines")) {
            for (let i = 0; i < turbineChild.children.length; i++) {
              const element = turbineChild.children[i];
              lines.push(element);
            }
          }
        }

        boxes.sort((a, b) => {
          const aNumber = a.id
            .replace(/^\D+/g, "")
            .replace("_", "") as any as number;
          const bNumber = b.id
            .replace(/^\D+/g, "")
            .replace("_", "") as any as number;
          return aNumber > bNumber ? 1 : -1;
        });

        if (data && data.breakerBoxes.length > 0) {
          for (let i = 0; i < boxes.length; i++) {
            const uiBox = boxes[i];
            const dataBox = data.breakerBoxes[i];

            for (let i = 0; i < uiBox.children.length; i++) {
              const element = uiBox.children[i];
              element.setAttribute("style", "display: block");
              element.addEventListener("click", () => setModalOpen(true));
              lines[i + 1].setAttribute(
                "style",
                `stroke: ${theme.colors.mainColor.primary}`
              );

              if (element.id.includes("On") && !dataBox.on) {
                element.setAttribute("style", "display: none");
              }

              if (element.id.includes("Off") && dataBox.on) {
                element.setAttribute("style", "display: none");
              } else if (element.id.includes("Off") && !dataBox.on) {
                lines[i + 1].setAttribute(
                  "style",
                  `stroke-dasharray: 3; stroke: ${theme.colors.signal}`
                );
              }
            }
          }
        }
      }
    };

    if (shouldUpdate) {
      const container = document.querySelector("#singleLineContainer");
      container?.setAttribute("display", "none");
      const section = document.querySelector("#seksjon");

      for (let i = 0; i < section!.children.length; i++) {
        const element = section!.children[i];
        if (element.id.includes("turbine")) {
          const data = dataModel.sections[0].turbines.find(
            (x) => x.id === element.id
          );
          for (let i = 0; i < element.children.length; i++) {
            const turbineChild = element.children[i];
            if (turbineChild.id.includes("unit")) {
              const unit = turbineChild;
              drawTurbineName(unit, data);

              for (let i = 0; i < unit.children.length; i++) {
                const element = unit.children[i];
                drawUnitErrorCounter(element, data);
                drawPersonellGraphics(element, data);
              }
            }
          }
          drawBreakerBoxesStatus(element, data);
        }
      }
      setShouldUpdate(false);
      container?.setAttribute("display", "block");
    }

    ActivateAllOtherBreakers();
  }, [dataModel, shouldUpdate, theme]);

  const handleButtonClick = () => {
    const newState = dataModel;

    for (let i = 0; i < newState.sections[0].turbines.length; i++) {
      const element = newState.sections[0].turbines[i];
      element.errors = Math.floor(Math.random() * 20) + 1;
      element.personnel = {
        present: Math.floor(Math.random() * 2) + 1 > 1 ? true : false,
        status: Math.floor(Math.random() * 2) + 1 > 1 ? "ok" : "warning",
      };
      for (let i = 0; i < element.breakerBoxes.length; i++) {
        const box = element.breakerBoxes[i];
        box.on = Math.floor(Math.random() * 2) + 1 > 1 ? true : false;
      }
    }

    setDataModel(newState);
    setShouldUpdate(true);
  };

  const ActivateAllOtherBreakers = () => {
    const sections = [
      document.querySelector("#seksjon_1_"),
      document.querySelector("#seksjon_2_"),
      document.querySelector("#seksjon_3_"),
    ];

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i]!;
      for (let i = 0; i < section?.children.length; i++) {
        const turbine = section?.children[i];
        for (let i = 0; i < turbine.children.length; i++) {
          const subElement = turbine.children[i];

          if (subElement.id.includes("unit")) {
            for (let i = 0; i < subElement.children.length; i++) {
              const turbineElement = subElement.children[i];
              if (turbineElement.id.includes("Personell")) {
                turbineElement.setAttribute("display", "none");
              }

              if (turbineElement.id.includes("error")) {
                turbineElement.setAttribute("display", "none");
              }
            }
          }

          if (subElement.id.includes("box")) {
            for (let i = 0; i < subElement.children.length; i++) {
              const boxState = subElement.children[i];
              if (boxState.id.includes("Off")) {
                boxState.setAttribute("display", "none");
              }
            }
          }
        }
      }
    }

    const exportLine = document.querySelector("#eksportLinje")!;
    for (let i = 0; i < exportLine.children.length; i++) {
      const element = exportLine.children[i];

      if (element.id.includes("box")) {
        for (let i = 0; i < element.children.length; i++) {
          const boxState = element.children[i];
          boxState.id.includes("Off") &&
            boxState.setAttribute("display", "none");
        }
      }
    }

    const trafo = document.querySelector("#stasjonsTrafo")!;
    for (let i = 0; i < trafo.children.length; i++) {
      const element = trafo.children[i];

      if (element.id.includes("box")) {
        for (let i = 0; i < element.children.length; i++) {
          const boxState = element.children[i];
          boxState.id.includes("Off") &&
            boxState.setAttribute("display", "none");
        }
      }
    }
  };

  return (
    <div>
      <SingleLineModelSVG mainColor={theme.colors.mainColor.primary} />
      {modalOpen && (
        <Modal size={Size.Medium}>
          <Container>
            <SingleLineManagerBody
              onSwitchClosed={() => handleButtonClick()}
              theme={theme}
            />
          </Container>
          <PortalConsumer>
            {(value) => {
              value.onClose = handleClose;
              return <span></span>; //TODO:Had to be added due to react complaints. Use Hook - context?
            }}
          </PortalConsumer>
        </Modal>
      )}
    </div>
  );
};
