import InfoCard from "components/InfoCard";
import { Size } from "components/Modal/Modal";
import ProgressBar from "components/ProgressBar";
import RealTimeLineChart from "components/RealTimeLineChart";
import { useGetCapacity } from "hooks/useGetCapacity";
import { useGetLastSampleForTag } from "hooks/useGetLastSample";
import { useTagsContext } from "hooks/useTags";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { toMegaWatt } from "utils/production-utils";
import { MeasurementUnitSymbolConstants } from "utils/utils";

type CurrentProductionProps = {
  plantId?: string | undefined;
  sectionId?: string | undefined;
};

const Content = styled.div`
  height: 100%;
`;

const TestArea = styled.div`
  height: 80%;
`;

const ProgressBarArea = styled.div`
  display: grid;
  grid-template-columns: 8fr 1fr;
  gap: ${(props) => props.theme.spacing.heavy};
  padding-left: ${(props) => props.theme.spacing.heavy};
  padding-right: ${(props) => props.theme.spacing.heavy};
`;
const ProductionProgressBar = styled(ProgressBar)`
  align-self: center;
`;

const ProductionPercentage = styled.div`
  align-self: center;
  font-size: ${(props) => props.theme.scaledFontSizes.mediumLarge};
`;

export const CurrentProductionCard: React.FC<CurrentProductionProps> = (
  props
) => {
  const { tags } = useTagsContext();
  const { formatMessage } = useIntl();
  const [capacity, setCapacity] = useState<number | undefined>(undefined);
  const refreshInterval = 10 * 1000;

  const { data: lastSample } = useGetLastSampleForTag(
    tags.WTURW,
    { plantId: props.plantId, sectionId: props.sectionId },
    refreshInterval
  );

  const { data: resourceCapacity } = useGetCapacity(
    { sectionId: props.sectionId },
    refreshInterval * 3
  );

  useEffect(() => {
    if (lastSample && resourceCapacity) {
      setCapacity(
        (toMegaWatt(Number(lastSample!.value)) /
          toMegaWatt(Number(resourceCapacity.capacity.value))) *
          100
      );
    }
  }, [lastSample, resourceCapacity]);

  return (
    <InfoCard
      displayNumber={true}
      keyNumber={toMegaWatt(Number(lastSample?.value))}
      keyNumberPrecision={1}
      title={formatMessage({
        id: "overview.current",
        defaultMessage: "Current (MW)",
      })}
      style={{ height: "100%" }}
      measurementType={MeasurementUnitSymbolConstants.megaWatt}
    >
      <Content>
        <ProgressBarArea>
          <ProductionProgressBar progress={capacity ?? 0} size={Size.Small} />
          {capacity && (
            <ProductionPercentage>{capacity.toFixed(1)}%</ProductionPercentage>
          )}
        </ProgressBarArea>
        <TestArea>
          <RealTimeLineChart
            data={
              lastSample
                ? {
                    time: new Date(lastSample.timestamp),
                    value: toMegaWatt(Number(lastSample!.value)),
                  }
                : null
            }
            min={0}
            max={
              resourceCapacity
                ? toMegaWatt(resourceCapacity.capacity.value)
                : 80
            }
            duration={3000}
          />
        </TestArea>
      </Content>
    </InfoCard>
  );
};
