import config from "../config";
import { ComponentUnit } from "../types";
import { useScadaPlusQuery } from "./useScadaPlusQuery";

export const useGetComponentUnits = (plantId: string, sectionId?: string) => {
  const constructQueryUrl = () => {
    if (sectionId) {
      return `${config.api.url}/${config.api.componentUnit}?sectionId=${sectionId}`;
    }
    return `${config.api.url}/${config.api.componentUnit}?plantId=${plantId}`;
  };

  return useScadaPlusQuery<Array<ComponentUnit>>(
    `componentUnits-${plantId}-${sectionId}`,
    constructQueryUrl()
  );
};

export const useGetComponentUnit = (componentUnitId: string) => {
  const constructQueryUrl = () => {
    return `${config.api.url}/${config.api.componentUnit}?componentUnitId=${componentUnitId}`;
  };

  return useScadaPlusQuery<Array<ComponentUnit>>(
    `componentUnit-${componentUnitId}`,
    constructQueryUrl()
  );
};
