import { UseQueryResult } from "react-query";
import config from "config";
import { IdCollection, Measurement } from "types";
import { useScadaPlusQuery } from "hooks/useScadaPlusQuery";

export type Capacity = {
  capacity: Measurement;
};

const baseUrl = `${config.api.url}/${config.api.capacity}`;

export const useGetCapacity = (
  ids: IdCollection,
  refreshInterval?: number
): UseQueryResult<Capacity> => {
  return useScadaPlusQuery<Capacity>(
    `capacity-${ids.plantId}`,
    createUrlWithParams(ids),
    refreshInterval
  );
};

const createUrlWithParams = (ids: IdCollection) => {
  if (ids.sectionId) {
    return `${baseUrl}?SectionId=${ids.sectionId}`;
  }
  if (ids.plantId) {
    return `${baseUrl}?PlantId=${ids.plantId}`;
  } else {
    return `${baseUrl}?AllPlants=true`;
  }
};
