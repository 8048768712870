import React from "react";
import { useIntl } from "react-intl";
import InfoCard from "../../../../components/InfoCard";
import { useSensorSubscription } from "../../../../hooks/useSubscription";
import { useTagsContext } from "../../../../hooks/useTags";
import TemperaturesData from "./TemperaturesData";
import styled from "styled-components";

const TemperaturesContainer = styled.div`
  padding-top: ${(props) => props.theme.spacing.light};
  height: 85%;
`;

type TemperaturesCardProps = {
  componentUnitId: string;
  onClick?: () => void;
};

export const TemperaturesCard: React.FC<TemperaturesCardProps> = (props) => {
  const { tags } = useTagsContext();
  const { formatMessage } = useIntl();

  const { sensorData: tower } = useSensorSubscription({
    filters: [
      {
        id: tags.WNAClntnTmp,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WTRMGbxOilTmp,
        windTurbineId: props.componentUnitId,
      },
      {
        id: tags.WMETEnvTmp,
        windTurbineId: props.componentUnitId,
      },
    ],
  });

  return (
    <InfoCard
      onClick={props.onClick}
      title={formatMessage({
        id: "pu.Temperatures",
        defaultMessage: "Missing text",
      })}
      style={{ height: "100%" }}
    >
      <TemperaturesContainer>
        <TemperaturesData
          sensorData={tower}
          nacelleTagName={tags.WNAClntnTmp}
          gearOilTagName={tags.WTRMGbxOilTmp}
          outdoorTagname={tags.WMETEnvTmp}
        />
      </TemperaturesContainer>
    </InfoCard>
  );
};
