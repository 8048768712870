import { useMsal } from "@azure/msal-react";
import Modal from "components/Modal";
import { Size, Background, PortalConsumer } from "components/Modal/Modal";
import Wendy from "components/Wendy";
import { WendyHeadStyles } from "components/Wendy/Wendy";
import React from "react";
import { MdClose } from "react-icons/md";
import { useIntl } from "react-intl";
import styled from "styled-components";
import Button from "../../components/Button";
import { usePermissions } from "../../hooks/usePermissions";
import { useScadaPlusTheme } from "../../hooks/useScadaPlusTheme";
import AccountInfo from "./AccountInfo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundColor.primary};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  justify-content: center;
  padding: ${(props) => props.theme.spacing.heavy};
  border: 3px solid ${(props) => props.theme.colors.mainColor.tertiary};
`;

const Title = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: ${(props) => props.theme.scaledFontSizes.large};
  margin-bottom: ${(props) => props.theme.spacing.light};
`;

const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubTitle = styled.p`
  margin-bottom: ${(props) => props.theme.spacing.heavy};
  font-size: ${(props) => props.theme.scaledFontSizes.small};
`;

const MainContent = styled.div`
  font-size: ${(props) => props.theme.scaledFontSizes.small};
`;

type UserProps = {
  onClose: () => void;
};

export const User: React.FC<UserProps> = (props) => {
  const { formatMessage } = useIntl();
  const { theme } = useScadaPlusTheme();
  const account = usePermissions();
  const { instance } = useMsal();

  return (
    <Modal size={Size.Medium} background={Background.Dark}>
      <Container data-testid="usermodaltestId">
        <TitleArea>
          <Title>
            <Wendy size={35} HeadStyle={WendyHeadStyles.Happy} />
            {formatMessage({
              id: "general.signedInAsUser",
              defaultMessage: "Translation Not Found",
            })}{" "}
            {account.user.firstName} {account.user.lastName}
          </Title>
          <PortalConsumer>
            {(value) => (
              <Button
                style={{
                  borderRadius: "50%",
                  height: "35.19px",
                }}
                onclick={() => value.close!()}
                size="xs"
                type="primary"
                icon={<MdClose fill={theme.colors.iconColor} size={18} />}
              />
            )}
          </PortalConsumer>
        </TitleArea>
        <SubTitle>
          {" "}
          {formatMessage({
            id: "about.account.permissionTitle",
            defaultMessage: "Translation Not Found",
          })}
        </SubTitle>
        <MainContent>
          <AccountInfo />
          <Button
            style={{ margin: theme.spacing.light }}
            size="medium"
            type="primary"
            onclick={() => {
              instance.logoutRedirect();
            }}
          >
            {formatMessage({
              id: "general.signOut",
              defaultMessage: "Translation Not Found",
            })}
          </Button>
        </MainContent>
      </Container>

      <PortalConsumer>
        {(value) => {
          value.onClose = props.onClose;
          return <span></span>; //TODO:Had to be added due to react complaints. Use Hook - context?
        }}
      </PortalConsumer>
    </Modal>
  );
};
