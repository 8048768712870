import { useIntl } from "react-intl";

export const useLocalDateTimeFormat = (): Intl.DateTimeFormat => {
  const { formatMessage } = useIntl();
  // alarmItem should have time style short

  const dateTimeLocal = new Intl.DateTimeFormat(
    formatMessage({
      id: "general.localeCode",
      defaultMessage: "en-GB",
    }),
    {
      dateStyle: "short",
      timeStyle: "medium",
    }
  );

  return dateTimeLocal;
};

export const useLocalDateTimeFormatShort = (): Intl.DateTimeFormat => {
  const { formatMessage } = useIntl();

  const dateTimeLocal = new Intl.DateTimeFormat(
    formatMessage({
      id: "general.localeCode",
      defaultMessage: "en-GB",
    }),
    {
      dateStyle: "short",
      timeStyle: "short",
    }
  );

  return dateTimeLocal;
};

export const useLocalDateFormat = (): Intl.DateTimeFormat => {
  const { formatMessage } = useIntl();

  const dateTimeLocal = new Intl.DateTimeFormat(
    formatMessage({
      id: "general.localeCode",
      defaultMessage: "en-GB",
    }),
    {
      dateStyle: "short",
      timeStyle: undefined,
    }
  );

  return dateTimeLocal;
};

export const useUTCDateTimeFormat = (): Intl.DateTimeFormat => {
  const { formatMessage } = useIntl();

  const dateTimeUTC = new Intl.DateTimeFormat(
    formatMessage({
      id: "general.localeCode",
      defaultMessage: "en-GB",
    }),
    {
      dateStyle: "short",
      timeStyle: "medium",
      timeZone: "utc",
    }
  );
  return dateTimeUTC;
};
