import React, { useEffect, useState } from "react";
import {
  SensorData,
  useSensorSubscription,
  SensorSubscriptionFilter,
} from "../../hooks/useSubscription";
import styled from "styled-components";
import InfoCard from "../InfoCard";
import { useIntl } from "react-intl";
import NumberValueFormatter from "../NumberValueFormatter";
import CardLabel from "../CardLabel";
import { useTagsContext } from "../../hooks/useTags";
import { useTheme } from "styled-components";
import WindAnimationMobileSVG from "components/svg/WindAnimationMobileSVG";

type WeatherProps = {
  plantId?: string;
  componentUnitId?: string;
};

const BackgroundContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5em;
  padding: ${(props) => props.theme.spacing.extraLight};
`;

const WeatherContainer1 = styled.div`
  padding-top: 10%;
  grid-column: 1/2;
  display: grid;
  grid-template-rows: 2;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  padding-bottom: 8%;
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundActive.primary};
  border-radius: ${(props) => props.theme.borderRadius.medium};
`;

const WeatherContainer2 = styled.div`
  display: grid;
  grid-template-rows: 2;
  padding-top: 10%;
  grid-column: 2/3;
  justify-content: center;
  padding-bottom: 10%;
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundActive.primary};
  border-radius: ${(props) => props.theme.borderRadius.medium};
`;

const WindSpeedValue = styled.div`
  grid-row: 1;
  grid-column: 2;
  display: inline;
  align-items: center;
`;

const WindDirection = styled.div`
  grid-row: 1;
  grid-column: 3;
  display: inline;
  align-items: top;
`;

const WinSpeedText = styled.div`
  grid-column: 2 /3;
  grid-row: 2;
  text-align: center;
  white-space: nowrap;
`;

const WavesValue = styled.div`
  grid-row: 1;
  justify-content: center;
`;

const WavesText = styled.div`
  padding-top: 3%;
  grid-row: 2;
  text-align: center;
  white-space: nowrap;
`;

export const WeatherMobile: React.FC<WeatherProps> = (props) => {
  const { tags } = useTagsContext();
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const [windSpeed, setWindSpeed] = useState<SensorData | null>(null);
  const [waveSigHeight, setwaveSigHeight] = useState<SensorData | null>(null);
  const [wdir, setWdir] = useState<SensorData | null>(null);

  const filters: Array<SensorSubscriptionFilter> = props.plantId
    ? [
        {
          id: tags.WMETEnvPres,
          windPowerPlantId: props.plantId,
        },
        {
          id: tags.WMETHorWdSpd,
          windPowerPlantId: props.plantId,
        },
        {
          id: tags.WMETWaveHeigth,
          windPowerPlantId: props.plantId,
        },
        {
          id: tags.WMETWaveHeigthAvg,
          windPowerPlantId: props.plantId,
        },
        {
          id: tags.WMETWaveSigHeight,
          windPowerPlantId: props.plantId,
        },
        {
          id: tags.WYAWYwAng,
          windPowerPlantId: props.componentUnitId,
        },
      ]
    : [
        {
          id: tags.WMETEnvPres,
          windPowerPlantId: props.componentUnitId,
        },
        {
          id: tags.WMETHorWdSpd,
          windPowerPlantId: props.componentUnitId,
        },
        {
          id: tags.WMETWaveHeigth,
          windPowerPlantId: props.componentUnitId,
        },
        {
          id: tags.WMETWaveHeigthAvg,
          windPowerPlantId: props.componentUnitId,
        },
        {
          id: tags.WMETWaveSigHeight,
          windPowerPlantId: props.componentUnitId,
        },
        {
          id: tags.WYAWYwAng,
          windPowerPlantId: props.componentUnitId,
        },
      ];

  const { sensorData: sensorData } = useSensorSubscription({
    filters: filters,
  });

  useEffect(() => {
    if (tags) {
      switch (sensorData?.id) {
        case tags.WMETHorWdSpd:
          setWindSpeed(sensorData);
          break;
        case tags.WMETWaveSigHeight:
          setwaveSigHeight(sensorData);
          break;
        case tags.WYAWYwAng:
          setWdir(sensorData);
          break;
        default:
          break;
      }
    }
  }, [sensorData, tags, waveSigHeight?.value, wdir]);

  return (
    <InfoCard
      title={formatMessage({
        id: "component.Weather",
        defaultMessage: "Weather",
      })}
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <BackgroundContainer>
        <WeatherContainer1>
          <WindSpeedValue>
            <NumberValueFormatter
              align={"center"}
              value={windSpeed ? Number(windSpeed.value) : Number(0)}
              precision={1}
            />
          </WindSpeedValue>
          <WindDirection>
            <WindAnimationMobileSVG
              angle={Number(wdir ? wdir?.value : 0) - 90.0}
              width={"30"}
              height={"30"}
              objectFill={theme.colors.mainColor.primary}
              r={0}
            />
          </WindDirection>
          <WinSpeedText>
            <CardLabel
              style={{
                alignContent: "center",
              }}
            >
              {formatMessage({
                id: "component.Weather.Wind",
                defaultMessage: "Text missing",
              })}
            </CardLabel>
          </WinSpeedText>
        </WeatherContainer1>
        <WeatherContainer2>
          <WavesValue>
            <NumberValueFormatter
              align={"center"}
              value={waveSigHeight ? Number(waveSigHeight.value) : Number(0)}
              precision={1}
            />
          </WavesValue>
          <WavesText>
            <CardLabel>
              {formatMessage({
                id: "component.Weather.WavesMobile",
                defaultMessage: "Missing text",
              })}
            </CardLabel>
          </WavesText>
        </WeatherContainer2>
      </BackgroundContainer>
    </InfoCard>
  );
};
