import React from "react";

interface IComponentProps {
  width: number;
  height: number;
  color?: string;
  style?: React.CSSProperties;
}

export const BodyPower: React.FC<IComponentProps> = (props) => {
  return (
    <svg
      data-testid="bodypower"
      width={props.width}
      height={props.height}
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8092 0.0454559C18.6312 0.0454559 18.4531 0.0454559 18.2751 0.0454559H7.72498C7.54692 0.0454559 7.36885 0.0454559 7.19079 0.0454559C5.05407 0.227274 3.05089 1.36364 1.75995 3.18182C0.424499 5.09091 -0.0206516 7.54546 0.60256 9.81818L3.40701 20.4545L3.45153 20.5455C5.05407 24.9091 6.56758 28 11.0191 28H15.0255C19.5215 28 20.9905 24.9091 22.593 20.5455L25.3975 9.86364C26.0207 7.59091 25.5755 5.18182 24.2401 3.22727C22.9491 1.36364 20.946 0.227274 18.8092 0.0454559Z"
        fill="url(#paint0_radial)"
      />
      <path
        d="M17.2512 12.5454L12.9332 20.9545C12.5326 21.7273 11.4197 21.4091 11.4197 20.5454V14.6364H9.50557C8.88236 14.6364 8.48172 13.9545 8.79333 13.4091L13.1113 4.99999C13.5119 4.22727 14.6248 4.54545 14.6248 5.40909V11.2727H16.539C17.1177 11.2727 17.5183 11.9545 17.2512 12.5454Z"
        fill="#FF762E"
      />
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13 11.8724) rotate(90) scale(16.1276 14.6247)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EBEBEB" />
        </radialGradient>
      </defs>
    </svg>
  );
};
