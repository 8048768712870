import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import React, { useEffect, useState } from "react";

type ProductionAlarmCounterSVGProps = {
  width: string;
  height: string;
  count?: number;
};

export const ProductionAlarmCounterSVG: React.FC<
  ProductionAlarmCounterSVGProps
> = ({ width, height, count }) => {
  const { theme } = useScadaPlusTheme();
  const [xValue, setXvalue] = useState(10);
  const [yValue, setYvalue] = useState(19);
  const [fontSize, setFontSize] = useState("1em");

  useEffect(() => {
    if (count !== undefined) {
      const digits = count.toString().length;
      if (digits === 1) {
        setXvalue(11);
        setFontSize(theme.constantFontSizes.small);
      }

      if (digits === 2) {
        setXvalue(9);
        setFontSize(theme.constantFontSizes.medium);
      }

      if (digits === 3) {
        setXvalue(7);
        setYvalue(39);
        setFontSize(theme.constantFontSizes.medium);
      }
    }
  }, [count, theme]);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.9286 14.9361C25.8368 17.3577 24.9764 19.6704 23.4777 21.5226L23.4172 21.0443L22.5699 21.1387L22.7972 23.0603L24.7109 22.8436L24.6118 21.9929L24.1502 22.0422C25.7617 20.0459 26.6885 17.5765 26.783 14.956C26.8854 11.9756 25.8787 9.0434 23.9442 6.7455C23.1463 5.80479 22.2184 4.97857 21.18 4.33455L20.7297 5.04854C21.6897 5.65881 22.5517 6.4047 23.2922 7.27988C25.1032 9.43191 26.0197 12.1399 25.9286 14.9361Z"
        fill={theme.colors.attention}
      />
      <path
        d="M12.7381 25.4333C10.2391 24.9417 7.96171 23.6117 6.31449 21.6414C5.55686 20.7366 4.93771 19.7321 4.5121 18.6554L4.89742 18.8474L5.28417 18.0912L3.57534 17.2027L2.69994 18.9121L3.45589 19.3047L3.68671 18.8556C3.90379 19.4014 4.16498 19.9217 4.45557 20.425C4.81455 21.0468 5.23233 21.6346 5.68567 22.1821C7.45632 24.2982 9.91596 25.7402 12.6071 26.259C15.2603 26.7799 18.0227 26.3694 20.3909 25.1403L19.9929 24.3831C17.7656 25.5506 15.1906 25.9122 12.7381 25.4333Z"
        fill={theme.colors.attention}
      />
      <path
        d="M6.30325 7.25877C7.95755 5.33648 10.245 4.03554 12.754 3.59363C13.9063 3.38236 15.0705 3.36156 16.2234 3.52493L15.8644 3.75195L16.3212 4.47516L17.9313 3.42712L16.9005 1.81154L16.1825 2.26558L16.4536 2.70121C15.1844 2.50626 13.8831 2.52726 12.6023 2.75355C9.89735 3.22962 7.44198 4.64723 5.67485 6.71362C3.91387 8.75671 2.9037 11.3532 2.79071 14.0437L3.63899 14.0869C3.72599 11.5891 4.67836 9.16408 6.30325 7.25877Z"
        fill={theme.colors.attention}
      />
      <text
        x={xValue}
        y={yValue}
        style={{ fontSize: fontSize }}
        fill={theme.colors.attention}
      >
        {count}
      </text>
    </svg>
  );
};
