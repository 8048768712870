import React from "react";
import { useTheme } from "styled-components";

interface IComponentProps {
  width: number;
  height: number;
  color?: string;
  style?: React.CSSProperties;
}

export const DisconnectedSVG: React.FC<IComponentProps> = (props) => {
  const theme = useTheme();

  return (
    <svg
      width={props.width.toString()}
      height={props.height.toString()}
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5857 12.2513L11.1988 13.6382L9.81182 12.2513L11.1988 10.8643C11.5455 10.5176 11.5455 9.82413 11.1988 9.4774C10.852 9.13066 10.1586 9.13066 9.81182 9.4774L8.42488 10.7488L7.61584 9.82413C6.92237 9.24624 5.99775 9.24624 5.41986 9.82413L3.68619 11.5578C1.72136 13.5226 1.49021 16.6432 3.1083 18.8392L0.681162 21.2663C0.334429 21.6131 0.334429 22.3065 0.681162 22.6533C1.0279 23 1.72136 23 2.0681 22.6533L4.49523 20.2261C6.69121 21.8442 9.81182 21.6131 11.7766 19.6483L13.5103 17.9146C14.0882 17.3367 14.0882 16.4121 13.5103 15.8342L12.5857 14.9096L13.9726 13.5226C14.3194 13.1759 14.3194 12.4824 13.9726 12.1357C13.6259 11.789 13.048 11.789 12.5857 12.2513Z"
        fill={props.color ? props.color : theme.colors.mainColor.primary}
      />
      <path
        d="M23.1033 0.231181C22.7565 -0.115553 22.0631 -0.115553 21.7163 0.231181L19.2892 2.65832C17.0932 1.04023 13.9726 1.27138 12.0078 3.23621L10.2741 4.96987C9.69623 5.54776 9.69623 6.47239 10.2741 7.16585L16.2842 13.1759C16.8621 13.7538 17.7867 13.7538 18.3646 13.1759L20.0982 11.3267C22.0631 9.36183 22.2942 6.24123 20.6761 4.04525L23.1033 1.61812C23.45 1.27138 23.45 0.693492 23.1033 0.231181Z"
        fill={props.color ? props.color : theme.colors.mainColor.primary}
      />
    </svg>
  );
};
