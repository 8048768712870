import React from "react";
import styled, { DefaultTheme, useTheme } from "styled-components";
import {
  resolveAlarmPriorityTileColor,
  resolveAlarmListPriorityTileColor,
  resolveAlarmPriorityDisplayTileFontColor,
} from "utils/alarm-utils";

const Container = styled.div`
  display: flex;
  width: ${(props: AlarmPriorityDisplayStyleProps) =>
    props.width ? props.width : "1em"};
  height: ${(props: AlarmPriorityDisplayStyleProps) =>
    props.height ? props.height : "1.5em"};
  background-color: ${(props: AlarmPriorityDisplayStyleProps) =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.signal};
  color: ${(props: AlarmPriorityDisplayStyleProps) =>
    props.fontColor ? props.fontColor : props.theme.colors.signal};
  font-size: ${(props) => props.theme.scaledFontSizes.small};

  border-radius: ${(props) => props.theme.borderRadius.light};
  justify-content: center;
  align-items: center;
`;

type AlarmPriorityDisplayStyleProps = {
  theme: DefaultTheme;
  backgroundColor?: string;
  fontColor?: string;
  height?: string;
  width?: string;
  fontSize?: string;
  alarmPriortity?: number;
  acknowledged?: boolean;
};

interface AlarmPriorityDisplayProps {
  height?: string;
  width?: string;
  alarmPriortity?: number;
  acknowledged?: boolean;
  listItem?: boolean;
}

export const AlarmPriorityDisplayTile: React.FC<AlarmPriorityDisplayProps> = (
  props
) => {
  const theme = useTheme();

  const alarmTileBackground = (): string | undefined => {
    let color;
    if (props.alarmPriortity && !props.listItem) {
      color = resolveAlarmPriorityTileColor(props.alarmPriortity, theme);
    } else if (props.alarmPriortity) {
      color = resolveAlarmListPriorityTileColor(
        props.alarmPriortity,
        theme,
        props.acknowledged
      );
    } else {
      color = "undefined";
    }
    return color;
  };

  const alarmTileFontColor = (): string | undefined => {
    let color;
    if (props.alarmPriortity && props.acknowledged) {
      color = resolveAlarmPriorityDisplayTileFontColor(
        props.alarmPriortity,
        theme,
        props.acknowledged
      );
    } else if (props.alarmPriortity) {
      color = resolveAlarmPriorityDisplayTileFontColor(
        props.alarmPriortity,
        theme
      );
    }
    return color;
  };

  return (
    <Container
      width={props.width}
      height={props.height}
      theme={theme}
      fontColor={alarmTileFontColor()}
      backgroundColor={alarmTileBackground()}
    >
      {props.children}
    </Container>
  );
};
