import HomeButton from "components/HomeButton";
import Loader from "components/Loader";
import React from "react";
import styled from "styled-components";

const LoadingLayout = styled.div`
  height: 100vh;
  display: grid;
  place-items: center;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
`;

const LoadingPage: React.FunctionComponent = () => (
  <LoadingLayout>
    <div>
      <Loader />
      <HomeButton />
    </div>
  </LoadingLayout>
);

export default LoadingPage;
