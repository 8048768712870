import React from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import axios from 'axios';
import { loginRequest } from "../config";

interface RequestInterceptorProps {
    children: JSX.Element,
}

export const RequestInterceptor: React.FC<RequestInterceptorProps> = ({ children }: RequestInterceptorProps) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  
  /* eslint-disable no-param-reassign */
  axios.interceptors.request.use(async (config) => {

    if (!account) {
      throw Error('No active account! Verify a user has been signed in.');
    }

    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account,
    });

    const bearer = `Bearer ${response.accessToken}`;
    if (config.headers === undefined) {
      config.headers = {};
    }
    config.headers.Authorization = bearer;

    return config;
  });
  /* eslint-enable no-param-reassign */

  return (
    <>
      {children}
    </>
  );
};

