import React from "react";
import styled, { keyframes } from "styled-components";

const animation = keyframes`  
0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
   `;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const GraphicContainer = styled.div`
  width: ${(p: GraphicContainerProps) =>
    p.size !== undefined ? p.size : 40}px;
  height: ${(p: GraphicContainerProps) =>
    p.size !== undefined ? p.size : 40}px;
  position: relative;
`;

const ChildContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: ${(props) => props.theme.spacing.medium};
`;

const Bouncer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.mainColor.primary};
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${animation} 3s infinite ease-in-out;
`;

const Bouncer2 = styled(Bouncer)`
  animation-delay: -1s;
`;

interface LoaderProps {
  children?: React.ReactNode;
  size?: number;
  style?: React.CSSProperties;
}

interface GraphicContainerProps {
  size?: number;
}

export const Loader: React.FC<LoaderProps> = (props) => {
  return (
    <Container style={props.style}>
      <GraphicContainer data-testid="loadinggraphictest" size={props.size}>
        <Bouncer />
        <Bouncer2 />
      </GraphicContainer>
      {props.children && <ChildContainer>{props.children}</ChildContainer>}
    </Container>
  );
};
