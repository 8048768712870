import { GenericMenu } from "../types";
import config from "../config";
import { useScadaPlusQuery } from "./useScadaPlusQuery";

export const useGetMenu = () => {
  return useScadaPlusQuery<GenericMenu>(
    `menu`,
    `${config.api.url}/${config.api.menu}`
  );
};
