import Button from "components/Button";
import { StandardButton } from "components/Button/Button";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import styled, { useTheme } from "styled-components";

type TextBoxProps = {
  onSubmit: (text: string) => void;
  onCancel: () => void;
  value?: string | undefined;
};

const Container = styled.div`
  display: grid;
  grid-template-rows: 2fr 1fr;
  grid-template-columns: 1fr 1fr;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor};
`;

const InputField = styled.textarea`
  grid-column: 1/-1;
  background-color: ${(props) =>
    props.theme.colors.buttonColors.primaryButtonTextColor.primary};
  display: grid;
  border: none;
  font-style: inherit;
  resize: none;
  &:focus {
    outline: none;
  }
  /* prettier-ignore */
  border-radius: 0 0 ${(props) => props.theme.borderRadius.medium} ${(props) =>
    props.theme.borderRadius.medium};
  padding: 5px;
`;

const Buttons = styled.div`
  display: flex;
  grid-column: 2/3;
  flex-direction: row;
  justify-content: flex-end;
`;

const SaveInput = styled.div`
  padding: 10px;
`;

const Cancel = styled.div`
  padding: 10px;
  & div {
    color: ${(props) =>
      props.theme.colors.buttonColors.primaryButtonTextColor.primary};
  }
`;

export const TextAreaWithButtons: React.FC<TextBoxProps> = (props) => {
  const [text, setText] = useState<string>("");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const theme = useTheme();
  const { formatMessage } = useIntl();

  useEffect(() => {
    setText(props.value ?? "");
  }, [props.value]);

  useEffect(() => {
    textAreaRef.current?.focus();
  }, [textAreaRef]);

  return (
    <Container>
      <InputField
        ref={textAreaRef}
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
      />
      <Buttons>
        <Cancel>
          <StandardButton
            color={theme.colors.buttonColors.primaryButtonColor}
            size="small"
            onclick={() => props.onCancel()}
          >
            {formatMessage({ id: "general.Cancel", defaultMessage: "Cancel" })}
          </StandardButton>
        </Cancel>
        <SaveInput>
          <Button
            type="primary"
            size="small"
            onclick={() => props.onSubmit(text)}
          >
            {formatMessage({ id: "general.Save", defaultMessage: "Save" })}
          </Button>
        </SaveInput>
      </Buttons>
    </Container>
  );
};
