import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import { SensorData } from "hooks/useSubscription";
import { useTagsContext } from "hooks/useTags";
export const useGetBladeColor = (unitOperationCode: SensorData | null) => {
  const { theme } = useScadaPlusTheme();
  const {
    tags: { WTUROpCde },
  } = useTagsContext();
  if (unitOperationCode?.id && WTUROpCde) {
    return unitOperationCode.value === "Normal"
      ? theme.colors.mainColor.primary
      : theme.colors.warning;
  } else {
    return theme.colors.gray.light.primary;
  }
};

