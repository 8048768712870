import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useQueryClient } from "react-query";
import styled, { useTheme } from "styled-components";
import config from "config";
import { SubscribeToAlarm, useAlarmSubscription } from "hooks/useSubscription";
import { GenericAlarm, GenericAlarmPriorityCounter } from "../../../types";
import MaintenanceAlarm from "../MaintenanceAlarm";
import { getTotalNumberOfAlarms } from "utils/alarm-utils";
import {
  getAlarmPrioritiesQueryName,
  getAlarmsQueryName,
} from "utils/query-invalidation-utils";
import AlarmPriorityHeaders from "components/AlarmPriorityHeaders";
import { useScadaPlusQuery } from "hooks/useScadaPlusQuery";
import AlarmList from "./AlarmList";
import { useGetAlarms } from "hooks/useGetAlarms";
import ListedAlarmsMessage from "components/ListedAlarmsMessage";

const AlarmTypeCountersArea = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 3fr;
  grid-row: 1 / 1;
  width: 100%;
`;

const Alarms = styled.div`
  grid-row: 2/3;
  grid-column: 1/2;
`;

const MaintenanceAlarmsContainer = styled.div`
  grid-row: 2/3;
  grid-column: 2/-1;
  padding: ${(props) => props.theme.spacing.heavy};
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundColor.primary};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  overflow-y: scroll;
`;

const MaintenanceAlarmsHeader = styled.div`
  justify-content: space-between;
  margin-bottom: 8px;
  display: flex;
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundColor.primary};
`;

const MaintenanceAlarmsScrollContainer = styled.div`
  margin-top: 1em;
`;

const MaintenanceAlarmsTitle = styled.div`
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
  color: ${(props) => props.theme.colors.textColor.tertiary};
`;

const MaintenanceAlarmsTitleNumber = styled.div`
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
  color: ${(props) => props.theme.colors.textColor.tertiary};
  font-weight: 700;
`;

type AlarmListProps = {
  plantId: string;
  sectionId: string | undefined;
  componentUnitId: string | undefined;
};

export const AlarmListContainer: React.FC<AlarmListProps> = (props) => {
  const pageSize = 60;
  const maintenancePriority = 4;
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const filterActiveAlarms = (alarm: GenericAlarm) => {
    return alarm.isActive;
  };

  const createFilter = (): SubscribeToAlarm => {
    if (props.sectionId) {
      return {
        filter: {
          sectorId: props.sectionId,
        },
      };
    }

    if (props.componentUnitId) {
      return {
        filter: {
          windTurbineId: props.componentUnitId,
        },
      };
    }

    return {
      filter: {
        windPowerPlantId: props.plantId,
      },
    };
  };

  const [maintenanceAlarms, setMaintenanceAlarms] = useState<
    Array<GenericAlarm>
  >([]);

  const alarmPrioritiesQueryName = getAlarmPrioritiesQueryName([
    props.plantId,
    props.sectionId,
  ]);

  const alarmsQueryName = getAlarmsQueryName([props.plantId, props.sectionId]);

  const { data: alarmData } = useAlarmSubscription(createFilter());
  const { data: alarms, isFetching } = useGetAlarms(
    {
      plantId: props.plantId,
      sectionId: props.sectionId,
      componentUnitId: props.componentUnitId,
    },
    { pageSize: pageSize, refreshInterval: 1000 * 3 }
  );

  const activeAlarms = alarms?.filter(filterActiveAlarms);

  const { data: alarmPriorityData } = useScadaPlusQuery<
    Array<GenericAlarmPriorityCounter>
  >(
    alarmPrioritiesQueryName,
    () => {
      const alarmCounterUrl = `${config.api.url}/${config.api.genericAlarmPriorityCounters}`;
      if (props.sectionId) {
        return `${alarmCounterUrl}?SectionId=${props.sectionId}`;
      }
      return `${alarmCounterUrl}?PlantId=${props.plantId}`;
    },
    1000 * 3
  );

  useEffect(() => {
    if (alarmData) {
      queryClient.invalidateQueries(alarmsQueryName);
      queryClient.invalidateQueries(alarmPrioritiesQueryName);
    }
  }, [
    alarmData,
    queryClient,
    props.plantId,
    props.sectionId,
    props.componentUnitId,
    alarmsQueryName,
    alarmPrioritiesQueryName,
  ]);

  useEffect(() => {
    if (alarms && alarms.length > 0) {
      const maintenanceAlarms = alarms.filter(
        (alarm: GenericAlarm) => alarm.priority === maintenancePriority
      );

      setMaintenanceAlarms(maintenanceAlarms);
    }
  }, [alarms, isFetching, maintenancePriority]);

  return (
    <React.Fragment>
      <AlarmTypeCountersArea className="AlarmTypeCountersArea">
        {alarmPriorityData && (
          <AlarmPriorityHeaders alarmPriorityData={alarmPriorityData} />
        )}
        <ListedAlarmsMessage
          activeAlarms={activeAlarms}
          alarmPriorityData={alarmPriorityData}
          containerHeight="3.2rem"
          fontSize={theme.scaledFontSizes.medium}
          textAlign="left"
          alignSelf="flex-end"
          padding="0.5rem"
        />
      </AlarmTypeCountersArea>
      <Alarms>{alarms && <AlarmList data={alarms} />}</Alarms>
      <MaintenanceAlarmsContainer>
        <MaintenanceAlarmsHeader>
          <MaintenanceAlarmsTitle>
            {formatMessage({
              id: "alarms.Maintenance",
              defaultMessage: "Maintenance",
            })}
          </MaintenanceAlarmsTitle>
          <MaintenanceAlarmsTitleNumber>
            {alarmPriorityData
              ? getTotalNumberOfAlarms(alarmPriorityData, maintenancePriority)
              : "0"}
          </MaintenanceAlarmsTitleNumber>
        </MaintenanceAlarmsHeader>
        <MaintenanceAlarmsScrollContainer>
          {maintenanceAlarms.map((a) => (
            <MaintenanceAlarm key={a.id} alarm={a} />
          ))}
        </MaintenanceAlarmsScrollContainer>
      </MaintenanceAlarmsContainer>
    </React.Fragment>
  );
};
