import NumberValueFormatter from "components/NumberValueFormatter";
import Gauge from "components/Gauge";
import InfoCard from "components/InfoCard";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import {
  SensorData,
  SensorSubscriptionFilter,
  useSensorSubscription,
} from "hooks/useSubscription";
import { useTagsContext } from "hooks/useTags";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { MeasurementUnitSymbolConstants } from "utils/utils";

type WindAndWeatherProps = {
  plantId?: string;
  componentUnitId?: string;
};

const WindAndWeatherCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${(props) => props.theme.spacing.heavy};
  padding: ${(props) => props.theme.spacing.light};
  height: 85%;
  contain: content;
`;
const WindDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundActive.primary};
  margin-bottom: 1rem;
`;

const WeatherDataContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: ${(props) => props.theme.spacing.medium};
  margin-bottom: 1rem;
`;

const WeatherData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundActive.primary};
`;

/* prettier-ignore */
const DataTitle = styled.div`
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth} ) {
      font-size: ${(props) => props.theme.scaledFontSizes.small}; 
  }
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.maxWidth}) and (display-mode: fullscreen)  {
      font-size: ${(props) => props.theme.scaledFontSizes.small}; 
  }
`;

export const WindAndWeatherCard: React.FC<WindAndWeatherProps> = (props) => {
  const { formatMessage } = useIntl();
  const { theme } = useScadaPlusTheme();
  const { tags } = useTagsContext();
  const [windSpeed, setWindSpeed] = useState<SensorData | null>(null);
  const [outdoorTemperature, setOutdoorTemperature] = useState<
    number | undefined
  >(undefined);
  const [waveSigHeight, setwaveSigHeight] = useState<SensorData | null>(null);
  const [min] = useState<number>(0);
  const [max] = useState<number>(25);
  const [needleValue, setNeedleValue] = useState(
    windSpeed ? Number(windSpeed.value) : Number(0)
  );
  const [minAngle] = useState<number>(-135);
  const [maxAngle] = useState<number>(135);
  const [tickCount] = useState<number>(11);

  /** Renders coloured arcs to demarcate target ranges. */
  const arcSegments = [
    { min: 0, max: 0.16, color: theme.colors.attention },
    { min: 0.16, max: 0.8, color: theme.colors.ready },
    { min: 0.8, max: 1, color: theme.colors.warning },
    {
      min: 0.75,
      max: 1,
    },
  ];

  const filters: Array<SensorSubscriptionFilter> = [
    {
      id: tags.WMETHorWdSpd,
      windPowerPlantId: props.plantId,
    },
    {
      id: tags.WMETWaveSigHeight,
      windPowerPlantId: props.plantId,
    },
    {
      id: tags.WMETEnvTmp,
      windTurbineId: props.componentUnitId,
    },
  ];

  const { sensorData: sensorData } = useSensorSubscription({
    filters: filters,
  });

  useEffect(() => {
    if (tags) {
      switch (sensorData?.id) {
        case tags.WMETEnvTmp:
          setOutdoorTemperature(Number(sensorData.value));
          break;
        case tags.WMETHorWdSpd:
          setWindSpeed(sensorData);
          break;
        case tags.WMETWaveSigHeight:
          setwaveSigHeight(sensorData);
          break;
        default:
          break;
      }
    }
  }, [sensorData, tags]);

  useEffect(() => {
    if (needleValue < min || needleValue > max) {
      setNeedleValue(min);
    } else {
      setNeedleValue(windSpeed ? Number(windSpeed.value) : Number(0));
    }
  }, [min, max, needleValue, windSpeed]);

  return (
    <InfoCard
      title={formatMessage({
        id: "component.Weather",
        defaultMessage: "Missing text",
      })}
      style={{
        height: "100%",
      }}
    >
      <WindAndWeatherCardContainer>
        <WindDataContainer>
          <Gauge
            min={min}
            max={max}
            value={needleValue}
            maxAngle={maxAngle}
            minAngle={minAngle}
            tickCount={Number(tickCount)}
            arcSegments={arcSegments}
          />
          <DataTitle>
            {formatMessage({
              id: "component.Weather.Wind",
              defaultMessage: "Missing text",
            })}
          </DataTitle>
          <NumberValueFormatter
            value={Number(windSpeed?.value)}
            precision={1}
            loaderSize={17}
            loaderPadding={"0.5rem"}
            measurementType={MeasurementUnitSymbolConstants.metersPerSecond}
          ></NumberValueFormatter>
        </WindDataContainer>
        <WeatherDataContainer>
          <WeatherData>
            <DataTitle>
              {formatMessage({
                id: "component.Weather.Temperature",
                defaultMessage: "Missing text",
              })}
            </DataTitle>
            <NumberValueFormatter
              value={outdoorTemperature}
              precision={2}
              measurementType={MeasurementUnitSymbolConstants.degreeCelcius}
            ></NumberValueFormatter>
          </WeatherData>
          <WeatherData>
            <DataTitle>
              {formatMessage({
                id: "component.Weather.Waves",
                defaultMessage: "Missing text",
              })}
            </DataTitle>
            <NumberValueFormatter
              value={Number(waveSigHeight?.value)}
              precision={2}
              measurementType={MeasurementUnitSymbolConstants.meter}
            ></NumberValueFormatter>
          </WeatherData>
        </WeatherDataContainer>
      </WindAndWeatherCardContainer>
    </InfoCard>
  );
};
