import { Credit } from "./types";
declare global {
  interface Window {
    config: WindowAppConfig;
  }
}

type WindowAppConfig = {
  ENV: string;
  API_URL: string;
  SIGNALR_SENSOR_SUBSCRIBE_URL: string;
  SIGNALR_ALARM_SUBSCRIBE_URL: string;
  SIGNALR_ERROR_MESSAGE_SUBSCRIBE_URL: string;
  AZURE_AD_TENANT_ID: string;
  AZURE_AD_CLIENT_ID: string;
  REDIRECT_URI: string;
};

export type ScadaPlusConfig = {
  api: Api;
  dev: boolean;
  clientId: string;
  tenantId: string;
  redirectUri: string;
  sensorSubscribeURL: string;
  alarmSubscribeURL: string;
  errorMessageSubscribeURL: string;
  screenResolutions: ScreenResolutions;
  links: ExternalLinks;
  credits: Array<Credit>;
  mapEnabled: Array<string>;
  marineEnabled: Array<string>;
  signalRUserIdHeader: string;
};

type ExternalLinks = {
  origo: string;
};

type ScreenResolutions = {
  min: ScreenResolution;
};

type ScreenResolution = { width: number; height: number };

type Api = {
  baseUrl: string;
  url: string;
  plants: string;
  sections: string;
  componentUnit: string;
  production: string;
  alarms: string;
  genericAlarm: string;
  tags: string;
  healthCheck: string;
  capacity: string;
  genericUsers: string;
  menu: string;
  genericAlarmPriorityCounters: string;
  lastSample: string;
  dailyReport: string;
  serviceBoarding: string;
  serviceBoardingCounters: string;
  servicePersonnel: string;
  serviceLocations: string;
};

const apiEndpoints: Api = {
  baseUrl: window.config.API_URL!,
  url: window.config.API_URL! + "/api",
  plants: "plants",
  componentUnit: "ComponentUnit",
  sections: "sections",
  production: "Production",
  alarms: "alarms",
  genericAlarm: "GenericAlarm",
  tags: "tags",
  healthCheck: "healthcheck",
  genericUsers: "GenericUsers/me",
  menu: "GenericMenu",
  genericAlarmPriorityCounters: `GenericAlarm/GenericAlarmPriorityCounters`,
  capacity: "GenericCapacity",
  lastSample: "lastsample",
  dailyReport: "DailyReport",
  serviceBoardingCounters: "ServiceBoarding/active-serviceboardings-counter",
  serviceBoarding: "ServiceBoarding",
  servicePersonnel: "ServicePersonnel",
  serviceLocations: "ServiceLocation",
};

const config: ScadaPlusConfig = {
  api: apiEndpoints,
  dev: window.config.ENV === "development",
  tenantId: window.config.AZURE_AD_TENANT_ID!,
  clientId: window.config.AZURE_AD_CLIENT_ID!,
  redirectUri: window.config.REDIRECT_URI!,
  sensorSubscribeURL: window.config.SIGNALR_SENSOR_SUBSCRIBE_URL!,
  alarmSubscribeURL: window.config.SIGNALR_ALARM_SUBSCRIBE_URL!,
  errorMessageSubscribeURL: window.config.SIGNALR_ERROR_MESSAGE_SUBSCRIBE_URL!,
  screenResolutions: { min: { width: 1440, height: 950 } },
  links: {
    origo: "https://www.origo-solutions.com",
  },
  credits: [
    {
      link: "https://leafletjs.com",
      name: "Leaflet JS - mobile-friendly interactive maps",
    },
  ],
  mapEnabled: [
    "4215da38-65f3-4acc-884f-ba99870d68fc",
    "6ef1fda5-64dd-4d9a-af7f-6da77c8841f7",
  ],
  marineEnabled: ["526e0529-f3e0-4031-90b4-f97bd00f7397"],
  signalRUserIdHeader: "x-scada-plus-user-id",
};

export default config;

export const loginRequest = {
  scopes: [window.config.AZURE_AD_CLIENT_ID! + "/.default"],
};
