import React, { useCallback, useMemo } from "react";
import { scaleLinear } from "d3";
import GaugeArc from "./GaugeArc";
import GaugeNeedle from "./GaugeNeedle";
import { GaugeProps } from "./gauge-types";
import GaugeSVGBase from "./GaugeSVGBase";
import styled from "styled-components";

const GaugeContainer = styled.div`
  height: 50%;
  width: 50%;
  display: flex;
  align-items: center;
`;

export const Gauge: React.FC<GaugeProps> = (props) => {
  const gaugeOrigin = {
    x: 140,
    y: 140,
  };

  const valueScale = useCallback(
    scaleLinear().domain([props.min, props.max]).range([0, 1]),
    [props.min, props.max]
  );

  /** Value scaled to [0,1] */
  const valueRef = useMemo(
    () => valueScale(props.value),
    [props.value, valueScale]
  );

  return (
    <GaugeContainer>
      <GaugeSVGBase
        className="gauge"
        viewBox={"0 0 280 280"}
        style={{
          transition: "all 0.25s 0.25s",
        }}
      >
        {props.arcSegments.map(({ min, max, color }, idx) => (
          <g key={`arcsegment-${idx}`}>
            <GaugeArc
              key={`gauge-arcsegment-${idx}`}
              inset={12}
              min={min}
              max={max}
              stroke={color}
              strokeWidth={40}
              center={gaugeOrigin}
              maxangle={props.maxAngle}
              minangle={props.minAngle}
            />
          </g>
        ))}
        <GaugeNeedle
          value={valueRef}
          center={gaugeOrigin}
          maxAngle={props.maxAngle}
          minAngle={props.minAngle}
          width={40.5}
          head={(0.7 * 250) / 2 - 5}
          tail={5}
        />
      </GaugeSVGBase>
    </GaugeContainer>
  );
};
