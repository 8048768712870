import { useTheme } from "styled-components";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import Button from "../../../components/Button";
import InfoCard from "../../../components/InfoCard";
import ActiveConnection from "../../../components/svg/ActiveConnectionSVG";
import Disconnected from "../../../components/svg/DisconnectedSVG";
import config from "../../../config";
import {
  useSensorSubscription,
  SensorSubscriptionFilter,
  SensorData,
} from "../../../hooks/useSubscription";
import { useTagsContext } from "../../../hooks/useTags";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

const UnitControlContainer = styled.div`
  height: 85%;
`;

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  padding: ${(props) => props.theme.spacing.heavy};
`;

const PUStartContainer = styled.div`
  display: grid;
  place-items: center;
  height: 75%;
`;

const Operations = styled.div`
  width: 100%;
  height: 100%;
  padding: ${(props) => props.theme.spacing.medium};
`;

const OperationsText = styled.div`
  width: 100%;
  height: 100%;
`;
const Title = styled.div`
  color: ${(props) => props.theme.colors.textColor.quaternary};
  font-weight: 600;
  grid-column: 1/2;
  align-self: start;
`;

const Status = styled.div`
  display: flex;
  margin-left: auto;
  align-self: start;
  grid-column: 2/3;
`;

const Icon = styled.div`
  margin-right: 6px;

  svg {
    vertical-align: middle;
  }
`;

interface UnitControlCardProps {
  powerPlantId: string;
  unitId: string;
  controlPermission: boolean;
}

const UnitControlCard: React.FC<UnitControlCardProps> = (props) => {
  const { formatMessage } = useIntl();
  const { tags } = useTagsContext();
  const [operationStatus, setOperationStatus] = useState<SensorData | null>(
    null
  );
  const [isOn, setIsOn] = useState(false);
  const theme = useTheme();

  const isDemoUrl = config.redirectUri
    ? config.redirectUri?.match("demo")
      ? true
      : false
    : false;

  const SendCmd = (cmd: string) => {
    const cmdwUrl = `${config.api.url}/${config.api.componentUnit}/${props.unitId}/${cmd}`;
    axios
      .put(cmdwUrl, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        toast.success(cmd + " sent");
      })
      .catch((error: AxiosError) => {
        toast.error(error.message);
      });
  };

  const filters: Array<SensorSubscriptionFilter> = isDemoUrl
    ? [
        {
          id: tags.WTURTurStCounter,
          windTurbineId: props.unitId,
        },
      ]
    : [
        {
          id: tags.WTURTurSt,
          windTurbineId: props.unitId,
        },
      ];

  const { sensorData: operation } = useSensorSubscription({
    filters: filters,
  });

  useEffect(() => {
    if (operation != null) {
      setOperationStatus(operationStatus);
      if (Number(operation?.value) >= 128) setIsOn(true);
      else setIsOn(false);
    }
  }, [operation, operationStatus]);

  return (
    <InfoCard style={{ height: "100%" }}>
      <UnitControlContainer>
        <TitleContainer>
          <Title>{!isDemoUrl ? "Zefyros T1" : "Demo turbine"}</Title>
          {isOn ? (
            <Status>
              <Icon>
                <ActiveConnection height={20} width={20} />
              </Icon>
              <span>
                {formatMessage({
                  id: "general.Active",
                  defaultMessage: "Active",
                })}
              </span>
            </Status>
          ) : (
            <Status>
              <Icon>
                <Disconnected
                  height={20}
                  width={20}
                  style={{ verticalAlign: "middle" }}
                />
              </Icon>
              <div>
                {formatMessage({
                  id: "general.Inactive",
                  defaultMessage: "Inactive",
                })}
              </div>
            </Status>
          )}
        </TitleContainer>

        <PUStartContainer>
          <OperationsText></OperationsText>
          {isDemoUrl && (
            <Operations>
              {isOn ? (
                <Button
                  type="primary"
                  size="medium"
                  onclick={() => SendCmd("stop")}
                  center={true}
                >
                  {formatMessage({
                    id: "pu.manualShutDown",
                    defaultMessage: "Manual Shut Down",
                  })}
                </Button>
              ) : (
                <Button
                  type="secondary"
                  size="medium"
                  onclick={() => SendCmd("start")}
                  center={true}
                >
                  {formatMessage({
                    id: "pu.manualStart",
                    defaultMessage: "Manual Start",
                  })}
                </Button>
              )}
            </Operations>
          )}

          {!isDemoUrl ? (
            <Operations>
              <Button
                type="secondary"
                opacity={0.3}
                hovercolor={theme.colors.buttonColors.secondaryButtonColor}
                size="medium"
                center={true}
                style={{ cursor: "default" }}
              >
                {formatMessage({
                  id: "pu.manualStart",
                  defaultMessage: "Manual Start",
                })}
              </Button>
            </Operations>
          ) : (
            <></>
          )}
        </PUStartContainer>
      </UnitControlContainer>
    </InfoCard>
  );
};

export default UnitControlCard;
