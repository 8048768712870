import styled from "styled-components";

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  padding: ${(props) => props.theme.spacing.light};
  width: min-content;
  color: ${(props) => props.theme.colors.textColor.primary};
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
`;

const TooltipHeader = styled.div`
  font-size: ${(props) => props.theme.scaledFontSizes.mediumLarge};
`;

const DataDescription = styled.div``;

const DataValue = styled.div`
  padding-left: 0.5rem;
  color: ${(props) => props.theme.colors.textColor.secondary};
`;

const ToolTipContentItem = styled.div`
  display: flex;
  flex-direction: row;
`;

type TooltipContentProps = {
  header: string;
  children?: JSX.Element;
  tooltipTableData: Array<{ description: string; value: string }>;
};

export const TooltipContent = (props: TooltipContentProps) => {
  return (
    <TooltipContainer>
      <TooltipHeader>{props.header}</TooltipHeader>
      {props.tooltipTableData.map((dataItem, index) => {
        return (
          <ToolTipContentItem key={index}>
            <DataDescription>{dataItem.description}</DataDescription>
            <DataValue>{dataItem.value}</DataValue>
          </ToolTipContentItem>
        );
      })}
      {props.children}
    </TooltipContainer>
  );
};
