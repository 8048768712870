import React from "react";
import styled, { DefaultTheme, useTheme } from "styled-components";

interface IComponentProps {
  width: number;
  height: number;
  color?: string;
  style?: React.CSSProperties;
  noAnimation?: boolean;
}

interface IInternalComponentProps {
  width: number;
  height: number;
  color?: string;
  theme: DefaultTheme;
}

const Container = styled.div<IComponentProps>`
  width: ${(props: IInternalComponentProps) => props.width}px;
  height: ${(props: IInternalComponentProps) => props.height}px;
`;

const Pulse = styled.div`
  box-shadow: 0 0 0 0
    ${(props: IInternalComponentProps) =>
      props.color ? props.color : props.theme.colors.mainColor.primary};
  transform: scale(1);
  animation: pulse 2s infinite;

  border-radius: 50%;
  width: ${(props: IInternalComponentProps) => props.width}px;
  height: ${(props: IInternalComponentProps) => props.height}px;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0
        ${(props: IInternalComponentProps) =>
          props.color ? props.color : props.theme.colors.mainColor.primary};
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 0.3em rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;

export const ActiveConnectionSVG: React.FC<IComponentProps> = (props) => {
  const theme = useTheme();

  if (props.noAnimation) {
    return (
      <Container width={props.width} height={props.height}>
        <svg
          style={{ marginTop: "-8px", ...props.style }}
          width="100%"
          height="100%"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="9"
            cy="8.99988"
            r="3"
            fill={props.color ? props.color : theme.colors.mainColor.primary}
          />
          <circle
            cx="9"
            cy="9.00012"
            r="5.5"
            stroke={props.color ? props.color : theme.colors.mainColor.primary}
            strokeOpacity="0.6"
          />
          <circle
            cx="9"
            cy="9"
            r="8.5"
            stroke={props.color ? props.color : theme.colors.mainColor.primary}
            strokeOpacity="0.4"
          />
          {props.children}
        </svg>
      </Container>
    );
  }

  return (
    <Pulse
      theme={theme}
      width={props.width}
      height={props.height}
      color={props.color}
    >
      <svg
        style={{ marginTop: "-8px", ...props.style }}
        width="100%"
        height="100%"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="9"
          cy="8.99988"
          r="3"
          fill={props.color ? props.color : theme.colors.mainColor.primary}
        />
        <circle
          cx="9"
          cy="9.00012"
          r="5.5"
          stroke={props.color ? props.color : theme.colors.mainColor.primary}
          strokeOpacity="0.6"
        />
        <circle
          cx="9"
          cy="9"
          r="8.5"
          stroke={props.color ? props.color : theme.colors.mainColor.primary}
          strokeOpacity="0.4"
        />
        {props.children}
      </svg>
    </Pulse>
  );
};
