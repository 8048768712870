import WindAndWeatherCard from "components/WindAndWeatherCard";
import React, { useState } from "react";
import styled from "styled-components";
import PowerCard from "../PowerCard";
import Alarms from "./Alarms";
import ProductionOverviewCard from "./ProductionOverview";
import GraphAndListTemperature from "./Temperatures";
import TemperaturesCard from "./TemperaturesCard";
import TurbineStatusCard from "./TurbineStatusCard";

const ExpandedTemperatureArea = styled.div`
  grid-column: 3 / -1;
  grid-row: 2 / -1;
`;

const TurbineStatusArea = styled.div`
  grid-column: 3 / 4;
  grid-row: 2 / 3;
`;

const TemperaturesArea = styled.div`
  grid-column: 3 / 4;
  grid-row: 4 / -1;
`;

const ProdOverViewArea = styled.div`
  grid-column: 4 / 5;
  grid-row: 3 / span 2;
`;

const AlarmArea = styled.div`
  grid-column: 5 / -1;
  grid-row: 2 / -1;
  min-height: 100%;
`;

const PowerArea = styled.div`
  grid-column: 4 / 5;
  grid-row: 2;
`;

const WeatherArea = styled.div`
  grid-column: 3 / 4;
  grid-row: 3;
`;

type ComponentProps = {
  componentUnitId: string;
  plantId: string;
};

export const ExpandableView: React.FC<ComponentProps> = (props) => {
  const [expandedTemperatureView, setExpandedTemperatureView] = useState(false);

  return (
    <>
      {!expandedTemperatureView && (
        <>
          <TurbineStatusArea>
            <TurbineStatusCard componentUnitId={props.componentUnitId} />
          </TurbineStatusArea>
          <WeatherArea>
            <WindAndWeatherCard
              componentUnitId={props.componentUnitId}
              plantId={props.plantId}
            />
          </WeatherArea>

          <PowerArea>
            <PowerCard componentUnitId={props.componentUnitId} />
          </PowerArea>

          <TemperaturesArea data-testid="temperaturesareatest">
            <TemperaturesCard
              onClick={() => setExpandedTemperatureView(true)}
              componentUnitId={props.componentUnitId}
            />
          </TemperaturesArea>
          <AlarmArea>
            <Alarms componentUnitId={props.componentUnitId} />
          </AlarmArea>
          <ProdOverViewArea>
            <ProductionOverviewCard componentUnitId={props.componentUnitId} />
          </ProdOverViewArea>
        </>
      )}

      {expandedTemperatureView && (
        <ExpandedTemperatureArea data-testid="expandedtemperatureareatest">
          <GraphAndListTemperature
            onClick={() => setExpandedTemperatureView(false)}
          />
        </ExpandedTemperatureArea>
      )}
    </>
  );
};
