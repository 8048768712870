import styled from "styled-components";

interface IAnimationProps {
  duration?: number;
  out?: boolean;
}

export const FadeAnimation = styled.div`
  ${(props: IAnimationProps) => {
    if (props.out) {
      return `animation-duration: ${props.duration ? props.duration : 1}s;
    animation-name: fade-out;
    opacity: 0;

    @keyframes fade-out {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }`;
    } else {
      return `animation-duration: ${props.duration ? props.duration : 1}s;
      animation-name: fade-in;
      @keyframes fade-in {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }`;
    }
  }}
`;
