import React from "react";
import { useScadaPlusTheme } from "../../../hooks/useScadaPlusTheme";

type WindTurbineProps = {
  width: string;
  height: string;
};

export const WindTurbineSVG: React.FC<WindTurbineProps> = (props) => {
  const { theme } = useScadaPlusTheme();
  return (
    <svg
      viewBox={"0 0 159 143"}
      width={props.width}
      height={props.height}
      key={theme.name}
    >
      <path
        d="M149.2 130.3C151.3 131.2 153.7 131.3 155.6 130.1C157.6 128.9 158.9 126.7 158.9 124.2C158.8 122.3 158 120.6 156.6 119.4L117.9 86.2C117.9 86.2 106.6 76.9 95.2 81.4C90.5 83.2 86.4 85 83.3 86.5C80.3 87.9 80.1 92.1 83 93.7L149.2 130.3Z"
        fill="url(#windturbineid1)"
      />
      <path
        d="M76.2 6.4C76 4.2 74.9 2 72.9 0.900002C70.9 -0.299998 68.3 -0.399998 66.1 0.900002C64.5 1.9 63.4 3.4 63 5.2L52.7 55.2C52.7 55.2 50.1 69.6 59.5 77.4C63.4 80.6 66.9 83.3 69.7 85.4C72.4 87.4 76.1 85.5 76.1 82.1L76.2 6.4Z"
        fill="url(#windturbineid2)"
      />
      <path
        d="M3.2 130.4C1.4 131.7 0 133.7 0 136C0 138.3 1.1 140.6 3.3 141.9C4.9 142.8 6.8 143 8.6 142.5L57.3 127.2C57.3 127.2 71.2 122.5 73.4 110.5C74.3 105.5 75 101.1 75.4 97.7C75.8 94.4 72.3 92 69.4 93.6L3.2 130.4Z"
        fill="url(#windturbineid3)"
      />
      <defs>
        <linearGradient
          id="windturbineid1"
          x1="137"
          y1="122"
          x2="147"
          y2="92"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.mainColor.secondary} />
          <stop
            offset="1"
            stopColor={theme.colors.mainColor.secondary}
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="windturbineid2"
          x1="76"
          y1="22.5"
          x2="44.9418"
          y2="27.6492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.mainColor.secondary} />
          <stop
            offset="1"
            stopColor={theme.colors.mainColor.secondary}
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="windturbineid3"
          x1="19"
          y1="121.5"
          x2="38"
          y2="147.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.mainColor.secondary} />
          <stop
            offset="1"
            stopColor={theme.colors.mainColor.secondary}
            stopOpacity="0"
          />
        </linearGradient>
      </defs>
    </svg>
  );
};
