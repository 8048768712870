import { useLocalDateTimeFormatShort } from "hooks/useDateTimeFormat";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

const InformationRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5%;
`;
const InformationTitle = styled.div`
  color: ${(props) => props.theme.colors.textColor.quaternary};
  font-size: ${(props) => props.theme.scaledFontSizes.small};
  font-family: ${(props) => props.theme.primaryFontFamily};
  font-weight: normal;
  line-height: 19px;
`;

const InformationValue = styled.div`
  color: ${(props) => props.theme.colors.textColor.primary};
  font-size: ${(props) => props.theme.scaledFontSizes.small};
  font-family: ${(props) => props.theme.primaryFontFamily};
`;

type AlarmInfoHeaderProps = {
  code?: string;
  timestamp: string;
  componentUnitName: string;
  area?: string;
  supplier?: string;
};

export const AlarmInfoHeader: React.FC<AlarmInfoHeaderProps> = (props) => {
  const { formatMessage } = useIntl();
  const dateTimeLocal = useLocalDateTimeFormatShort();

  return (
    <InformationRow>
      <InformationContainer>
        <InformationTitle>
          {formatMessage({
            id: "alarms.Type",
            defaultMessage: "Type",
          })}
        </InformationTitle>
        <InformationValue>{props.code}</InformationValue>
      </InformationContainer>
      <InformationContainer>
        <InformationTitle>
          {formatMessage({
            id: "alarms.Time",
            defaultMessage: "Time",
          })}
        </InformationTitle>
        <InformationValue>
          {dateTimeLocal.format(
            props.timestamp ? new Date(props.timestamp) : undefined
          )}
        </InformationValue>
      </InformationContainer>
      <InformationContainer>
        <InformationTitle>
          {formatMessage({
            id: "alarms.Unit",
            defaultMessage: "Unit",
          })}
        </InformationTitle>
        <InformationValue>{props.componentUnitName}</InformationValue>
      </InformationContainer>
      <InformationContainer>
        <InformationTitle>
          {formatMessage({
            id: "alarms.Area",
            defaultMessage: "Area",
          })}
        </InformationTitle>
        <InformationValue>{props.area}</InformationValue>
      </InformationContainer>
      <InformationContainer>
        <InformationTitle>
          {formatMessage({
            id: "alarms.Supplier",
            defaultMessage: "Supplier",
          })}
        </InformationTitle>
        <InformationValue>{props.supplier}</InformationValue>
      </InformationContainer>
      <InformationContainer>
        <InformationTitle>
          {formatMessage({
            id: "alarms.Role",
            defaultMessage: "Role",
          })}
        </InformationTitle>
        <InformationValue>Operator</InformationValue>
      </InformationContainer>
      <InformationContainer>
        <InformationTitle>
          {formatMessage({
            id: "alarms.CurrentValue",
            defaultMessage: "Current Value",
          })}
        </InformationTitle>
        <InformationValue>Lorem</InformationValue>
      </InformationContainer>
    </InformationRow>
  );
};
