import StackedBarChart from "components/StackedBarChart";
import { ToolTipText } from "components/StackedBarChart/StackedBarChart";
import React from "react";
import { MeasurementUnitSymbolConstants } from "utils/utils";
import { Production } from "../../types";
import { valuesToMwh } from "../../utils/production-utils";
import InfoCard from "../InfoCard";

export enum Mode {
  Month,
  Hour,
}

type ProductionChartProps = {
  data: Production | undefined;
  title: string;
  keyValue?: number;
  isLoading: boolean;
  mode: Mode;
  fullWidth?: boolean;
  getTooltipText?: (
    actual: number,
    forecast: number,
    timestamp: number
  ) => [ToolTipText, ToolTipText];
};

export const ProductionChart: React.FC<ProductionChartProps> = (props) => {
  return (
    <InfoCard
      style={{ height: "100%" }}
      title={props.title}
      keyNumber={props.keyValue}
      keyNumberPrecision={1}
      measurementType={MeasurementUnitSymbolConstants.megaWattHour}
      displayNumber={true}
    >
      <StackedBarChart
        data={valuesToMwh(props.data)}
        mode={props.mode}
        fullWidth={props.fullWidth}
        getTooltipText={props.getTooltipText}
      />
    </InfoCard>
  );
};
