import config from "config";
import React from "react";
import { IntlProvider } from "react-intl";
import languageFiles from "../translations";

const localStorageKey = "scadaplus_language";

type Dispatch = (language: string) => void;

type State = {
  language: string;
  translationFile: any;
};

const getLanguageFromLocalStorage = (): string => {
  const storedTheme = localStorage.getItem(localStorageKey);
  if (storedTheme !== null) {
    return storedTheme;
  }
  return "en";
};

const getDefaultLanguage = (): string => getLanguageFromLocalStorage();

const getLanguageFile = (language: string): any => {
  for (let i = 0; i < languageFiles.length; i++) {
    const element = languageFiles[i];
    if (
      element["general.languageName"].toLowerCase() === language.toLowerCase()
    ) {
      return element;
    }
  }
  return languageFiles[0];
};

export const LanguageContext = React.createContext<State>({
  language: "en",
  translationFile: languageFiles[0],
});

export const LanguageDispatchContext = React.createContext<
  Dispatch | undefined
>(undefined);

const languageReducer = (state: State, language: string) => {
  localStorage.setItem(localStorageKey, language);
  return { language: language, translationFile: getLanguageFile(language) };
};

const ScadaPlusLanguageProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(languageReducer, {
    language: getDefaultLanguage(),
    translationFile: getLanguageFile(getDefaultLanguage()),
  });

  return (
    <LanguageContext.Provider value={state}>
      <IntlProvider
        locale={state.language}
        messages={state.translationFile}
        onError={(error) => {
          config.dev && console.error(error);
        }}
      >
        <LanguageDispatchContext.Provider value={dispatch}>
          {props.children}
        </LanguageDispatchContext.Provider>
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export { ScadaPlusLanguageProvider };
