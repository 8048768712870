import CircleSVG from "components/svg/CircleSVG";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import styled, { DefaultTheme } from "styled-components";

const ContentGrid = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  border-radius: ${(props: SIFEventOverviewStyleProps) =>
    props.theme.borderRadius.light};
  grid-template-rows: 1fr 1fr;
  padding: 0.5rem;
  background-color: ${(props: SIFEventOverviewStyleProps) =>
    props.theme.colors.cardBackgroundHover};
  align-items: center;
`;

const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const EventCounter = styled.div`
  display: flex;
  flex-direction: column;
  width: 80px;
  align-items: center;
  font-size: ${(props: SIFEventOverviewStyleProps) =>
    props.theme.constantFontSizes.small};
  text-align: center;
`;

interface SIFEventOverviewStyleProps {
  backgroundColor?: string;
  theme: DefaultTheme;
}

export const SIFEventOverview: React.FC = () => {
  const { theme } = useScadaPlusTheme();

  const circleSize = "2.8rem";
  const circleFontSize = theme.constantFontSizes.mediumLarge;
  return (
    <ContentGrid>
      <GridRow>
        <EventCounter>
          <CircleSVG count={2} size={circleSize} fontSize={circleFontSize}>
            2
          </CircleSVG>
          <p>Safe Trips</p>
        </EventCounter>
        <EventCounter>
          <CircleSVG count={2} size={circleSize} fontSize={circleFontSize}>
            2
          </CircleSVG>
          <p>Spurious Trips</p>
        </EventCounter>
        <EventCounter>
          <CircleSVG count={0} size={circleSize} fontSize={circleFontSize}>
            0
          </CircleSVG>
          <p>Device Failure</p>
        </EventCounter>
      </GridRow>
      <GridRow>
        <EventCounter>
          <CircleSVG count={0} size={circleSize} fontSize={circleFontSize}>
            0
          </CircleSVG>
          <p>Failed Trips</p>
        </EventCounter>
        <EventCounter>
          <CircleSVG count={0} size={circleSize} fontSize={circleFontSize}>
            0
          </CircleSVG>
          <p>Exessive Bypass Time</p>
        </EventCounter>
      </GridRow>
    </ContentGrid>
  );
};
