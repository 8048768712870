import ProductionAlarmCounterSVG from "components/svg/ProductionAlarmCounterSVG";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type ProductionAlarmCounterProps = {
  count: number;
};

export const SectionProductionAlarmCounter: React.FC<
  ProductionAlarmCounterProps
> = ({ count }) => {
  return (
    <Container>
      <ProductionAlarmCounterSVG height="1.8em" width="1.8em" count={count} />
    </Container>
  );
};
