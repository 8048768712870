import React from "react";
import styled from "styled-components";

type ProductionIndicatorSVGProps = {
  color: string;
  width: string | number;
  height: string;
  isMapEnabled: boolean;
};

const ProductionCircleSVG = styled.svg`
  top: 5px;
  left: 3px;
  position: absolute;
`;

export const ProductionIndicatorSVG: React.FC<ProductionIndicatorSVGProps> = (
  props
) => {
  return props.isMapEnabled ? (
    <ProductionCircleSVG
      viewBox="-20 -10 159 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="50px"
      height="50px"
    >
      <path
        d="M135.1 51.3C125.7 18 92.4 -3.29999 57.7 2.90001C50.7 4.20001 44.1 6.50001 38.1 9.60001"
        stroke={props.color}
        strokeWidth="2"
        strokeMiterlimit="10"
        data-testid="turbinespintestnode"
      />
      <path
        d="M140.1 44.2L135 51.4L127.8 46.2"
        stroke={props.color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M54.2 136C63 138.1 72.3 138.4 81.7 136.7C113.6 131 136.3 103.9 137.6 72.8"
        stroke={props.color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M58.9 143.4L54.1 136L61.5 131.2"
        stroke={props.color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M21.6 21.8C6.39999 37 -1.30001 59.1 2.69999 81.8C6.29999 101.7 18.1 118 34.1 127.7"
        stroke={props.color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M12.8 22.6L21.5 21.6L22.6 30.3"
        stroke={props.color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </ProductionCircleSVG>
  ) : (
    <ProductionCircleSVG
      viewBox="0 0 159 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
    >
      <path
        d="M135.1 51.3C125.7 18 92.4 -3.29999 57.7 2.90001C50.7 4.20001 44.1 6.50001 38.1 9.60001"
        stroke={props.color}
        strokeWidth="2"
        strokeMiterlimit="10"
        data-testid="turbinespintestnode"
      />
      <path
        d="M140.1 44.2L135 51.4L127.8 46.2"
        stroke={props.color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M54.2 136C63 138.1 72.3 138.4 81.7 136.7C113.6 131 136.3 103.9 137.6 72.8"
        stroke={props.color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M58.9 143.4L54.1 136L61.5 131.2"
        stroke={props.color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M21.6 21.8C6.39999 37 -1.30001 59.1 2.69999 81.8C6.29999 101.7 18.1 118 34.1 127.7"
        stroke={props.color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M12.8 22.6L21.5 21.6L22.6 30.3"
        stroke={props.color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </ProductionCircleSVG>
  );
};
