import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import styled from "styled-components";
import { useIntl } from "react-intl";
import Modal from "components/Modal";
import { Size, Background, PortalConsumer } from "components/Modal/Modal";
import BoatSVG from "components/svg/BoatSVG";
import HardhatPersonnelSVG from "components/svg/HardhatPersonnelSVG";
import WindTurbineSVG from "components/svg/WindTurbineSVG";
import { isUsingMobileDevice } from "utils/device-utils";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import {
  ActiveBoardingCounters,
  ServiceBoarding,
  ServiceLocation,
} from "types";

import config from "config";
import Button from "components/Button";
import BoardedPersonnel from "components/BoardedPersonnel";
import PersonnelRegistration from "components/PersonnelRegistration";
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";

const mobile = isUsingMobileDevice(window.navigator);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  justify-content: center;
  padding: ${(props) => props.theme.spacing.heavy};
  border: 3px solid ${(props) => props.theme.colors.mainColor.tertiary};
`;

const Title = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${(props) => props.theme.colors.textColor.tertiary};
  font-size: ${(props) => props.theme.scaledFontSizes.large};
  margin-bottom: ${(props) => props.theme.spacing.light};
`;

const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StatusHeading = styled.div`
  padding-top: 0%;
  grid-column: 1;
  grid-row: 1;
  display: flex;
  justify-content: left;
  align-items: left;
`;

const MainContent = styled.div`
  align-items: left;
  width: 100%;
  padding-top: 2%;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  margin-left: auto;
  margin-right: 0;
`;

const BoardingBox = styled.div`
  width: 100%;
  display: inline-block;
  overflow-y: auto;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  height: 50vh;
`;

const ContentBoat = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 0.5fr 2fr;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  background-color: ${(props) => props.theme.colors.mainColor.quinary};
  width: 100%;
`;

const SpaceBetween = styled.div`
  width: 100%;
  display: inline-block;
  padding-top: 2.5%;
`;

const BoatData = styled.div`
  font-weight: Bold;
  font-size: Medium;
  grid-column: 2;
  grid-row: 1;
  padding-top: 3%;
  padding-bottom: 8%;
`;

const ContentTurbine = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 0.5fr 2fr;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  background-color: ${(props) => props.theme.colors.mainColor.quinary};
  width: 100%;
`;

const IconBoxTurbine = styled.div`
  display: grid;
  grid-template-rows: 0.1fr auto;
  grid-template-columns: 0.4fr 1fr 0.12fr;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 120px;
  padding-top: 0%;
  padding-bottom: 5%;
`;

const TurbineData = styled.div`
  font-weight: Bold;
  font-size: Medium;
  grid-column: 2;
  grid-row: 1;
  padding-top: 3%;
  padding-bottom: 5%;
`;

const ButtonReg = styled.div`
  padding-top: 2%;
  float: right;
  margin-left: auto;
  margin-right: auto;
`;

const IconBoxBoat = styled.div`
  display: grid;
  grid-template-rows: 0.1fr auto;
  grid-template-columns: 0.4fr 1fr 0.12fr;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 120px;
  padding-top: 0%;
`;

const Counter = styled.div`
  padding-top: 0%;
  grid-column: 2;
  grid-row: 2;
  display: flex;
  justify-content: center;
  align-items: top;
  width: 55px;
  color: ${(props) => props.theme.colors.textColor.secondary};
  font-size: ${(props) => props.theme.numberSizes.large};
`;

const GraphicsBoat = styled.div`
  padding-top: 20%;
  grid-column: 2;
  grid-row: 1;
  justify-content: center;
  align-items: center;
  width: 70%;
`;

const GraphicsTurbine = styled.div`
  padding-top: 20%;
  grid-column: 2;
  grid-row: 1;
  justify-content: center;
  align-items: center;
  width: 80%;
  font-size: ${(props) => props.theme.numberSizes.small};
`;

interface PersonnelProps {
  componentUnitId: string;
  plantId: string;
  data?: ServiceBoarding;
  onClose: () => void;
}

export const PersonnelTracking: React.FC<PersonnelProps> = (props) => {
  const { theme } = useScadaPlusTheme();
  const [showPersonnelRegistration, setshowPersonnelRegistration] =
    useState(false);
  const { formatMessage } = useIntl();
  const [BoardingDataEmpty] = useState<ServiceBoarding>();
  const queryClient = useQueryClient();
  const [action] = useState<string>("reload");

  const Click = () => {
    setshowPersonnelRegistration(true);
  };

  const urlBoarding = `${config.api.url}/${config.api.serviceBoarding}?IsActive=true&plantId=${props.plantId}`;
  const urlBoardingCount = `${config.api.url}/${config.api.serviceBoardingCounters}?IsOffshore=true&ComponentUnitId=${props.componentUnitId}`;
  const urlBoardingCountVessel = `${config.api.url}/${config.api.serviceBoardingCounters}?IsOffshore=true&plantId=${props.plantId}`;
  const urlServiceLocation = `${config.api.url}/${config.api.serviceLocations}?plantId=${props.plantId}`;

  const { data: LocationData } = useQuery<Array<ServiceLocation>>(
    urlServiceLocation,
    async () => {
      return await axios
        .get(urlServiceLocation, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          if (error.response) {
            toast.error("Boarding, Error: " + error.response.status);
          }
        });
    },
    {
      retry: 3,
      retryDelay: 1,
      staleTime: 1000,
      refetchOnWindowFocus: true,
    }
  );

  const { data: BoardingData } = useQuery<Array<ServiceBoarding>>(
    urlBoarding,
    async () => {
      return await axios
        .get(urlBoarding, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          if (error.response) {
            toast.error("Boarding, Error: " + error.response.status);
          }
        });
    },
    {
      retry: 3,
      retryDelay: 1,
      staleTime: 1000,
      refetchOnWindowFocus: true,
    }
  );

  const { data: OnBoardCnt } = useQuery<ActiveBoardingCounters>(
    urlBoardingCount,
    async () => {
      return await axios
        .get(urlBoardingCount, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          if (error.response) {
            toast.error("Boarding, Error: " + error.response.status);
          }
        });
    },
    {
      retry: 3,
      retryDelay: 1,
      staleTime: 1000,
      refetchOnWindowFocus: true,
    }
  );

  const { data: OnBoardCntVessel } = useQuery<ActiveBoardingCounters>(
    urlBoardingCountVessel,
    async () => {
      return await axios
        .get(urlBoardingCountVessel, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          if (error.response) {
            toast.error("Boarding, Error: " + error.response.status);
          }
        });
    },
    {
      retry: 3,
      retryDelay: 1,
      staleTime: 1000,
      refetchOnWindowFocus: true,
    }
  );

  const b = OnBoardCntVessel?.serviceLocationTypes.filter(
    (x) => x.serviceLocationTypeDescription === "Vessel"
  );
  let onBoardBoat: number | undefined;
  if (b !== undefined) {
    onBoardBoat = b[0] ? b[0].servicePersonellOnBoard : 0;
  }

  const t = OnBoardCnt?.serviceLocationTypes.filter(
    (x) => x.serviceLocationTypeDescription === "Wind Turbine"
  );
  let onBoardTurbine: number | undefined;
  if (t !== undefined) {
    onBoardTurbine = t[0] ? t[0].servicePersonellOnBoard : 0;
  }

  let locationVesselId = "";
  const locationVessel = LocationData?.filter(
    (x) => x.serviceLocationTypeDescription === "Vessel"
  );
  if (locationVessel && locationVessel.length > 0) {
    locationVesselId = locationVessel[0].serviceLocationId;
  }

  const updateAction = (): void => {
    queryClient.invalidateQueries(urlBoarding);
    queryClient.invalidateQueries(urlBoardingCount);
    queryClient.invalidateQueries(urlServiceLocation);
    queryClient.invalidateQueries(urlBoardingCountVessel);
  };

  return (
    <Modal
      size={mobile ? Size.FullPage : Size.Medium}
      background={Background.Dark}
    >
      <Container>
        <TitleArea>
          <Title>
            <StatusHeading>
              {formatMessage({
                id: "header.PersTracking",
                defaultMessage: "tbn",
              })}
            </StatusHeading>
          </Title>
          <PortalConsumer>
            {(value) => (
              <Button
                style={{
                  borderRadius: "50%",
                  height: "35.19px",
                }}
                onclick={() => value.close?.()}
                size="xs"
                type="primary"
                icon={<MdClose fill={theme.colors.iconColor} size={18} />}
              />
            )}
          </PortalConsumer>
        </TitleArea>
        <MainContent>
          <BoardingBox>
            {onBoardBoat ? (
              <ContentBoat>
                {(onBoardBoat ? onBoardBoat > 0 : true) ? (
                  <IconBoxBoat>
                    <GraphicsBoat>
                      <BoatSVG width={"100%"} height={"50%"} />
                    </GraphicsBoat>
                    <Counter>{onBoardBoat}</Counter>
                  </IconBoxBoat>
                ) : (
                  <div></div>
                )}
                <BoatData>
                  {BoardingData &&
                    BoardingData.filter(
                      (x) => x.currentLocationId === locationVesselId
                    ).map((b) => (
                      <BoardedPersonnel
                        key={b.serviceBoardingId}
                        action={action}
                        actionFunc={updateAction}
                        data={b}
                        type="b"
                        plantId={props.plantId}
                        componentUnitId={props.componentUnitId}
                        boardedId={b.serviceBoardingId}
                      />
                    ))}
                </BoatData>
              </ContentBoat>
            ) : (
              <div>
                {formatMessage({
                  id: "boarding.VesselNoPersonnel",
                  defaultMessage: "Translation Not Found",
                })}
              </div>
            )}

            <SpaceBetween />

            {onBoardTurbine ? (
              <ContentTurbine>
                {(onBoardTurbine ? onBoardTurbine > 0 : true) ? (
                  <IconBoxTurbine>
                    <GraphicsTurbine>
                      <WindTurbineSVG width={"100%"} height={"50%"} />
                    </GraphicsTurbine>
                    <Counter>{onBoardTurbine}</Counter>
                  </IconBoxTurbine>
                ) : (
                  <div></div>
                )}
                <TurbineData>
                  {BoardingData &&
                    BoardingData.filter(
                      (x) =>
                        x.currentServiceLocation.componentUnitId ===
                          props.componentUnitId &&
                        //x.currentLocationId === locationCompId &&
                        x.plantId === props.plantId
                    ).map((b) => (
                      <BoardedPersonnel
                        key={b.serviceBoardingId}
                        action={action}
                        actionFunc={updateAction}
                        data={b}
                        type="t"
                        plantId={props.plantId}
                        componentUnitId={props.componentUnitId}
                        boardedId={b.serviceBoardingId}
                      />
                    ))}
                </TurbineData>
              </ContentTurbine>
            ) : (
              <div>
                {formatMessage({
                  id: "boarding.ProductuinUnitNoPersonnel",
                  defaultMessage: "Translation Not Found",
                })}
              </div>
            )}
          </BoardingBox>
          <ButtonReg>
            <Button
              type="primary"
              size="medium"
              center={false}
              onclick={Click}
              iconSize={30}
              icon={
                <HardhatPersonnelSVG
                  width={"50%"}
                  height={"50%"}
                  color={theme.colors.textColor.tertiary}
                  style={{
                    display: "inline",
                    justifyContent: "right",
                    alignItems: "right",
                    height: "20",
                    width: "20",
                  }}
                ></HardhatPersonnelSVG>
              }
            >
              {formatMessage({
                id: "header.PersReg",
                defaultMessage: "Translation Not Found",
              })}
            </Button>
          </ButtonReg>
        </MainContent>
      </Container>
      <PortalConsumer>
        {(value) => {
          value.onClose = props.onClose;
          return <span></span>; // TODO: Had to be added due to react complaints. Use Hook - context?
        }}
      </PortalConsumer>
      {showPersonnelRegistration && (
        <PersonnelRegistration
          action={action}
          actionFunc={updateAction}
          onClose={() =>
            setshowPersonnelRegistration(!showPersonnelRegistration)
          }
          componentUnitId={props.componentUnitId}
          plantId={props.plantId}
          data={BoardingDataEmpty}
          newBoarding={true}
        />
      )}
    </Modal>
  );
};
