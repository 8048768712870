import { PortalConsumer } from "components/Modal/Modal";
import {
  Wendy,
  WendyBodyStyles,
  WendyHeadStyles,
} from "components/Wendy/Wendy";
import React from "react";
import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useIntl } from "react-intl";
import styled, { DefaultTheme } from "styled-components";
import Button from "../../../components/Button";

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: ${(props) => props.theme.scaledFontSizes.mediumLarge};
`;

const MainContainer = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin: ${(props) => props.theme.spacing.medium};
  }
`;

const ActionBar = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 2em;
`;
const ActionButtons = styled.div`
  grid-column: 6/-1;
  display: flex;
  gap: 5px;
  justify-self: end;
`;

const ClosingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.signal};
`;

type ModalBodyProps = {
  theme: DefaultTheme;
  onSwitchClosed: () => void;
};

enum SwitchStatus {
  Confirm,
  Processing,
  Done,
}

export const SingleLineManagerBody: React.FC<ModalBodyProps> = ({
  theme,
  onSwitchClosed,
}) => {
  const { formatMessage } = useIntl();
  const [switchStatus, setSwitchStatus] = useState<SwitchStatus>(
    SwitchStatus.Confirm
  );

  const checkCurrentStatus = (status: SwitchStatus) => switchStatus === status;

  useEffect(() => {
    const setStatusDone = () => {
      setSwitchStatus(SwitchStatus.Done);
      onSwitchClosed();
    };

    if (switchStatus === SwitchStatus.Processing) {
      const timer = setTimeout(() => setStatusDone(), 2000);
      return () => clearTimeout(timer);
    }
  }, [switchStatus, onSwitchClosed]);

  return (
    <PortalConsumer>
      {(value) => (
        <>
          <TopContainer>
            {!checkCurrentStatus(SwitchStatus.Processing) && (
              <>
                <Title>Single Line Manager</Title>
                <Button
                  style={{
                    borderRadius: "50%",
                    height: "35.19px",
                  }}
                  onclick={() => value.close!()}
                  size="xs"
                  type="primary"
                  icon={<MdClose fill={theme.colors.iconColor} size={18} />}
                />
              </>
            )}
          </TopContainer>
          {checkCurrentStatus(SwitchStatus.Confirm) && (
            <>
              <MainContainer>
                <Wendy
                  size={32}
                  BodyStyle={WendyBodyStyles.Alert}
                  HeadStyle={WendyHeadStyles.Surprised}
                />
                <div>
                  <p>Please confirm selected switch to be closed.</p>
                  <p>Affected Production Units: T1, T2, T3</p>
                </div>
              </MainContainer>
              <ActionBar>
                <ActionButtons>
                  <Button
                    onclick={() => value.close!()}
                    type="primary"
                    size="small"
                  >
                    {formatMessage({
                      id: "general.Cancel",
                      defaultMessage: "Translation Not Found",
                    })}
                  </Button>
                  <Button
                    onclick={() => setSwitchStatus(SwitchStatus.Processing)}
                    type="primary"
                    size="small"
                  >
                    {formatMessage({
                      id: "general.Confirm",
                      defaultMessage: "Translation Not Found",
                    })}
                  </Button>
                </ActionButtons>
              </ActionBar>
            </>
          )}
          {checkCurrentStatus(SwitchStatus.Processing) && (
            <ClosingContainer>
              <Wendy HeadStyle={WendyHeadStyles.EyesShut} />
              <h2>Closing</h2>
              <svg
                width="240"
                height="31"
                viewBox="0 0 240 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M239.5 30H142L113 1"
                  stroke="#FF762E"
                  stroke-width="2"
                  stroke-dasharray="4 4"
                />
                <path
                  d="M0 30L97.5 30"
                  stroke="#FF762E"
                  stroke-width="2"
                  stroke-dasharray="4 4"
                />
              </svg>
            </ClosingContainer>
          )}
          {checkCurrentStatus(SwitchStatus.Done) && (
            <ClosingContainer>
              <Wendy BodyStyle={WendyBodyStyles.Alert} />
              <h2>Closed!</h2>
            </ClosingContainer>
          )}
        </>
      )}
    </PortalConsumer>
  );
};
