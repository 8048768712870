import { StandardButton } from "components/Button/Button";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useScadaPlusTheme } from "../../../../hooks/useScadaPlusTheme";

const EditCorrectiveActionButtonContainer = styled.div`
  & div {
    color: ${(props) =>
      props.theme.colors.buttonColors.primaryButtonTextColor.primary};
  }
  grid-column: 3/4;
  justify-self: end;
`;

type Props = {
  edit: boolean;
  correctiveAction?: string;
  onClick: () => void;
};

export const EditCorrectiveActionButton: React.FC<Props> = ({
  edit,
  correctiveAction,
  onClick,
}) => {
  const { formatMessage } = useIntl();
  const { theme } = useScadaPlusTheme();

  return (
    <React.Fragment>
      {!edit && (
        <EditCorrectiveActionButtonContainer>
          <StandardButton
            color={theme.colors.buttonColors.primaryButtonColor}
            size="small"
            onclick={onClick}
          >
            {correctiveAction
              ? formatMessage({
                  id: "alarms.Edit",
                  defaultMessage: "Edit",
                })
              : formatMessage({
                  id: "alarms.AddCorrectiveAction",
                  defaultMessage: "Add corrective action",
                })}
          </StandardButton>
        </EditCorrectiveActionButtonContainer>
      )}
    </React.Fragment>
  );
};
