import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { retSaveDateFormat } from "utils/date-and-time-utils";
import Modal from "components/Modal";
import { Size, Background, PortalConsumer } from "components/Modal/Modal";
import { isUsingMobileDevice } from "utils/device-utils";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import {
  ServiceBoardingInsert,
  ServiceBoardingUpdate,
  ServiceLocations,
  ServicePersonnel,
  ServiceBoarding,
  ServicePersonnelInsert,
} from "types";
import config from "config";
import { useScadaPlusQuery } from "hooks/useScadaPlusQuery";
import Button from "components/Button";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { MdClose } from "react-icons/md";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import DropDownSelect from "components/DropDownSelect";

const PersonnelRegistrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor};
  padding: ${(props) => props.theme.spacing.heavy};
  border: 3px solid ${(props) => props.theme.colors.mainColor.tertiary};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  font-size: ${(props) => props.theme.scaledFontSizes.mediumLarge};
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.textColor.tertiary};
  font-size: ${(props) => props.theme.scaledFontSizes.large};
`;

const Heading = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing.light};
`;

const DropdownContainer = styled.div`
  justify-content: stretch;
  padding-top: 1%;
  align-items: left;
`;

const FieldLabel = styled.div`
  padding: 0.3125rem 0;
`;

const InputContainer = styled.div`
  padding-top: 1%;
  justify-content: stretch;
  align-items: left;
`;

const InputField = styled.input`
  width: 100%;
  height: 2.25rem;
  padding: 0.125rem 0.5rem;
  background-color: ${(props) => props.theme.colors.textInputBackgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.light};
  border: 1px solid;
  &:focus {
    border-color: ${(props) => props.theme.colors.mainColor.secondary};
    border-width: 0.125rem;
    outline: 0;
  }
`;

const BoardingDatesContainer = styled.div`
  padding-top: 1%;
  display: grid;
  grid-template-columns: 0.5fr 0.1fr 0.5fr 1fr;
  width: 100%;
`;

const FromDate = styled.div`
  width: 100%;
  grid-column: 1;
`;

const ToDate = styled.div`
  width: 100%;
  grid-column: 3;
`;

const PurposeTextArea = styled.textarea`
  padding: 2px 8px;
  width: 100%;
  height: 7vh;
  background-color: ${(props) => props.theme.colors.textInputBackgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.light};
  &:focus {
    border-color: ${(props) => props.theme.colors.mainColor.secondary};
    border-width: 2px;
    outline: 0;
  }
`;

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.warning};
  display: inline-block;
`;

const ButtonCancel = styled.div`
  grid-column: 1;
  float: right;
`;

const ButtonSave = styled.div`
  padding-left: 2%;
  float: right;
  grid-column: 1;
`;

const mobile = isUsingMobileDevice(window.navigator);

interface PersonnelProps {
  componentUnitId: string;
  plantId: string;
  boardedId?: string;
  data?: ServiceBoarding;
  onClose: () => void;
  action: string;
  actionFunc: (arg: string) => void;
  newBoarding?: boolean;
}

interface IFormInputs {
  location: string;
  personId: string;
  firstName: string;
  lastName: string;
  company: string;
  fromDateTime: string;
  toDateTime: string;
  purpose: string;
  newBoarding: boolean;
}

export const PersonnelRegistration: React.FC<PersonnelProps> = (props) => {
  const [SaveCloseButtonFace, setBtnFace] = useState("close");
  const [hlpBtnFace, sethlpBtnFace] = useState("close");
  const [inputFirstName, setinputFirstName] = useState(
    props.data?.person?.firstName
  );
  const [inputLastName, setinputLastName] = useState(
    props.data?.person?.lastName
  );
  const [personID, setPersonID] = React.useState(props.data?.person?.id);
  const [inputCompany, setinputCompany] = useState(props.data?.person?.company);
  const [inputDateFrom] = useState(props.data?.dateTimeFrom);
  const [inputDateTo] = useState(props.data?.dateTimeTo);

  const [inputLocation, setInputLocation] = useState("");
  const [inputPurpose] = useState(props.data?.purpose);
  const [selectedLocation, setSelectedLocation] = useState(PropsValue());
  const { formatMessage, formatDate } = useIntl();
  const queryClient = useQueryClient();

  const { theme } = useScadaPlusTheme();
  const [selectedPerson, setSelectedPerson] = useState(PropsValue());
  const [newBoarding] = useState(props.newBoarding);

  const [personList] = useState(optType());
  const [locationList] = useState(optType());
  const [SearchMode, setSearchMode] = useState(true);
  const [locationData, setLocationData] = useState<Array<ServiceLocations>>();
  const [initLocation, setInitLocation] = useState(true);

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<IFormInputs>();
  const serviceBoardingUrl = `${config.api.url}/${config.api.serviceBoarding}/${props.boardedId}`;
  const urlBoardingCount = `${config.api.url}/${config.api.serviceBoardingCounters}?IsActive=true&plantId=${props.plantId}`;
  const SearchUrl = `${config.api.url}/${config.api.servicePersonnel}?plantId=${props.plantId}&SearchText=`;
  const urlBoarding = `${config.api.url}/${config.api.serviceBoarding}?IsActive=true&plantId=${props.plantId}`;
  const urlServiceLocation = `${config.api.url}/${config.api.serviceLocations}?plantId=${props.plantId}`;
  const urlBoardingCountVessel = `${config.api.url}/${config.api.serviceBoardingCounters}?IsOffshore=true&plantId=${props.plantId}`;

  const { data: servicePersonData } = useScadaPlusQuery<
    Array<ServicePersonnel>
  >(SearchUrl, SearchUrl, undefined, undefined);

  type Options = {
    value: string;
    label: string;
  };

  const SaveBoarding: ServiceBoardingUpdate = {
    serviceBoardingId: props.boardedId,
    currentServiceLocationId: inputLocation
      ? inputLocation
      : props.data?.currentLocationId,
    dateTimeFrom: inputDateFrom ? inputDateFrom : props.data?.dateTimeFrom,
    dateTimeTo: inputDateTo ? inputDateTo : props.data?.dateTimeTo,
    servicePersonnelId: props.data?.servicePersonnelId,
    purpose: inputPurpose ? inputPurpose : props.data?.purpose,
  };

  const CreatePerson: ServicePersonnelInsert = {
    firstName: inputFirstName,
    lastName: inputLastName,
    company: inputCompany,
    plantId: props.plantId,
  };

  const CreateBoarding: ServiceBoardingInsert = {
    servicePersonnelId: personID,
    dateTimeFrom: inputDateFrom,
    dateTimeTo: inputDateTo,
    currentServiceLocationId: inputLocation,
    plantId: props.plantId,
    purpose: inputPurpose,
  };

  const setSaveCloseButtonFace = (face: string) => {
    const SaveText = formatMessage({
      id: "boarding.Save",
      defaultMessage: "Text missing",
    });
    const CloseText = formatMessage({
      id: "boarding.Close",
      defaultMessage: "Text missing",
    });

    setBtnFace(face === "save" ? SaveText : CloseText);
    sethlpBtnFace(face === "save" ? "save" : "close");
  };

  const SaveExistingBoarding = () => {
    axios
      .post(serviceBoardingUrl, JSON.stringify(SaveBoarding), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        queryClient.invalidateQueries(urlBoarding);
        queryClient.invalidateQueries(urlBoardingCount);
        queryClient.invalidateQueries(urlServiceLocation);
        queryClient.invalidateQueries(urlBoardingCountVessel);
        props.actionFunc("reload");
      })
      .catch((error: AxiosError) => {
        toast.error(error.message);
      });
  };

  const CreateNewBoarding = () => {
    const saveNewUrl = `${config.api.url}/${config.api.serviceBoarding}`;
    axios
      .post(saveNewUrl, JSON.stringify(CreateBoarding), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        queryClient.invalidateQueries(urlBoarding);
        queryClient.invalidateQueries(urlBoardingCount);
        queryClient.invalidateQueries(urlServiceLocation);
        queryClient.invalidateQueries(urlBoardingCountVessel);
      })
      .catch((error: AxiosError) => {
        toast.error(error.message);
      });
  };

  function optType(): Options[] {
    return [{ value: "", label: "" }];
  }

  function PropsValue(): any {
    return selectedPerson;
  }

  const SaveNewPerson: any = async () => {
    const servicePersonnelUrl = `${config.api.url}/${config.api.servicePersonnel}`;
    axios
      .post(servicePersonnelUrl, JSON.stringify(CreatePerson), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        CreateBoarding.servicePersonnelId = response.data.id;
        CreateNewBoarding();
        queryClient.invalidateQueries(urlBoarding);
        queryClient.invalidateQueries(urlBoardingCount);
        queryClient.invalidateQueries(urlServiceLocation);
        queryClient.invalidateQueries(urlBoardingCountVessel);
      });
  };

  const onSubmit: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    event?.preventDefault();
    if (!newBoarding) {
      SaveBoarding.purpose = data.purpose;
      SaveBoarding.dateTimeFrom = new Date(
        retSaveDateFormat(data.fromDateTime)
      );
      SaveBoarding.dateTimeTo = new Date(retSaveDateFormat(data.toDateTime));
      SaveExistingBoarding();
    } else {
      CreateBoarding.servicePersonnelId = personID;
      CreateBoarding.purpose = data.purpose;
      CreateBoarding.dateTimeFrom = new Date(
        retSaveDateFormat(data.fromDateTime)
      );
      CreateBoarding.dateTimeTo = new Date(retSaveDateFormat(data.toDateTime));
      CreateBoarding.currentServiceLocationId = inputLocation;
      if (personID === undefined || personID === "") {
        SaveNewPerson();
      } else {
        CreateNewBoarding();
      }
    }

    if (hlpBtnFace === "save") {
      setSaveCloseButtonFace("close");
    }
  };

  const fieldsChanged = () => {
    setSaveCloseButtonFace("save");
  };

  const loadPersonnelForSelect = () => {
    personList.length = 0;
    servicePersonData?.forEach((element) => {
      const id = element.id ? element.id : "";
      const firstN = element.firstName;
      const lastN = element.lastName;
      const comp = element.company;
      personList.push({
        value: id,
        label: firstN + " " + lastN + " - " + comp,
      });
    });
  };

  loadPersonnelForSelect();

  const { data: locationDataRes } = useScadaPlusQuery<Array<ServiceLocations>>(
    `${config.api.serviceLocations}?PlantId=${props.plantId}`,
    `${config.api.url}/${config.api.serviceLocations}?PlantId=${props.plantId}`
  );

  const loadLocationForSelect = () => {
    locationList.length = 0;
    locationData?.forEach((element) => {
      const id = element.serviceLocationId ? element.serviceLocationId : "";
      const desc = element.description;
      locationList.push({ value: id, label: desc ? desc : "" });
    });
  };

  loadLocationForSelect();

  const HandleChangePerson = (selectedOption: any) => {
    setPersonID(selectedOption.value);

    const found = servicePersonData?.find(
      (item) => item.id === selectedOption.value
    );
    if (found !== undefined) {
      setSearchMode(false);
      setValue("firstName", found.firstName ? found.firstName : "");
      setValue("lastName", found.lastName ? found.lastName : "");
      setValue("company", found.company ? found.company : "");
    }
  };

  const HandleChangeLocation = (selectedOption: any) => {
    setInputLocation(selectedOption.value);
    fieldsChanged();
  };

  const HandleCreatePerson = (inputValue: any) => {
    personList.push({ value: inputValue, label: inputValue });
    const found = personList.find((item) => item.value === inputValue);
    setSelectedPerson(found);

    if (found !== undefined) {
      const newFirstName = found.label.split("-")[0].split(" ")[0].trim();
      const lastNamePresent = found.label.split("-")[0].split(" ").length > 1;
      const newLastName = lastNamePresent
        ? found.label.split("-")[0].split(" ")[1].trim()
        : "";
      const newCompany =
        found.label.split("-").length > 1
          ? found.label.split("-")[1].trim()
          : "";

      setinputFirstName(newFirstName);
      setValue("firstName", newFirstName);

      if (newLastName !== "") {
        setinputLastName(newLastName);
        setValue("lastName", newLastName);
      }

      if (newCompany !== "") {
        setinputCompany(newCompany);
        setValue("company", newCompany);
      }

      setSearchMode(false);
    }
  };

  useEffect(() => {
    setLocationData(locationDataRes ? locationDataRes : []);

    const tmpLoc = inputLocation
      ? inputLocation
      : props.data?.currentLocationId;

    if (newBoarding) {
      if (locationList.length > 0) {
        if (initLocation) {
          let resLoc = "";
          locationDataRes?.forEach((loc) => {
            if (loc.description?.startsWith("Vessel")) {
              resLoc = loc.serviceLocationId;
              setInitLocation(false);
            }
          });
          setSelectedLocation(resLoc);
          setInputLocation(resLoc);
          setInitLocation(false);
        } else {
          setSelectedLocation(tmpLoc);
        }
      }
    } else {
      setSearchMode(false);
      setSelectedLocation(tmpLoc);
    }
  }, [
    initLocation,
    inputLocation,
    locationDataRes,
    locationList.length,
    newBoarding,
    props.data?.currentLocationId,
  ]);

  const getErrorMess = () => {
    return formatMessage({
      id: "boarding.ErrorMessage",
      defaultMessage: "Text missing",
    });
  };

  return (
    <Modal
      size={mobile ? Size.FullPage : Size.Medium}
      background={Background.Dark}
    >
      <PersonnelRegistrationContainer>
        <ModalHeader>
          <Heading>
            {formatMessage({
              id: "header.PersReg",
              defaultMessage: "Missing text",
            })}
          </Heading>
          <PortalConsumer>
            {(value) => (
              <Button
                style={{
                  borderRadius: "50%",
                  height: "35.19px",
                }}
                onclick={() => value.close?.()}
                size="xs"
                type="primary"
                icon={<MdClose fill={theme.colors.iconColor} size={18} />}
              />
            )}
          </PortalConsumer>
        </ModalHeader>

        <form onSubmit={handleSubmit(onSubmit)} onChange={fieldsChanged}>
          <DropdownContainer>
            <FieldLabel>
              {formatMessage({
                id: "boarding.Location",
                defaultMessage: "Text missing",
              })}
            </FieldLabel>
            <DropDownSelect
              options={locationList}
              onChange={HandleChangeLocation}
              onCreate={undefined}
              selectedOption={selectedLocation}
              dropdownColor={theme.colors.textInputBackgroundColor}
              dropdownHover={theme.colors.mainColor.quaternary}
              dropdownSelected={theme.colors.mainColor.secondary}
            />
          </DropdownContainer>

          <DropdownContainer>
            <FieldLabel>
              {formatMessage({
                id: "boarding.FirstName",
                defaultMessage: "Text Missing",
              })}
            </FieldLabel>
            {SearchMode ? (
              <>
                <DropDownSelect
                  placeholderSelect={formatMessage({
                    id: "boarding.Select",
                    defaultMessage: "Text Missing",
                  })}
                  options={personList}
                  onChange={HandleChangePerson}
                  onCreate={HandleCreatePerson}
                  selectedOption={selectedPerson}
                  dropdownColor={theme.colors.textInputBackgroundColor}
                  dropdownHover={theme.colors.mainColor.quaternary}
                  dropdownSelected={theme.colors.mainColor.secondary}
                />
              </>
            ) : (
              <>
                <InputField
                  {...register("firstName", { required: true })}
                  defaultValue={inputFirstName}
                />
                <ErrorMessage>
                  {errors.firstName && getErrorMess()}
                </ErrorMessage>
              </>
            )}
          </DropdownContainer>

          <InputContainer>
            <FieldLabel>
              {formatMessage({
                id: "boarding.LastName",
                defaultMessage: "Text Missing",
              })}
            </FieldLabel>
            <InputField
              {...register("lastName", { required: true })}
              defaultValue={inputLastName}
            />
            <ErrorMessage>{errors.lastName && getErrorMess()}</ErrorMessage>
          </InputContainer>

          <InputContainer>
            <FieldLabel>
              {formatMessage({
                id: "boarding.Company",
                defaultMessage: "Company",
              })}
            </FieldLabel>
            <InputField
              {...register("company", { required: true })}
              defaultValue={inputCompany}
            />
            <ErrorMessage>{errors.company && getErrorMess()}</ErrorMessage>
          </InputContainer>

          <BoardingDatesContainer>
            <FieldLabel>
              {formatMessage({
                id: "boarding.Boarded",
                defaultMessage: "Boarded",
              })}
            </FieldLabel>
            <FromDate>
              <InputField
                {...register("fromDateTime", { required: true })}
                defaultValue={formatDate(inputDateFrom)}
              />
              <ErrorMessage>
                {errors.fromDateTime && getErrorMess()}
              </ErrorMessage>
            </FromDate>
            <ToDate>
              <InputField
                {...register("toDateTime", {
                  required: false,
                })}
                defaultValue={formatDate(inputDateTo)}
              />
              <ErrorMessage>{errors.toDateTime && getErrorMess()}</ErrorMessage>
            </ToDate>
          </BoardingDatesContainer>

          <InputContainer>
            <FieldLabel>
              {formatMessage({
                id: "boarding.Purpose",
                defaultMessage: "Purpose",
              })}
            </FieldLabel>
            <PurposeTextArea
              {...register("purpose", { required: true })}
              defaultValue={inputPurpose}
            />
            <ErrorMessage>{errors.purpose && getErrorMess()}</ErrorMessage>
          </InputContainer>

          <ButtonSave>
            <PortalConsumer>
              {(value) => (
                <Button
                  type="primary"
                  size="medium"
                  center={true}
                  onclick={() => {
                    if (hlpBtnFace === "close") value.close?.();
                  }}
                >
                  {SaveCloseButtonFace}
                </Button>
              )}
            </PortalConsumer>
          </ButtonSave>

          <ButtonCancel>
            <PortalConsumer>
              {(value) => (
                <Button
                  type="primary"
                  size="medium"
                  center={true}
                  onclick={() => {
                    value.close?.();
                  }}
                >
                  {formatMessage({
                    id: "general.Cancel",
                    defaultMessage: "Text Missing",
                  })}
                </Button>
              )}
            </PortalConsumer>
          </ButtonCancel>
        </form>
      </PersonnelRegistrationContainer>
      <PortalConsumer>
        {(value) => {
          value.onClose = props.onClose;
          return <span></span>; // TODO: Had to be added due to react complaints. Use Hook - context?
        }}
      </PortalConsumer>
    </Modal>
  );
};
