import React from "react";
import { ThemeContext, ThemeDispatchContext } from "../providers/ScadaPlusThemeProvider";

export const useScadaPlusTheme = () => {
  const context = React.useContext(ThemeContext);
  if (context === undefined) {
    throw new Error(
      "useThemeContext must be used within a ScadaPlusThemeProvider"
    );
  }
  return context;
};

export const useScadaPlusThemeDispatch = () => {
  const context = React.useContext(ThemeDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useAuthDispatch must be used within a ScadaPlusThemeProvider"
    );
  }
  return context;
};
