import React from "react";

interface IComponentProps {
  width: number;
  height: number;
  color?: string;
  style?: React.CSSProperties;
}

export const HeadEyesShut: React.FC<IComponentProps> = (props) => {
  return (
    <svg
      data-testid="headeyesshut"
      width={props.width}
      height={props.width / 2}
      viewBox="0 0 28 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.36566 13.0455H21.6343C25.151 13.0455 28 10.1364 28 6.54545C28 2.95455 25.151 0 21.6343 0H6.36566C2.84897 0 0 2.90909 0 6.5C0 10.0909 2.84897 13.0455 6.36566 13.0455Z"
        fill="url(#paint0_radial)"
      />
      <path
        d="M9.65977 7.40909H3.82829C3.33862 7.40909 2.93799 7 2.93799 6.5C2.93799 6 3.33862 5.59091 3.82829 5.59091H9.70428C10.1939 5.59091 10.5946 6 10.5946 6.5C10.5946 7 10.1494 7.40909 9.65977 7.40909Z"
        fill="#FF762E"
      />
      <path
        d="M25.0175 7.40909H19.1415C18.6518 7.40909 18.2512 7 18.2512 6.5C18.2512 6 18.6518 5.59091 19.1415 5.59091H25.0175C25.5071 5.59091 25.9078 6 25.9078 6.5C25.9078 7 25.5071 7.40909 25.0175 7.40909Z"
        fill="#FF762E"
      />
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14 5.51923) rotate(90) scale(7.52623 16.1538)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EBEBEB" />
        </radialGradient>
      </defs>
    </svg>
  );
};
