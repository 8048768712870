import { useState } from "react";
import { useSignalRContext } from "./useSignalR";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useScadaPlusErrorDispatch } from "./useScadaPlusError";
import { useIntl } from "react-intl";

const SignalrActions = {
  SensorTarget: "newSensorData",
  AlarmTarget: "newAlarm",
  ErrorMessageTarget: "errorMessageTarget",
};

export type SensorData = {
  id: string;
  value: string;
  timestamp: string;
  windPowerPlantId?: string | null;
  windTurbineId?: string | null;
  sectorId?: string | null;
};

type AlarmData = {
  id: string;
  alarmtypeId: number;
  timestamp: string;
  windTurbineId: string | null;
  windPowerPlantId: string | null;
  sectorId: string | null;
};

type SensorSubscriptionState = {
  sensorData: SensorData | null;
  error: Error | null;
};

export type SubscribeToSensor = {
  filters: Array<SensorSubscriptionFilter>;
};

export type SensorSubscriptionFilter = {
  windPowerPlantId?: string | null;
  windTurbineId?: string | null;
  sectorId?: string | null;
  id: string;
};

type AlarmSubscriptionState = {
  data: AlarmData | null;
  error: Error | null;
};
export type SubscribeToAlarm = {
  filter: AlarmSubscriptionFilter;
};

type AlarmSubscriptionFilter = {
  windPowerPlantId?: string | null;
  windTurbineId?: string | null;
  sectorId?: string | null;
};

type ErrorMessage = {
  windPowerPlantId: string;
  message: string;
  title: string;
  windPowerPlantName: string;
};
const useErrorMessageSubscription = () => {
  const signalRContext = useSignalRContext();
  const dispatchError = useScadaPlusErrorDispatch();
  const { formatMessage } = useIntl();

  useDeepCompareEffect(() => {
    const onErrorData = (errorMessage: ErrorMessage) => {
      const staticMessage = formatMessage({
        id: "error.UnableToReceiveRealTimeData",
        defaultMessage: "SCADA+ does not receive real time data frp,",
      });

      dispatchError({
        show: true,
        text: `${staticMessage} ${errorMessage.windPowerPlantName}`,
      });
    };
    if (signalRContext.errorMessageConnection) {
      signalRContext.errorMessageConnection.connection.on(
        SignalrActions.ErrorMessageTarget,
        onErrorData
      );
    }
  }, [signalRContext.errorMessageConnection, dispatchError, formatMessage]);
};

const useAlarmSubscription = (subscribeTo: SubscribeToAlarm) => {
  const signalRContext = useSignalRContext();
  const { formatMessage } = useIntl();
  const dispatchError = useScadaPlusErrorDispatch();
  const [state, setState] = useState<AlarmSubscriptionState>({
    data: null,
    error: null,
  });

  useDeepCompareEffect(() => {
    const onAlarmData = (data: any) => {
      const alarmData = JSON.parse(data)
      if (
        alarmData.windPowerPlantId === subscribeTo.filter.windPowerPlantId ||
        alarmData.sectorId === subscribeTo.filter.sectorId ||
        alarmData.windTurbineId === subscribeTo.filter.windTurbineId
      ) {
        setState({ data: alarmData, error: null });
      }
    };

    if (signalRContext.alarmConnection) {
      signalRContext.alarmConnection.connection.on(
        SignalrActions.AlarmTarget,
        onAlarmData
      );

      signalRContext.alarmConnection?.connection
        ?.invoke("SubscribeTo", subscribeTo)
        // .catch(() => {
        //   dispatchError({
        //     show: true,
        //     text: formatMessage({
        //       id: "error.UnableToFetchData",
        //       defaultMessage:
        //         "We are really sorry - SCADA+ is experiencing issues retrieving data. Please contact Origo Support at: +47 932 38 009 or via e-mail at: support@origo.no",
        //     }),
        //   });
        // });
    }

    return () => {
      if (signalRContext.alarmConnection) {
        signalRContext.alarmConnection.connection.off(
          SignalrActions.AlarmTarget,
          onAlarmData
        );
      }
    };
  }, [signalRContext.alarmConnection, subscribeTo]);
  return state;
};

const useSensorSubscription = (subscribeTo: SubscribeToSensor) => {
  const signalRContext = useSignalRContext();
  const dispatchError = useScadaPlusErrorDispatch();
  const { formatMessage } = useIntl();
  const [state, setState] = useState<SensorSubscriptionState>({
    sensorData: null,
    error: null,
  });

  useDeepCompareEffect(() => {
    const onSensorData = (data: any) => {
      const sensorData = JSON.parse(data)
      const powerPlantId =
        subscribeTo.filters
          .map((x) => x.windPowerPlantId)
          .indexOf(sensorData.windPowerPlantId) > -1;
      const turbineId =
        subscribeTo.filters
          .map((x) => x.windTurbineId)
          .indexOf(sensorData.windTurbineId) > -1;
      const sectorId =
        subscribeTo.filters
          .map((x) => x.sectorId)
          .indexOf(sensorData.sectorId) > -1;
      const tag =
        subscribeTo.filters.map((x) => x.id).indexOf(sensorData.id) > -1;

      if (tag && (powerPlantId || turbineId || sectorId)) {
        setState({ sensorData: sensorData, error: null });
      }
    };

    if (signalRContext.sensorConnection) {
      signalRContext.sensorConnection.connection.on(
        SignalrActions.SensorTarget,
        onSensorData
      );

      signalRContext.sensorConnection?.connection
        ?.invoke("SubscribeTo", subscribeTo)
        // .catch(() => {
        //   dispatchError({
        //     show: true,
        //     text: formatMessage({
        //       id: "error.UnableToFetchData",
        //       defaultMessage:
        //         "We are really sorry - SCADA+ is experiencing issues retrieving data. Please contact Origo Support at: +47 932 38 009 or via e-mail at: support@origo.no",
        //     }),
        //   });
        // });
    }

    return () => {
      if (signalRContext.sensorConnection) {
        signalRContext.sensorConnection.connection.off(
          SignalrActions.SensorTarget,
          onSensorData
        );
      }
    };
  }, [signalRContext.sensorConnection, subscribeTo]);

  return state;
};

export {
  useAlarmSubscription,
  useSensorSubscription,
  useErrorMessageSubscription,
};
