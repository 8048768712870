import { ServiceBoarding } from "types";
import { PortalConsumer } from "components/Modal/Modal";
import { useIntl } from "react-intl";
import Button from "../Button";
import OpenEditSVG from "../svg/OpenEditSVG";
import PersonnelRegistration from "components/PersonnelRegistration";
import React, { useState } from "react";
import styled from "styled-components";
import { useLocalDateTimeFormatShort } from "hooks/useDateTimeFormat";

const Container = styled.div`
  border-radius: ${(props) => props.theme.borderRadius.medium};
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 2fr 0.5fr;
  width: 98%;
`;

const BoardingBox = styled.div`
  grid-column: 1;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 2fr;
  padding-bottom: 4%;
`;

const PersonBoarding = styled.div`
  grid-column: 1;
  font-weight: Normal;
  display: inline-block;
  font-size: ${(props) => props.theme.scaledFontSizes.mediumLarge};
  color: ${(props) => props.theme.colors.textColor.secondary};
`;

const From = styled.div`
  grid-row: 2;
  grid-column: 1;
  display: inline-block;
  font-weight: Normal;
  color: ${(props) => props.theme.colors.textColor.secondary};
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
`;

const Purpose = styled.div`
  grid-row: 3;
  grid-column: 1;
  display: inline-block;
  font-weight: Normal;
  color: ${(props) => props.theme.colors.textColor.secondary};
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
`;

const FromLabel = styled.div`
  grid-column: 1;
  font-weight: Bold;
  font-size: Medium;
  display: inline-block;
  color: ${(props) => props.theme.colors.textColor.secondary};
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
`;

const PurposeLabel = styled.div`
  grid-column: 1;
  font-weight: Bold;
  font-size: Medium;
  display: inline-block;
  color: ${(props) => props.theme.colors.textColor.tertiary};
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
`;

const IconOpenEdit = styled.div`
  grid-column: 3;
  display: flex;
  justify-content: top;
  width: 60px;
  display: inline-block;
  padding-top: 3%;
`;

type BoardedProps = {
  data: ServiceBoarding;
  type: string;
  componentUnitId: string;
  plantId: string;
  boardedId?: string;
  action: string;
  actionFunc: (arg: string) => void;
};

export const BoardedPersonnel: React.FC<BoardedProps> = (props) => {
  const dateTimeLocal = useLocalDateTimeFormatShort();
  const [action, setAction] = useState<string>("Mr John Obi");
  const updateAction = (name: string): void => {
    setAction(name);
    props.actionFunc("reload");
  };
  const [showPersonnelRegistration, setShowPersonnelRegistration] =
    useState(false);
  const { formatMessage } = useIntl();

  const clickEditRegistration = () => {
    setShowPersonnelRegistration(true);
  };

  return (
    <Container>
      <BoardingBox key={props.data.serviceBoardingId}>
        <PersonBoarding>
          {props.data.person?.firstName} {props.data.person?.lastName} |{" "}
          {props.data.person?.company}
        </PersonBoarding>
        <From>
          <FromLabel>
            {formatMessage({
              id: "boarding.Boarded",
              defaultMessage: "tbn",
            })}
            :
          </FromLabel>{" "}
          {dateTimeLocal.format(
            props.data.dateTimeFrom
              ? new Date(props.data.dateTimeFrom)
              : undefined
          )}
        </From>
        <Purpose>
          <PurposeLabel>
            {formatMessage({
              id: "boarding.Purpose",
              defaultMessage: "tbn",
            })}
            :
          </PurposeLabel>{" "}
          {props.data.purpose}
        </Purpose>
      </BoardingBox>
      <IconOpenEdit>
        <PortalConsumer>
          {(value) => (
            <Button
              onclick={clickEditRegistration}
              size="xs"
              type="secondary"
              icon={<OpenEditSVG width={"100%"} height={"100%"} />}
            />
          )}
        </PortalConsumer>
        {showPersonnelRegistration && (
          <PersonnelRegistration
            action={action}
            actionFunc={updateAction}
            onClose={() =>
              setShowPersonnelRegistration(!showPersonnelRegistration)
            }
            componentUnitId={props.componentUnitId}
            plantId={props.plantId}
            boardedId={props.data.serviceBoardingId}
            data={props.data}
            newBoarding={false}
          />
        )}
      </IconOpenEdit>
    </Container>
  );
};
