import { LatLngTuple } from "leaflet";
import React from "react";
import styled from "styled-components";
import AlarmDisplay from "../../AlarmDisplay";
import CustomMarker from "components/CustomMarker";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import { useSensorSubscription } from "hooks/useSubscription";
import { useTagsContext } from "hooks/useTags";
import { GenericAlarmPriorityCounter, Layers, ComponentUnit } from "types";
import { getHighestPriority, getTotalNumberOfAlarms } from "utils/alarm-utils";
import {
  getProductionInPercentage,
  resolvePriorityColor,
} from "utils/production-utils";
import CircleMarkerPersonnel from "./CircleMarkerPersonnel/CircleMarkerPersonnel";
import { TooltipContent } from "../WindTurbineMarker/TooltipContent";
import { useIntl } from "react-intl";
import AlarmPriorityHeaders from "components/AlarmPriorityHeaders";
import { useGetAlarmData } from "../WindTurbineMarker/hooks";
import { useProductionUnitCircleMarkerData } from "./hooks";

type CircleMarkerProps = {
  componentUnit: ComponentUnit;
  onClicked: () => void;
  layers: Layers;
  size: OuterCircleSize;
  productionLayerActive: boolean;
};

type OuterCircleProps = { color: string; size: OuterCircleSize };
export enum OuterCircleSize {
  SMALL = "25px",
  MEDIUM = "35px",
  LARGE = "40px",
}

const OuterCircle = styled.div<OuterCircleProps>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: ${(props) => props.color};
  padding: 0.1em;
`;

const PersonnelContainer = styled.div`
  position: absolute;
  top: -${(props) => props.theme.spacing.extraHeavy};
  left: 48%;
`;

export const CircleMarker: React.FC<CircleMarkerProps> = (props) => {
  const { formatMessage } = useIntl();
  const { tags } = useTagsContext();
  const { theme } = useScadaPlusTheme();

  const { newAlarmData, unitOperationCode, unitStatusCode } =
    useProductionUnitCircleMarkerData(props.componentUnit.id);

  const alarmPriorityData = useGetAlarmData(
    props.componentUnit.id,
    newAlarmData
  );

  const alarmPriorityCounter = getHighestPriority(
    alarmPriorityData ? alarmPriorityData : []
  );

  const markerPos: LatLngTuple = [
    props.componentUnit.coordinates.latitude,
    props.componentUnit.coordinates.longitude,
  ];

  const { sensorData: currentProduction } = useSensorSubscription({
    filters: [
      {
        id: tags.WTURW,
        windTurbineId: props.componentUnit.id,
      },
    ],
  });

  const totalNumberOfAlarms = getTotalNumberOfAlarms(
    alarmPriorityData ? alarmPriorityData : []
  );

  const mapToTooltip = () => {
    const tooltipTableData: Array<{ description: string; value: string }> = [];

    tooltipTableData.push({
      description: formatMessage({
        id: "overview.current",
        defaultMessage: "Missing translation",
      }),
      value:
        getProductionInPercentage(
          currentProduction && Number(currentProduction.value) > 0
            ? Number(currentProduction.value)
            : 0,
          props.componentUnit?.energyProductionCapacity ?? 0
        ).toFixed(2) + "  %",
    });

    return tooltipTableData;
  };

  const showAlarmDisplay = (
    alarmPriorityData: GenericAlarmPriorityCounter[]
  ) => {
    if (alarmPriorityData.length > 0) {
      return (
        <AlarmPriorityHeaders
          alarmPriorityData={alarmPriorityData}
          alarmTileWidth="110%"
        />
      );
    } else {
      return undefined;
    }
  };

  return (
    <CustomMarker
      markerPosition={markerPos}
      onClick={() => props.onClicked()}
      toolTipContent={
        <TooltipContent
          header={props.componentUnit.shortName}
          tooltipTableData={mapToTooltip()}
        >
          {showAlarmDisplay(alarmPriorityData ? alarmPriorityData : [])}
        </TooltipContent>
      }
    >
      {props.layers.personnel && (
        <PersonnelContainer>
          <CircleMarkerPersonnel />
        </PersonnelContainer>
      )}

      <OuterCircle
        size={props.size}
        color={resolvePriorityColor(
          unitOperationCode?.value,
          unitStatusCode?.value,
          theme
        )}
      >
        <AlarmDisplay
          outerCircle={true}
          priority={Number(alarmPriorityCounter?.priority)}
        >
          {totalNumberOfAlarms <= 0 ? "  " : totalNumberOfAlarms}
        </AlarmDisplay>
      </OuterCircle>
    </CustomMarker>
  );
};
