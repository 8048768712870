import { LatLngBoundsExpression } from "leaflet";
import { ComponentUnit } from "../types";

export const getAverageLongitude = (units: Array<ComponentUnit>) => {
  return (
    units
      .map((x) => x.coordinates.longitude)
      .reduce((sum, current) => sum + current, 0) / units.length
  );
};

export const getAverageLatitude = (units: Array<ComponentUnit>) => {
  return (
    units
      .map((x) => x.coordinates.latitude)
      .reduce((sum, current) => sum + current, 0) / units.length
  );
};

const getMinLatitude = (units: Array<ComponentUnit>) => {
  const min = 100;
  const minLat = units
    .map((aWindTurbine) => aWindTurbine.coordinates.latitude)
    .reduce((min, p) => (p < min ? p : min), min);
  return minLat;
};
const getMaxLatitude = (units: Array<ComponentUnit>) => {
  const max = 0;
  const maxLat = units
    .map((aWindTurbine) => aWindTurbine.coordinates.latitude)
    .reduce((max, p) => (p > max ? p : max), max);
  return maxLat;
};

const getMaxLongitude = (units: Array<ComponentUnit>) => {
  const max = 0;
  const maxLong = units
    .map((aWindTurbine) => aWindTurbine.coordinates.longitude)
    .reduce((max, p) => (p > max ? p : max), max);
  return maxLong;
};

const getMinLongitude = (units: Array<ComponentUnit>) => {
  const min = 100;
  const minLong = units
    .map((aWindTurbine) => aWindTurbine.coordinates.longitude)
    .reduce((min, p) => (p < min ? p : min), min);
  return minLong;
};

export const getMapBounds = (units: Array<ComponentUnit>) => {
  const bounds: LatLngBoundsExpression = [
    [getMinLatitude(units), getMinLongitude(units)],
    [getMaxLatitude(units), getMaxLongitude(units)],
  ];
  return bounds;
};
