// STKN new WT status card
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTheme } from "styled-components";
import InfoCard from "../../../components/InfoCard";
import TurbineStatus from "../../TurbineStatus";
import { useTagsContext } from "../../../hooks/useTags";
import { useIntl } from "react-intl";
import {
  WindTurbineOperationValues,
  WindTurbineStatusValues,
} from "utils/tag-utils";
import {
  resolveCardBackGroundColor,
  resolveHover,
  shouldUsePrimaryStyle,
  shouldUseSignalStyle,
  shouldUseWarningStyle,
} from "utils/production-utils";
import StatusCardIconResolver from "../StatusCardIconResolver";
import { SensorData, useSensorSubscription } from "hooks/useSubscription";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 85%;
`;

const StatusHeader = styled.div`
  font-size: ${(props) => props.theme.scaledFontSizes.large};
`;

const StatusDescription = styled.div`
  font-size: ${(props) => props.theme.scaledFontSizes.mediumLarge};
`;

const IconScalingContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 50%;
  width: 50%;
`;

interface StatusCardProps {
  componentUnitId: string;
  plantId: string;
  mobile?: boolean;
}

export const StatusCard: React.FC<StatusCardProps> = (props) => {
  const { tags } = useTagsContext();
  const { formatMessage } = useIntl();
  const [operationCode, setOperationCode] = useState<SensorData | null>(null);
  const [statusCode, setStatusCode] = useState<SensorData | null>(null);
  const [showTurbineStatus, setshowTurbineStatus] = useState(false);
  const theme = useTheme();

  const { sensorData: unitOperationCode } = useSensorSubscription({
    filters: [
      {
        id: tags.WTUROpCde,
        windTurbineId: props.componentUnitId,
      },
    ],
  });

  const { sensorData: unitStatusCode } = useSensorSubscription({
    filters: [
      {
        id: tags.WTURStCde,
        windTurbineId: props.componentUnitId,
      },
    ],
  });

  useEffect(() => {
    switch (unitOperationCode?.id) {
      case tags.WTUROpCde:
        setOperationCode(unitOperationCode);
        break;
      default:
        break;
    }
  }, [unitOperationCode, tags]);

  useEffect(() => {
    switch (unitStatusCode?.id) {
      case tags.WTURStCde:
        setStatusCode(unitStatusCode);
        break;
      default:
        break;
    }
  }, [unitStatusCode, tags]);

  const Click = () => {
    setshowTurbineStatus(!showTurbineStatus);
  };

  const resolveStatusHeader = () => {
    if (shouldUseWarningStyle(operationCode?.value, statusCode?.value)) {
      return formatMessage({
        id: "statusTurbine.Warning",
        defaultMessage: "Missing translation",
      });
    } else if (shouldUseSignalStyle(operationCode?.value, statusCode?.value)) {
      return formatMessage({
        id: "statusTurbine.Downtime",
        defaultMessage: "Missing translation",
      });
    } else if (shouldUsePrimaryStyle(operationCode?.value, statusCode?.value)) {
      return formatMessage({
        id: "overview.GridStatus.Normal",
        defaultMessage: "Missing translation",
      });
    } else {
      return formatMessage({
        id: "pu.Unknown",
        defaultMessage: "Missing translation",
      });
    }
  };

  const resolveStatusDescription = () => {
    if (statusCode?.value === WindTurbineStatusValues.ForcedCCWRetwistCables) {
      {
        return formatMessage({
          id: "pu.Status.ForcedCCWRetwistCables",
          defaultMessage: "Missing translation",
        });
      }
    } else if (
      statusCode?.value === WindTurbineStatusValues.ForcedCWRetwistCables
    ) {
      {
        return formatMessage({
          id: "pu.Status.ForcedCWRetwistCables",
          defaultMessage: "Missing translation",
        });
      }
    } else if (
      statusCode?.value === WindTurbineStatusValues.WeakWindNoOperation
    ) {
      {
        return formatMessage({
          id: "pu.Status.WeakWindNoOperation",
          defaultMessage: "Missing translation",
        });
      }
    } else if (statusCode?.value === WindTurbineStatusValues.NotYawed) {
      {
        return formatMessage({
          id: "pu.Status.NotYawed",
          defaultMessage: "Missing translation",
        });
      }
    } else if (
      statusCode?.value === WindTurbineStatusValues.OutOfWorkManualRestart
    ) {
      {
        return formatMessage({
          id: "pu.Status.OutOfWorkManualRestart",
          defaultMessage: "Missing translation",
        });
      }
    } else if (
      statusCode?.value === WindTurbineStatusValues.StrongWindNoOperation
    ) {
      {
        return formatMessage({
          id: "pu.Status.StrongWindNoOperation",
          defaultMessage: "Missing translation",
        });
      }
    } else if (
      statusCode?.value === WindTurbineStatusValues.StrongWindTurbineNotStarting
    ) {
      {
        return formatMessage({
          id: "pu.Status.StrongWindTurbineNotStarting",
          defaultMessage: "Missing translation",
        });
      }
    } else if (
      statusCode?.value === WindTurbineStatusValues.WaitingForSufficientWind
    ) {
      {
        return formatMessage({
          id: "pu.Status.WaitingForSufficientWind",
          defaultMessage: "Missing translation",
        });
      }
    } else if (!operationCode?.value) {
      {
        return formatMessage({
          id: "pu.Status",
          defaultMessage: "Missing translation",
        });
      }
    } else if (operationCode?.value === WindTurbineOperationValues.Normal) {
      {
        return "";
      }
    } else {
      {
        return formatMessage({
          id: "statusTurbine.Downtime",
          defaultMessage: "Missing translation",
        });
      }
    }
  };

  return (
    <InfoCard
      title={formatMessage({
        id: "pu.Status",
        defaultMessage: "Missing translation",
      })}
      style={{
        height: "100%",
      }}
      onClick={Click}
      backgroundColor={resolveCardBackGroundColor(
        operationCode?.value,
        statusCode?.value,
        theme
      )}
      hoverColor={resolveHover(operationCode?.value, statusCode?.value, theme)}
    >
      <Container>
        <IconScalingContainer>
          <StatusCardIconResolver
            operationCode={operationCode?.value}
            statusCode={statusCode?.value}
            iconHeight="100%"
            iconWidth="100%"
            size={50}
          />
        </IconScalingContainer>
        <StatusHeader>{resolveStatusHeader()}</StatusHeader>
        <StatusDescription>{resolveStatusDescription()}</StatusDescription>
        {showTurbineStatus && (
          <TurbineStatus
            onClose={() => setshowTurbineStatus(!showTurbineStatus)}
            windTurbineId={props.componentUnitId}
            plantId={props.plantId}
            backgroundColor={resolveCardBackGroundColor(
              operationCode?.value,
              statusCode?.value,
              theme
            )}
            text1={resolveStatusHeader()}
            text2={resolveStatusDescription()}
            warningBackgroundColor={theme.colors.textColor.primary}
            warningSymbolColor={theme.colors.warning}
            operationCode={operationCode?.value}
            statusCode={statusCode?.value}
          />
        )}
      </Container>
    </InfoCard>
  );
};
