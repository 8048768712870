import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { GenericMenu } from "../../types";
import Button from "../Button";
import { MdBrightness2, MdPerson, MdWbSunny } from "react-icons/md";
import {
  useScadaPlusTheme,
  useScadaPlusThemeDispatch,
} from "../../hooks/useScadaPlusTheme";
import { light, dark, ThemeNames } from "../../theme";
import { useIntl } from "react-intl";
import {
  useScadaPlusLanguage,
  useScadaPlusLanguageDispatch,
} from "../../hooks/useScadaPlusLanguage";
import NavigationMenu from "../NavigationMenu";
import User from "../User";
import Clock from "components/Clock";
import { TimeZone } from "components/Clock/Clock";
import HomeButton from "components/HomeButton";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: "3em";
  grid-template-rows: 1fr 0.5fr;
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundColor.primary};
  padding: 1%;
  background-color: ${(props) => props.theme.colors.headerBackgroundColor};
`;

const ScadaPlusArea = styled.div`
  grid-column: 1;
  grid-row: 1;
  place-self: left;
`;

const MenuArea = styled.div`
  grid-column: 1;
  place-self: left;
  grid-row: 2;
`;

const ButtonsArea = styled.div`
  grid-column: 3;
  display: flex;
  //flex-direction: row;
  grid-row: 2;
  justify-content: flex-end;
  gap: ${(props) => props.theme.spacing.heavy};
`;

const LanguageText = styled.div`
  font-size: ${(props) => props.theme.constantFontSizes.medium};
  color: ${(props) => props.theme.colors.headerButtonIconColor};
  display: flex;
  place-items: center;
`;

const ClockArea = styled.div`
  padding-top: 3%;
  grid-column: 3;
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-right: 0.2em;
  font-weight: 600;
  white-space: nowrap;
`;

const LocalTime = styled.div`
  font-size: 1em;
  color: ${(props) => props.theme.colors.mainColor.primary};
`;
const UTCTime = styled.div`
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
  color: ${(props) => props.theme.colors.mainColor.secondary};
`;
const UTC = styled.span`
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
`;

type HeaderProps = {
  menu: GenericMenu;
  mobile?: boolean;
};

export const MobileHeader: React.FC<HeaderProps> = (props) => {
  const history = useHistory();
  const dispatchTheme = useScadaPlusThemeDispatch();
  const language = useScadaPlusLanguage();
  const dispatchLanguage = useScadaPlusLanguageDispatch();
  const { formatMessage } = useIntl();
  const { theme } = useScadaPlusTheme();
  const flipTheme = () => {
    if (theme.name === ThemeNames.Light) {
      dispatchTheme(dark);
    } else {
      dispatchTheme(light);
    }
  };

  const flipLanguage = () => {
    if (language.language === "en") {
      dispatchLanguage("no");
    } else {
      dispatchLanguage("en");
    }
  };

  return (
    <Grid>
      <ScadaPlusArea>
        <HomeButton onClicked={() => history.push("/")} size={"2.5em"} />
      </ScadaPlusArea>
      <MenuArea>
        <NavigationMenu menu={props.menu} mobile={props.mobile} />
      </MenuArea>
      <ButtonsArea>
        <Button
          style={{ backgroundColor: `${theme.colors.headerDropdownColor}` }}
          size="small"
          type="secondary"
          icon={
            theme.name === "dark" ? (
              <MdWbSunny fill={theme.colors.headerButtonIconColor} />
            ) : (
              <MdBrightness2 fill={theme.colors.headerButtonIconColor} />
            )
          }
          iconSize={props.mobile ? 20 : 25}
          onclick={() => flipTheme()}
        />
        <Button
          style={{ backgroundColor: `${theme.colors.headerDropdownColor}` }}
          size="small"
          type="secondary"
          onclick={() => flipLanguage()}
        >
          <LanguageText>
            {formatMessage({
              id: "general.languageName",
              defaultMessage: "M",
            })}
          </LanguageText>
        </Button>
        {!props.mobile && <UserButton />}
      </ButtonsArea>

      <ClockArea>
        <LocalTime>
          <Clock timeZone={TimeZone.LOCAL} />
        </LocalTime>
        <UTCTime>
          <UTC>UTC</UTC> <Clock timeZone={TimeZone.UTC} />
        </UTCTime>
      </ClockArea>
    </Grid>
  );
};

const UserButton: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const { theme } = useScadaPlusTheme();

  return (
    <>
      <Button
        size="small"
        type="secondary"
        style={{
          backgroundColor: `${theme.colors.headerDropdownColor}`,
        }}
        onclick={() => setShowModal(!showModal)}
        icon={<MdPerson size={25} fill={theme.colors.headerButtonIconColor} />}
      ></Button>
      {showModal && <User onClose={() => setShowModal(!showModal)} />}
    </>
  );
};
