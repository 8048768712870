import { DefaultTheme } from "styled-components";
import { Tags } from "../types";

interface TagsIndexer {
  [key: string]: string | undefined;
}

export enum WindTurbineStatusValues {
  FaultCondition = "Fault condition",
  ForcedCCWRetwistCables = "Forced CCW retwist of cables",
  ForcedCWRetwistCables = "Forced CW retwist of cables",
  WeakWindNoOperation = "No operation, too weak wind",
  NotYawed = "Not yawed up in the wind yet",
  OutOfWorkManualRestart = "Out of work, has to be started manually",
  SufficientWindForProduction = "Sufficient wind for production",
  StrongWindNoOperation = "Too strong wind to operate",
  StrongWindTurbineNotStarting = "Too strong wind to start the turbine",
  WaitingForSufficientWind = "Waiting for sufficient wind for production",
}

export enum WindTurbineOperationValues {
  Normal = "Normal",
  OperationalStopAutomaticRestart = "Operational stop with automatic start",
  ServiceMode = "Service-mode",
  StoppedRequiresManualReset = "Stopped, requires manual reset",
  StoppedSevereError = "Stopped, severe error",
}

export const getTagFromId = (tagId: string, tags: Tags) => {
  let tag = "";
  Object.keys(tags).forEach((k) => {
    if ((tags as TagsIndexer)[k] === tagId) {
      tag = k;
      return tag;
    }
  });
  return tag;
};

export const getTemperatureColor = (
  tagId: string,
  tags: Tags,
  theme: DefaultTheme
) => {
  const tag = getTagFromId(tagId, tags);
  const color = theme.colors.temperatureColors?.get(tag);
  return color ? color : theme.colors.mainColor.primary;
};
