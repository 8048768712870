import config from "config";
import { useScadaPlusQuery } from "hooks/useScadaPlusQuery";
import { useTagsContext } from "hooks/useTags";
import { LastSample } from "types";
export const useGetLastProduction = (componentUnitId: string) => {
  const { tags } = useTagsContext();
  const { data: lastProductionData } = useScadaPlusQuery<LastSample>(
    `last-sample-${componentUnitId}-${tags.WTURW}`,
    `${config.api.url}/${config.api.lastSample}?windTurbineId=${componentUnitId}&id=${tags.WTURW}`
  );
  return lastProductionData;
};