import React from "react";

const getTrans = (angle: number, deltaRadius: number) => {
  const rotX = 87.5 - deltaRadius;
  const rotY = 87.5;

  return (
    "translate(" +
    deltaRadius +
    " " +
    0 +
    ") rotate(" +
    angle +
    ", " +
    rotX +
    ", " +
    rotY +
    ")"
  );
};

interface IComponentProps {
  width: number | string;
  height: number | string;
  color?: string;
  style?: React.CSSProperties;
  fill?: string;
  objectFill: string;
  colorMru?: string;
  posGnssX?: number;
  posGnssY?: number;
  posMruX?: number;
  posMruY?: number;
  angle?: number;
  r?: number;
}

export const PositionAndWindAnimationSVG: React.FC<IComponentProps> = (
  props
) => {
  return (
    <svg
      width={props.height}
      height={props.width}
      viewBox="-20 -20 210 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="87.5" cy="87.5" r="35.119" fill={props.fill} />
      <circle
        cx="87.5"
        cy="87.5"
        r="60.3095"
        stroke={props.fill}
        strokeWidth="2"
      />
      <circle
        cx="87.5"
        cy="87.5"
        r="86.5"
        stroke={props.fill}
        strokeWidth="2"
      />
      <circle
        cx={props.posGnssX}
        cy={props.posGnssY}
        r="16.0714"
        fill={props.objectFill}
        fillOpacity="0.8"
      />
      <circle
        cx={props.posMruX}
        cy={props.posMruY}
        r="14.0714"
        strokeWidth="3"
        stroke={props.colorMru}
      />
      <path
        transform={getTrans(
          props.angle ? props.angle + 270.0 : 0,
          props.r ? props.r : 0
        )}
        d="M112.745 87.5545C111.846 87.8642 111.846 89.1358 112.745 89.4455L152.243 103.051C153.144 103.361 153.927 102.356 153.407 101.558L145.24 89.0466C145.024 88.7144 145.024 88.2856 145.24 87.9534L153.407 75.4415C153.927 74.6441 153.144 73.6394 152.243 73.9495L112.745 87.5545Z"
        fill={props.objectFill}
        fillOpacity="0.8"
      />
    </svg>
  );
};
