import { usePersonnelLocationCounter } from "hooks/useGetPersonnelOnSite";
import { ComponentUnit, ObjectType, PersonnelLevel } from "types";
type PersonellInformation = {
  turbines: ObjectType<PersonnelLevel>;
};

export const usePersonnel = (
  plantId: string,
  personnel: boolean,
  componentUnits: ComponentUnit[]
) => {
  const { data: personnelLocationCounter } = usePersonnelLocationCounter(
    plantId,
    ""
  );
  const personnelInformation: PersonellInformation = {
    turbines: {},
  };

  //TODO: Extract to seperate function to make more clean
  const turbines = personnelLocationCounter
    ? personnelLocationCounter.serviceLocationTypes.filter(
        (personnel) =>
          personnel.serviceLocationTypeDescription === "Wind Turbine"
      )
    : undefined;

  if (personnel && componentUnits) {
    const turbineIds = componentUnits.map((x) => x.id);

    turbineIds.forEach((element) => {
      const t = turbines?.find((s) => s.componentUnitId === element);

      if (t && t.servicePersonellOnBoard && t.servicePersonellOnBoard > 0) {
        personnelInformation.turbines[element] = "attention";
      } else personnelInformation.turbines[element] = "none";
    });
  }
  return personnelInformation;
};
