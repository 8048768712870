import React from "react";

interface IComponentProps {
  width: number | string;
  height: number | string;
  color?: string;
  style?: React.CSSProperties;
}

export const ArrowDropDownSVG: React.FC<IComponentProps> = (props) => {
  return (
    <svg
      width={props.width.toString()}
      height={props.height.toString()}
      viewBox="0 0 48 25"
      fill={props.color}
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
    >
      <path
        id="path47"
        transform="matrix(1,0,0,0.55814817,0,0.02245693)"
        d="M 20.339686,35.127719 10.213858,17.589272 0.08803105,0.05082566 20.339686,0.05082512 40.591339,0.05082458 30.465512,17.589272 Z"
      />
    </svg>
  );
};
