import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { StyledToastContainer } from "../hooks/useToasts";
import { Slide } from "react-toastify";
import { useTagsContext, useTagsDispatch } from "../hooks/useTags";
import { ActionType } from "../providers/ScadaPlusTagsProvider";
import { useGetTags } from "../hooks/useGetTags";
import { useGetMenu } from "../hooks/useGetMenu";
import Routes from "./Routes";
import LoadingPage from "./LoadingPage";
import config from "../config";
import {
  usePermissions,
  usePermissionsDispatch,
} from "../hooks/usePermissions";
import MobileRoutes from "./MobileRoutes";
import { isUsingMobileDevice } from "../utils/device-utils";
import { useGlobalSignalRConnection } from "../hooks/useConnection";
import { useSignalRDispatch } from "../hooks/useSignalR";
import { ActionType as SignalRActionType } from "../providers/ScadaPlusSignalRProvider";
import { useErrorMessageSubscription } from "../hooks/useSubscription";
import axios from "axios";
const ScadaPlus: React.FC = () => {
  const dispatch = useTagsDispatch();
  const { isTagsLoaded } = useTagsContext();
  const { data: tags, isFetching: isFetchingTagNames } = useGetTags();
  const { data: menu, isFetching: fetchingMenu } = useGetMenu();
  const permissions = usePermissions();
  const permissionDispatch = usePermissionsDispatch();
  const errorMessageConnection = useGlobalSignalRConnection(
    config.errorMessageSubscribeURL
  );
  const dispatchSignalR = useSignalRDispatch();
  useErrorMessageSubscription();
  React.useEffect(() => {
    if (errorMessageConnection && errorMessageConnection.connection) {
      dispatchSignalR({
        connection: errorMessageConnection.connection!,
        connectionId: errorMessageConnection.connectionId!,
        type: SignalRActionType.SetErrorMessageConnection,
      });
    }
  }, [dispatchSignalR, errorMessageConnection]);

  React.useEffect(() => {
    if (tags && !isFetchingTagNames) {
      dispatch({
        tags: tags,
        type: ActionType.SetTags,
      });
    }
  }, [dispatch, isFetchingTagNames, tags]);

  React.useEffect(() => {
    axios
      .get(`${config.api.url}/${config.api.genericUsers}`)
      .then((res) => permissionDispatch(res.data));
  }, [permissionDispatch]);

  return (
    <Router>
      <StyledToastContainer transition={Slide} />
      {fetchingMenu || !menu || !isTagsLoaded || !permissions.loaded ? (
        <LoadingPage />
      ) : isUsingMobileDevice(window.navigator) ? (
        <MobileRoutes menu={menu} />
      ) : (
        <Routes menu={menu} />
      )}
    </Router>
  );
};

export default ScadaPlus;
