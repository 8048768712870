import { useLocalDateTimeFormatShort } from "hooks/useDateTimeFormat";
import { FormattedRelativeTime, useIntl } from "react-intl";

type FormattedTimestampProps = {
  timestamp: string;
};

export const FormattedTimestamp = (props: FormattedTimestampProps) => {
  const dateTimeLocal = useLocalDateTimeFormatShort();
  const adjustedtimeStamp = props.timestamp;
  const fiveMinutesInMilliseconds = 5 * 60 * 1000;
  const fiveMinutesAgo = new Date(
    new Date().setMilliseconds(-fiveMinutesInMilliseconds)
  );
  const timestamp = new Date(adjustedtimeStamp);
  const difference = timestamp.getTime() - fiveMinutesAgo.getTime();

  const seconds = ((new Date().getTime() - timestamp.getTime()) / 1000) * -1;

  //Only less than 5 minutes
  if (difference <= fiveMinutesInMilliseconds && difference > 0) {
    return (
      <FormattedRelativeTime updateIntervalInSeconds={1} value={seconds} />
    );
  } else {
    return <span>{dateTimeLocal.format(timestamp)}</span>;
  }
};
