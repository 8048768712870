import { useQuery, UseQueryResult } from "react-query";
import { ErrorMessage, ObjectType } from "../types";
import axios from 'axios';

export const useScadaPlusQuery = <T>(
  queryName: string,
  url: string | (() => string),
  refetchInterval?: number | undefined,
  onError?: (errorMessage: ErrorMessage) => void,
  onQuery?: () => void | undefined,
  ): UseQueryResult<T> => {

  const options: ObjectType<number> = {};

  if (refetchInterval) {
    options["refetchInterval"] = refetchInterval;
  }
  
  return useQuery<T, string>(
    queryName,
    async () => {
      try {

       const response = await axios.get(typeof url === 'string' ? url : url())
        
        onQuery && onQuery();

        if (response.status === 200) {
          const data = await response.data
          return data;
        } else {
            onError &&
              onError({
                status: response.status,
                text: response.statusText,
              });
        }
      } catch (e) {
        const error = e as Error;
        onError &&
          onError({
            text: error.message.toString(),
          });
      }
    },
    options
  );
};
