import React from "react";
import styled, { DefaultTheme } from "styled-components";
import AlarmPriorityDisplayTile from "components/AlarmPriorityDisplay";
import { GenericAlarmPriorityCounter } from "types";

/* prettier-ignore */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.spacing.heavy};
  background-color: ${(props) =>
    props.theme.colors.componentBackgroundActive.primary};
  @media screen and (max-height: ${(props) => props.theme.mediaQueries.maxHeight}) {
  padding: ${(props) => props.theme.spacing.medium};
  }
  grid-column-gap: ${(props) => props.theme.spacing.light};
  border-radius: ${(props) => props.theme.borderRadius.light};
`;

const AlarmCount = styled.span`
  font-size: ${(props) => props.theme.scaledFontSizes.mediumLarge};
  color: ${(props) => props.theme.colors.textColor.primary};
`;

type AlarmTypeCounterProps = {
  data: GenericAlarmPriorityCounter;
  theme: DefaultTheme;
};

export const AlarmTypeCounter: React.FC<AlarmTypeCounterProps> = (props) => (
  <Container>
    <AlarmCount>{props.data.numberOfAlarms}</AlarmCount>

    <AlarmPriorityDisplayTile
      height="100%"
      width="100%"
      alarmPriortity={props.data.priority}
    >
      Pri {props.data.priority}
    </AlarmPriorityDisplayTile>
  </Container>
);
