import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { GenericAlarm } from "types";
import { useToasts } from "hooks/useToasts";
import Button from "components/Button";
import config from "config";
import { useMutation } from "react-query";
import CheckBox from "components/CheckBox";
import { useIntl } from "react-intl";
import FadeAnimation from "components/FadeAnimation";
import Wendy from "components/Wendy";
import { WendyHeadStyles, WendyBodyStyles } from "components/Wendy/Wendy";
import AlarmItem from "./AlarmItem";
import { Acknowledge, Type, Time, Title, Unit, Area, Supplier } from "./areas";
import axios from "axios";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";

const Container = styled.div``;

const Header = styled.div`
  display: grid;
  grid-template-columns: repeat(17, minmax(0, 1fr));
  background-color: ${(props) => props.theme.colors.mainColor.tertiary};
  padding: ${(props) => props.theme.spacing.medium};
  border-radius: ${(props) => props.theme.borderRadius.light};
  padding-right: calc(
    ${(props) => props.theme.scrollBarWidth} +
      ${(props) => props.theme.spacing.medium}
  ); //Compensate for scrollbar
  margin-bottom: 2px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: right;
  background-color: ${(props) => props.theme.colors.mainColor.quinary};
  padding: ${(props) => props.theme.spacing.medium};
  padding-right: calc(
    ${(props) => props.theme.scrollBarWidth} +
      ${(props) => props.theme.spacing.medium}
  ); //Compensate for scrollbar
  border-radius: ${(props) => props.theme.borderRadius.light};
  margin-bottom: 2px;
`;

const ListContainer = styled.div`
  max-height: 70vh;
  overflow-y: scroll;
  white-space: nowrap;
`;

const NoAlarmsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  color: ${(props) => props.theme.colors.textColor.secondary};
`;

const ToastContent = styled.div`
  display: flex;
  align-items: center;
`;

const ToastText = styled.div`
  margin-left: ${(props) => props.theme.spacing.medium};
`;

interface IComponentProps {
  data: Array<GenericAlarm>;
}

export type AlarmViewModel = {
  data: GenericAlarm;
  checked: boolean;
  loading: boolean;
};

type Post = {
  ids: Array<string>;
};

export const AlarmList: React.FC<IComponentProps> = (props) => {
  const { formatMessage } = useIntl();
  const scadaToasts = useToasts({ autoClose: false });
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const { theme } = useScadaPlusTheme();
  const [alarms, setAlarms] = useState<Array<AlarmViewModel>>([]);

  const filterActiveAlarms = (alarm: AlarmViewModel) => {
    return alarm.data.isActive;
  };
  const activeAlarms = alarms?.filter(filterActiveAlarms);

  const mutatePutAcknowledge = useMutation((data: Post) =>
    axios
      .put(
        `${config.api.url}/${config.api.genericAlarm}/bulkacknowledge`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => true)
      .catch(() => false)
  );

  useEffect(() => {
    setShouldUpdate(true);
  }, [props.data]);

  useEffect(() => {
    if (shouldUpdate) {
      setShouldUpdate(false);
      if (props.data.length > alarms.length) {
        for (let i = alarms.length; i < props.data.length; i++) {
          const newAlarm = props.data[i];
          setAlarms((current) => [
            ...current,
            {
              data: newAlarm,
              checked: newAlarm.isAcknowledged,
              loading: false,
            },
          ]);
        }
      } else {
        setAlarms([]);
        const newAlarms: AlarmViewModel[] = [];
        for (let i = 0; i < props.data.length; i++) {
          const element = props.data[i];
          newAlarms.push({
            data: element,
            checked: element.isAcknowledged,
            loading: false,
          });
        }
        setAlarms(newAlarms);
      }
    }
  }, [alarms.length, props.data, shouldUpdate]);

  const toggleAlarmCheckBox = (alarmId: string) => {
    const newArr = [...alarms];

    const alarm = newArr.find((element) => element.data.id === alarmId);

    if (alarm) {
      newArr[newArr.indexOf(alarm)].checked = !alarm.checked;
      setAlarms(newArr);
      toggleButtonState(newArr);
    }
  };

  const toggleButtonState = (alarms: AlarmViewModel[]) => {
    if (newlyCheckedAlarms(alarms).length > 0) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };

  const newlyCheckedAlarms = (alarms: AlarmViewModel[]) => {
    return alarms.filter(
      (alarmEntry) =>
        alarmEntry.checked === true && !alarmEntry.data.isAcknowledged
    );
  };

  const acknowledgeCheckedAlarms = async () => {
    const newArr = [...alarms];

    const alarmsToAcknowledge = newlyCheckedAlarms(newArr);

    if (alarmsToAcknowledge.length > 0) {
      const id = scadaToasts.basic(
        <ToastContent>
          <Wendy HeadStyle={WendyHeadStyles.Surprised} />
          <ToastText style={{ marginLeft: "1em" }}>
            {formatMessage({
              id: "alarms.UpdatingAlarms",
              defaultMessage: "Updating alarm(s)...",
            })}
          </ToastText>
        </ToastContent>
      );

      alarmsToAcknowledge.forEach((alarm) => {
        newArr[newArr.indexOf(alarm)].loading = true;
      });
      setAlarms(newArr);

      try {
        const ids = {
          ids: alarmsToAcknowledge.map((a) => a.data.id),
        };
        const data = await mutatePutAcknowledge.mutateAsync(ids);

        if (data) {
          alarmsToAcknowledge.forEach((alarm) => {
            const index = newArr.indexOf(alarm);
            newArr[index].loading = false;
            newArr[index].data.isAcknowledged = true;
          });
          setAlarms(newArr);
          toggleButtonState(newArr);
          setShouldUpdate(true);
          setSelectAll(false);
          scadaToasts.update(id, {
            type: "success",
            render: (
              <ToastContent>
                <Wendy HeadStyle={WendyHeadStyles.Happy} />
                <ToastText>
                  {formatMessage({
                    id: "alarms.AlarmsAcknowledged",
                    defaultMessage: "Alarm(s)s acknowledged!",
                  })}
                </ToastText>
              </ToastContent>
            ),
            autoClose: 1000,
          });
        }
      } catch {
        alarmsToAcknowledge.forEach((alarm) => {
          const index = newArr.indexOf(alarm);
          newArr[index].loading = false;
        });

        setAlarms(newArr);
        scadaToasts.update(id, {
          type: "error",
          render: (
            <ToastContent>
              <Wendy
                BodyStyle={WendyBodyStyles.Alert}
                HeadStyle={WendyHeadStyles.Surprised}
              />
              <ToastText>
                {formatMessage({
                  id: "alarms.AcknowledgeError",
                  defaultMessage: "Failed to acknowledge alarm(s)",
                })}
              </ToastText>
            </ToastContent>
          ),
          autoClose: 1000,
        });
      }
    }
  };

  return (
    <Container>
      <Header data-testid="listheader">
        <Acknowledge>
          <CheckBox
            onChange={(e: React.MouseEvent) => {
              e.stopPropagation();
              const alarmsToUpdate = [...alarms];
              alarmsToUpdate.forEach((alarm) => {
                if (!alarm.data.isAcknowledged) {
                  alarm.checked = !selectAll;
                }
              });
              setAlarms(alarmsToUpdate);
              setSelectAll(!selectAll);
              toggleButtonState(alarmsToUpdate);
            }}
            checked={selectAll}
            checkedBackgroundColor={`${
              !selectAll ? "transparent" : theme.colors.textColor.primary
            }`}
            checkedIconColor={
              !selectAll
                ? "transparent"
                : theme.colors.alarmPriorities?.[1].NotAcknowledged
                    .priorityTileFontColor
            }
          />
          <div>
            {formatMessage({
              id: "alarms.Priority",
              defaultMessage: "Missing Text",
            })}
          </div>
        </Acknowledge>
        <Type>
          {formatMessage({
            id: "alarms.Type",
            defaultMessage: "Missing Text",
          })}
        </Type>
        <Time>
          {formatMessage({
            id: "alarms.Time",
            defaultMessage: "Missing Text",
          })}
        </Time>
        <Title>
          {formatMessage({
            id: "alarms.Title",
            defaultMessage: "Missing Text",
          })}
        </Title>
        <Unit>
          {formatMessage({
            id: "alarms.Unit",
            defaultMessage: "Missing Text",
          })}
        </Unit>
        <Area>
          {formatMessage({
            id: "alarms.Area",
            defaultMessage: "Missing Text",
          })}
        </Area>
        <Supplier>
          {formatMessage({
            id: "alarms.Supplier",
            defaultMessage: "Missing Text",
          })}
        </Supplier>
      </Header>
      <ListContainer>
        {props.data && (
          <>
            {activeAlarms.length > 0 ? (
              activeAlarms.map((alarm, i) => (
                <FadeAnimation key={i}>
                  <AlarmItem
                    toggleCheckbox={toggleAlarmCheckBox}
                    alarmView={alarm}
                  />
                </FadeAnimation>
              ))
            ) : (
              <NoAlarmsContainer>
                <h3>
                  {formatMessage({
                    id: "alarms.noAlarms",
                    defaultMessage: "Missing Text",
                  })}
                </h3>
              </NoAlarmsContainer>
            )}
          </>
        )}
      </ListContainer>
      <Footer>
        <Button
          disabled={!buttonState}
          align-self="right"
          type="primary"
          size="small"
          onclick={() => acknowledgeCheckedAlarms()}
        >
          {formatMessage({
            id: "alarms.AcknowledgeSelection",
            defaultMessage: "Missing text",
          })}
        </Button>
      </Footer>
    </Container>
  );
};
