import React from "react";
import styled from "styled-components";
import {
  resolveAlarmPriorityDisplayTileFontColor,
  resolveAlarmPriorityTileColor,
} from "../../../../utils/alarm-utils";

type CircleProps = {
  priority: number;
  outerCircle?: boolean;
  size?: string;
  lessBorderRadius?: boolean;
};

const containerSize = (props: CircleProps) => {
  if (props.outerCircle) {
    return "100%";
  } else if (props.size) {
    return props.size;
  } else {
    return "20px";
  }
};

const Container = styled.div<CircleProps>`
  height: ${(props) => containerSize(props)};
  width: ${(props) => containerSize(props)};
  background-color: ${(props) =>
    props.priority
      ? resolveAlarmPriorityTileColor(props.priority, props.theme)
      : props.theme.colors.componentBackgroundActive.primary};
  color: ${(props) =>
    resolveAlarmPriorityDisplayTileFontColor(props.priority, props.theme)};
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => (props.lessBorderRadius ? "30%" : "50%")};
  display: flex;
  font-size: ${(props) => props.theme.constantFontSizes.small};
`;

const AlarmDisplay: React.FC<CircleProps> = (props) => {
  return (
    <Container
      priority={props.priority}
      size={props.size}
      outerCircle={props.outerCircle}
      lessBorderRadius={props.lessBorderRadius}
      data-testid="alarmcircletestid"
    >
      {props.children}
    </Container>
  );
};

export default AlarmDisplay;
