import React from "react";
import { useScadaPlusTheme } from "../../../hooks/useScadaPlusTheme";

type ComponentProps = {
  mainColor: string;
};

export const SingleLineModelSVG: React.FC<ComponentProps> = (props) => {
  const { theme } = useScadaPlusTheme();
  return (
    <svg id="singleLineContainer" display="none" viewBox="0 0 1574.9 716.2">
      <g id="singleLine" key={theme.name}>
        <line
          id="hovedLinje"
          className="st0"
          x1="1483.5"
          y1="199.9"
          x2="13.5"
          y2="199.9"
        />
        <g id="stasjonsTrafo">
          <g id="trafoLabel">
            <path
              id="background_18_"
              className="st1"
              d="M1433.4,275.1c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
				c-2.8,0-5-2.2-5-5V275.1z"
            />
            <text id="name_9_" transform="matrix(1 0 0 1 1457.3385 287.0461)">
              <tspan x="0" y="0" className="st2 st3 st4">
                Stasjons
              </tspan>
              <tspan x="9.4" y="14.4" className="st2 st3 st4">
                Trafo
              </tspan>
            </text>
          </g>
          <g id="currentSource_9_">
            <circle className="st5" cx="1482.5" cy="245.5" r="6.3" />
            <circle className="st5" cx="1482.5" cy="252.8" r="6.3" />
          </g>
          <line className="st0" x1="1482.5" y1="270.1" x2="1482.5" y2="226.8" />
          <line className="st0" x1="1482.5" y1="212.8" x2="1482.5" y2="199" />
          <g id="box_131_">
            <g id="boxOn_70_">
              <g>
                <path
                  className="st6"
                  d="M1475.4,223.8v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
						C1476.8,226.8,1475.4,225.5,1475.4,223.8z"
                />
                <path
                  className="st7"
                  d="M1475.4,223.8v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
						C1476.8,226.8,1475.4,225.5,1475.4,223.8z"
                />
              </g>
              <path className="st7" d="M1482.4,213.3v13" />
            </g>
            <g id="boxOff_82_">
              <path
                className="st8"
                d="M1489.4,215.8v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8
					C1488.1,212.8,1489.4,214.2,1489.4,215.8z"
              />
              <path className="st9" d="M1478.6,219.8h7.7" />
            </g>
          </g>
        </g>
        <g id="eksportLinje">
          <g id="trafoLabel_1_">
            <path
              id="background_19_"
              className="st1"
              d="M1397.4,5c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88c-2.8,0-5-2.2-5-5
				V5z"
            />
            <text id="name_14_" transform="matrix(1 0 0 1 1423.9849 16.9379)">
              <tspan x="0" y="0" className="st2 st3 st4">
                Eksport
              </tspan>
              <tspan x="10.5" y="14.4" className="st2 st3 st4">
                linje
              </tspan>
            </text>
          </g>
          <g id="metricOut">
            <text
              id="name_10_"
              transform="matrix(1 0 0 1 1544.6145 92.6841)"
              className="st10 st3 st4"
            >
              MWh
            </text>
            <text
              id="name_11_"
              transform="matrix(1 0 0 1 1465.1689 92.4756)"
              className="st10 st3 st11"
            >
              23.333
            </text>
          </g>
          <g id="metricIn">
            <text
              id="name_13_"
              transform="matrix(1 0 0 1 1544.6145 158.6216)"
              className="st10 st3 st4"
            >
              MWh
            </text>
            <text
              id="name_12_"
              transform="matrix(1 0 0 1 1465.1689 158.6215)"
              className="st10 st3 st11"
            >
              23.333
            </text>
          </g>
          <g id="eksportIn">
            <circle className="st12" cx="1446.4" cy="152.2" r="3.8" />
            <g>
              <circle className="st0" cx="1446.4" cy="152.2" r="8.5" />
            </g>
          </g>
          <g id="eksportUt">
            <circle className="st12" cx="1446.4" cy="86.3" r="3.8" />
            <circle className="st0" cx="1446.4" cy="86.3" r="8.5" />
          </g>
          <line className="st0" x1="1446.4" y1="40" x2="1446.4" y2="111.5" />
          <line className="st0" x1="1446.4" y1="125.5" x2="1446.4" y2="199.9" />
          <g id="box_9_">
            <g id="boxOn_5_">
              <g>
                <path
                  className="st6"
                  d="M1439.2,122.5v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
						C1440.5,125.5,1439.2,124.2,1439.2,122.5z"
                />
                <path
                  className="st7"
                  d="M1439.2,122.5v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
						C1440.5,125.5,1439.2,124.2,1439.2,122.5z"
                />
              </g>
              <path className="st7" d="M1446.2,112v13" />
            </g>
            <g id="boxOff_4_">
              <path
                className="st8"
                d="M1453.2,114.5v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8
					C1451.9,111.5,1453.2,112.9,1453.2,114.5z"
              />
              <path className="st9" d="M1442.4,118.5h7.7" />
            </g>
          </g>
        </g>
        <g id="seksjon">
          <g id="turbine_1_">
            <g id="currentSource_7_">
              <circle className="st5" cx="1116" cy="338.8" r="6.3" />
              <circle className="st5" cx="1123.3" cy="338.8" r="6.3" />
            </g>
            <g id="unit_7_">
              <path
                id="background_14_"
                className="st1"
                d="M1136.7,324c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V324z"
              />
              <text
                id="name_7_"
                transform="matrix(1 0 0 1 1207.4359 344.2432)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_7_">
                <linearGradient
                  id="SVGID_1_"
                  gradientUnits="userSpaceOnUse"
                  x1="1187.2314"
                  y1="857.7624"
                  x2="1189.3424"
                  y2="864.0934"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st13"
                  d="M1194,349.5c0.4,0.2,0.9,0.2,1.4,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L1194,349.5z"
                />

                <linearGradient
                  id="SVGID_2_"
                  gradientUnits="userSpaceOnUse"
                  x1="1179.1964"
                  y1="873.3232"
                  x2="1172.6425"
                  y2="872.2367"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st14"
                  d="M1178.6,323.3c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.4,0,1.4-0.7L1178.6,323.3z"
                />

                <linearGradient
                  id="SVGID_3_"
                  gradientUnits="userSpaceOnUse"
                  x1="1169.5159"
                  y1="858.6848"
                  x2="1173.5259"
                  y2="853.1982"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st15"
                  d="M1163.2,349.5c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L1163.2,349.5z"
                />
              </g>
              <g id="spin_7_">
                <path
                  className="st16"
                  d="M1193.2,341.5c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C1192.2,334.6,1193.3,338,1193.2,341.5z"
                />
                <path
                  className="st16"
                  d="M1176.7,354.7c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C1183,354.8,1179.7,355.3,1176.7,354.7z"
                />
                <path
                  className="st16"
                  d="M1168.6,331.9c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.3-0.1l-0.4,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C1165.4,337.3,1166.6,334.3,1168.6,331.9z"
                />
              </g>
              <g id="error_7_">
                <circle className="st17" cx="1194.3" cy="330" r="9" />
                <text
                  transform="matrix(1 0 0 1 1187.6099 334.4858)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_7_">
                <g id="personellGreen_7_">
                  <circle className="st19" cx="1179.6" cy="341" r="8.3" />
                  <path
                    className="st18"
                    d="M1183.9,344.6c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C1178.4,344.6,1181.1,344.6,1183.9,344.6z M1180.8,337.2c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C1180.8,336.4,1180.8,336.8,1180.8,337.2z M1177.7,339c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2
							c0.5-0.4,0.8-0.9,0.8-1.6C1180.4,339,1179.1,339,1177.7,339z"
                  />
                </g>
                <g id="personellOrange_7_">
                  <path
                    className="st20"
                    d="M1170.1,345.4l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C1170.1,347.3,1169.5,346.2,1170.1,345.4z"
                  />
                  <path
                    className="st18"
                    d="M1183.9,345.5c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C1178.4,345.5,1181.1,345.5,1183.9,345.5z M1180.8,338.2c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C1180.8,337.3,1180.8,337.7,1180.8,338.2z M1177.7,339.9c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2
							c0.5-0.4,0.8-0.9,0.8-1.6C1180.4,339.9,1179.1,339.9,1177.7,339.9z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_7_">
              <line className="st7" x1="1054" y1="339" x2="1063.9" y2="339" />
              <line className="st7" x1="1077.9" y1="339" x2="1085.9" y2="339" />
              <line className="st7" x1="1099.4" y1="339" x2="1140.7" y2="339" />
            </g>
            <g id="box_15_" className="clickableBox">
              <g id="Box3On_7_">
                <path
                  className="st21"
                  d="M1143.7,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C1140.7,333.3,1142,332,1143.7,332z"
                />
                <path className="st7" d="M1154.2,339h-13" />
              </g>
              <g id="Box3Off_7_">
                <path
                  className="st8"
                  d="M1143.7,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C1140.7,333.3,1142,332,1143.7,332z"
                />
                <path className="st9" d="M1147.7,342.8v-7.7" />
              </g>
            </g>
            <g id="box_14_" className="clickableBox">
              <g>
                <g>
                  <path
                    className="st6"
                    d="M1088.4,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C1085.4,333.3,1086.7,332,1088.4,332z"
                  />
                  <path
                    className="st7"
                    d="M1088.4,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C1085.4,333.3,1086.7,332,1088.4,332z"
                  />
                </g>
                <path className="st7" d="M1098.9,339h-13" />
              </g>
              <g id="Box2Off_7_">
                <path
                  className="st8"
                  d="M1088.4,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C1085.4,333.3,1086.7,332,1088.4,332z"
                />
                <path className="st9" d="M1092.4,342.8v-7.7" />
              </g>
            </g>
            <g id="box_13_" className="clickableBox">
              <g id="Box2On_7_">
                <g>
                  <path
                    className="st6"
                    d="M1066.9,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C1063.9,333.3,1065.2,332,1066.9,332z"
                  />
                  <path
                    className="st7"
                    d="M1066.9,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C1063.9,333.3,1065.2,332,1066.9,332z"
                  />
                </g>
                <path className="st7" d="M1077.4,339h-13" />
              </g>
              <g id="box1Off_7_">
                <path
                  className="st8"
                  d="M1066.9,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C1063.9,333.3,1065.2,332,1066.9,332z"
                />
                <path className="st9" d="M1070.9,342.8v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine_2_">
            <g id="currentSource_6_">
              <circle className="st5" cx="1116" cy="294.1" r="6.3" />
              <circle className="st5" cx="1123.3" cy="294.1" r="6.3" />
            </g>
            <g id="unit_6_">
              <path
                id="background_12_"
                className="st1"
                d="M1136.7,279.3c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V279.3z"
              />
              <text
                id="name_6_"
                transform="matrix(1 0 0 1 1207.4359 299.5426)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_6_">
                <linearGradient
                  id="SVGID_4_"
                  gradientUnits="userSpaceOnUse"
                  x1="1187.2314"
                  y1="902.463"
                  x2="1189.3424"
                  y2="908.794"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st22"
                  d="M1194,304.8c0.4,0.2,0.9,0.2,1.4,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L1194,304.8z"
                />

                <linearGradient
                  id="SVGID_5_"
                  gradientUnits="userSpaceOnUse"
                  x1="1179.1964"
                  y1="918.0239"
                  x2="1172.6425"
                  y2="916.9374"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st23"
                  d="M1178.6,278.6c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.4,0,1.4-0.7L1178.6,278.6z"
                />

                <linearGradient
                  id="SVGID_6_"
                  gradientUnits="userSpaceOnUse"
                  x1="1169.5159"
                  y1="903.3854"
                  x2="1173.5259"
                  y2="897.8988"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st24"
                  d="M1163.2,304.8c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L1163.2,304.8z"
                />
              </g>
              <g id="spin_6_">
                <path
                  className="st16"
                  d="M1193.2,296.8c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C1192.2,289.9,1193.3,293.3,1193.2,296.8z"
                />
                <path
                  className="st16"
                  d="M1176.7,310c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C1183,310.1,1179.7,310.6,1176.7,310z"
                />
                <path
                  className="st16"
                  d="M1168.6,287.2c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.3-0.1l-0.4,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C1165.4,292.6,1166.6,289.6,1168.6,287.2z"
                />
              </g>
              <g id="error_6_">
                <circle className="st17" cx="1194.3" cy="285.3" r="9" />
                <text
                  transform="matrix(1 0 0 1 1187.6099 289.7852)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_6_">
                <g id="personellGreen_6_">
                  <circle className="st19" cx="1179.6" cy="296.3" r="8.3" />
                  <path
                    className="st18"
                    d="M1183.9,299.9c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C1178.4,299.9,1181.1,299.9,1183.9,299.9z M1180.8,292.5c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C1180.8,291.7,1180.8,292.1,1180.8,292.5z M1177.7,294.3c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2
							c0.5-0.4,0.8-0.9,0.8-1.6C1180.4,294.3,1179.1,294.3,1177.7,294.3z"
                  />
                </g>
                <g id="personellOrange_6_">
                  <path
                    className="st20"
                    d="M1170.1,300.7l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C1170.1,302.6,1169.5,301.5,1170.1,300.7z"
                  />
                  <path
                    className="st18"
                    d="M1183.9,300.8c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C1178.4,300.8,1181.1,300.8,1183.9,300.8z M1180.8,293.4c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C1180.8,292.6,1180.8,293,1180.8,293.4z M1177.7,295.2c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2
							c0.5-0.4,0.8-0.9,0.8-1.6C1180.4,295.2,1179.1,295.2,1177.7,295.2z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_6_">
              <line
                className="st7"
                x1="1054"
                y1="294.3"
                x2="1063.9"
                y2="294.3"
              />
              <line
                className="st7"
                x1="1077.9"
                y1="294.3"
                x2="1085.9"
                y2="294.3"
              />
              <line
                className="st7"
                x1="1099.4"
                y1="294.3"
                x2="1140.7"
                y2="294.3"
              />
            </g>
            <g id="box_12_" className="clickableBox">
              <g id="boxOn_6_">
                <path
                  className="st21"
                  d="M1143.7,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C1140.7,288.6,1142,287.3,1143.7,287.3z"
                />
                <path className="st7" d="M1154.2,294.3h-13" />
              </g>
              <g id="boxOff_10_">
                <path
                  className="st8"
                  d="M1143.7,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C1140.7,288.6,1142,287.3,1143.7,287.3z"
                />
                <path className="st9" d="M1147.7,298.1v-7.7" />
              </g>
            </g>
            <g id="box_11_" className="clickableBox">
              <g id="boxOn_7_">
                <g>
                  <path
                    className="st6"
                    d="M1088.4,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C1085.4,288.6,1086.7,287.3,1088.4,287.3z"
                  />
                  <path
                    className="st7"
                    d="M1088.4,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C1085.4,288.6,1086.7,287.3,1088.4,287.3z"
                  />
                </g>
                <path className="st7" d="M1098.9,294.3h-13" />
              </g>
              <g id="boxOff_9_">
                <path
                  className="st8"
                  d="M1088.4,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C1085.4,288.6,1086.7,287.3,1088.4,287.3z"
                />
                <path className="st9" d="M1092.4,298.1v-7.7" />
              </g>
            </g>
            <g id="box_10_" className="clickableBox">
              <g id="boxOn_8_">
                <g>
                  <path
                    className="st6"
                    d="M1066.9,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C1063.9,288.6,1065.2,287.3,1066.9,287.3z"
                  />
                  <path
                    className="st7"
                    d="M1066.9,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C1063.9,288.6,1065.2,287.3,1066.9,287.3z"
                  />
                </g>
                <path className="st7" d="M1077.4,294.3h-13" />
              </g>
              <g id="boxOff_8_">
                <path
                  className="st8"
                  d="M1066.9,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C1063.9,288.6,1065.2,287.3,1066.9,287.3z"
                />
                <path className="st9" d="M1070.9,298.1v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine_3_">
            <g id="currentSource_8_">
              <circle className="st5" cx="1116" cy="249.4" r="6.3" />
              <circle className="st5" cx="1123.3" cy="249.4" r="6.3" />
            </g>
            <g id="unit_8_">
              <path
                id="background_16_"
                className="st1"
                d="M1136.7,234.6c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V234.6z"
              />
              <text
                id="name_8_"
                transform="matrix(1 0 0 1 1207.4359 254.8419)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_8_">
                <linearGradient
                  id="SVGID_25_"
                  gradientUnits="userSpaceOnUse"
                  x1="1187.2314"
                  y1="947.1637"
                  x2="1189.3424"
                  y2="953.4947"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st43"
                  d="M1194,260.1c0.4,0.2,0.9,0.2,1.4,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L1194,260.1z"
                />

                <linearGradient
                  id="SVGID_26_"
                  gradientUnits="userSpaceOnUse"
                  x1="1179.1964"
                  y1="962.7245"
                  x2="1172.6425"
                  y2="961.638"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st44"
                  d="M1178.6,233.9c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.4,0,1.4-0.7L1178.6,233.9z"
                />

                <linearGradient
                  id="SVGID_27_"
                  gradientUnits="userSpaceOnUse"
                  x1="1169.5159"
                  y1="948.0861"
                  x2="1173.5259"
                  y2="942.5995"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st45"
                  d="M1163.2,260.1c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L1163.2,260.1z"
                />
              </g>
              <g id="spin_8_">
                <path
                  className="st16"
                  d="M1193.2,252.1c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C1192.2,245.2,1193.3,248.6,1193.2,252.1z"
                />
                <path
                  className="st16"
                  d="M1176.7,265.3c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C1183,265.4,1179.7,265.9,1176.7,265.3z"
                />
                <path
                  className="st16"
                  d="M1168.6,242.5c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.3-0.1l-0.4,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C1165.4,247.9,1166.6,244.9,1168.6,242.5z"
                />
              </g>
              <g id="error_8_">
                <circle className="st17" cx="1194.3" cy="240.6" r="9" />
                <text
                  transform="matrix(1 0 0 1 1187.6099 245.0845)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_8_">
                <g id="personellGreen_8_">
                  <circle className="st19" cx="1179.6" cy="251.6" r="8.3" />
                  <path
                    className="st18"
                    d="M1183.9,255.2c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C1178.4,255.2,1181.1,255.2,1183.9,255.2z M1180.8,247.8c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C1180.8,247,1180.8,247.4,1180.8,247.8z M1177.7,249.6c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2
							c0.5-0.4,0.8-0.9,0.8-1.6C1180.4,249.6,1179.1,249.6,1177.7,249.6z"
                  />
                </g>
                <g id="personellOrange_8_">
                  <path
                    className="st20"
                    d="M1170.1,256l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C1170.1,257.9,1169.5,256.8,1170.1,256z"
                  />
                  <path
                    className="st18"
                    d="M1183.9,256.1c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C1178.4,256.1,1181.1,256.1,1183.9,256.1z M1180.8,248.7c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C1180.8,247.9,1180.8,248.3,1180.8,248.7z M1177.7,250.5c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2
							c0.5-0.4,0.8-0.9,0.8-1.6C1180.4,250.5,1179.1,250.5,1177.7,250.5z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_8_">
              <line
                className="st7"
                x1="1054"
                y1="249.6"
                x2="1063.9"
                y2="249.6"
              />
              <line
                className="st7"
                x1="1077.9"
                y1="249.6"
                x2="1085.9"
                y2="249.6"
              />
              <line
                className="st7"
                x1="1099.4"
                y1="249.6"
                x2="1140.7"
                y2="249.6"
              />
            </g>
            <g id="box_2_" className="clickableBox">
              <g id="BoxOn_3_">
                <path
                  className="st21"
                  d="M1143.7,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C1140.7,243.9,1142,242.6,1143.7,242.6z"
                />
                <path className="st7" d="M1154.2,249.6h-13" />
              </g>
              <g id="boxOff_7_">
                <path
                  className="st8"
                  d="M1143.7,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C1140.7,243.9,1142,242.6,1143.7,242.6z"
                />
                <path className="st9" d="M1147.7,253.4v-7.7" />
              </g>
            </g>
            <g id="box_3_" className="clickableBox">
              <g id="BoxOn_2_">
                <g>
                  <path
                    className="st6"
                    d="M1088.4,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C1085.4,243.9,1086.7,242.6,1088.4,242.6z"
                  />
                  <path
                    className="st7"
                    d="M1088.4,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C1085.4,243.9,1086.7,242.6,1088.4,242.6z"
                  />
                </g>
                <path className="st7" d="M1098.9,249.6h-13" />
              </g>
              <g id="boxOff_6_">
                <path
                  className="st8"
                  d="M1088.4,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C1085.4,243.9,1086.7,242.6,1088.4,242.6z"
                />
                <path className="st9" d="M1092.4,253.4v-7.7" />
              </g>
            </g>
            <g id="box_4_" className="clickableBox">
              <g id="BoxOn_1_">
                <g>
                  <path
                    className="st6"
                    d="M1066.9,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C1063.9,243.9,1065.2,242.6,1066.9,242.6z"
                  />
                  <path
                    className="st7"
                    d="M1066.9,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C1063.9,243.9,1065.2,242.6,1066.9,242.6z"
                  />
                </g>
                <path className="st7" d="M1077.4,249.6h-13" />
              </g>
              <g id="boxOff_5_">
                <path
                  className="st8"
                  d="M1066.9,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C1063.9,243.9,1065.2,242.6,1066.9,242.6z"
                />
                <path className="st9" d="M1070.9,253.4v-7.7" />
              </g>
            </g>
          </g>
          <g id="s4Label">
            <path
              id="background_22_"
              className="st1"
              d="M1039,171.1c0-2.8,2.2-5,5-5h19c2.8,0,5,2.2,5,5v11c0,2.8-2.2,5-5,5h-19
				c-2.8,0-5-2.2-5-5V171.1z"
            />
            <text
              id="name_15_"
              transform="matrix(1 0 0 1 1045.8352 180.9498)"
              className="st2 st3 st4"
            >
              S4
            </text>
          </g>
          <g id="seksjonLinje">
            <line
              id="linje"
              className="st0"
              x1="1053.5"
              y1="199.9"
              x2="1053.5"
              y2="212.8"
            />

            <line
              id="linje_1_"
              className="st0"
              x1="1053.5"
              y1="226.8"
              x2="1053.5"
              y2="340"
            />
            <g id="box_5_">
              <g id="boxOn_1_">
                <g>
                  <path
                    className="st6"
                    d="M1046.5,223.8v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
							C1047.8,226.8,1046.5,225.5,1046.5,223.8z"
                  />
                  <path
                    className="st7"
                    d="M1046.5,223.8v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
							C1047.8,226.8,1046.5,225.5,1046.5,223.8z"
                  />
                </g>
                <path className="st7" d="M1053.5,213.3v13" />
              </g>
              <g id="boxOff_1_">
                <path
                  className="st8"
                  d="M1060.5,215.8v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8
						C1059.2,212.8,1060.5,214.2,1060.5,215.8z"
                />
                <path className="st9" d="M1049.7,219.8h7.7" />
              </g>
            </g>
          </g>
        </g>
        <g id="seksjon_1_">
          <g id="turbine_15_">
            <g id="currentSource_19_">
              <circle className="st5" cx="769.7" cy="338.8" r="6.3" />
              <circle className="st5" cx="776.9" cy="338.8" r="6.3" />
            </g>
            <g id="unit_18_">
              <path
                id="background_43_"
                className="st1"
                d="M790.4,324c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V324z"
              />
              <text
                id="name_26_"
                transform="matrix(1 0 0 1 861.1041 344.2432)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_18_">
                <linearGradient
                  id="SVGID_28_"
                  gradientUnits="userSpaceOnUse"
                  x1="840.8997"
                  y1="857.7624"
                  x2="843.0107"
                  y2="864.0934"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st46"
                  d="M847.6,349.5c0.4,0.2,0.9,0.2,1.3,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L847.6,349.5z"
                />

                <linearGradient
                  id="SVGID_29_"
                  gradientUnits="userSpaceOnUse"
                  x1="832.8647"
                  y1="873.3232"
                  x2="826.3107"
                  y2="872.2367"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st47"
                  d="M832.2,323.3c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.3,0,1.3-0.7L832.2,323.3z"
                />

                <linearGradient
                  id="SVGID_30_"
                  gradientUnits="userSpaceOnUse"
                  x1="823.1841"
                  y1="858.6848"
                  x2="827.1942"
                  y2="853.1982"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st48"
                  d="M816.8,349.5c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L816.8,349.5z"
                />
              </g>
              <g id="spin_18_">
                <path
                  className="st16"
                  d="M846.9,341.5c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C845.8,334.6,847,338,846.9,341.5z"
                />
                <path
                  className="st16"
                  d="M830.3,354.7c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C836.6,354.8,833.4,355.3,830.3,354.7z"
                />
                <path
                  className="st16"
                  d="M822.3,331.9c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.3-0.1l-0.5,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C819,337.3,820.2,334.3,822.3,331.9z"
                />
              </g>
              <g id="error_18_">
                <circle className="st17" cx="848" cy="330" r="9" />
                <text
                  transform="matrix(1 0 0 1 841.2781 334.4858)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_18_">
                <g id="personellGreen_18_">
                  <circle className="st19" cx="833.2" cy="341" r="8.3" />
                  <path
                    className="st18"
                    d="M837.6,344.6c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C832,344.6,834.8,344.6,837.6,344.6z M834.4,337.2c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C834.4,336.4,834.4,336.8,834.4,337.2z M831.3,339c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C834.1,339,832.7,339,831.3,339z"
                  />
                </g>
                <g id="personellOrange_18_">
                  <path
                    className="st20"
                    d="M823.7,345.4l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C823.8,347.3,823.2,346.2,823.7,345.4z"
                  />
                  <path
                    className="st18"
                    d="M837.6,345.5c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C832,345.5,834.8,345.5,837.6,345.5z M834.4,338.2c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C834.4,337.3,834.4,337.7,834.4,338.2z M831.3,339.9c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C834.1,339.9,832.7,339.9,831.3,339.9z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_18_">
              <line className="st7" x1="707.7" y1="339" x2="717.5" y2="339" />
              <line className="st7" x1="731.5" y1="339" x2="739.5" y2="339" />
              <line className="st7" x1="753" y1="339" x2="794.4" y2="339" />
            </g>
            <g id="box_65_">
              <g id="Box3On_8_">
                <path
                  className="st21"
                  d="M797.4,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C794.4,333.3,795.7,332,797.4,332
						z"
                />
                <path className="st7" d="M807.9,339h-13" />
              </g>
              <g id="Box3Off_8_">
                <path
                  className="st8"
                  d="M797.4,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C794.4,333.3,795.7,332,797.4,332z
						"
                />
                <path className="st9" d="M801.4,342.8v-7.7" />
              </g>
            </g>
            <g id="box_64_">
              <g>
                <g>
                  <path
                    className="st6"
                    d="M742,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,333.3,740.4,332,742,332z"
                  />
                  <path
                    className="st7"
                    d="M742,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,333.3,740.4,332,742,332z"
                  />
                </g>
                <path className="st7" d="M752.5,339h-13" />
              </g>
              <g id="Box2Off_8_">
                <path
                  className="st8"
                  d="M742,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,333.3,740.4,332,742,332z"
                />
                <path className="st9" d="M746,342.8v-7.7" />
              </g>
            </g>
            <g id="box_63_">
              <g id="Box2On_8_">
                <g>
                  <path
                    className="st6"
                    d="M720.5,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C717.5,333.3,718.9,332,720.5,332
							z"
                  />
                  <path
                    className="st7"
                    d="M720.5,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C717.5,333.3,718.9,332,720.5,332
							z"
                  />
                </g>
                <path className="st7" d="M731,339h-13" />
              </g>
              <g id="box1Off_8_">
                <path
                  className="st8"
                  d="M720.5,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C717.5,333.3,718.9,332,720.5,332z
						"
                />
                <path className="st9" d="M724.5,342.8v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine_14_">
            <g id="currentSource_18_">
              <circle className="st5" cx="769.7" cy="294.1" r="6.3" />
              <circle className="st5" cx="776.9" cy="294.1" r="6.3" />
            </g>
            <g id="unit_17_">
              <path
                id="background_41_"
                className="st1"
                d="M790.4,279.3c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V279.3z"
              />
              <text
                id="name_25_"
                transform="matrix(1 0 0 1 861.1041 299.5426)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_17_">
                <linearGradient
                  id="SVGID_31_"
                  gradientUnits="userSpaceOnUse"
                  x1="840.8997"
                  y1="902.463"
                  x2="843.0107"
                  y2="908.794"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st49"
                  d="M847.6,304.8c0.4,0.2,0.9,0.2,1.3,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L847.6,304.8z"
                />

                <linearGradient
                  id="SVGID_32_"
                  gradientUnits="userSpaceOnUse"
                  x1="832.8647"
                  y1="918.0239"
                  x2="826.3107"
                  y2="916.9374"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st50"
                  d="M832.2,278.6c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.3,0,1.3-0.7L832.2,278.6z"
                />

                <linearGradient
                  id="SVGID_33_"
                  gradientUnits="userSpaceOnUse"
                  x1="823.1841"
                  y1="903.3854"
                  x2="827.1942"
                  y2="897.8988"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st51"
                  d="M816.8,304.8c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L816.8,304.8z"
                />
              </g>
              <g id="spin_17_">
                <path
                  className="st16"
                  d="M846.9,296.8c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C845.8,289.9,847,293.3,846.9,296.8z"
                />
                <path
                  className="st16"
                  d="M830.3,310c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C836.6,310.1,833.4,310.6,830.3,310z"
                />
                <path
                  className="st16"
                  d="M822.3,287.2c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.3-0.1l-0.5,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C819,292.6,820.2,289.6,822.3,287.2z"
                />
              </g>
              <g id="error_17_">
                <circle className="st17" cx="848" cy="285.3" r="9" />
                <text
                  transform="matrix(1 0 0 1 841.2781 289.7852)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_17_">
                <g id="personellGreen_17_">
                  <circle className="st19" cx="833.2" cy="296.3" r="8.3" />
                  <path
                    className="st18"
                    d="M837.6,299.9c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C832,299.9,834.8,299.9,837.6,299.9z M834.4,292.5c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C834.4,291.7,834.4,292.1,834.4,292.5z M831.3,294.3c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C834.1,294.3,832.7,294.3,831.3,294.3z"
                  />
                </g>
                <g id="personellOrange_17_">
                  <path
                    className="st20"
                    d="M823.7,300.7l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C823.8,302.6,823.2,301.5,823.7,300.7z"
                  />
                  <path
                    className="st18"
                    d="M837.6,300.8c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C832,300.8,834.8,300.8,837.6,300.8z M834.4,293.4c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C834.4,292.6,834.4,293,834.4,293.4z M831.3,295.2c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C834.1,295.2,832.7,295.2,831.3,295.2z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_17_">
              <line
                className="st7"
                x1="707.7"
                y1="294.3"
                x2="717.5"
                y2="294.3"
              />
              <line
                className="st7"
                x1="731.5"
                y1="294.3"
                x2="739.5"
                y2="294.3"
              />
              <line className="st7" x1="753" y1="294.3" x2="794.4" y2="294.3" />
            </g>
            <g id="box_62_">
              <g id="boxOn_34_">
                <path
                  className="st21"
                  d="M797.4,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C794.4,288.6,795.7,287.3,797.4,287.3z"
                />
                <path className="st7" d="M807.9,294.3h-13" />
              </g>
              <g id="boxOff_39_">
                <path
                  className="st8"
                  d="M797.4,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C794.4,288.6,795.7,287.3,797.4,287.3z"
                />
                <path className="st9" d="M801.4,298.1v-7.7" />
              </g>
            </g>
            <g id="box_61_">
              <g id="boxOn_33_">
                <g>
                  <path
                    className="st6"
                    d="M742,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,288.6,740.4,287.3,742,287.3
							z"
                  />
                  <path
                    className="st7"
                    d="M742,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,288.6,740.4,287.3,742,287.3
							z"
                  />
                </g>
                <path className="st7" d="M752.5,294.3h-13" />
              </g>
              <g id="boxOff_38_">
                <path
                  className="st8"
                  d="M742,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,288.6,740.4,287.3,742,287.3z
						"
                />
                <path className="st9" d="M746,298.1v-7.7" />
              </g>
            </g>
            <g id="box_60_">
              <g id="boxOn_32_">
                <g>
                  <path
                    className="st6"
                    d="M720.5,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C717.5,288.6,718.9,287.3,720.5,287.3z"
                  />
                  <path
                    className="st7"
                    d="M720.5,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C717.5,288.6,718.9,287.3,720.5,287.3z"
                  />
                </g>
                <path className="st7" d="M731,294.3h-13" />
              </g>
              <g id="boxOff_37_">
                <path
                  className="st8"
                  d="M720.5,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C717.5,288.6,718.9,287.3,720.5,287.3z"
                />
                <path className="st9" d="M724.5,298.1v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine_13_">
            <g id="currentSource_17_">
              <circle className="st5" cx="769.7" cy="464.6" r="6.3" />
              <circle className="st5" cx="776.9" cy="464.6" r="6.3" />
            </g>
            <g id="unit_16_">
              <path
                id="background_39_"
                className="st1"
                d="M790.4,449.7c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V449.7z"
              />
              <text
                id="name_24_"
                transform="matrix(1 0 0 1 861.1041 470.0181)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_16_">
                <linearGradient
                  id="SVGID_34_"
                  gradientUnits="userSpaceOnUse"
                  x1="840.8997"
                  y1="731.9875"
                  x2="843.0107"
                  y2="738.3185"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st52"
                  d="M847.6,475.2c0.4,0.2,0.9,0.2,1.3,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L847.6,475.2z"
                />

                <linearGradient
                  id="SVGID_35_"
                  gradientUnits="userSpaceOnUse"
                  x1="832.8647"
                  y1="747.5483"
                  x2="826.3107"
                  y2="746.4619"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st53"
                  d="M832.2,449.1c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.3,0,1.3-0.7L832.2,449.1z"
                />

                <linearGradient
                  id="SVGID_36_"
                  gradientUnits="userSpaceOnUse"
                  x1="823.1841"
                  y1="732.9099"
                  x2="827.1942"
                  y2="727.4233"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st54"
                  d="M816.8,475.3c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L816.8,475.3z"
                />
              </g>
              <g id="spin_16_">
                <path
                  className="st16"
                  d="M846.9,467.3c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C845.8,460.4,847,463.8,846.9,467.3z"
                />
                <path
                  className="st16"
                  d="M830.3,480.4c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C836.6,480.6,833.4,481,830.3,480.4z"
                />
                <path
                  className="st16"
                  d="M822.3,457.7c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.3-0.1l-0.5,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1C819,463.1,820.2,460,822.3,457.7z
						"
                />
              </g>
              <g id="error_16_">
                <circle className="st17" cx="848" cy="455.7" r="9" />
                <text
                  transform="matrix(1 0 0 1 841.2781 460.2606)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_16_">
                <g id="personellGreen_16_">
                  <circle className="st19" cx="833.2" cy="466.8" r="8.3" />
                  <path
                    className="st18"
                    d="M837.6,470.3c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C832,470.3,834.8,470.3,837.6,470.3z M834.4,463c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C834.4,462.1,834.4,462.6,834.4,463z M831.3,464.8c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C834.1,464.8,832.7,464.8,831.3,464.8z"
                  />
                </g>
                <g id="personellOrange_16_">
                  <path
                    className="st20"
                    d="M823.7,471.1l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C823.8,473.1,823.2,472,823.7,471.1z"
                  />
                  <path
                    className="st18"
                    d="M837.6,471.3c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C832,471.3,834.8,471.3,837.6,471.3z M834.4,463.9c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C834.4,463.1,834.4,463.5,834.4,463.9z M831.3,465.7c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C834.1,465.7,832.7,465.7,831.3,465.7z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_16_">
              <line
                className="st7"
                x1="707.7"
                y1="464.7"
                x2="717.5"
                y2="464.7"
              />
              <line
                className="st7"
                x1="731.5"
                y1="464.7"
                x2="739.5"
                y2="464.7"
              />
              <line className="st7" x1="753" y1="464.7" x2="794.4" y2="464.7" />
            </g>
            <g id="box_59_">
              <g id="Box3On_6_">
                <path
                  className="st21"
                  d="M797.4,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C794.4,459.1,795.7,457.7,797.4,457.7z"
                />
                <path className="st7" d="M807.9,464.7h-13" />
              </g>
              <g id="Box3Off_6_">
                <path
                  className="st8"
                  d="M797.4,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C794.4,459.1,795.7,457.7,797.4,457.7z"
                />
                <path className="st9" d="M801.4,468.6v-7.7" />
              </g>
            </g>
            <g id="box_58_">
              <g>
                <g>
                  <path
                    className="st6"
                    d="M742,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,459.1,740.4,457.7,742,457.7
							z"
                  />
                  <path
                    className="st7"
                    d="M742,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,459.1,740.4,457.7,742,457.7
							z"
                  />
                </g>
                <path className="st7" d="M752.5,464.7h-13" />
              </g>
              <g id="Box2Off_6_">
                <path
                  className="st8"
                  d="M742,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,459.1,740.4,457.7,742,457.7z
						"
                />
                <path className="st9" d="M746,468.6v-7.7" />
              </g>
            </g>
            <g id="box_57_">
              <g id="Box2On_6_">
                <g>
                  <path
                    className="st6"
                    d="M720.5,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C717.5,459.1,718.9,457.7,720.5,457.7z"
                  />
                  <path
                    className="st7"
                    d="M720.5,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C717.5,459.1,718.9,457.7,720.5,457.7z"
                  />
                </g>
                <path className="st7" d="M731,464.7h-13" />
              </g>
              <g id="box1Off_6_">
                <path
                  className="st8"
                  d="M720.5,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C717.5,459.1,718.9,457.7,720.5,457.7z"
                />
                <path className="st9" d="M724.5,468.6v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine_12_">
            <g id="currentSource_16_">
              <circle className="st5" cx="769.7" cy="419.9" r="6.3" />
              <circle className="st5" cx="776.9" cy="419.9" r="6.3" />
            </g>
            <g id="unit_15_">
              <path
                id="background_37_"
                className="st1"
                d="M790.4,405c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V405z"
              />
              <text
                id="name_23_"
                transform="matrix(1 0 0 1 861.1041 425.3174)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_15_">
                <linearGradient
                  id="SVGID_37_"
                  gradientUnits="userSpaceOnUse"
                  x1="840.8997"
                  y1="776.6882"
                  x2="843.0107"
                  y2="783.0192"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st55"
                  d="M847.6,430.5c0.4,0.2,0.9,0.2,1.3,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L847.6,430.5z"
                />

                <linearGradient
                  id="SVGID_38_"
                  gradientUnits="userSpaceOnUse"
                  x1="832.8647"
                  y1="792.249"
                  x2="826.3107"
                  y2="791.1625"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st56"
                  d="M832.2,404.4c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.3,0,1.3-0.7L832.2,404.4z"
                />

                <linearGradient
                  id="SVGID_39_"
                  gradientUnits="userSpaceOnUse"
                  x1="823.1841"
                  y1="777.6106"
                  x2="827.1942"
                  y2="772.124"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st57"
                  d="M816.8,430.6c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L816.8,430.6z"
                />
              </g>
              <g id="spin_15_">
                <path
                  className="st16"
                  d="M846.9,422.6c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C845.8,415.7,847,419.1,846.9,422.6z"
                />
                <path
                  className="st16"
                  d="M830.3,435.7c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C836.6,435.9,833.4,436.3,830.3,435.7z"
                />
                <path
                  className="st16"
                  d="M822.3,413c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.3-0.1l-0.5,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1C819,418.4,820.2,415.3,822.3,413z
						"
                />
              </g>
              <g id="error_15_">
                <circle className="st17" cx="848" cy="411" r="9" />
                <text
                  transform="matrix(1 0 0 1 841.2781 415.56)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_15_">
                <g id="personellGreen_15_">
                  <circle className="st19" cx="833.2" cy="422.1" r="8.3" />
                  <path
                    className="st18"
                    d="M837.6,425.6c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C832,425.6,834.8,425.6,837.6,425.6z M834.4,418.3c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C834.4,417.4,834.4,417.9,834.4,418.3z M831.3,420.1c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C834.1,420.1,832.7,420.1,831.3,420.1z"
                  />
                </g>
                <g id="personellOrange_15_">
                  <path
                    className="st20"
                    d="M823.7,426.4l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C823.8,428.4,823.2,427.3,823.7,426.4z"
                  />
                  <path
                    className="st18"
                    d="M837.6,426.6c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C832,426.6,834.8,426.6,837.6,426.6z M834.4,419.2c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C834.4,418.4,834.4,418.8,834.4,419.2z M831.3,421c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C834.1,421,832.7,421,831.3,421z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_15_">
              <line className="st7" x1="707.7" y1="420" x2="717.5" y2="420" />
              <line className="st7" x1="731.5" y1="420" x2="739.5" y2="420" />
              <line className="st7" x1="753" y1="420" x2="794.4" y2="420" />
            </g>
            <g id="box_56_">
              <g id="boxOn_31_">
                <path
                  className="st21"
                  d="M797.4,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C794.4,414.4,795.7,413,797.4,413
						z"
                />
                <path className="st7" d="M807.9,420h-13" />
              </g>
              <g id="boxOff_36_">
                <path
                  className="st8"
                  d="M797.4,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C794.4,414.4,795.7,413,797.4,413z
						"
                />
                <path className="st9" d="M801.4,423.9v-7.7" />
              </g>
            </g>
            <g id="box_55_">
              <g id="boxOn_30_">
                <g>
                  <path
                    className="st6"
                    d="M742,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,414.4,740.4,413,742,413z"
                  />
                  <path
                    className="st7"
                    d="M742,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,414.4,740.4,413,742,413z"
                  />
                </g>
                <path className="st7" d="M752.5,420h-13" />
              </g>
              <g id="boxOff_35_">
                <path
                  className="st8"
                  d="M742,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,414.4,740.4,413,742,413z"
                />
                <path className="st9" d="M746,423.9v-7.7" />
              </g>
            </g>
            <g id="box_54_">
              <g id="boxOn_29_">
                <g>
                  <path
                    className="st6"
                    d="M720.5,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C717.5,414.4,718.9,413,720.5,413
							z"
                  />
                  <path
                    className="st7"
                    d="M720.5,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C717.5,414.4,718.9,413,720.5,413
							z"
                  />
                </g>
                <path className="st7" d="M731,420h-13" />
              </g>
              <g id="boxOff_34_">
                <path
                  className="st8"
                  d="M720.5,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C717.5,414.4,718.9,413,720.5,413z
						"
                />
                <path className="st9" d="M724.5,423.9v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine_11_">
            <g id="currentSource_15_">
              <circle className="st5" cx="769.7" cy="594.7" r="6.3" />
              <circle className="st5" cx="776.9" cy="594.7" r="6.3" />
            </g>
            <g id="unit_14_">
              <path
                id="background_35_"
                className="st1"
                d="M790.4,579.9c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V579.9z"
              />
              <text
                id="name_22_"
                transform="matrix(1 0 0 1 861.1041 600.1435)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_14_">
                <linearGradient
                  id="SVGID_40_"
                  gradientUnits="userSpaceOnUse"
                  x1="840.8997"
                  y1="601.8621"
                  x2="843.0107"
                  y2="608.1931"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st58"
                  d="M847.6,605.4c0.4,0.2,0.9,0.2,1.3,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L847.6,605.4z"
                />

                <linearGradient
                  id="SVGID_41_"
                  gradientUnits="userSpaceOnUse"
                  x1="832.8647"
                  y1="617.423"
                  x2="826.3107"
                  y2="616.3365"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st59"
                  d="M832.2,579.2c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.3,0,1.3-0.7L832.2,579.2z"
                />

                <linearGradient
                  id="SVGID_42_"
                  gradientUnits="userSpaceOnUse"
                  x1="823.1841"
                  y1="602.7845"
                  x2="827.1942"
                  y2="597.2979"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st60"
                  d="M816.8,605.4c-0.4,0.3-0.7,0.7-0.7,1.2s0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L816.8,605.4z"
                />
              </g>
              <g id="spin_14_">
                <path
                  className="st16"
                  d="M846.9,597.4c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C845.8,590.5,847,593.9,846.9,597.4z"
                />
                <path
                  className="st16"
                  d="M830.3,610.6c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C836.6,610.7,833.4,611.2,830.3,610.6z"
                />
                <path
                  className="st16"
                  d="M822.3,587.8c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.3-0.1l-0.5,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C819,593.2,820.2,590.2,822.3,587.8z"
                />
              </g>
              <g id="error_14_">
                <circle className="st17" cx="848" cy="585.9" r="9" />
                <text
                  transform="matrix(1 0 0 1 841.2781 590.386)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_14_">
                <g id="personellGreen_14_">
                  <circle className="st19" cx="833.2" cy="596.9" r="8.3" />
                  <path
                    className="st18"
                    d="M837.6,600.5c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C832,600.5,834.8,600.5,837.6,600.5z M834.4,593.1c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C834.4,592.3,834.4,592.7,834.4,593.1z M831.3,594.9c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C834.1,594.9,832.7,594.9,831.3,594.9z"
                  />
                </g>
                <g id="personellOrange_14_">
                  <path
                    className="st20"
                    d="M823.7,601.3l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C823.8,603.2,823.2,602.1,823.7,601.3z"
                  />
                  <path
                    className="st18"
                    d="M837.6,601.4c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C832,601.4,834.8,601.4,837.6,601.4z M834.4,594.1c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C834.4,593.2,834.4,593.6,834.4,594.1z M831.3,595.8c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C834.1,595.8,832.7,595.8,831.3,595.8z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_14_">
              <line
                className="st7"
                x1="707.7"
                y1="594.9"
                x2="717.5"
                y2="594.9"
              />
              <line
                className="st7"
                x1="731.5"
                y1="594.9"
                x2="739.5"
                y2="594.9"
              />
              <line className="st7" x1="753" y1="594.9" x2="794.4" y2="594.9" />
            </g>
            <g id="box_53_">
              <g id="Box3On_5_">
                <path
                  className="st21"
                  d="M797.4,587.9h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C794.4,589.2,795.7,587.9,797.4,587.9z"
                />
                <path className="st7" d="M807.9,594.9h-13" />
              </g>
              <g id="Box3Off_5_">
                <path
                  className="st8"
                  d="M797.4,587.9h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C794.4,589.2,795.7,587.9,797.4,587.9z"
                />
                <path className="st9" d="M801.4,598.7V591" />
              </g>
            </g>
            <g id="box_52_">
              <g>
                <g>
                  <path
                    className="st6"
                    d="M742,587.9h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,589.2,740.4,587.9,742,587.9
							z"
                  />
                  <path
                    className="st7"
                    d="M742,587.9h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,589.2,740.4,587.9,742,587.9
							z"
                  />
                </g>
                <path className="st7" d="M752.5,594.9h-13" />
              </g>
              <g id="Box2Off_5_">
                <path
                  className="st8"
                  d="M742,587.9h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,589.2,740.4,587.9,742,587.9z
						"
                />
                <path className="st9" d="M746,598.7V591" />
              </g>
            </g>
            <g id="box_51_">
              <g id="Box2On_5_">
                <g>
                  <path
                    className="st6"
                    d="M720.5,587.9h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C717.5,589.2,718.9,587.9,720.5,587.9z"
                  />
                  <path
                    className="st7"
                    d="M720.5,587.9h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C717.5,589.2,718.9,587.9,720.5,587.9z"
                  />
                </g>
                <path className="st7" d="M731,594.9h-13" />
              </g>
              <g id="box1Off_5_">
                <path
                  className="st8"
                  d="M720.5,587.9h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C717.5,589.2,718.9,587.9,720.5,587.9z"
                />
                <path className="st9" d="M724.5,598.7V591" />
              </g>
            </g>
          </g>
          <g id="turbine_10_">
            <g id="currentSource_14_">
              <circle className="st5" cx="769.7" cy="550" r="6.3" />
              <circle className="st5" cx="776.9" cy="550" r="6.3" />
            </g>
            <g id="unit_13_">
              <path
                id="background_33_"
                className="st1"
                d="M790.4,535.2c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V535.2z"
              />
              <text
                id="name_21_"
                transform="matrix(1 0 0 1 861.1041 555.4428)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_13_">
                <linearGradient
                  id="SVGID_43_"
                  gradientUnits="userSpaceOnUse"
                  x1="840.8997"
                  y1="646.5628"
                  x2="843.0107"
                  y2="652.8938"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st61"
                  d="M847.6,560.7c0.4,0.2,0.9,0.2,1.3,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L847.6,560.7z"
                />

                <linearGradient
                  id="SVGID_44_"
                  gradientUnits="userSpaceOnUse"
                  x1="832.8647"
                  y1="662.1236"
                  x2="826.3107"
                  y2="661.0371"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st62"
                  d="M832.2,534.5c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.3,0,1.3-0.7L832.2,534.5z"
                />

                <linearGradient
                  id="SVGID_45_"
                  gradientUnits="userSpaceOnUse"
                  x1="823.1841"
                  y1="647.4852"
                  x2="827.1942"
                  y2="641.9986"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st63"
                  d="M816.8,560.7c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L816.8,560.7z"
                />
              </g>
              <g id="spin_13_">
                <path
                  className="st16"
                  d="M846.9,552.7c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C845.8,545.8,847,549.2,846.9,552.7z"
                />
                <path
                  className="st16"
                  d="M830.3,565.9c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C836.6,566,833.4,566.5,830.3,565.9z"
                />
                <path
                  className="st16"
                  d="M822.3,543.1c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.3-0.1l-0.5,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C819,548.5,820.2,545.5,822.3,543.1z"
                />
              </g>
              <g id="error_13_">
                <circle className="st17" cx="848" cy="541.2" r="9" />
                <text
                  transform="matrix(1 0 0 1 841.2781 545.6854)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_13_">
                <g id="personellGreen_13_">
                  <circle className="st19" cx="833.2" cy="552.2" r="8.3" />
                  <path
                    className="st18"
                    d="M837.6,555.8c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C832,555.8,834.8,555.8,837.6,555.8z M834.4,548.4c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C834.4,547.6,834.4,548,834.4,548.4z M831.3,550.2c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C834.1,550.2,832.7,550.2,831.3,550.2z"
                  />
                </g>
                <g id="personellOrange_13_">
                  <path
                    className="st20"
                    d="M823.7,556.6l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C823.8,558.5,823.2,557.4,823.7,556.6z"
                  />
                  <path
                    className="st18"
                    d="M837.6,556.7c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C832,556.7,834.8,556.7,837.6,556.7z M834.4,549.3c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C834.4,548.5,834.4,548.9,834.4,549.3z M831.3,551.1c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C834.1,551.1,832.7,551.1,831.3,551.1z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_13_">
              <line
                className="st7"
                x1="707.7"
                y1="550.2"
                x2="717.5"
                y2="550.2"
              />
              <line
                className="st7"
                x1="731.5"
                y1="550.2"
                x2="739.5"
                y2="550.2"
              />
              <line className="st7" x1="753" y1="550.2" x2="794.4" y2="550.2" />
            </g>
            <g id="box_50_">
              <g id="boxOn_28_">
                <path
                  className="st21"
                  d="M797.4,543.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C794.4,544.5,795.7,543.2,797.4,543.2z"
                />
                <path className="st7" d="M807.9,550.2h-13" />
              </g>
              <g id="boxOff_33_">
                <path
                  className="st8"
                  d="M797.4,543.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C794.4,544.5,795.7,543.2,797.4,543.2z"
                />
                <path className="st9" d="M801.4,554v-7.7" />
              </g>
            </g>
            <g id="box_49_">
              <g id="boxOn_27_">
                <g>
                  <path
                    className="st6"
                    d="M742,543.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,544.5,740.4,543.2,742,543.2
							z"
                  />
                  <path
                    className="st7"
                    d="M742,543.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,544.5,740.4,543.2,742,543.2
							z"
                  />
                </g>
                <path className="st7" d="M752.5,550.2h-13" />
              </g>
              <g id="boxOff_32_">
                <path
                  className="st8"
                  d="M742,543.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,544.5,740.4,543.2,742,543.2z
						"
                />
                <path className="st9" d="M746,554v-7.7" />
              </g>
            </g>
            <g id="box_48_">
              <g id="boxOn_26_">
                <g>
                  <path
                    className="st6"
                    d="M720.5,543.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C717.5,544.5,718.9,543.2,720.5,543.2z"
                  />
                  <path
                    className="st7"
                    d="M720.5,543.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C717.5,544.5,718.9,543.2,720.5,543.2z"
                  />
                </g>
                <path className="st7" d="M731,550.2h-13" />
              </g>
              <g id="boxOff_31_">
                <path
                  className="st8"
                  d="M720.5,543.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C717.5,544.5,718.9,543.2,720.5,543.2z"
                />
                <path className="st9" d="M724.5,554v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine_9_">
            <g id="currentSource_13_">
              <circle className="st5" cx="796.7" cy="696" r="6.3" />
              <circle className="st5" cx="803.9" cy="696" r="6.3" />
            </g>
            <g id="unit_12_">
              <path
                id="background_31_"
                className="st1"
                d="M817.4,681.2c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V681.2z"
              />
              <text
                id="name_20_"
                transform="matrix(1 0 0 1 888.1041 701.4627)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_12_">
                <linearGradient
                  id="SVGID_46_"
                  gradientUnits="userSpaceOnUse"
                  x1="867.8997"
                  y1="500.5429"
                  x2="870.0107"
                  y2="506.8739"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st64"
                  d="M874.6,706.7c0.4,0.2,0.9,0.2,1.3,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L874.6,706.7z"
                />

                <linearGradient
                  id="SVGID_47_"
                  gradientUnits="userSpaceOnUse"
                  x1="859.8647"
                  y1="516.1037"
                  x2="853.3107"
                  y2="515.0172"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st65"
                  d="M859.2,680.5c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.3,0,1.3-0.7L859.2,680.5z"
                />

                <linearGradient
                  id="SVGID_48_"
                  gradientUnits="userSpaceOnUse"
                  x1="850.1841"
                  y1="501.4653"
                  x2="854.1942"
                  y2="495.9787"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st66"
                  d="M843.8,706.7c-0.4,0.3-0.7,0.7-0.7,1.2s0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L843.8,706.7z"
                />
              </g>
              <g id="spin_12_">
                <path
                  className="st16"
                  d="M873.9,698.7c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C872.8,691.8,874,695.2,873.9,698.7z"
                />
                <path
                  className="st16"
                  d="M857.3,711.9c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C863.6,712,860.4,712.5,857.3,711.9z"
                />
                <path
                  className="st16"
                  d="M849.3,689.1c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.3-0.1l-0.5,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C846,694.5,847.2,691.5,849.3,689.1z"
                />
              </g>
              <g id="error_12_">
                <circle className="st17" cx="875" cy="687.2" r="9" />
                <text
                  transform="matrix(1 0 0 1 868.2781 691.7053)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_12_">
                <g id="personellGreen_12_">
                  <circle className="st19" cx="860.2" cy="698.2" r="8.3" />
                  <path
                    className="st18"
                    d="M864.6,701.8c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C859,701.8,861.8,701.8,864.6,701.8z M861.4,694.4c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C861.4,693.6,861.4,694,861.4,694.4z M858.3,696.2c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C861.1,696.2,859.7,696.2,858.3,696.2z"
                  />
                </g>
                <g id="personellOrange_12_">
                  <path
                    className="st20"
                    d="M850.7,702.6l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C850.8,704.5,850.2,703.4,850.7,702.6z"
                  />
                  <path
                    className="st18"
                    d="M864.6,702.7c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C859,702.7,861.8,702.7,864.6,702.7z M861.4,695.4c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C861.4,694.5,861.4,694.9,861.4,695.4z M858.3,697.2c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C861.1,697.2,859.7,697.2,858.3,697.2z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_12_">
              <line
                className="st7"
                x1="734.7"
                y1="696.2"
                x2="744.5"
                y2="696.2"
              />
              <line
                className="st7"
                x1="758.5"
                y1="696.2"
                x2="766.5"
                y2="696.2"
              />
              <line className="st7" x1="780" y1="696.2" x2="821.4" y2="696.2" />
            </g>
            <g id="box_47_">
              <g id="Box3On_4_">
                <path
                  className="st21"
                  d="M824.4,689.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C821.4,690.5,822.7,689.2,824.4,689.2z"
                />
                <path className="st7" d="M834.9,696.2h-13" />
              </g>
              <g id="Box3Off_4_">
                <path
                  className="st8"
                  d="M824.4,689.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C821.4,690.5,822.7,689.2,824.4,689.2z"
                />
                <path className="st9" d="M828.4,700v-7.7" />
              </g>
            </g>
            <g id="box_46_">
              <g>
                <g>
                  <path
                    className="st6"
                    d="M769,689.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C766,690.5,767.4,689.2,769,689.2
							z"
                  />
                  <path
                    className="st7"
                    d="M769,689.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C766,690.5,767.4,689.2,769,689.2
							z"
                  />
                </g>
                <path className="st7" d="M779.5,696.2h-13" />
              </g>
              <g id="Box2Off_4_">
                <path
                  className="st8"
                  d="M769,689.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C766,690.5,767.4,689.2,769,689.2z
						"
                />
                <path className="st9" d="M773,700v-7.7" />
              </g>
            </g>
            <g id="box_45_">
              <g id="Box2On_4_">
                <g>
                  <path
                    className="st6"
                    d="M747.5,689.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C744.5,690.5,745.9,689.2,747.5,689.2z"
                  />
                  <path
                    className="st7"
                    d="M747.5,689.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C744.5,690.5,745.9,689.2,747.5,689.2z"
                  />
                </g>
                <path className="st7" d="M758,696.2h-13" />
              </g>
              <g id="box1Off_4_">
                <path
                  className="st8"
                  d="M747.5,689.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C744.5,690.5,745.9,689.2,747.5,689.2z"
                />
                <path className="st9" d="M751.5,700v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine_8_">
            <g id="currentSource_12_">
              <circle className="st5" cx="796.7" cy="651.3" r="6.3" />
              <circle className="st5" cx="803.9" cy="651.3" r="6.3" />
            </g>
            <g id="unit_11_">
              <path
                id="background_29_"
                className="st1"
                d="M817.4,636.5c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V636.5z"
              />
              <text
                id="name_19_"
                transform="matrix(1 0 0 1 888.1041 656.762)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_11_">
                <linearGradient
                  id="SVGID_49_"
                  gradientUnits="userSpaceOnUse"
                  x1="867.8997"
                  y1="545.2435"
                  x2="870.0107"
                  y2="551.5745"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st67"
                  d="M874.6,662c0.4,0.2,0.9,0.2,1.3,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L874.6,662z"
                />

                <linearGradient
                  id="SVGID_50_"
                  gradientUnits="userSpaceOnUse"
                  x1="859.8647"
                  y1="560.8044"
                  x2="853.3107"
                  y2="559.7178"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st68"
                  d="M859.2,635.8c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.3,0,1.3-0.7L859.2,635.8z"
                />

                <linearGradient
                  id="SVGID_51_"
                  gradientUnits="userSpaceOnUse"
                  x1="850.1841"
                  y1="546.166"
                  x2="854.1942"
                  y2="540.6793"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st69"
                  d="M843.8,662c-0.4,0.3-0.7,0.7-0.7,1.2s0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2c0,0,2.9-1,3.4-3.5
						c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L843.8,662z"
                />
              </g>
              <g id="spin_11_">
                <path
                  className="st16"
                  d="M873.9,654c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C872.8,647.1,874,650.5,873.9,654z"
                />
                <path
                  className="st16"
                  d="M857.3,667.2c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C863.6,667.3,860.4,667.8,857.3,667.2z"
                />
                <path
                  className="st16"
                  d="M849.3,644.4c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.3-0.1l-0.5,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C846,649.8,847.2,646.8,849.3,644.4z"
                />
              </g>
              <g id="error_11_">
                <circle className="st17" cx="875" cy="642.5" r="9" />
                <text
                  transform="matrix(1 0 0 1 868.2781 647.0046)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_11_">
                <g id="personellGreen_11_">
                  <circle className="st19" cx="860.2" cy="653.5" r="8.3" />
                  <path
                    className="st18"
                    d="M864.6,657.1c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C859,657.1,861.8,657.1,864.6,657.1z M861.4,649.7c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C861.4,648.9,861.4,649.3,861.4,649.7z M858.3,651.5c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C861.1,651.5,859.7,651.5,858.3,651.5z"
                  />
                </g>
                <g id="personellOrange_11_">
                  <path
                    className="st20"
                    d="M850.7,657.9l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C850.8,659.8,850.2,658.7,850.7,657.9z"
                  />
                  <path
                    className="st18"
                    d="M864.6,658c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8c-0.7-0.3-1.4-0.4-2.2-0.5
							c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C859,658,861.8,658,864.6,658z M861.4,650.7c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3c0-0.2-0.1-0.3-0.3-0.3
							c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0c0-0.4,0-0.8,0-1.3
							c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6c1.7,0,3.4,0,5.2,0
							c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C861.4,649.8,861.4,650.2,861.4,650.7z M858.3,652.5c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C861.1,652.5,859.7,652.5,858.3,652.5z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_11_">
              <line
                className="st7"
                x1="734.7"
                y1="651.5"
                x2="744.5"
                y2="651.5"
              />
              <line
                className="st7"
                x1="758.5"
                y1="651.5"
                x2="766.5"
                y2="651.5"
              />
              <line className="st7" x1="780" y1="651.5" x2="821.4" y2="651.5" />
            </g>
            <g id="box_44_">
              <g id="boxOn_25_">
                <path
                  className="st21"
                  d="M824.4,644.5h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C821.4,645.8,822.7,644.5,824.4,644.5z"
                />
                <path className="st7" d="M834.9,651.5h-13" />
              </g>
              <g id="boxOff_30_">
                <path
                  className="st8"
                  d="M824.4,644.5h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C821.4,645.8,822.7,644.5,824.4,644.5z"
                />
                <path className="st9" d="M828.4,655.3v-7.7" />
              </g>
            </g>
            <g id="box_43_">
              <g id="boxOn_24_">
                <g>
                  <path
                    className="st6"
                    d="M769,644.5h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C766,645.8,767.4,644.5,769,644.5
							z"
                  />
                  <path
                    className="st7"
                    d="M769,644.5h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C766,645.8,767.4,644.5,769,644.5
							z"
                  />
                </g>
                <path className="st7" d="M779.5,651.5h-13" />
              </g>
              <g id="boxOff_29_">
                <path
                  className="st8"
                  d="M769,644.5h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C766,645.8,767.4,644.5,769,644.5z
						"
                />
                <path className="st9" d="M773,655.3v-7.7" />
              </g>
            </g>
            <g id="box_42_">
              <g id="boxOn_23_">
                <g>
                  <path
                    className="st6"
                    d="M747.5,644.5h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C744.5,645.8,745.9,644.5,747.5,644.5z"
                  />
                  <path
                    className="st7"
                    d="M747.5,644.5h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C744.5,645.8,745.9,644.5,747.5,644.5z"
                  />
                </g>
                <path className="st7" d="M758,651.5h-13" />
              </g>
              <g id="boxOff_28_">
                <path
                  className="st8"
                  d="M747.5,644.5h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C744.5,645.8,745.9,644.5,747.5,644.5z"
                />
                <path className="st9" d="M751.5,655.3v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine1_1_">
            <g id="currentSource_11_">
              <circle className="st5" cx="769.7" cy="249.4" r="6.3" />
              <circle className="st5" cx="776.9" cy="249.4" r="6.3" />
            </g>
            <g id="unit_10_">
              <path
                id="background_27_"
                className="st1"
                d="M790.4,234.6c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V234.6z"
              />
              <text
                id="name_18_"
                transform="matrix(1 0 0 1 861.1041 254.8419)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_10_">
                <linearGradient
                  id="SVGID_52_"
                  gradientUnits="userSpaceOnUse"
                  x1="840.8997"
                  y1="947.1637"
                  x2="843.0107"
                  y2="953.4947"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st70"
                  d="M847.6,260.1c0.4,0.2,0.9,0.2,1.3,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L847.6,260.1z"
                />

                <linearGradient
                  id="SVGID_53_"
                  gradientUnits="userSpaceOnUse"
                  x1="832.8647"
                  y1="962.7245"
                  x2="826.3107"
                  y2="961.638"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st71"
                  d="M832.2,233.9c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.3,0,1.3-0.7L832.2,233.9z"
                />

                <linearGradient
                  id="SVGID_54_"
                  gradientUnits="userSpaceOnUse"
                  x1="823.1841"
                  y1="948.0861"
                  x2="827.1942"
                  y2="942.5995"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st72"
                  d="M816.8,260.1c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L816.8,260.1z"
                />
              </g>
              <g id="spin_10_">
                <path
                  className="st16"
                  d="M846.9,252.1c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C845.8,245.2,847,248.6,846.9,252.1z"
                />
                <path
                  className="st16"
                  d="M830.3,265.3c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C836.6,265.4,833.4,265.9,830.3,265.3z"
                />
                <path
                  className="st16"
                  d="M822.3,242.5c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.3-0.1l-0.5,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C819,247.9,820.2,244.9,822.3,242.5z"
                />
              </g>
              <g id="error_10_">
                <circle className="st17" cx="848" cy="240.6" r="9" />
                <text
                  transform="matrix(1 0 0 1 841.2781 245.0845)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_10_">
                <g id="personellGreen_10_">
                  <circle className="st19" cx="833.2" cy="251.6" r="8.3" />
                  <path
                    className="st18"
                    d="M837.6,255.2c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C832,255.2,834.8,255.2,837.6,255.2z M834.4,247.8c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C834.4,247,834.4,247.4,834.4,247.8z M831.3,249.6c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C834.1,249.6,832.7,249.6,831.3,249.6z"
                  />
                </g>
                <g id="personellOrange_10_">
                  <path
                    className="st20"
                    d="M823.7,256l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C823.8,257.9,823.2,256.8,823.7,256z"
                  />
                  <path
                    className="st18"
                    d="M837.6,256.1c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C832,256.1,834.8,256.1,837.6,256.1z M834.4,248.7c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C834.4,247.9,834.4,248.3,834.4,248.7z M831.3,250.5c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C834.1,250.5,832.7,250.5,831.3,250.5z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_10_">
              <line
                className="st7"
                x1="707.7"
                y1="249.6"
                x2="717.5"
                y2="249.6"
              />
              <line
                className="st7"
                x1="731.5"
                y1="249.6"
                x2="739.5"
                y2="249.6"
              />
              <line className="st7" x1="753" y1="249.6" x2="794.4" y2="249.6" />
            </g>
            <g id="box_41_">
              <g id="BoxOn_6_">
                <path
                  className="st21"
                  d="M797.4,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C794.4,243.9,795.7,242.6,797.4,242.6z"
                />
                <path className="st7" d="M807.9,249.6h-13" />
              </g>
              <g id="boxOff_27_">
                <path
                  className="st8"
                  d="M797.4,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C794.4,243.9,795.7,242.6,797.4,242.6z"
                />
                <path className="st9" d="M801.4,253.4v-7.7" />
              </g>
            </g>
            <g id="box_40_">
              <g id="BoxOn_5_">
                <g>
                  <path
                    className="st6"
                    d="M742,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,243.9,740.4,242.6,742,242.6
							z"
                  />
                  <path
                    className="st7"
                    d="M742,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,243.9,740.4,242.6,742,242.6
							z"
                  />
                </g>
                <path className="st7" d="M752.5,249.6h-13" />
              </g>
              <g id="boxOff_26_">
                <path
                  className="st8"
                  d="M742,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C739,243.9,740.4,242.6,742,242.6z
						"
                />
                <path className="st9" d="M746,253.4v-7.7" />
              </g>
            </g>
            <g id="box_39_">
              <g id="BoxOn_4_">
                <g>
                  <path
                    className="st6"
                    d="M720.5,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C717.5,243.9,718.9,242.6,720.5,242.6z"
                  />
                  <path
                    className="st7"
                    d="M720.5,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C717.5,243.9,718.9,242.6,720.5,242.6z"
                  />
                </g>
                <path className="st7" d="M731,249.6h-13" />
              </g>
              <g id="boxOff_25_">
                <path
                  className="st8"
                  d="M720.5,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C717.5,243.9,718.9,242.6,720.5,242.6z"
                />
                <path className="st9" d="M724.5,253.4v-7.7" />
              </g>
            </g>
          </g>
          <g id="s4Label_1_">
            <path
              id="background_25_"
              className="st1"
              d="M692.7,171.1c0-2.8,2.2-5,5-5h19c2.8,0,5,2.2,5,5v11c0,2.8-2.2,5-5,5h-19
				c-2.8,0-5-2.2-5-5V171.1z"
            />
            <text
              id="name_17_"
              transform="matrix(1 0 0 1 699.5884 180.9498)"
              className="st2 st3 st4"
            >
              S3
            </text>
          </g>
          <g id="seksjonLinje_1_">
            <line
              id="linje_5_"
              className="st0"
              x1="707.2"
              y1="199.9"
              x2="707.2"
              y2="212.8"
            />
            <line
              id="linje_4_"
              className="st0"
              x1="735.1"
              y1="594.9"
              x2="735.1"
              y2="697.2"
            />
            <line
              id="linje_3_"
              className="st0"
              x1="707.2"
              y1="226.8"
              x2="707.2"
              y2="595.4"
            />
            <g id="box_37_">
              <g id="boxOn_22_">
                <g>
                  <path
                    className="st6"
                    d="M700.2,223.8v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
							C701.5,226.8,700.2,225.5,700.2,223.8z"
                  />
                  <path
                    className="st7"
                    d="M700.2,223.8v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
							C701.5,226.8,700.2,225.5,700.2,223.8z"
                  />
                </g>
                <path className="st7" d="M707.2,213.3v13" />
              </g>
              <g id="boxOff_24_">
                <path
                  className="st8"
                  d="M714.2,215.8v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8
						C712.8,212.8,714.2,214.2,714.2,215.8z"
                />
                <path className="st9" d="M703.3,219.8h7.7" />
              </g>
            </g>
            <g id="box_36_">
              <g id="boxOn_21_">
                <g>
                  <path
                    className="st6"
                    d="M700.2,371.3v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
							C701.5,374.3,700.2,373,700.2,371.3z"
                  />
                  <path
                    className="st7"
                    d="M700.2,371.3v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
							C701.5,374.3,700.2,373,700.2,371.3z"
                  />
                </g>
                <path className="st7" d="M707.2,360.8v13" />
              </g>
              <g id="boxOff_23_">
                <path
                  className="st8"
                  d="M714.2,363.3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8
						C712.8,360.3,714.2,361.7,714.2,363.3z"
                />
                <path className="st9" d="M703.3,367.3h7.7" />
              </g>
            </g>
            <g id="box_35_">
              <g id="boxOn_20_">
                <g>
                  <path
                    className="st6"
                    d="M700.2,395v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8C701.5,398,700.2,396.7,700.2,395z
							"
                  />
                  <path
                    className="st7"
                    d="M700.2,395v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8C701.5,398,700.2,396.7,700.2,395z
							"
                  />
                </g>
                <path className="st7" d="M707.2,384.5v13" />
              </g>
              <g id="boxOff_22_">
                <path
                  className="st8"
                  d="M714.2,387v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8C712.8,384,714.2,385.4,714.2,387z
						"
                />
                <path className="st9" d="M703.3,391h7.7" />
              </g>
            </g>
            <g id="box_34_">
              <g id="boxOn_19_">
                <g>
                  <path
                    className="st6"
                    d="M700.2,498.3v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
							C701.5,501.3,700.2,500,700.2,498.3z"
                  />
                  <path
                    className="st7"
                    d="M700.2,498.3v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
							C701.5,501.3,700.2,500,700.2,498.3z"
                  />
                </g>
                <path className="st7" d="M707.2,487.8v13" />
              </g>
              <g id="boxOff_21_">
                <path
                  className="st8"
                  d="M714.2,490.3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8
						C712.8,487.3,714.2,488.7,714.2,490.3z"
                />
                <path className="st9" d="M703.3,494.3h7.7" />
              </g>
            </g>
            <g id="box_1_">
              <g id="boxOn_18_">
                <g>
                  <path
                    className="st6"
                    d="M700.2,522v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8C701.5,525,700.2,523.7,700.2,522z
							"
                  />
                  <path
                    className="st7"
                    d="M700.2,522v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8C701.5,525,700.2,523.7,700.2,522z
							"
                  />
                </g>
                <path className="st7" d="M707.2,511.5v13" />
              </g>
              <g id="boxOff_20_">
                <path
                  className="st8"
                  d="M714.2,514v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8C712.8,511,714.2,512.4,714.2,514z
						"
                />
                <path className="st9" d="M703.3,518h7.7" />
              </g>
            </g>
          </g>
        </g>
        <g id="seksjon_2_">
          <g id="turbine_23_">
            <g id="currentSource_28_">
              <circle className="st5" cx="423.3" cy="338.8" r="6.3" />
              <circle className="st5" cx="430.6" cy="338.8" r="6.3" />
            </g>
            <g id="unit_27_">
              <path
                id="background_63_"
                className="st1"
                d="M444,324c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88c-2.8,0-5-2.2-5-5
					V324z"
              />
              <text
                id="name_36_"
                transform="matrix(1 0 0 1 514.7724 344.2432)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_27_">
                <linearGradient
                  id="SVGID_55_"
                  gradientUnits="userSpaceOnUse"
                  x1="494.568"
                  y1="857.7624"
                  x2="496.679"
                  y2="864.0934"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st73"
                  d="M501.3,349.5c0.4,0.2,0.9,0.2,1.4,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L501.3,349.5z"
                />

                <linearGradient
                  id="SVGID_56_"
                  gradientUnits="userSpaceOnUse"
                  x1="486.533"
                  y1="873.3232"
                  x2="479.9789"
                  y2="872.2367"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st74"
                  d="M485.9,323.3c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.4,0,1.4-0.7L485.9,323.3z"
                />

                <linearGradient
                  id="SVGID_57_"
                  gradientUnits="userSpaceOnUse"
                  x1="476.8524"
                  y1="858.6848"
                  x2="480.8624"
                  y2="853.1982"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st75"
                  d="M470.5,349.5c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L470.5,349.5z"
                />
              </g>
              <g id="spin_27_">
                <path
                  className="st16"
                  d="M500.5,341.5c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C499.5,334.6,500.7,338,500.5,341.5z"
                />
                <path
                  className="st16"
                  d="M484,354.7c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C490.3,354.8,487.1,355.3,484,354.7z"
                />
                <path
                  className="st16"
                  d="M475.9,331.9c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.4-0.1l-0.5,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C472.7,337.3,473.9,334.3,475.9,331.9z"
                />
              </g>
              <g id="error_27_">
                <circle className="st17" cx="501.6" cy="330" r="9" />
                <text
                  transform="matrix(1 0 0 1 494.9464 334.4858)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_27_">
                <g id="personellGreen_27_">
                  <circle className="st19" cx="486.9" cy="341" r="8.3" />
                  <path
                    className="st18"
                    d="M491.2,344.6c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C485.7,344.6,488.5,344.6,491.2,344.6z M488.1,337.2c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C488.1,336.4,488.1,336.8,488.1,337.2z M485,339c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C487.8,339,486.4,339,485,339z"
                  />
                </g>
                <g id="personellOrange_27_">
                  <path
                    className="st20"
                    d="M477.4,345.4l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C477.5,347.3,476.8,346.2,477.4,345.4z"
                  />
                  <path
                    className="st18"
                    d="M491.2,345.5c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C485.7,345.5,488.5,345.5,491.2,345.5z M488.1,338.2c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C488.1,337.3,488.1,337.7,488.1,338.2z M485,339.9c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C487.8,339.9,486.4,339.9,485,339.9z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_27_">
              <line className="st7" x1="361.3" y1="339" x2="371.2" y2="339" />
              <line className="st7" x1="385.2" y1="339" x2="393.2" y2="339" />
              <line className="st7" x1="406.7" y1="339" x2="448" y2="339" />
            </g>
            <g id="box_97_">
              <g id="Box3On_12_">
                <path
                  className="st21"
                  d="M451,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C448,333.3,449.4,332,451,332z"
                />
                <path className="st7" d="M461.5,339h-13" />
              </g>
              <g id="Box3Off_12_">
                <path
                  className="st8"
                  d="M451,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C448,333.3,449.4,332,451,332z"
                />
                <path className="st9" d="M455,342.8v-7.7" />
              </g>
            </g>
            <g id="box_96_">
              <g>
                <g>
                  <path
                    className="st6"
                    d="M395.7,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C392.7,333.3,394.1,332,395.7,332
							z"
                  />
                  <path
                    className="st7"
                    d="M395.7,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C392.7,333.3,394.1,332,395.7,332
							z"
                  />
                </g>
                <path className="st7" d="M406.2,339h-13" />
              </g>
              <g id="Box2Off_13_">
                <path
                  className="st8"
                  d="M395.7,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C392.7,333.3,394.1,332,395.7,332z
						"
                />
                <path className="st9" d="M399.7,342.8v-7.7" />
              </g>
            </g>
            <g id="box_95_">
              <g id="Box2On_12_">
                <g>
                  <path
                    className="st6"
                    d="M374.2,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C371.2,333.3,372.5,332,374.2,332
							z"
                  />
                  <path
                    className="st7"
                    d="M374.2,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C371.2,333.3,372.5,332,374.2,332
							z"
                  />
                </g>
                <path className="st7" d="M384.7,339h-13" />
              </g>
              <g id="box1Off_12_">
                <path
                  className="st8"
                  d="M374.2,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C371.2,333.3,372.5,332,374.2,332z
						"
                />
                <path className="st9" d="M378.2,342.8v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine_22_">
            <g id="currentSource_27_">
              <circle className="st5" cx="423.3" cy="294.1" r="6.3" />
              <circle className="st5" cx="430.6" cy="294.1" r="6.3" />
            </g>
            <g id="unit_26_">
              <path
                id="background_61_"
                className="st1"
                d="M444,279.3c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V279.3z"
              />
              <text
                id="name_35_"
                transform="matrix(1 0 0 1 514.7724 299.5426)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_26_">
                <linearGradient
                  id="SVGID_58_"
                  gradientUnits="userSpaceOnUse"
                  x1="494.568"
                  y1="902.463"
                  x2="496.679"
                  y2="908.794"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st76"
                  d="M501.3,304.8c0.4,0.2,0.9,0.2,1.4,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L501.3,304.8z"
                />

                <linearGradient
                  id="SVGID_59_"
                  gradientUnits="userSpaceOnUse"
                  x1="486.533"
                  y1="918.0239"
                  x2="479.9789"
                  y2="916.9374"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st77"
                  d="M485.9,278.6c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.4,0,1.4-0.7L485.9,278.6z"
                />

                <linearGradient
                  id="SVGID_60_"
                  gradientUnits="userSpaceOnUse"
                  x1="476.8524"
                  y1="903.3854"
                  x2="480.8624"
                  y2="897.8988"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st78"
                  d="M470.5,304.8c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L470.5,304.8z"
                />
              </g>
              <g id="spin_26_">
                <path
                  className="st16"
                  d="M500.5,296.8c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C499.5,289.9,500.7,293.3,500.5,296.8z"
                />
                <path
                  className="st16"
                  d="M484,310c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C490.3,310.1,487.1,310.6,484,310z"
                />
                <path
                  className="st16"
                  d="M475.9,287.2c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.4-0.1l-0.5,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C472.7,292.6,473.9,289.6,475.9,287.2z"
                />
              </g>
              <g id="error_26_">
                <circle className="st17" cx="501.6" cy="285.3" r="9" />
                <text
                  transform="matrix(1 0 0 1 494.9464 289.7852)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_26_">
                <g id="personellGreen_26_">
                  <circle className="st19" cx="486.9" cy="296.3" r="8.3" />
                  <path
                    className="st18"
                    d="M491.2,299.9c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C485.7,299.9,488.5,299.9,491.2,299.9z M488.1,292.5c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C488.1,291.7,488.1,292.1,488.1,292.5z M485,294.3c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C487.8,294.3,486.4,294.3,485,294.3z"
                  />
                </g>
                <g id="personellOrange_26_">
                  <path
                    className="st20"
                    d="M477.4,300.7l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C477.5,302.6,476.8,301.5,477.4,300.7z"
                  />
                  <path
                    className="st18"
                    d="M491.2,300.8c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C485.7,300.8,488.5,300.8,491.2,300.8z M488.1,293.4c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C488.1,292.6,488.1,293,488.1,293.4z M485,295.2c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C487.8,295.2,486.4,295.2,485,295.2z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_26_">
              <line
                className="st7"
                x1="361.3"
                y1="294.3"
                x2="371.2"
                y2="294.3"
              />
              <line
                className="st7"
                x1="385.2"
                y1="294.3"
                x2="393.2"
                y2="294.3"
              />
              <line className="st7" x1="406.7" y1="294.3" x2="448" y2="294.3" />
            </g>
            <g id="box_94_">
              <g id="boxOn_51_">
                <path
                  className="st21"
                  d="M451,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C448,288.6,449.4,287.3,451,287.3
						z"
                />
                <path className="st7" d="M461.5,294.3h-13" />
              </g>
              <g id="boxOff_59_">
                <path
                  className="st8"
                  d="M451,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C448,288.6,449.4,287.3,451,287.3z
						"
                />
                <path className="st9" d="M455,298.1v-7.7" />
              </g>
            </g>
            <g id="box_93_">
              <g id="boxOn_50_">
                <g>
                  <path
                    className="st6"
                    d="M395.7,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C392.7,288.6,394.1,287.3,395.7,287.3z"
                  />
                  <path
                    className="st7"
                    d="M395.7,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C392.7,288.6,394.1,287.3,395.7,287.3z"
                  />
                </g>
                <path className="st7" d="M406.2,294.3h-13" />
              </g>
              <g id="boxOff_58_">
                <path
                  className="st8"
                  d="M395.7,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C392.7,288.6,394.1,287.3,395.7,287.3z"
                />
                <path className="st9" d="M399.7,298.1v-7.7" />
              </g>
            </g>
            <g id="box_92_">
              <g id="boxOn_49_">
                <g>
                  <path
                    className="st6"
                    d="M374.2,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C371.2,288.6,372.5,287.3,374.2,287.3z"
                  />
                  <path
                    className="st7"
                    d="M374.2,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C371.2,288.6,372.5,287.3,374.2,287.3z"
                  />
                </g>
                <path className="st7" d="M384.7,294.3h-13" />
              </g>
              <g id="boxOff_57_">
                <path
                  className="st8"
                  d="M374.2,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C371.2,288.6,372.5,287.3,374.2,287.3z"
                />
                <path className="st9" d="M378.2,298.1v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine_21_">
            <g id="currentSource_26_">
              <circle className="st5" cx="423.3" cy="464.6" r="6.3" />
              <circle className="st5" cx="430.6" cy="464.6" r="6.3" />
            </g>
            <g id="unit_25_">
              <path
                id="background_59_"
                className="st1"
                d="M444,449.7c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V449.7z"
              />
              <text
                id="name_34_"
                transform="matrix(1 0 0 1 514.7724 470.0181)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_25_">
                <linearGradient
                  id="SVGID_61_"
                  gradientUnits="userSpaceOnUse"
                  x1="494.568"
                  y1="731.9875"
                  x2="496.679"
                  y2="738.3185"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st79"
                  d="M501.3,475.2c0.4,0.2,0.9,0.2,1.4,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L501.3,475.2z"
                />

                <linearGradient
                  id="SVGID_62_"
                  gradientUnits="userSpaceOnUse"
                  x1="486.533"
                  y1="747.5483"
                  x2="479.9789"
                  y2="746.4619"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st80"
                  d="M485.9,449.1c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.4,0,1.4-0.7L485.9,449.1z"
                />

                <linearGradient
                  id="SVGID_63_"
                  gradientUnits="userSpaceOnUse"
                  x1="476.8524"
                  y1="732.9099"
                  x2="480.8624"
                  y2="727.4233"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st81"
                  d="M470.5,475.3c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L470.5,475.3z"
                />
              </g>
              <g id="spin_25_">
                <path
                  className="st16"
                  d="M500.5,467.3c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C499.5,460.4,500.7,463.8,500.5,467.3z"
                />
                <path
                  className="st16"
                  d="M484,480.4c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C490.3,480.6,487.1,481,484,480.4z"
                />
                <path
                  className="st16"
                  d="M475.9,457.7c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.4-0.1l-0.5,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C472.7,463.1,473.9,460,475.9,457.7z"
                />
              </g>
              <g id="error_25_">
                <circle className="st17" cx="501.6" cy="455.7" r="9" />
                <text
                  transform="matrix(1 0 0 1 494.9464 460.2606)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_25_">
                <g id="personellGreen_25_">
                  <circle className="st19" cx="486.9" cy="466.8" r="8.3" />
                  <path
                    className="st18"
                    d="M491.2,470.3c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C485.7,470.3,488.5,470.3,491.2,470.3z M488.1,463c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C488.1,462.1,488.1,462.6,488.1,463z M485,464.8c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C487.8,464.8,486.4,464.8,485,464.8z"
                  />
                </g>
                <g id="personellOrange_25_">
                  <path
                    className="st20"
                    d="M477.4,471.1l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C477.5,473.1,476.8,472,477.4,471.1z"
                  />
                  <path
                    className="st18"
                    d="M491.2,471.3c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C485.7,471.3,488.5,471.3,491.2,471.3z M488.1,463.9c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C488.1,463.1,488.1,463.5,488.1,463.9z M485,465.7c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C487.8,465.7,486.4,465.7,485,465.7z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_25_">
              <line
                className="st7"
                x1="361.3"
                y1="464.7"
                x2="371.2"
                y2="464.7"
              />
              <line
                className="st7"
                x1="385.2"
                y1="464.7"
                x2="393.2"
                y2="464.7"
              />
              <line className="st7" x1="406.7" y1="464.7" x2="448" y2="464.7" />
            </g>
            <g id="box_91_">
              <g id="Box3On_11_">
                <path
                  className="st21"
                  d="M451,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C448,459.1,449.4,457.7,451,457.7
						z"
                />
                <path className="st7" d="M461.5,464.7h-13" />
              </g>
              <g id="Box3Off_11_">
                <path
                  className="st8"
                  d="M451,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C448,459.1,449.4,457.7,451,457.7z
						"
                />
                <path className="st9" d="M455,468.6v-7.7" />
              </g>
            </g>
            <g id="box_90_">
              <g>
                <g>
                  <path
                    className="st6"
                    d="M395.7,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C392.7,459.1,394.1,457.7,395.7,457.7z"
                  />
                  <path
                    className="st7"
                    d="M395.7,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C392.7,459.1,394.1,457.7,395.7,457.7z"
                  />
                </g>
                <path className="st7" d="M406.2,464.7h-13" />
              </g>
              <g id="Box2Off_12_">
                <path
                  className="st8"
                  d="M395.7,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C392.7,459.1,394.1,457.7,395.7,457.7z"
                />
                <path className="st9" d="M399.7,468.6v-7.7" />
              </g>
            </g>
            <g id="box_89_">
              <g id="Box2On_11_">
                <g>
                  <path
                    className="st6"
                    d="M374.2,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C371.2,459.1,372.5,457.7,374.2,457.7z"
                  />
                  <path
                    className="st7"
                    d="M374.2,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C371.2,459.1,372.5,457.7,374.2,457.7z"
                  />
                </g>
                <path className="st7" d="M384.7,464.7h-13" />
              </g>
              <g id="box1Off_11_">
                <path
                  className="st8"
                  d="M374.2,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C371.2,459.1,372.5,457.7,374.2,457.7z"
                />
                <path className="st9" d="M378.2,468.6v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine_20_">
            <g id="currentSource_25_">
              <circle className="st5" cx="423.3" cy="419.9" r="6.3" />
              <circle className="st5" cx="430.6" cy="419.9" r="6.3" />
            </g>
            <g id="unit_24_">
              <path
                id="background_57_"
                className="st1"
                d="M444,405c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88c-2.8,0-5-2.2-5-5
					V405z"
              />
              <text
                id="name_33_"
                transform="matrix(1 0 0 1 514.7724 425.3174)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_24_">
                <linearGradient
                  id="SVGID_64_"
                  gradientUnits="userSpaceOnUse"
                  x1="494.568"
                  y1="776.6882"
                  x2="496.679"
                  y2="783.0192"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st82"
                  d="M501.3,430.5c0.4,0.2,0.9,0.2,1.4,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L501.3,430.5z"
                />

                <linearGradient
                  id="SVGID_65_"
                  gradientUnits="userSpaceOnUse"
                  x1="486.533"
                  y1="792.249"
                  x2="479.9789"
                  y2="791.1625"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st83"
                  d="M485.9,404.4c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.4,0,1.4-0.7L485.9,404.4z"
                />

                <linearGradient
                  id="SVGID_66_"
                  gradientUnits="userSpaceOnUse"
                  x1="476.8524"
                  y1="777.6106"
                  x2="480.8624"
                  y2="772.124"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st84"
                  d="M470.5,430.6c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L470.5,430.6z"
                />
              </g>
              <g id="spin_24_">
                <path
                  className="st16"
                  d="M500.5,422.6c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C499.5,415.7,500.7,419.1,500.5,422.6z"
                />
                <path
                  className="st16"
                  d="M484,435.7c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C490.3,435.9,487.1,436.3,484,435.7z"
                />
                <path
                  className="st16"
                  d="M475.9,413c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.4-0.1l-0.5,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C472.7,418.4,473.9,415.3,475.9,413z"
                />
              </g>
              <g id="error_24_">
                <circle className="st17" cx="501.6" cy="411" r="9" />
                <text
                  transform="matrix(1 0 0 1 494.9464 415.56)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_24_">
                <g id="personellGreen_24_">
                  <circle className="st19" cx="486.9" cy="422.1" r="8.3" />
                  <path
                    className="st18"
                    d="M491.2,425.6c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C485.7,425.6,488.5,425.6,491.2,425.6z M488.1,418.3c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C488.1,417.4,488.1,417.9,488.1,418.3z M485,420.1c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C487.8,420.1,486.4,420.1,485,420.1z"
                  />
                </g>
                <g id="personellOrange_24_">
                  <path
                    className="st20"
                    d="M477.4,426.4l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C477.5,428.4,476.8,427.3,477.4,426.4z"
                  />
                  <path
                    className="st18"
                    d="M491.2,426.6c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C485.7,426.6,488.5,426.6,491.2,426.6z M488.1,419.2c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C488.1,418.4,488.1,418.8,488.1,419.2z M485,421c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C487.8,421,486.4,421,485,421z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_24_">
              <line className="st7" x1="361.3" y1="420" x2="371.2" y2="420" />
              <line className="st7" x1="385.2" y1="420" x2="393.2" y2="420" />
              <line className="st7" x1="406.7" y1="420" x2="448" y2="420" />
            </g>
            <g id="box_88_">
              <g id="boxOn_48_">
                <path
                  className="st21"
                  d="M451,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C448,414.4,449.4,413,451,413z"
                />
                <path className="st7" d="M461.5,420h-13" />
              </g>
              <g id="boxOff_56_">
                <path
                  className="st8"
                  d="M451,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C448,414.4,449.4,413,451,413z"
                />
                <path className="st9" d="M455,423.9v-7.7" />
              </g>
            </g>
            <g id="box_87_">
              <g id="boxOn_47_">
                <g>
                  <path
                    className="st6"
                    d="M395.7,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C392.7,414.4,394.1,413,395.7,413
							z"
                  />
                  <path
                    className="st7"
                    d="M395.7,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C392.7,414.4,394.1,413,395.7,413
							z"
                  />
                </g>
                <path className="st7" d="M406.2,420h-13" />
              </g>
              <g id="boxOff_55_">
                <path
                  className="st8"
                  d="M395.7,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C392.7,414.4,394.1,413,395.7,413z
						"
                />
                <path className="st9" d="M399.7,423.9v-7.7" />
              </g>
            </g>
            <g id="box_86_">
              <g id="boxOn_46_">
                <g>
                  <path
                    className="st6"
                    d="M374.2,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C371.2,414.4,372.5,413,374.2,413
							z"
                  />
                  <path
                    className="st7"
                    d="M374.2,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C371.2,414.4,372.5,413,374.2,413
							z"
                  />
                </g>
                <path className="st7" d="M384.7,420h-13" />
              </g>
              <g id="boxOff_54_">
                <path
                  className="st8"
                  d="M374.2,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C371.2,414.4,372.5,413,374.2,413z
						"
                />
                <path className="st9" d="M378.2,423.9v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine_19_">
            <g id="currentSource_24_">
              <circle className="st5" cx="423.3" cy="594.7" r="6.3" />
              <circle className="st5" cx="430.6" cy="594.7" r="6.3" />
            </g>
            <g id="unit_23_">
              <path
                id="background_55_"
                className="st1"
                d="M444,579.9c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V579.9z"
              />
              <text
                id="name_32_"
                transform="matrix(1 0 0 1 514.7724 600.1435)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_23_">
                <linearGradient
                  id="SVGID_67_"
                  gradientUnits="userSpaceOnUse"
                  x1="494.568"
                  y1="601.8621"
                  x2="496.679"
                  y2="608.1931"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st85"
                  d="M501.3,605.4c0.4,0.2,0.9,0.2,1.4,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L501.3,605.4z"
                />

                <linearGradient
                  id="SVGID_68_"
                  gradientUnits="userSpaceOnUse"
                  x1="486.533"
                  y1="617.423"
                  x2="479.9789"
                  y2="616.3365"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st86"
                  d="M485.9,579.2c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.4,0,1.4-0.7L485.9,579.2z"
                />

                <linearGradient
                  id="SVGID_69_"
                  gradientUnits="userSpaceOnUse"
                  x1="476.8524"
                  y1="602.7845"
                  x2="480.8624"
                  y2="597.2979"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st87"
                  d="M470.5,605.4c-0.4,0.3-0.7,0.7-0.7,1.2s0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L470.5,605.4z"
                />
              </g>
              <g id="spin_23_">
                <path
                  className="st16"
                  d="M500.5,597.4c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3L494,585c1.2,0.8,2.3,1.7,3.2,2.8
						C499.5,590.5,500.7,593.9,500.5,597.4z"
                />
                <path
                  className="st16"
                  d="M484,610.6c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C490.3,610.7,487.1,611.2,484,610.6z"
                />
                <path
                  className="st16"
                  d="M475.9,587.8c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.4-0.1l-0.5,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C472.7,593.2,473.9,590.2,475.9,587.8z"
                />
              </g>
              <g id="error_23_">
                <circle className="st17" cx="501.6" cy="585.9" r="9" />
                <text
                  transform="matrix(1 0 0 1 494.9464 590.386)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_23_">
                <g id="personellGreen_23_">
                  <circle className="st19" cx="486.9" cy="596.9" r="8.3" />
                  <path
                    className="st18"
                    d="M491.2,600.5c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C485.7,600.5,488.5,600.5,491.2,600.5z M488.1,593.1c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C488.1,592.3,488.1,592.7,488.1,593.1z M485,594.9c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C487.8,594.9,486.4,594.9,485,594.9z"
                  />
                </g>
                <g id="personellOrange_23_">
                  <path
                    className="st20"
                    d="M477.4,601.3l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C477.5,603.2,476.8,602.1,477.4,601.3z"
                  />
                  <path
                    className="st18"
                    d="M491.2,601.4c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C485.7,601.4,488.5,601.4,491.2,601.4z M488.1,594.1c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C488.1,593.2,488.1,593.6,488.1,594.1z M485,595.8c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C487.8,595.8,486.4,595.8,485,595.8z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_23_">
              <line
                className="st7"
                x1="361.3"
                y1="594.9"
                x2="371.2"
                y2="594.9"
              />
              <line
                className="st7"
                x1="385.2"
                y1="594.9"
                x2="393.2"
                y2="594.9"
              />
              <line className="st7" x1="406.7" y1="594.9" x2="448" y2="594.9" />
            </g>
            <g id="box_85_">
              <g id="Box3On_10_">
                <path
                  className="st21"
                  d="M451,587.9h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C448,589.2,449.4,587.9,451,587.9
						z"
                />
                <path className="st7" d="M461.5,594.9h-13" />
              </g>
              <g id="Box3Off_10_">
                <path
                  className="st8"
                  d="M451,587.9h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C448,589.2,449.4,587.9,451,587.9z
						"
                />
                <path className="st9" d="M455,598.7V591" />
              </g>
            </g>
            <g id="box_84_">
              <g>
                <g>
                  <path
                    className="st6"
                    d="M395.7,587.9h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C392.7,589.2,394.1,587.9,395.7,587.9z"
                  />
                  <path
                    className="st7"
                    d="M395.7,587.9h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C392.7,589.2,394.1,587.9,395.7,587.9z"
                  />
                </g>
                <path className="st7" d="M406.2,594.9h-13" />
              </g>
              <g id="Box2Off_11_">
                <path
                  className="st8"
                  d="M395.7,587.9h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C392.7,589.2,394.1,587.9,395.7,587.9z"
                />
                <path className="st9" d="M399.7,598.7V591" />
              </g>
            </g>
            <g id="box_83_">
              <g id="Box2On_10_">
                <g>
                  <path
                    className="st6"
                    d="M374.2,587.9h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C371.2,589.2,372.5,587.9,374.2,587.9z"
                  />
                  <path
                    className="st7"
                    d="M374.2,587.9h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C371.2,589.2,372.5,587.9,374.2,587.9z"
                  />
                </g>
                <path className="st7" d="M384.7,594.9h-13" />
              </g>
              <g id="box1Off_10_">
                <path
                  className="st8"
                  d="M374.2,587.9h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C371.2,589.2,372.5,587.9,374.2,587.9z"
                />
                <path className="st9" d="M378.2,598.7V591" />
              </g>
            </g>
          </g>
          <g id="turbine_18_">
            <g id="currentSource_23_">
              <circle className="st5" cx="423.3" cy="550" r="6.3" />
              <circle className="st5" cx="430.6" cy="550" r="6.3" />
            </g>
            <g id="unit_22_">
              <path
                id="background_53_"
                className="st1"
                d="M444,535.2c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V535.2z"
              />
              <text
                id="name_31_"
                transform="matrix(1 0 0 1 514.7724 555.4428)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_22_">
                <linearGradient
                  id="SVGID_70_"
                  gradientUnits="userSpaceOnUse"
                  x1="494.568"
                  y1="646.5628"
                  x2="496.679"
                  y2="652.8938"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st88"
                  d="M501.3,560.7c0.4,0.2,0.9,0.2,1.4,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L501.3,560.7z"
                />

                <linearGradient
                  id="SVGID_71_"
                  gradientUnits="userSpaceOnUse"
                  x1="486.533"
                  y1="662.1236"
                  x2="479.9789"
                  y2="661.0371"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st89"
                  d="M485.9,534.5c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.4,0,1.4-0.7L485.9,534.5z"
                />

                <linearGradient
                  id="SVGID_72_"
                  gradientUnits="userSpaceOnUse"
                  x1="476.8524"
                  y1="647.4852"
                  x2="480.8624"
                  y2="641.9986"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st90"
                  d="M470.5,560.7c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L470.5,560.7z"
                />
              </g>
              <g id="spin_22_">
                <path
                  className="st16"
                  d="M500.5,552.7c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C499.5,545.8,500.7,549.2,500.5,552.7z"
                />
                <path
                  className="st16"
                  d="M484,565.9c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C490.3,566,487.1,566.5,484,565.9z"
                />
                <path
                  className="st16"
                  d="M475.9,543.1c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.4-0.1l-0.5,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C472.7,548.5,473.9,545.5,475.9,543.1z"
                />
              </g>
              <g id="error_22_">
                <circle className="st17" cx="501.6" cy="541.2" r="9" />
                <text
                  transform="matrix(1 0 0 1 494.9464 545.6854)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_22_">
                <g id="personellGreen_22_">
                  <circle className="st19" cx="486.9" cy="552.2" r="8.3" />
                  <path
                    className="st18"
                    d="M491.2,555.8c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C485.7,555.8,488.5,555.8,491.2,555.8z M488.1,548.4c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C488.1,547.6,488.1,548,488.1,548.4z M485,550.2c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C487.8,550.2,486.4,550.2,485,550.2z"
                  />
                </g>
                <g id="personellOrange_22_">
                  <path
                    className="st20"
                    d="M477.4,556.6l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C477.5,558.5,476.8,557.4,477.4,556.6z"
                  />
                  <path
                    className="st18"
                    d="M491.2,556.7c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C485.7,556.7,488.5,556.7,491.2,556.7z M488.1,549.3c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C488.1,548.5,488.1,548.9,488.1,549.3z M485,551.1c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C487.8,551.1,486.4,551.1,485,551.1z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_22_">
              <line
                className="st7"
                x1="361.3"
                y1="550.2"
                x2="371.2"
                y2="550.2"
              />
              <line
                className="st7"
                x1="385.2"
                y1="550.2"
                x2="393.2"
                y2="550.2"
              />
              <line className="st7" x1="406.7" y1="550.2" x2="448" y2="550.2" />
            </g>
            <g id="box_82_">
              <g id="boxOn_45_">
                <path
                  className="st21"
                  d="M451,543.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C448,544.5,449.4,543.2,451,543.2
						z"
                />
                <path className="st7" d="M461.5,550.2h-13" />
              </g>
              <g id="boxOff_53_">
                <path
                  className="st8"
                  d="M451,543.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C448,544.5,449.4,543.2,451,543.2z
						"
                />
                <path className="st9" d="M455,554v-7.7" />
              </g>
            </g>
            <g id="box_81_">
              <g id="boxOn_44_">
                <g>
                  <path
                    className="st6"
                    d="M395.7,543.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C392.7,544.5,394.1,543.2,395.7,543.2z"
                  />
                  <path
                    className="st7"
                    d="M395.7,543.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C392.7,544.5,394.1,543.2,395.7,543.2z"
                  />
                </g>
                <path className="st7" d="M406.2,550.2h-13" />
              </g>
              <g id="boxOff_52_">
                <path
                  className="st8"
                  d="M395.7,543.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C392.7,544.5,394.1,543.2,395.7,543.2z"
                />
                <path className="st9" d="M399.7,554v-7.7" />
              </g>
            </g>
            <g id="box_80_">
              <g id="boxOn_43_">
                <g>
                  <path
                    className="st6"
                    d="M374.2,543.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C371.2,544.5,372.5,543.2,374.2,543.2z"
                  />
                  <path
                    className="st7"
                    d="M374.2,543.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C371.2,544.5,372.5,543.2,374.2,543.2z"
                  />
                </g>
                <path className="st7" d="M384.7,550.2h-13" />
              </g>
              <g id="boxOff_51_">
                <path
                  className="st8"
                  d="M374.2,543.2h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C371.2,544.5,372.5,543.2,374.2,543.2z"
                />
                <path className="st9" d="M378.2,554v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine1_2_">
            <g id="currentSource_20_">
              <circle className="st5" cx="423.3" cy="249.4" r="6.3" />
              <circle className="st5" cx="430.6" cy="249.4" r="6.3" />
            </g>
            <g id="unit_19_">
              <path
                id="background_47_"
                className="st1"
                d="M444,234.6c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V234.6z"
              />
              <text
                id="name_28_"
                transform="matrix(1 0 0 1 514.7724 254.8419)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_19_">
                <linearGradient
                  id="SVGID_79_"
                  gradientUnits="userSpaceOnUse"
                  x1="494.568"
                  y1="947.1637"
                  x2="496.679"
                  y2="953.4947"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st97"
                  d="M501.3,260.1c0.4,0.2,0.9,0.2,1.4,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L501.3,260.1z"
                />

                <linearGradient
                  id="SVGID_80_"
                  gradientUnits="userSpaceOnUse"
                  x1="486.533"
                  y1="962.7245"
                  x2="479.9789"
                  y2="961.638"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st98"
                  d="M485.9,233.9c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.4,0,1.4-0.7L485.9,233.9z"
                />

                <linearGradient
                  id="SVGID_81_"
                  gradientUnits="userSpaceOnUse"
                  x1="476.8524"
                  y1="948.0861"
                  x2="480.8624"
                  y2="942.5995"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st99"
                  d="M470.5,260.1c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L470.5,260.1z"
                />
              </g>
              <g id="spin_19_">
                <path
                  className="st16"
                  d="M500.5,252.1c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C499.5,245.2,500.7,248.6,500.5,252.1z"
                />
                <path
                  className="st16"
                  d="M484,265.3c-3.1-0.6-6-2.3-8.1-4.8c-1-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C490.3,265.4,487.1,265.9,484,265.3z"
                />
                <path
                  className="st16"
                  d="M475.9,242.5c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.4-0.1l-0.5,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C472.7,247.9,473.9,244.9,475.9,242.5z"
                />
              </g>
              <g id="error_19_">
                <circle className="st17" cx="501.6" cy="240.6" r="9" />
                <text
                  transform="matrix(1 0 0 1 494.9464 245.0845)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_19_">
                <g id="personellGreen_19_">
                  <circle className="st19" cx="486.9" cy="251.6" r="8.3" />
                  <path
                    className="st18"
                    d="M491.2,255.2c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C485.7,255.2,488.5,255.2,491.2,255.2z M488.1,247.8c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C488.1,247,488.1,247.4,488.1,247.8z M485,249.6c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C487.8,249.6,486.4,249.6,485,249.6z"
                  />
                </g>
                <g id="personellOrange_19_">
                  <path
                    className="st20"
                    d="M477.4,256l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C477.5,257.9,476.8,256.8,477.4,256z"
                  />
                  <path
                    className="st18"
                    d="M491.2,256.1c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C485.7,256.1,488.5,256.1,491.2,256.1z M488.1,248.7c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C488.1,247.9,488.1,248.3,488.1,248.7z M485,250.5c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C487.8,250.5,486.4,250.5,485,250.5z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_19_">
              <line
                className="st7"
                x1="361.3"
                y1="249.6"
                x2="371.2"
                y2="249.6"
              />
              <line
                className="st7"
                x1="385.2"
                y1="249.6"
                x2="393.2"
                y2="249.6"
              />
              <line className="st7" x1="406.7" y1="249.6" x2="448" y2="249.6" />
            </g>
            <g id="box_73_">
              <g id="BoxOn_9_">
                <path
                  className="st21"
                  d="M451,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C448,243.9,449.4,242.6,451,242.6
						z"
                />
                <path className="st7" d="M461.5,249.6h-13" />
              </g>
              <g id="boxOff_47_">
                <path
                  className="st8"
                  d="M451,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C448,243.9,449.4,242.6,451,242.6z
						"
                />
                <path className="st9" d="M455,253.4v-7.7" />
              </g>
            </g>
            <g id="box_72_">
              <g id="BoxOn_8_">
                <g>
                  <path
                    className="st6"
                    d="M395.7,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C392.7,243.9,394.1,242.6,395.7,242.6z"
                  />
                  <path
                    className="st7"
                    d="M395.7,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C392.7,243.9,394.1,242.6,395.7,242.6z"
                  />
                </g>
                <path className="st7" d="M406.2,249.6h-13" />
              </g>
              <g id="boxOff_46_">
                <path
                  className="st8"
                  d="M395.7,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C392.7,243.9,394.1,242.6,395.7,242.6z"
                />
                <path className="st9" d="M399.7,253.4v-7.7" />
              </g>
            </g>
            <g id="box_71_">
              <g id="BoxOn_7_">
                <g>
                  <path
                    className="st6"
                    d="M374.2,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C371.2,243.9,372.5,242.6,374.2,242.6z"
                  />
                  <path
                    className="st7"
                    d="M374.2,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C371.2,243.9,372.5,242.6,374.2,242.6z"
                  />
                </g>
                <path className="st7" d="M384.7,249.6h-13" />
              </g>
              <g id="boxOff_45_">
                <path
                  className="st8"
                  d="M374.2,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C371.2,243.9,372.5,242.6,374.2,242.6z"
                />
                <path className="st9" d="M378.2,253.4v-7.7" />
              </g>
            </g>
          </g>
          <g id="s4Label_2_">
            <path
              id="background_45_"
              className="st1"
              d="M346.3,171.1c0-2.8,2.2-5,5-5h19c2.8,0,5,2.2,5,5v11c0,2.8-2.2,5-5,5h-19
				c-2.8,0-5-2.2-5-5V171.1z"
            />
            <text
              id="name_27_"
              transform="matrix(1 0 0 1 353.4384 180.9498)"
              className="st2 st3 st4"
            >
              S2
            </text>
          </g>
          <g id="seksjonLinje_2_">
            <line
              id="linje_8_"
              className="st0"
              x1="360.8"
              y1="199.9"
              x2="360.8"
              y2="212.8"
            />

            <line
              id="linje_6_"
              className="st0"
              x1="360.8"
              y1="226.8"
              x2="360.8"
              y2="595.4"
            />
            <g id="box_69_">
              <g id="boxOn_39_">
                <g>
                  <path
                    className="st6"
                    d="M353.8,223.8v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
							C355.2,226.8,353.8,225.5,353.8,223.8z"
                  />
                  <path
                    className="st7"
                    d="M353.8,223.8v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
							C355.2,226.8,353.8,225.5,353.8,223.8z"
                  />
                </g>
                <path className="st7" d="M360.8,213.3v13" />
              </g>
              <g id="boxOff_44_">
                <path
                  className="st8"
                  d="M367.8,215.8v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8
						C366.5,212.8,367.8,214.2,367.8,215.8z"
                />
                <path className="st9" d="M357,219.8h7.7" />
              </g>
            </g>
            <g id="box_68_">
              <g id="boxOn_38_">
                <g>
                  <path
                    className="st6"
                    d="M353.8,371.3v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
							C355.2,374.3,353.8,373,353.8,371.3z"
                  />
                  <path
                    className="st7"
                    d="M353.8,371.3v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
							C355.2,374.3,353.8,373,353.8,371.3z"
                  />
                </g>
                <path className="st7" d="M360.8,360.8v13" />
              </g>
              <g id="boxOff_43_">
                <path
                  className="st8"
                  d="M367.8,363.3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8
						C366.5,360.3,367.8,361.7,367.8,363.3z"
                />
                <path className="st9" d="M357,367.3h7.7" />
              </g>
            </g>
            <g id="box_67_">
              <g id="boxOn_37_">
                <g>
                  <path
                    className="st6"
                    d="M353.8,395v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8C355.2,398,353.8,396.7,353.8,395z
							"
                  />
                  <path
                    className="st7"
                    d="M353.8,395v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8C355.2,398,353.8,396.7,353.8,395z
							"
                  />
                </g>
                <path className="st7" d="M360.8,384.5v13" />
              </g>
              <g id="boxOff_42_">
                <path
                  className="st8"
                  d="M367.8,387v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8C366.5,384,367.8,385.4,367.8,387z
						"
                />
                <path className="st9" d="M357,391h7.7" />
              </g>
            </g>
            <g id="box_66_">
              <g id="boxOn_36_">
                <g>
                  <path
                    className="st6"
                    d="M353.8,498.3v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
							C355.2,501.3,353.8,500,353.8,498.3z"
                  />
                  <path
                    className="st7"
                    d="M353.8,498.3v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8
							C355.2,501.3,353.8,500,353.8,498.3z"
                  />
                </g>
                <path className="st7" d="M360.8,487.8v13" />
              </g>
              <g id="boxOff_41_">
                <path
                  className="st8"
                  d="M367.8,490.3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8
						C366.5,487.3,367.8,488.7,367.8,490.3z"
                />
                <path className="st9" d="M357,494.3h7.7" />
              </g>
            </g>
            <g id="box_38_">
              <g id="boxOn_35_">
                <g>
                  <path
                    className="st6"
                    d="M353.8,522v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8C355.2,525,353.8,523.7,353.8,522z
							"
                  />
                  <path
                    className="st7"
                    d="M353.8,522v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8C355.2,525,353.8,523.7,353.8,522z
							"
                  />
                </g>
                <path className="st7" d="M360.8,511.5v13" />
              </g>
              <g id="boxOff_40_">
                <path
                  className="st8"
                  d="M367.8,514v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8C366.5,511,367.8,512.4,367.8,514z
						"
                />
                <path className="st9" d="M357,518h7.7" />
              </g>
            </g>
          </g>
        </g>
        <g id="seksjon_3_">
          <g id="turbine_31_">
            <g id="currentSource_37_">
              <circle className="st5" cx="77" cy="338.8" r="6.3" />
              <circle className="st5" cx="84.3" cy="338.8" r="6.3" />
            </g>
            <g id="unit_36_">
              <path
                id="background_83_"
                className="st1"
                d="M97.7,324c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V324z"
              />
              <text
                id="name_46_"
                transform="matrix(1 0 0 1 168.4407 344.2432)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_36_">
                <linearGradient
                  id="SVGID_82_"
                  gradientUnits="userSpaceOnUse"
                  x1="148.2362"
                  y1="857.7624"
                  x2="150.3472"
                  y2="864.0934"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st100"
                  d="M155,349.5c0.4,0.2,0.9,0.2,1.4,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L155,349.5z"
                />

                <linearGradient
                  id="SVGID_83_"
                  gradientUnits="userSpaceOnUse"
                  x1="140.2012"
                  y1="873.3232"
                  x2="133.6472"
                  y2="872.2367"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st101"
                  d="M139.6,323.3c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.4,0,1.4-0.7L139.6,323.3z"
                />

                <linearGradient
                  id="SVGID_84_"
                  gradientUnits="userSpaceOnUse"
                  x1="130.5207"
                  y1="858.6848"
                  x2="134.5307"
                  y2="853.1982"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st102"
                  d="M124.2,349.5c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L124.2,349.5z"
                />
              </g>
              <g id="spin_36_">
                <path
                  className="st16"
                  d="M154.2,341.5c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C153.2,334.6,154.3,338,154.2,341.5z"
                />
                <path
                  className="st16"
                  d="M137.7,354.7c-3.1-0.6-6-2.3-8.1-4.8c-0.9-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1
						l0.9,0.5l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C144,354.8,140.7,355.3,137.7,354.7z"
                />
                <path
                  className="st16"
                  d="M129.6,331.9c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.4-0.1l-0.4,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C126.4,337.3,127.6,334.3,129.6,331.9z"
                />
              </g>
              <g id="error_36_">
                <circle className="st17" cx="155.3" cy="330" r="9" />
                <text
                  transform="matrix(1 0 0 1 148.6146 334.4858)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_36_">
                <g id="personellGreen_36_">
                  <circle className="st19" cx="140.6" cy="341" r="8.3" />
                  <path
                    className="st18"
                    d="M144.9,344.6c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C139.4,344.6,142.1,344.6,144.9,344.6z M141.8,337.2c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C141.8,336.4,141.8,336.8,141.8,337.2z M138.7,339c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C141.4,339,140.1,339,138.7,339z"
                  />
                </g>
                <g id="personellOrange_36_">
                  <path
                    className="st20"
                    d="M131.1,345.4l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C131.1,347.3,130.5,346.2,131.1,345.4z"
                  />
                  <path
                    className="st18"
                    d="M144.9,345.5c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C139.4,345.5,142.1,345.5,144.9,345.5z M141.8,338.2c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C141.8,337.3,141.8,337.7,141.8,338.2z M138.7,339.9c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C141.4,339.9,140.1,339.9,138.7,339.9z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_36_">
              <line className="st7" x1="15" y1="339" x2="24.9" y2="339" />
              <line className="st7" x1="38.9" y1="339" x2="46.9" y2="339" />
              <line className="st7" x1="60.4" y1="339" x2="101.7" y2="339" />
            </g>
            <g id="box_129_">
              <g id="Box3On_16_">
                <path
                  className="st21"
                  d="M104.7,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C101.7,333.3,103,332,104.7,332z"
                />
                <path className="st7" d="M115.2,339h-13" />
              </g>
              <g id="Box3Off_16_">
                <path
                  className="st8"
                  d="M104.7,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C101.7,333.3,103,332,104.7,332z"
                />
                <path className="st9" d="M108.7,342.8v-7.7" />
              </g>
            </g>
            <g id="box_128_">
              <g>
                <g>
                  <path
                    className="st6"
                    d="M49.4,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C46.4,333.3,47.7,332,49.4,332z"
                  />
                  <path
                    className="st7"
                    d="M49.4,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C46.4,333.3,47.7,332,49.4,332z"
                  />
                </g>
                <path className="st7" d="M59.9,339h-13" />
              </g>
              <g id="Box2Off_17_">
                <path
                  className="st8"
                  d="M49.4,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C46.4,333.3,47.7,332,49.4,332z"
                />
                <path className="st9" d="M53.4,342.8v-7.7" />
              </g>
            </g>
            <g id="box_127_">
              <g id="Box2On_16_">
                <g>
                  <path
                    className="st6"
                    d="M27.9,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C24.9,333.3,26.2,332,27.9,332z"
                  />
                  <path
                    className="st7"
                    d="M27.9,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C24.9,333.3,26.2,332,27.9,332z"
                  />
                </g>
                <path className="st7" d="M38.4,339h-13" />
              </g>
              <g id="box1Off_16_">
                <path
                  className="st8"
                  d="M27.9,332h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C24.9,333.3,26.2,332,27.9,332z"
                />
                <path className="st9" d="M31.9,342.8v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine_30_">
            <g id="currentSource_36_">
              <circle className="st5" cx="77" cy="294.1" r="6.3" />
              <circle className="st5" cx="84.3" cy="294.1" r="6.3" />
            </g>
            <g id="unit_35_">
              <path
                id="background_81_"
                className="st1"
                d="M97.7,279.3c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V279.3z"
              />
              <text
                id="name_45_"
                transform="matrix(1 0 0 1 168.4407 299.5426)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_35_">
                <linearGradient
                  id="SVGID_85_"
                  gradientUnits="userSpaceOnUse"
                  x1="148.2362"
                  y1="902.463"
                  x2="150.3472"
                  y2="908.794"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st103"
                  d="M155,304.8c0.4,0.2,0.9,0.2,1.4,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L155,304.8z"
                />

                <linearGradient
                  id="SVGID_86_"
                  gradientUnits="userSpaceOnUse"
                  x1="140.2012"
                  y1="918.0239"
                  x2="133.6472"
                  y2="916.9374"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st104"
                  d="M139.6,278.6c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.4,0,1.4-0.7L139.6,278.6z"
                />

                <linearGradient
                  id="SVGID_87_"
                  gradientUnits="userSpaceOnUse"
                  x1="130.5207"
                  y1="903.3854"
                  x2="134.5307"
                  y2="897.8988"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st105"
                  d="M124.2,304.8c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L124.2,304.8z"
                />
              </g>
              <g id="spin_35_">
                <path
                  className="st16"
                  d="M154.2,296.8c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C153.2,289.9,154.3,293.3,154.2,296.8z"
                />
                <path
                  className="st16"
                  d="M137.7,310c-3.1-0.6-6-2.3-8.1-4.8c-0.9-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1l0.9,0.5
						l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C144,310.1,140.7,310.6,137.7,310z"
                />
                <path
                  className="st16"
                  d="M129.6,287.2c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.4-0.1l-0.4,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C126.4,292.6,127.6,289.6,129.6,287.2z"
                />
              </g>
              <g id="error_35_">
                <circle className="st17" cx="155.3" cy="285.3" r="9" />
                <text
                  transform="matrix(1 0 0 1 148.6146 289.7852)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_35_">
                <g id="personellGreen_35_">
                  <circle className="st19" cx="140.6" cy="296.3" r="8.3" />
                  <path
                    className="st18"
                    d="M144.9,299.9c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C139.4,299.9,142.1,299.9,144.9,299.9z M141.8,292.5c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C141.8,291.7,141.8,292.1,141.8,292.5z M138.7,294.3c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C141.4,294.3,140.1,294.3,138.7,294.3z"
                  />
                </g>
                <g id="personellOrange_35_">
                  <path
                    className="st20"
                    d="M131.1,300.7l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C131.1,302.6,130.5,301.5,131.1,300.7z"
                  />
                  <path
                    className="st18"
                    d="M144.9,300.8c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C139.4,300.8,142.1,300.8,144.9,300.8z M141.8,293.4c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C141.8,292.6,141.8,293,141.8,293.4z M138.7,295.2c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C141.4,295.2,140.1,295.2,138.7,295.2z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_35_">
              <line className="st7" x1="15" y1="294.3" x2="24.9" y2="294.3" />
              <line className="st7" x1="38.9" y1="294.3" x2="46.9" y2="294.3" />
              <line
                className="st7"
                x1="60.4"
                y1="294.3"
                x2="101.7"
                y2="294.3"
              />
            </g>
            <g id="box_126_">
              <g id="boxOn_68_">
                <path
                  className="st21"
                  d="M104.7,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C101.7,288.6,103,287.3,104.7,287.3z"
                />
                <path className="st7" d="M115.2,294.3h-13" />
              </g>
              <g id="boxOff_79_">
                <path
                  className="st8"
                  d="M104.7,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C101.7,288.6,103,287.3,104.7,287.3z"
                />
                <path className="st9" d="M108.7,298.1v-7.7" />
              </g>
            </g>
            <g id="box_125_">
              <g id="boxOn_67_">
                <g>
                  <path
                    className="st6"
                    d="M49.4,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C46.4,288.6,47.7,287.3,49.4,287.3z"
                  />
                  <path
                    className="st7"
                    d="M49.4,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C46.4,288.6,47.7,287.3,49.4,287.3z"
                  />
                </g>
                <path className="st7" d="M59.9,294.3h-13" />
              </g>
              <g id="boxOff_78_">
                <path
                  className="st8"
                  d="M49.4,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C46.4,288.6,47.7,287.3,49.4,287.3z"
                />
                <path className="st9" d="M53.4,298.1v-7.7" />
              </g>
            </g>
            <g id="box_124_">
              <g id="boxOn_66_">
                <g>
                  <path
                    className="st6"
                    d="M27.9,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C24.9,288.6,26.2,287.3,27.9,287.3z"
                  />
                  <path
                    className="st7"
                    d="M27.9,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C24.9,288.6,26.2,287.3,27.9,287.3z"
                  />
                </g>
                <path className="st7" d="M38.4,294.3h-13" />
              </g>
              <g id="boxOff_77_">
                <path
                  className="st8"
                  d="M27.9,287.3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C24.9,288.6,26.2,287.3,27.9,287.3z"
                />
                <path className="st9" d="M31.9,298.1v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine_29_">
            <g id="currentSource_35_">
              <circle className="st5" cx="77" cy="464.6" r="6.3" />
              <circle className="st5" cx="84.3" cy="464.6" r="6.3" />
            </g>
            <g id="unit_34_">
              <path
                id="background_79_"
                className="st1"
                d="M97.7,449.7c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V449.7z"
              />
              <text
                id="name_44_"
                transform="matrix(1 0 0 1 168.4407 470.0181)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_34_">
                <linearGradient
                  id="SVGID_88_"
                  gradientUnits="userSpaceOnUse"
                  x1="148.2362"
                  y1="731.9875"
                  x2="150.3472"
                  y2="738.3185"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st106"
                  d="M155,475.2c0.4,0.2,0.9,0.2,1.4,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L155,475.2z"
                />

                <linearGradient
                  id="SVGID_89_"
                  gradientUnits="userSpaceOnUse"
                  x1="140.2012"
                  y1="747.5483"
                  x2="133.6472"
                  y2="746.4619"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st107"
                  d="M139.6,449.1c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.4,0,1.4-0.7L139.6,449.1z"
                />

                <linearGradient
                  id="SVGID_90_"
                  gradientUnits="userSpaceOnUse"
                  x1="130.5207"
                  y1="732.9099"
                  x2="134.5307"
                  y2="727.4233"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st108"
                  d="M124.2,475.3c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L124.2,475.3z"
                />
              </g>
              <g id="spin_34_">
                <path
                  className="st16"
                  d="M154.2,467.3c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C153.2,460.4,154.3,463.8,154.2,467.3z"
                />
                <path
                  className="st16"
                  d="M137.7,480.4c-3.1-0.6-6-2.3-8.1-4.8c-0.9-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1
						l0.9,0.5l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C144,480.6,140.7,481,137.7,480.4z"
                />
                <path
                  className="st16"
                  d="M129.6,457.7c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.4-0.1l-0.4,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C126.4,463.1,127.6,460,129.6,457.7z"
                />
              </g>
              <g id="error_34_">
                <circle className="st17" cx="155.3" cy="455.7" r="9" />
                <text
                  transform="matrix(1 0 0 1 148.6146 460.2606)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_34_">
                <g id="personellGreen_34_">
                  <circle className="st19" cx="140.6" cy="466.8" r="8.3" />
                  <path
                    className="st18"
                    d="M144.9,470.3c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C139.4,470.3,142.1,470.3,144.9,470.3z M141.8,463c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C141.8,462.1,141.8,462.6,141.8,463z M138.7,464.8c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C141.4,464.8,140.1,464.8,138.7,464.8z"
                  />
                </g>
                <g id="personellOrange_34_">
                  <path
                    className="st20"
                    d="M131.1,471.1l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C131.1,473.1,130.5,472,131.1,471.1z"
                  />
                  <path
                    className="st18"
                    d="M144.9,471.3c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C139.4,471.3,142.1,471.3,144.9,471.3z M141.8,463.9c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C141.8,463.1,141.8,463.5,141.8,463.9z M138.7,465.7c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C141.4,465.7,140.1,465.7,138.7,465.7z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_34_">
              <line className="st7" x1="15" y1="464.7" x2="24.9" y2="464.7" />
              <line className="st7" x1="38.9" y1="464.7" x2="46.9" y2="464.7" />
              <line
                className="st7"
                x1="60.4"
                y1="464.7"
                x2="101.7"
                y2="464.7"
              />
            </g>
            <g id="box_123_">
              <g id="Box3On_15_">
                <path
                  className="st21"
                  d="M104.7,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C101.7,459.1,103,457.7,104.7,457.7z"
                />
                <path className="st7" d="M115.2,464.7h-13" />
              </g>
              <g id="Box3Off_15_">
                <path
                  className="st8"
                  d="M104.7,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C101.7,459.1,103,457.7,104.7,457.7z"
                />
                <path className="st9" d="M108.7,468.6v-7.7" />
              </g>
            </g>
            <g id="box_122_">
              <g>
                <g>
                  <path
                    className="st6"
                    d="M49.4,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C46.4,459.1,47.7,457.7,49.4,457.7z"
                  />
                  <path
                    className="st7"
                    d="M49.4,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C46.4,459.1,47.7,457.7,49.4,457.7z"
                  />
                </g>
                <path className="st7" d="M59.9,464.7h-13" />
              </g>
              <g id="Box2Off_16_">
                <path
                  className="st8"
                  d="M49.4,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C46.4,459.1,47.7,457.7,49.4,457.7z"
                />
                <path className="st9" d="M53.4,468.6v-7.7" />
              </g>
            </g>
            <g id="box_121_">
              <g id="Box2On_15_">
                <g>
                  <path
                    className="st6"
                    d="M27.9,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C24.9,459.1,26.2,457.7,27.9,457.7z"
                  />
                  <path
                    className="st7"
                    d="M27.9,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C24.9,459.1,26.2,457.7,27.9,457.7z"
                  />
                </g>
                <path className="st7" d="M38.4,464.7h-13" />
              </g>
              <g id="box1Off_15_">
                <path
                  className="st8"
                  d="M27.9,457.7h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C24.9,459.1,26.2,457.7,27.9,457.7z"
                />
                <path className="st9" d="M31.9,468.6v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine_28_">
            <g id="currentSource_34_">
              <circle className="st5" cx="77" cy="419.9" r="6.3" />
              <circle className="st5" cx="84.3" cy="419.9" r="6.3" />
            </g>
            <g id="unit_33_">
              <path
                id="background_77_"
                className="st1"
                d="M97.7,405c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V405z"
              />
              <text
                id="name_43_"
                transform="matrix(1 0 0 1 168.4407 425.3174)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_33_">
                <linearGradient
                  id="SVGID_91_"
                  gradientUnits="userSpaceOnUse"
                  x1="148.2362"
                  y1="776.6882"
                  x2="150.3472"
                  y2="783.0192"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st109"
                  d="M155,430.5c0.4,0.2,0.9,0.2,1.4,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L155,430.5z"
                />

                <linearGradient
                  id="SVGID_92_"
                  gradientUnits="userSpaceOnUse"
                  x1="140.2012"
                  y1="792.249"
                  x2="133.6472"
                  y2="791.1625"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st110"
                  d="M139.6,404.4c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.4,0,1.4-0.7L139.6,404.4z"
                />

                <linearGradient
                  id="SVGID_93_"
                  gradientUnits="userSpaceOnUse"
                  x1="130.5207"
                  y1="777.6106"
                  x2="134.5307"
                  y2="772.124"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st111"
                  d="M124.2,430.6c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L124.2,430.6z"
                />
              </g>
              <g id="spin_33_">
                <path
                  className="st16"
                  d="M154.2,422.6c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1l-0.6,0.1
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C153.2,415.7,154.3,419.1,154.2,422.6z"
                />
                <path
                  className="st16"
                  d="M137.7,435.7c-3.1-0.6-6-2.3-8.1-4.8c-0.9-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1
						l0.9,0.5l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C144,435.9,140.7,436.3,137.7,435.7z"
                />
                <path
                  className="st16"
                  d="M129.6,413c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.4-0.1l-0.4,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C126.4,418.4,127.6,415.3,129.6,413z"
                />
              </g>
              <g id="error_33_">
                <circle className="st17" cx="155.3" cy="411" r="9" />
                <text
                  transform="matrix(1 0 0 1 148.6146 415.56)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_33_">
                <g id="personellGreen_33_">
                  <circle className="st19" cx="140.6" cy="422.1" r="8.3" />
                  <path
                    className="st18"
                    d="M144.9,425.6c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C139.4,425.6,142.1,425.6,144.9,425.6z M141.8,418.3c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C141.8,417.4,141.8,417.9,141.8,418.3z M138.7,420.1c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C141.4,420.1,140.1,420.1,138.7,420.1z"
                  />
                </g>
                <g id="personellOrange_33_">
                  <path
                    className="st20"
                    d="M131.1,426.4l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C131.1,428.4,130.5,427.3,131.1,426.4z"
                  />
                  <path
                    className="st18"
                    d="M144.9,426.6c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C139.4,426.6,142.1,426.6,144.9,426.6z M141.8,419.2c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C141.8,418.4,141.8,418.8,141.8,419.2z M138.7,421c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C141.4,421,140.1,421,138.7,421z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_33_">
              <line className="st7" x1="15" y1="420" x2="24.9" y2="420" />
              <line className="st7" x1="38.9" y1="420" x2="46.9" y2="420" />
              <line className="st7" x1="60.4" y1="420" x2="101.7" y2="420" />
            </g>
            <g id="box_120_">
              <g id="boxOn_65_">
                <path
                  className="st21"
                  d="M104.7,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C101.7,414.4,103,413,104.7,413z"
                />
                <path className="st7" d="M115.2,420h-13" />
              </g>
              <g id="boxOff_76_">
                <path
                  className="st8"
                  d="M104.7,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C101.7,414.4,103,413,104.7,413z"
                />
                <path className="st9" d="M108.7,423.9v-7.7" />
              </g>
            </g>
            <g id="box_119_">
              <g id="boxOn_64_">
                <g>
                  <path
                    className="st6"
                    d="M49.4,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C46.4,414.4,47.7,413,49.4,413z"
                  />
                  <path
                    className="st7"
                    d="M49.4,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C46.4,414.4,47.7,413,49.4,413z"
                  />
                </g>
                <path className="st7" d="M59.9,420h-13" />
              </g>
              <g id="boxOff_75_">
                <path
                  className="st8"
                  d="M49.4,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C46.4,414.4,47.7,413,49.4,413z"
                />
                <path className="st9" d="M53.4,423.9v-7.7" />
              </g>
            </g>
            <g id="box_118_">
              <g id="boxOn_63_">
                <g>
                  <path
                    className="st6"
                    d="M27.9,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C24.9,414.4,26.2,413,27.9,413z"
                  />
                  <path
                    className="st7"
                    d="M27.9,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C24.9,414.4,26.2,413,27.9,413z"
                  />
                </g>
                <path className="st7" d="M38.4,420h-13" />
              </g>
              <g id="boxOff_74_">
                <path
                  className="st8"
                  d="M27.9,413h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8C24.9,414.4,26.2,413,27.9,413z"
                />
                <path className="st9" d="M31.9,423.9v-7.7" />
              </g>
            </g>
          </g>
          <g id="turbine1_3_">
            <g id="currentSource_29_">
              <circle className="st5" cx="77" cy="249.4" r="6.3" />
              <circle className="st5" cx="84.3" cy="249.4" r="6.3" />
            </g>
            <g id="unit_28_">
              <path
                id="background_67_"
                className="st1"
                d="M97.7,234.6c0-2.8,2.2-5,5-5h88c2.8,0,5,2.2,5,5v30c0,2.8-2.2,5-5,5h-88
					c-2.8,0-5-2.2-5-5V234.6z"
              />
              <text
                id="name_38_"
                transform="matrix(1 0 0 1 168.4407 254.8419)"
                className="st2 st3 st4"
              >
                T1
              </text>
              <g id="blades_28_">
                <linearGradient
                  id="SVGID_106_"
                  gradientUnits="userSpaceOnUse"
                  x1="148.2362"
                  y1="947.1637"
                  x2="150.3472"
                  y2="953.4947"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st124"
                  d="M155,260.1c0.4,0.2,0.9,0.2,1.4,0c0.4-0.3,0.7-0.7,0.7-1.2c0-0.4-0.2-0.8-0.5-1l-8.2-7c0,0-2.4-2-4.8-1
						c-1,0.4-1.9,0.8-2.5,1.1c-0.6,0.3-0.7,1.2-0.1,1.5L155,260.1z"
                />

                <linearGradient
                  id="SVGID_107_"
                  gradientUnits="userSpaceOnUse"
                  x1="140.2012"
                  y1="962.7245"
                  x2="133.6472"
                  y2="961.638"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st125"
                  d="M139.6,233.9c0-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-1-0.3-1.4,0c-0.3,0.2-0.6,0.5-0.7,0.9l-2.2,10.6
						c0,0-0.5,3,1.4,4.7c0.8,0.7,1.6,1.2,2.2,1.7c0.6,0.4,1.4,0,1.4-0.7L139.6,233.9z"
                />

                <linearGradient
                  id="SVGID_108_"
                  gradientUnits="userSpaceOnUse"
                  x1="130.5207"
                  y1="948.0861"
                  x2="134.5307"
                  y2="942.5995"
                  gradientTransform="matrix(1 0 0 -1 0 1204.0819)"
                >
                  <stop offset="0" style={{ stopColor: props.mainColor }} />
                  <stop
                    offset="1"
                    style={{ stopColor: props.mainColor, stopOpacity: 0 }}
                  />
                </linearGradient>
                <path
                  className="st126"
                  d="M124.2,260.1c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.5,0.2,1,0.7,1.2c0.3,0.2,0.7,0.2,1.1,0.1l10.3-3.2
						c0,0,2.9-1,3.4-3.5c0.2-1.1,0.3-2,0.4-2.7c0.1-0.7-0.7-1.2-1.3-0.9L124.2,260.1z"
                />
              </g>
              <g id="spin_28_">
                <path
                  className="st16"
                  d="M154.2,252.1c-0.1,3-1.2,5.9-3.1,8.3l-0.1-0.6l-1.1,0.1l0.3,2.4l2.4-0.3l-0.1-1.1L152,261
						c2-2.5,3.2-5.6,3.3-8.9c0.1-3.7-1.1-7.4-3.6-10.3c-1-1.2-2.2-2.2-3.5-3l-0.6,0.9c1.2,0.8,2.3,1.7,3.2,2.8
						C153.2,245.2,154.3,248.6,154.2,252.1z"
                />
                <path
                  className="st16"
                  d="M137.7,265.3c-3.1-0.6-6-2.3-8.1-4.8c-0.9-1.1-1.7-2.4-2.3-3.7l0.5,0.2l0.5-0.9l-2.1-1.1l-1.1,2.1
						l0.9,0.5l0.3-0.6c0.3,0.7,0.6,1.3,1,2c0.5,0.8,1,1.5,1.5,2.2c2.2,2.7,5.3,4.5,8.7,5.1c3.3,0.7,6.8,0.1,9.8-1.4l-0.5-0.9
						C144,265.4,140.7,265.9,137.7,265.3z"
                />
                <path
                  className="st16"
                  d="M129.6,242.5c2.1-2.4,4.9-4,8.1-4.6c1.4-0.3,2.9-0.3,4.4-0.1l-0.4,0.3l0.6,0.9l2-1.3l-1.3-2l-0.9,0.6
						l0.3,0.5c-1.6-0.2-3.2-0.2-4.8,0.1c-3.4,0.6-6.5,2.4-8.7,5c-2.2,2.6-3.5,5.8-3.6,9.2l1.1,0.1
						C126.4,247.9,127.6,244.9,129.6,242.5z"
                />
              </g>
              <g id="error_28_">
                <circle className="st17" cx="155.3" cy="240.6" r="9" />
                <text
                  transform="matrix(1 0 0 1 148.6146 245.0845)"
                  className="st18 st3 st4"
                >
                  10
                </text>
              </g>
              <g id="Personell_28_">
                <g id="personellGreen_28_">
                  <circle className="st19" cx="140.6" cy="251.6" r="8.3" />
                  <path
                    className="st18"
                    d="M144.9,255.2c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C139.4,255.2,142.1,255.2,144.9,255.2z M141.8,247.8c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C141.8,247,141.8,247.4,141.8,247.8z M138.7,249.6c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C141.4,249.6,140.1,249.6,138.7,249.6z"
                  />
                </g>
                <g id="personellOrange_28_">
                  <path
                    className="st20"
                    d="M131.1,256l8.4-13c0.5-0.8,1.7-0.8,2.2,0l8.4,13c0.5,0.8-0.1,1.9-1.1,1.9h-16.8
							C131.1,257.9,130.5,256.8,131.1,256z"
                  />
                  <path
                    className="st18"
                    d="M144.9,256.1c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.4-0.7-0.6-1.1-0.8
							c-0.7-0.3-1.4-0.4-2.2-0.5c-1-0.1-2,0-2.9,0.3c-0.5,0.2-1,0.4-1.4,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0,0.7,0,1c0,0,0,0,0,0
							C139.4,256.1,142.1,256.1,144.9,256.1z M141.8,248.7c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3
							c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.4,0,0.9,0,1.3c0,0,0,0.1,0,0.1c-0.2,0-0.3,0-0.5,0
							c0-0.4,0-0.8,0-1.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.7,0.4-1,1-1,1.7c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
							c1.7,0,3.4,0,5.2,0c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.7-0.4-1.2-1-1.5c0,0-0.1,0-0.1-0.1
							C141.8,247.9,141.8,248.3,141.8,248.7z M138.7,250.5c0,0.8,0.4,1.4,1.1,1.8c0.8,0.4,1.5,0.3,2.2-0.2c0.5-0.4,0.8-0.9,0.8-1.6
							C141.4,250.5,140.1,250.5,138.7,250.5z"
                  />
                </g>
              </g>
            </g>
            <g id="lines_28_">
              <line className="st7" x1="15" y1="249.6" x2="24.9" y2="249.6" />
              <line className="st7" x1="38.9" y1="249.6" x2="46.9" y2="249.6" />
              <line
                className="st7"
                x1="60.4"
                y1="249.6"
                x2="101.7"
                y2="249.6"
              />
            </g>
            <g id="box_105_">
              <g id="BoxOn_12_">
                <path
                  className="st21"
                  d="M104.7,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C101.7,243.9,103,242.6,104.7,242.6z"
                />
                <path className="st7" d="M115.2,249.6h-13" />
              </g>
              <g id="boxOff_67_">
                <path
                  className="st8"
                  d="M104.7,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C101.7,243.9,103,242.6,104.7,242.6z"
                />
                <path className="st9" d="M108.7,253.4v-7.7" />
              </g>
            </g>
            <g id="box_104_">
              <g id="BoxOn_11_">
                <g>
                  <path
                    className="st6"
                    d="M49.4,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C46.4,243.9,47.7,242.6,49.4,242.6z"
                  />
                  <path
                    className="st7"
                    d="M49.4,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C46.4,243.9,47.7,242.6,49.4,242.6z"
                  />
                </g>
                <path className="st7" d="M59.9,249.6h-13" />
              </g>
              <g id="boxOff_66_">
                <path
                  className="st8"
                  d="M49.4,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C46.4,243.9,47.7,242.6,49.4,242.6z"
                />
                <path className="st9" d="M53.4,253.4v-7.7" />
              </g>
            </g>
            <g id="box_103_">
              <g id="BoxOn_10_">
                <g>
                  <path
                    className="st6"
                    d="M27.9,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C24.9,243.9,26.2,242.6,27.9,242.6z"
                  />
                  <path
                    className="st7"
                    d="M27.9,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
							C24.9,243.9,26.2,242.6,27.9,242.6z"
                  />
                </g>
                <path className="st7" d="M38.4,249.6h-13" />
              </g>
              <g id="boxOff_65_">
                <path
                  className="st8"
                  d="M27.9,242.6h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8
						C24.9,243.9,26.2,242.6,27.9,242.6z"
                />
                <path className="st9" d="M31.9,253.4v-7.7" />
              </g>
            </g>
          </g>
          <g id="s4Label_3_">
            <path
              id="background_65_"
              className="st1"
              d="M0,171.1c0-2.8,2.2-5,5-5h19c2.8,0,5,2.2,5,5v11c0,2.8-2.2,5-5,5H5c-2.8,0-5-2.2-5-5
				V171.1z"
            />
            <text
              id="name_37_"
              transform="matrix(1 0 0 1 7.9504 180.9498)"
              className="st2 st3 st4"
            >
              S1
            </text>
          </g>
          <g id="seksjonLinje_3_">
            <line
              id="linje_11_"
              className="st0"
              x1="14.5"
              y1="199.9"
              x2="14.5"
              y2="212.8"
            />

            <line
              id="linje_9_"
              className="st0"
              x1="14.5"
              y1="226.8"
              x2="14.5"
              y2="465"
            />
            <g id="box_101_">
              <g id="boxOn_56_">
                <g>
                  <path
                    className="st6"
                    d="M7.5,223.8v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8C8.8,226.8,7.5,225.5,7.5,223.8z"
                  />
                  <path
                    className="st7"
                    d="M7.5,223.8v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8C8.8,226.8,7.5,225.5,7.5,223.8z"
                  />
                </g>
                <path className="st7" d="M14.5,213.3v13" />
              </g>
              <g id="boxOff_64_">
                <path
                  className="st8"
                  d="M21.5,215.8v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8
						C20.2,212.8,21.5,214.2,21.5,215.8z"
                />
                <path className="st9" d="M10.7,219.8h7.7" />
              </g>
            </g>
            <g id="box_100_">
              <g id="boxOn_55_">
                <g>
                  <path
                    className="st6"
                    d="M7.5,371.3v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8C8.8,374.3,7.5,373,7.5,371.3z"
                  />
                  <path
                    className="st7"
                    d="M7.5,371.3v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8C8.8,374.3,7.5,373,7.5,371.3z"
                  />
                </g>
                <path className="st7" d="M14.5,360.8v13" />
              </g>
              <g id="boxOff_63_">
                <path
                  className="st8"
                  d="M21.5,363.3v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8
						C20.2,360.3,21.5,361.7,21.5,363.3z"
                />
                <path className="st9" d="M10.7,367.3h7.7" />
              </g>
            </g>
            <g id="box_99_">
              <g id="boxOn_54_">
                <g>
                  <path
                    className="st6"
                    d="M7.5,395v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8C8.8,398,7.5,396.7,7.5,395z"
                  />
                  <path
                    className="st7"
                    d="M7.5,395v-8c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v8c0,1.7-1.3,3-3,3h-8C8.8,398,7.5,396.7,7.5,395z"
                  />
                </g>
                <path className="st7" d="M14.5,384.5v13" />
              </g>
              <g id="boxOff_62_">
                <path
                  className="st8"
                  d="M21.5,387v8c0,1.7-1.3,3-3,3h-8c-1.7,0-3-1.3-3-3v-8c0-1.7,1.3-3,3-3h8C20.2,384,21.5,385.4,21.5,387z"
                />
                <path className="st9" d="M10.7,391h7.7" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
