import { useLocalDateTimeFormat } from "hooks/useDateTimeFormat";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

const About = styled.div`
  grid-row: 1/2;
  color: ${(props) => props.theme.colors.textColor.quaternary};
  font-size: ${(props) => props.theme.scaledFontSizes.small};
  font-family: ${(props) => props.theme.primaryFontFamily};
  font-weight: normal;
`;

const Comment = styled.div`
  display: flex;
  grid-row: 2/3;
  color: ${(props) => props.theme.colors.textColor.primary};
  font-size: ${(props) => props.theme.scaledFontSizes.small};
  font-family: ${(props) => props.theme.primaryFontFamily};
`;

type AlarmCommentSectionProps = {
  comment?: string;
  createdAt: string;
  createdBy?: string;
};

export const AlarmCommentSection: React.FC<AlarmCommentSectionProps> = (
  props
) => {
  const { formatMessage } = useIntl();
  const dateTimeLocal = useLocalDateTimeFormat();

  return (
    <div>
      <About>
        {dateTimeLocal.format(
          props.createdAt ? new Date(props.createdAt) : undefined
        )}{" "}
        {formatMessage({
          id: "alarms.AcknowledgedBy",
          defaultMessage: "By",
        })}{" "}
        {props.createdBy}
      </About>
      <Comment>{props.comment}</Comment>
    </div>
  );
};
