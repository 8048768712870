import React from "react";

interface IComponentProps {
  width: number;
  height: number;
  color?: string;
  style?: React.CSSProperties;
}

export const BodyAlert: React.FC<IComponentProps> = (props) => {
  return (
    <svg
      data-testid="bodyalert"
      width={props.width}
      height={props.height}
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8092 0.0454559C18.6312 0.0454559 18.4531 0.0454559 18.275 0.0454559H7.72496C7.5469 0.0454559 7.36884 0.0454559 7.19078 0.0454559C5.05405 0.227274 3.05088 1.36364 1.75994 3.18182C0.424484 5.09091 -0.0206669 7.54546 0.602545 9.81818L3.407 20.4545L3.45151 20.5455C5.05406 24.9091 6.56757 28 11.0191 28H15.0254C19.5215 28 20.9905 24.9091 22.593 20.5455L25.3975 9.86364C26.0207 7.59091 25.5755 5.18182 24.2401 3.22727C22.9491 1.36364 20.9459 0.227274 18.8092 0.0454559Z"
        fill="url(#paint0_radial)"
      />
      <path
        d="M10.841 5.77273L11.6423 15.3636C11.6868 15.9545 12.1765 16.4091 12.7107 16.4091C13.2894 16.4091 13.7345 15.9545 13.779 15.3636L14.5803 5.77273C14.6248 5.13637 14.1351 4.59091 13.5119 4.59091H11.9094C11.2862 4.59091 10.7965 5.09091 10.841 5.77273Z"
        fill="#FF762E"
      />
      <path
        d="M12.7107 22.6364C13.7432 22.6364 14.5803 21.7816 14.5803 20.7273C14.5803 19.6729 13.7432 18.8182 12.7107 18.8182C11.6781 18.8182 10.841 19.6729 10.841 20.7273C10.841 21.7816 11.6781 22.6364 12.7107 22.6364Z"
        fill="#FF762E"
      />
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13 11.8724) rotate(90) scale(16.1276 14.6247)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EBEBEB" />
        </radialGradient>
      </defs>
    </svg>
  );
};
