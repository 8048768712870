import InfoCard from "components/InfoCard";
import Wendy from "components/Wendy";
import { WendyHeadStyles, WendyBodyStyles } from "components/Wendy/Wendy";
import { useUTCDateTimeFormat } from "hooks/useDateTimeFormat";
import React from "react";
import { useIntl } from "react-intl";
import styled, { DefaultTheme } from "styled-components";

const ErrorTitleContainer = styled.div`
  display: flex;
  align-items: flex-end;

  & > p {
    margin-left: ${(props) => props.theme.spacing.medium};
  }
`;

const ErrorAlertMessage = styled.p`
  font-weight: 600;
`;

const ErrorLastQueriedTimeStamp = styled.p`
  margin-top: ${(props) => props.theme.spacing.medium};
`;

const ErrorTextContainer = styled.div`
  padding: ${(props) => props.theme.spacing.heavy};
`;

type InfoCardErrorViewProps = {
  theme: DefaultTheme;
  lastQueriedTimestamp: Date | undefined;
  error: string | null;
};

export const InfoCardErrorView: React.FC<InfoCardErrorViewProps> = (props) => {
  const { formatMessage } = useIntl();
  const dateTimeUTC = useUTCDateTimeFormat();
  return (
    <InfoCard
      style={{
        height: "100%",
        backgroundColor: props.theme.colors.errorCardBackgroundColor,
        color: props.theme.colors.textColor.primary,
      }}
      title={
        <ErrorTitleContainer>
          <Wendy
            HeadStyle={WendyHeadStyles.Sad}
            BodyStyle={WendyBodyStyles.Alert}
          />
          <p>
            {formatMessage({
              id: "error.SomethingWentWrong",
              defaultMessage: "Translation Not Found",
            })}
          </p>
        </ErrorTitleContainer>
      }
    >
      <ErrorTextContainer>
        <ErrorAlertMessage>
          {formatMessage({
            id: "error.DidNotReceiveInfo",
            defaultMessage: "Translation Not Found",
          })}
        </ErrorAlertMessage>
        {props.lastQueriedTimestamp && (
          <ErrorLastQueriedTimeStamp>
            <span>
              {formatMessage({
                id: "error.MissingDataFrom",
                defaultMessage: "Translation Not Found",
              })}{" "}
            </span>
            <span>{dateTimeUTC.format(props.lastQueriedTimestamp)}</span>
            <span>
              {" "}
              {formatMessage({
                id: "error.MissingDataWasNotAvailable",
                defaultMessage: "Translation Not Found",
              })}
            </span>
          </ErrorLastQueriedTimeStamp>
        )}

        {props.error && (
          <p>
            {formatMessage({
              id: "error.Reason",
              defaultMessage: "Translation Not Found",
            })}{" "}
            {props.error.toString()}
          </p>
        )}
      </ErrorTextContainer>
    </InfoCard>
  );
};
