import React from "react";

interface IComponentProps {
  width: number;
  height: number;
  color?: string;
  style?: React.CSSProperties;
}

export const BodyEmpty: React.FC<IComponentProps> = (props) => {
  return (
    <svg
      data-testid="bodyempty"
      width={props.width}
      height={props.height}
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8092 0.0454559C18.6311 0.0454559 18.4531 0.0454559 18.275 0.0454559H7.72495C7.54689 0.0454559 7.36882 0.0454559 7.19076 0.0454559C5.05404 0.227274 3.05086 1.36364 1.75992 3.18182C0.424469 5.09091 -0.0206821 7.54546 0.602529 9.81818L3.40698 20.4545L3.4515 20.5455C5.05404 24.9091 6.56755 28 11.0191 28H15.0254C19.5214 28 20.9904 24.9091 22.593 20.5455L25.3974 9.86364C26.0207 7.59091 25.5755 5.18182 24.24 3.22727C22.9491 1.36364 20.9459 0.227274 18.8092 0.0454559Z"
        fill="url(#paint0_radial)"
      />
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13 11.8724) rotate(90) scale(16.1276 14.6247)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EBEBEB" />
        </radialGradient>
      </defs>
    </svg>
  );
};
