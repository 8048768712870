import React from "react";

interface IComponentProps {
  width: number;
  height: number;
  color?: string;
  style?: React.CSSProperties;
}

export const HeadHappy: React.FC<IComponentProps> = (props) => {
  return (
    <svg
      data-testid="headhappy"
      width={props.width}
      height={props.width / 2}
      viewBox="0 0 28 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6343 0H6.36566C2.84897 0 0 2.90909 0 6.5C0 10.0909 2.84897 13 6.36566 13H21.6343C25.151 13 28 10.0909 28 6.5C28 2.90909 25.151 0 21.6343 0Z"
        fill="url(#paint0_radial)"
      />
      <path
        d="M9.57074 8.63636C9.08107 8.63636 8.68044 8.22727 8.68044 7.72727C8.68044 6.5909 7.74562 5.63636 6.63274 5.63636C5.51986 5.63636 4.58505 6.5909 4.58505 7.72727C4.58505 8.22727 4.18441 8.63636 3.69475 8.63636C3.20508 8.63636 2.80444 8.22727 2.80444 7.72727C2.80444 5.5909 4.54053 3.81818 6.63274 3.81818C8.72495 3.81818 10.461 5.5909 10.461 7.72727C10.461 8.22727 10.0604 8.63636 9.57074 8.63636Z"
        fill="#FF762E"
      />
      <path
        d="M24.2607 8.63636C23.7711 8.63636 23.3704 8.22727 23.3704 7.72727C23.3704 6.5909 22.4356 5.63636 21.3227 5.63636C20.2099 5.63636 19.275 6.5909 19.275 7.72727C19.275 8.22727 18.8744 8.63636 18.3847 8.63636C17.8951 8.63636 17.4944 8.22727 17.4944 7.72727C17.4944 5.5909 19.2305 3.81818 21.3227 3.81818C23.4149 3.81818 25.151 5.5909 25.151 7.72727C25.151 8.22727 24.7504 8.63636 24.2607 8.63636Z"
        fill="#FF762E"
      />
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14 5.5) rotate(90) scale(7.5 16.1538)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EBEBEB" />
        </radialGradient>
      </defs>
    </svg>
  );
};
