import React, { useEffect, useState } from "react";
import { useTagsContext } from "../../../../../hooks/useTags";
import styled from "styled-components";
import config from "../../../../../config";
import { useSensorSubscription } from "../../../../../hooks/useSubscription";
import { useIntl } from "react-intl";
import { useScadaPlusQuery } from "../../../../../hooks/useScadaPlusQuery";
import { useParams } from "react-router-dom";

const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr repeat(3, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 10px;
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
  color: ${(props) => props.theme.colors.textColor.primary};
  padding: ${(props) => props.theme.spacing.medium};
`;
const ListHeader = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.textColor.secondary};
`;
const ListElement = styled.div`
  color: ${(props) => props.theme.colors.textColor.secondary};
`;

const CurrentTemp = styled.div`
  grid-column: 2/3;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  padding-left: ${(props) => props.theme.spacing.medium};
  font-weight: 600;
`;
const AvgTempMinute = styled.div`
  grid-column: 3/4;
  grid-gap: 10px;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  padding-left: ${(props) => props.theme.spacing.medium};
`;
const AvgTempHour = styled.div`
  grid-column: 4/5;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  padding-left: ${(props) => props.theme.spacing.medium};
`;
const AvgTempDay = styled.div`
  grid-column: 5/6;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  padding-left: ${(props) => props.theme.spacing.medium};
`;

type Temperature = {
  objectNameId: string;
  averageTemperatureLast10Minutes: number | undefined;
  averageTemperatureLastHour: number | undefined;
  averageTemperatureLastDay: number | undefined;
  objectName: string;
};

type WindProductionParams = {
  plantId: string;
  unitId: string;
};
type ListProps = {
  onError: (timestamp: Date | undefined, error: string | undefined) => void;
};

const toTemperatureDisplay = (value: number | undefined) => {
  return `${value ? value.toFixed(2) : "-"} °C `;
};

export const TemperaturesList: React.FC<ListProps> = (props) => {
  const { unitId } = useParams<WindProductionParams>();
  const { tags } = useTagsContext();
  const { formatMessage } = useIntl();
  const [lastQueriedTimestamp, setLastQueriedTimeStamp] = useState<
    Date | undefined
  >(undefined);
  const [nacelle, setNacelle] = useState<number | undefined>(undefined);
  const [gearOil, setGearOil] = useState<number | undefined>(undefined);
  const [outdoor, setOutdoor] = useState<number | undefined>(undefined);
  const [rotor, setRotor] = useState<number | undefined>(undefined);
  const [stator, setStator] = useState<number | undefined>(undefined);

  const HeaderTitles: Array<string> = [
    "",
    formatMessage({ id: "pu.Current", defaultMessage: "Current" }),
    formatMessage({ id: "pu.10Minutes", defaultMessage: "10 Min" }),
    formatMessage({ id: "pu.1Hour", defaultMessage: "Current" }),
    formatMessage({ id: "pu.24Hours", defaultMessage: "Current" }),
  ];
  const { sensorData: realTimeTemps } = useSensorSubscription({
    filters: [
      {
        id: tags.WNAClntnTmp,
        windTurbineId: unitId,
      },
      {
        id: tags.WTRMGbxOilTmp,
        windTurbineId: unitId,
      },
      {
        id: tags.WMETEnvTmp,
        windTurbineId: unitId,
      },
      { id: tags.WTURRotTmp, windTurbineId: unitId },
      { id: tags.WTURSttTmp, windTurbineId: unitId },
    ],
  });

  const { data, isFetching } = useScadaPlusQuery<Array<Temperature>>(
    `temperatures-list-${unitId}`,
    `${config.api.url}/TemperatureAverages?WindTurbineId=${unitId}`,
    60 * 1000,
    (errorMessage) => {
      if (errorMessage.status) {
        props.onError(
          lastQueriedTimestamp,
          `Server response status: ${errorMessage.status} - ${errorMessage.text}`
        );
      } else {
        props.onError(lastQueriedTimestamp, errorMessage.text);
      }
    },
    () => {
      setLastQueriedTimeStamp(new Date());
    }
  );

  useEffect(() => {
    switch (realTimeTemps?.id) {
      case tags.WNAClntnTmp:
        setNacelle(Number(realTimeTemps.value));
        break;
      case tags.WTRMGbxOilTmp:
        setGearOil(Number(realTimeTemps.value));
        break;
      case tags.WMETEnvTmp:
        setOutdoor(Number(realTimeTemps.value));
        break;
      case tags.WTURRotTmp:
        setRotor(Number(realTimeTemps.value));
        break;
      case tags.WTURSttTmp:
        setStator(Number(realTimeTemps.value));
        break;

      default:
        break;
    }
  }, [
    realTimeTemps,
    tags.WNAClntnTmp,
    tags.WTURRotTmp,
    tags.WTURSttTmp,
    tags.WMETEnvTmp,
    tags.WTRMGbxOilTmp,
  ]);

  const realTimeData = (props: string) => {
    switch (props) {
      case tags.WTRMGbxOilTmp:
        return (
          <CurrentTemp key={tags.WTRMGbxOilTmp}>
            {toTemperatureDisplay(gearOil)}
          </CurrentTemp>
        );

      case tags.WMETEnvTmp:
        return (
          <CurrentTemp key={tags.WMETEnvTmp}>
            {toTemperatureDisplay(outdoor)}
          </CurrentTemp>
        );

      case tags.WTURRotTmp:
        return (
          <CurrentTemp key={tags.WTURRotTmp}>
            {toTemperatureDisplay(rotor)}
          </CurrentTemp>
        );

      case tags.WTURSttTmp:
        return (
          <CurrentTemp key={tags.WTURSttTmp}>
            {toTemperatureDisplay(stator)}
          </CurrentTemp>
        );

      case tags.WNAClntnTmp:
        return (
          <CurrentTemp key={tags.WNAClntnTmp}>
            {toTemperatureDisplay(nacelle)}
          </CurrentTemp>
        );

      default:
    }
  };

  return (
    <Container>
      {HeaderTitles.map((item) => (
        <ListHeader key={item}>{item}</ListHeader>
      ))}

      {!isFetching &&
        data &&
        data.map((temperatureEntries) => (
          <React.Fragment key={`parent_${temperatureEntries.objectNameId}`}>
            <ListElement key={temperatureEntries.objectName}>
              {formatMessage({
                id: `unit.Temperature.${temperatureEntries.objectName}`,
              })}
            </ListElement>
            {realTimeData(temperatureEntries.objectNameId)}

            <AvgTempMinute key={`avg_${temperatureEntries.objectNameId}`}>
              {toTemperatureDisplay(
                temperatureEntries.averageTemperatureLast10Minutes
              )}
            </AvgTempMinute>
            <AvgTempHour key={`hour_${temperatureEntries.objectNameId}`}>
              {toTemperatureDisplay(
                temperatureEntries.averageTemperatureLastHour
              )}
            </AvgTempHour>
            <AvgTempDay key={`day_${temperatureEntries.objectNameId}`}>
              {toTemperatureDisplay(
                temperatureEntries.averageTemperatureLastDay
              )}
            </AvgTempDay>
          </React.Fragment>
        ))}
    </Container>
  );
};
