import React from "react";
import { useTheme } from "styled-components";
import { EventGraphBarsProps } from "../interfaces";

export const EventGraphBars: React.FC<EventGraphBarsProps> = (props) => {
  const theme = useTheme();
  // toDo: clean up the height line once the scalebug is fixed
  return (
    <>
      {props.data.map(({ value, label }) => {
        return (
          <rect
            key={`bar-${label}`}
            x={props.scaleX(label)}
            y={props.scaleY(value)}
            width={props.scaleX.bandwidth()}
            height={props.height - (props.scaleY(value) || 0)}
            fill={theme.colors.cardBackgroundHover}
          />
        );
      })}
    </>
  );
};
