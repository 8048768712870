import React from "react";
import { MdComment, MdFlag } from "react-icons/md";
import styled, { DefaultTheme } from "styled-components";
import FormattedTimestamp from "components/FormattedTimestamp";
import AlarmPriorityDisplayTile from "components/AlarmPriorityDisplay";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import { GenericAlarm } from "types";
import {
  resolveAlarmBackgroundColor,
  resolveHoverColor,
  resolveAlarmDescriptionFontColor,
  resolveTimestampColor,
} from "utils/alarm-utils";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props: AlarmStyleProps) =>
    resolveAlarmDescriptionFontColor(
      props.genericAlarm,
      props.acknowledged,
      props.theme
    )};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  background-color: ${(props: AlarmStyleProps) =>
    resolveAlarmBackgroundColor(
      props.genericAlarm,
      props.theme,
      props.acknowledged
    )};

  &:hover {
    cursor: ${(props: AlarmStyleProps) =>
      props.light ? "default" : "pointer"};
    background-color: ${(props: AlarmStyleProps) =>
      resolveHoverColor(
        props.genericAlarm,
        props.theme,
        props.acknowledged,
        props.disableHover
      )};
  }
`;

const AlarmContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.2rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin-right: ${(props) => props.theme.spacing.medium};
  }
`;

const Timestamp = styled.div`
  font-size: ${(props) => props.theme.scaledFontSizes.small};
  color: ${(props: AlarmStyleProps) =>
    resolveTimestampColor(props.genericAlarm, props.acknowledged, props.theme)};
`;

const AlarmText = styled.div`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 15.625rem;
  word-break: break-all;
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
`;

const StyledFlag = styled(MdFlag)`
  width: 24px;
  height: 24px;
  color: ${(props) => props.theme.colors.signal};
`;

interface AlarmProps {
  alarm: GenericAlarm;
  notClickable?: boolean;
  light?: boolean;
  alarmList?: boolean;
  onClick?: () => void;
}

interface AlarmStyleProps {
  genericAlarm: GenericAlarm;
  theme: DefaultTheme;
  acknowledged: boolean;
  timestampColor?: string;
  light?: boolean;
  disableHover?: boolean;
}

export const Alarm: React.FC<AlarmProps> = (props) => {
  const { theme } = useScadaPlusTheme();

  return (
    <Container
      genericAlarm={props.alarm}
      acknowledged={props.alarm.isAcknowledged}
      onClick={props.notClickable ? undefined : props.onClick}
      disableHover={props.notClickable}
      light={props.light}
    >
      <AlarmContainer>
        <HeaderContainer>
          <AlarmPriorityDisplayTile
            alarmPriortity={props.alarm.priority}
            acknowledged={
              props.alarm.isAcknowledged
                ? props.alarm.isAcknowledged
                : undefined
            }
            height="1.3rem"
            width={theme.spacing.extraHeavy}
            listItem
          >
            {props.alarm.priority}
          </AlarmPriorityDisplayTile>
          {!props.light && (
            <>
              {props.alarm.comments.length > 0 && <MdComment />}
              {props.alarm.isFlagged && <StyledFlag />}
            </>
          )}
          <Timestamp
            genericAlarm={props.alarm}
            acknowledged={props.alarm.isAcknowledged}
          >
            <FormattedTimestamp
              timestamp={props.alarm.timestamp}
            ></FormattedTimestamp>
          </Timestamp>
        </HeaderContainer>
        <AlarmText>{props.alarm.description}</AlarmText>
      </AlarmContainer>
    </Container>
  );
};
